import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Table, Space, message } from "antd";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import UCCLiensModel from "./UCCLiensModel";
import {
  createUccLiens,
  deleteUccLiens,
  getUccLiens,
  updateUccLiens,
} from "../../../../../services/adminForm.services";
import { useQuery } from "../../../../../helpers/utility";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const UCCLiens = ({ selectedOwner }) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [data, setData] = useState([]);

  const query = useQuery();
  const applicationId = query.get("id");

  const columns = [
    {
      title: "Debtor",
      dataIndex: "debtor",
      key: "debtor",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Secured Party",
      dataIndex: "secured_party",
      key: "secured_party",
      ellipsis: true,
      width: 100,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Collateral",
      dataIndex: "collateral",
      key: "collateral",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Filing No",
      dataIndex: "filing_no",
      key: "filing_no",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Filing Date",
      dataIndex: "filing_date",
      key: "filing_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  useEffect(() => {
    fetchUccLiens();
  }, [selectedOwner]);

  function fetchUccLiens(id = applicationId) {
    getUccLiens({
      applicationId: id,
      ownerId: selectedOwner,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("get ucc liens error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("get ucc liens error");
        }
      });
  }

  function saveUccLiens(values) {
    let data = values;
    data.filing_date = moment(values.filing_date).format(dateFormat);
    data.application = applicationId;
    data.ownership = selectedOwner;

    createUccLiens(data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Saved UCC Liens");
        fetchUccLiens(applicationId);
      })
      .catch((error) => {
        console.error("save ucc liens error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to save UCC Liens"
          );
        } else {
          message.error("save ucc lines error");
        }
      });
  }

  function handleUpdateUccLiens(values) {
    let data = values;
    data.filing_date = moment(values.filing_date).format(dateFormat);
    data.application = applicationId;

    updateUccLiens(editTable.id, data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Updated UCC Liens");
        fetchUccLiens(applicationId);
      })
      .catch((error) => {
        console.error("save ucc liens error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to update UCC Liens"
          );
        } else {
          message.error("save ucc liens error");
        }
      });
  }

  function removeColumn(id) {
    deleteUccLiens(id)
      .then((res) => {
        message.success(res.data.message || "Removed UCC Liens");
        fetchUccLiens(applicationId);
      })
      .catch((error) => {
        console.error("delete ucc liens error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to remove UCC Liens"
          );
        } else {
          message.error("delete ucc liens error");
        }
      });
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Button
            onClick={() => openModal({ status: "create" })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Details
          </Button>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <UCCLiensModel
          create={saveUccLiens}
          update={handleUpdateUccLiens}
          setVisible={setVisible}
          title="UCCLiens"
          editTable={editTable}
        />
      )}
    </Fragment>
  );
};

export default UCCLiens;
