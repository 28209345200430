import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const UCCLiensModel = ({ create, update, title, editTable, setVisible }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      let formValues = editTable;
      formValues.filing_date = moment(editTable.filing_date);
      form.setFieldsValue(formValues);
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    // refresh();
  };

  function onFinish(values) {
    if (editTable.status === "update") {
      update(values);
    } else {
      create(values);
    }
  }

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="debtor"
            label="Debtor"
            rules={[
              {
                required: true,
                message: "Please input your debtor!",
              },
            ]}
          >
            <Input placeholder="Debtor" />
          </Form.Item>
          <Form.Item
            name="secured_party"
            label="Secured Party"
            rules={[
              {
                required: true,
                message: "Please input your  secured party!",
              },
            ]}
          >
            <Input placeholder="Secured Party" />
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: "Please input your state!",
              },
            ]}
          >
            <Input placeholder="State" />
          </Form.Item>
          <Form.Item
            name="collateral"
            label="Collateral"
            rules={[
              {
                required: true,
                message: "Please input collateral!",
              },
            ]}
          >
            <Input placeholder="Collateral" />
          </Form.Item>
          <Form.Item
            name="filing_no"
            label="Filing No"
            rules={[
              {
                required: true,
                message: "Please input your filing no!",
              },
            ]}
          >
            <Input placeholder="Filing No" />
          </Form.Item>
          <Form.Item
            name="filing_date"
            label="Filing Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              format={dateFormat}
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};
export default UCCLiensModel;
