import React, { useContext, useState } from "react";
import ToolbarHOC from "../../components/Toolbar/ToolbarHOC";
import MobileNavigationItems from "../../components/Toolbar/NavigationItems/MobileNavigationItems";
import { Layout } from "antd";
import "./DefaultLayout.css";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import AdminToolbar from "../../components/Admin/Toolbar/AdminToolbar";
import AdminMobileNavigationItems from "../../components/Admin/Toolbar/AdminNavigationItems/AdminMobileNavigationItems";
import { isAdmin } from "../../helpers/utility";
const { Header, Content, Footer } = Layout;

function DefaultLayout({ children }) {
  return (
    <Layout className="layout">
      <Header className="layout-header">
        {isAdmin() ? <AdminToolbar /> : <ToolbarHOC />}
      </Header>
      <Content className="layout-body">{children}</Content>
      {isMobileOnly ? (
        isAdmin() ? (
          <AdminMobileNavigationItems />
        ) : (
          <MobileNavigationItems />
        )
      ) : (
        <Footer className="footer">
          Copyright © 2021 i4Strategies. All Rights Reserved |{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>
        </Footer>
      )}
    </Layout>
  );
}

export default DefaultLayout;
