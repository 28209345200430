import React, { Fragment } from "react";
import OwnerLiens from "./OwnerLiens";
import JudgementsLiens from "./JudgementsLiens";
import BankruptcyLiens from "./BankruptcyLiens";

const Liens = ({ selectedOwner }) => {
  return (
    <Fragment>
      <OwnerLiens selectedOwner={selectedOwner} />
      <br />
      <JudgementsLiens selectedOwner={selectedOwner} />
      <br />
      <BankruptcyLiens selectedOwner={selectedOwner} />
      <br />
    </Fragment>
  );
};

export default Liens;
