import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const LiensModel = ({ create, update, title, editTable, setVisible }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      let formValues = editTable;
      formValues.filling_date = moment(editTable.filling_date);
      formValues.release_date = moment(editTable.release_date);
      form.setFieldsValue(formValues);
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    // refresh();
  };

  function onFinish(values) {
    if (editTable.status === "update") {
      update(values);
    } else {
      create(values);
    }
  }

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="creditor"
            label="Creditor"
            rules={[
              {
                required: true,
                message: "Please input creditor!",
              },
            ]}
          >
            <Input placeholder="Creditor" />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please input amount!",
              },
            ]}
          >
            <Input placeholder="Amount" type="number" />
          </Form.Item>
          <Form.Item
            name="county"
            label="County"
            rules={[
              {
                required: true,
                message: "Please input county!",
              },
            ]}
          >
            <Input placeholder="County" />
          </Form.Item>
          <Form.Item
            name="property"
            label="Property"
            rules={[
              {
                required: true,
                message: "Please input property!",
              },
            ]}
          >
            <Input placeholder="Property" />
          </Form.Item>
          <Form.Item
            name="filing_no"
            label="Filing Number"
            rules={[
              {
                required: true,
                message: "Please input filing number!",
              },
            ]}
          >
            <Input placeholder="Filing No." />
          </Form.Item>
          <Form.Item
            name="filling_date"
            label="Filing Date"
            rules={[
              {
                required: true,
                message: "Please input filing date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              format={dateFormat}
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item
            name="release_date"
            label="Release Date"
            rules={[
              {
                required: true,
                message: "Please input release date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              format={dateFormat}
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};

export default LiensModel;
