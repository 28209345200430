import React, { useEffect, useContext, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  InputNumber,
  Typography,
  Collapse,
  Row,
  Col,
  Upload,
  message,
} from "antd";
import {
  midLabelLayout,
  tailLayout,
} from "../../../../config/formLayout.config";
import {
  getPrevious3Yrs,
  getTokenIfNotExpired,
} from "../../../../helpers/utility";
import {
  getFinancialReports,
  createFinancialReports,
} from "../../../../services/vendorProfile.services";
import { UploadOutlined } from "@ant-design/icons";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import {
  profileCompletion,
  uploadFileUrl,
} from "../../../../constants/defaultKeys";
import moment from "moment";
import {
  bradStreetValidation,
  grossRevenueValidation,
} from "../../../../config/validation.config";

const { Paragraph } = Typography;
const { Panel } = Collapse;
const dateFormat = "YYYY";

const uploadButtonProps = {
  accept: ".doc,.docx, .pdf",
  maxCount: 1,
  action: uploadFileUrl,
  name: "docs",
  onChange(info) {
    // if headers are set as default then "antd" is setting the header before loggin into the application,
    // so setting headers when file change is triggered
    uploadButtonProps["headers"] = {
      Authorization: `Bearer ${getTokenIfNotExpired()}`,
    };
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const FinancialReports = (props) => {
  const vendorId = props.vendorId;
  const [form] = Form.useForm();
  const prev3yrs = getPrevious3Yrs();
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );
  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );

  const [disabled, setDisabled] = useState(false);

  const financialReportsData = vendorProfileFormData.financial_reports;

  useEffect(() => {
    vendorId &&
      getFinancialReports(vendorId)
        .then((response) => {
          const data = response.data.data;
          if (Object.keys(data).length === 0) {
            let formValues = {
              ...financialReportsData,
              prev_1yr: moment().subtract(1, "year"),
              prev_2yr: moment().subtract(2, "year"),
              prev_3yr: moment().subtract(3, "year"),
              financial_statement:
                financialReportsData.financial_statement_file_list[0]?.response
                  ?.names[0] || null,
              contracts_in_process:
                financialReportsData.contracts_in_process_file_list[0]?.response
                  ?.names[0] || null,
              tax_return:
                financialReportsData.tax_return_file_list[0]?.response
                  ?.names[0] || null,
              tax_payer_certificate:
                financialReportsData.tax_payer_certificate_file_list[0]
                  ?.response?.names[0] || null,
            };
            setFormFieldValues(formValues);
          } else {
            let fileList = {
              financial_statement_file_list: data.financial_statement
                ? [
                    {
                      uid: 0,
                      name:
                        financialReportsData.financial_statement_file_list[0]
                          ?.response?.names[0] ||
                        financialReportsData.financial_statement_file_list[0]
                          ?.name ||
                        data.financial_statement,
                      status: "done",
                    },
                  ]
                : [],
              contracts_in_process_file_list: data.contracts_in_process
                ? [
                    {
                      uid: 1,
                      name:
                        financialReportsData.contracts_in_process_file_list[0]
                          ?.response?.names[0] ||
                        financialReportsData.contracts_in_process_file_list[0]
                          ?.name ||
                        data.contracts_in_process,
                      status: "done",
                    },
                  ]
                : [],
              tax_return_file_list: data.tax_return
                ? [
                    {
                      uid: 2,
                      name:
                        financialReportsData.tax_return_file_list[0]?.response
                          ?.names[0] ||
                        financialReportsData.tax_return_file_list[0]?.name ||
                        data.tax_return,
                      status: "done",
                    },
                  ]
                : [],
              tax_payer_certificate_file_list: data.tax_payer_certificate
                ? [
                    {
                      uid: 3,
                      name:
                        financialReportsData.tax_payer_certificate_file_list[0]
                          ?.response?.names[0] ||
                        financialReportsData.tax_payer_certificate_file_list[0]
                          ?.name ||
                        data.tax_payer_certificate,
                      status: "done",
                    },
                  ]
                : [],
            };

            let formValues = {
              ...data,
              ...financialReportsData,
              prev_1yr: moment().subtract(1, "year"),
              prev_2yr: moment().subtract(2, "year"),
              prev_3yr: moment().subtract(3, "year"),
              bradstreet_number: data.bradstreet_number,
              prev_1yr_revenue: data.prev_1yr_revenue,
              prev_2yr_revenue: data.prev_2yr_revenue,
              prev_3yr_revenue: data.prev_3yr_revenue,
              tax_payer_certificate: data.tax_payer_certificate,
              tax_return: data.tax_return,
              contracts_in_process: data.contracts_in_process,
              financial_statement: data.financial_statement,
            };
            setFormFieldValues(formValues, fileList);
          }
        })
        .catch((error) => console.error(error));
  }, [vendorId]);

  function setFormFieldValues(data, fileList = financialReportsData) {
    setVendorProfileDataFinancial({ ...data, ...fileList });
    form.setFieldsValue(data);
  }

  function setVendorProfileDataFinancial(changedValues) {
    vendorProfileFormData.financial_reports = {
      ...financialReportsData,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function onFormValuesChange(changedValues) {
    let key = Object.keys(changedValues)[0];
    if (
      key !== "financial_statement" &&
      key !== "contracts_in_process" &&
      key !== "tax_return" &&
      key !== "tax_payer_certificate"
    ) {
      setVendorProfileDataFinancial(form.getFieldsValue());
    }
  }

  const normFile = (e, field) => {
    vendorProfileFormData.financial_reports[field] = e.fileList;
    setVendorProfileFormData({ ...vendorProfileFormData });
    if (e && e.fileList && e.fileList.length > 0 && e.fileList[0].response) {
      return e?.fileList[0]?.response?.names[0];
    }
  };

  function onFinish(values) {
    let formData = {
      ...values,
      vendor_id: vendorId,
      prev_1yr: values["prev_1yr"].format(dateFormat),
      prev_2yr: values["prev_2yr"].format(dateFormat),
      prev_3yr: values["prev_3yr"].format(dateFormat),
    };
    setDisabled(true);

    createFinancialReports(formData)
      .then((response) => {
        setDisabled(false);
        if (!vendorDetails.financial_status) {
          setVendorDetails({
            ...vendorDetails,
            financial_status: true,
            profile_completion_status:
              vendorDetails.profile_completion_status +
              profileCompletion.financial_status,
          });
        }
        message.success("Financial Reports added successfully");
        props.onStepChange(props.currentStep + 1);
      })
      .catch((error) => {
        setDisabled(false);
        console.error(error);
        if (error && error.response) {
          if (error.response.status === 400) {
            message.error(
              "Something went wrong with the form being saved. Please contact administrator"
            );
          } else {
            message.error(
              error.response.data.message || error.response.message
            );
          }
        } else {
          message.error("Error creating Financial Reports");
        }
      });
  }

  const get3YrFormItems = (
    <Form.Item label="State the firm's gross revenues for the past three years">
      {prev3yrs.map((eachYear, index) => (
        <Row gutter={[8, 0]} key={index}>
          <Col span={12}>
            {index == 0 && <Paragraph className="header-label">Year</Paragraph>}
            <Form.Item
              name={`prev_${index + 1}yr`}
              className="formitem-no-margin"
            >
              <DatePicker picker="year" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            {index == 0 && (
              <Paragraph className="header-label mandatory-field-mark">
                Gross Revenue
              </Paragraph>
            )}
            <Form.Item
              className="formitem-no-margin"
              name={`prev_${index + 1}yr_revenue`}
              rules={grossRevenueValidation}
            >
              <InputNumber
                min={0}
                style={{ width: 150 }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder="Revenue"
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
    </Form.Item>
  );

  return (
    <Form
      name="financial_reports"
      form={form}
      layout="horizontal"
      {...midLabelLayout}
      colon={false}
      onFinish={onFinish}
      onValuesChange={onFormValuesChange}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Current Financial Status" key="1">
          <Form.Item
            name="bradstreet_number"
            label="Dun & Bradstreet Number"
            rules={bradStreetValidation}
          >
            <Input placeholder="" />
          </Form.Item>
          {get3YrFormItems}
          <Form.Item
            name="financial_statement"
            className="multiline-form-item"
            label="Applicant firm’s (or parent firm’s) most recent audited financial statement"
            rules={[
              {
                required: true,
              },
            ]}
            getValueFromEvent={(e) =>
              normFile(e, "financial_statement_file_list")
            }
          >
            <Upload
              {...uploadButtonProps}
              fileList={financialReportsData.financial_statement_file_list}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="contracts_in_process"
            className="multiline-form-item"
            label="Contracts in process and projects under contract but not yet started, 
                including name of owner, architect, construction manager and general contractor 
                (as relevant), value of contract, percent complete and expected completion date"
            rules={[
              {
                required: true,
              },
            ]}
            getValueFromEvent={(e) =>
              normFile(e, "contracts_in_process_file_list")
            }
          >
            <Upload
              {...uploadButtonProps}
              fileList={financialReportsData.contracts_in_process_file_list}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="tax_return"
            className="multiline-form-item"
            label="Your most recent tax return"
            rules={[
              {
                required: true,
              },
            ]}
            getValueFromEvent={(e) => normFile(e, "tax_return_file_list")}
          >
            <Upload
              {...uploadButtonProps}
              fileList={financialReportsData.tax_return_file_list}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="tax_payer_certificate"
            className="multiline-form-item"
            label={
              <span>
                Upload your Taxpayer Certification below. It can be either of{" "}
                <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">W-9 form</a>{" "}
                or{" "}
                <a href="https://www.irs.gov/pub/irs-pdf/fw8bene.pdf">
                  W-8BEN-E form
                </a>
              </span>
            }
            rules={[
              {
                required: true,
              },
            ]}
            getValueFromEvent={(e) =>
              normFile(e, "tax_payer_certificate_file_list")
            }
          >
            <Upload
              {...uploadButtonProps}
              fileList={financialReportsData.tax_payer_certificate_file_list}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FinancialReports;
