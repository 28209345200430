import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Space,
  Collapse,
  message,
  Form,
  DatePicker,
  Input,
  Checkbox,
} from "antd";
import { confirmModal } from "../../../../reusable/ModalWizz";
import MWBEModel from "./MWBEModel";
import {
  createDetails,
  createMWBE,
  deleteMWBE,
  getApplicationNoRecords,
  getDetails,
  getMWBE,
  updateApplicationNoRecords,
  updateMWBE,
} from "../../../../services/adminForm.services";
import { useQuery } from "../../../../helpers/utility";
import moment from "moment";
import {
  narrowLabelLayout,
  tailLayout,
} from "../../../../config/formLayout.config";

const { Panel } = Collapse;
const dateFormat = "YYYY-MM-DD";

const MWBE = () => {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [data, setData] = useState([]);
  const [appNoRcd, setAppNoRcdData] = useState(null);
  const [checkBox, setCheckBox] = useState({
    no_mwbe_records: appNoRcd?.no_mwbe_records,
  });
  const [checked, setChecked] = useState({
    key: [],
  });

  const query = useQuery();
  const applicationId = query.get("id");

  const columns = [
    {
      title: "Certifying Agency",
      dataIndex: "certifying_agency",
      key: "certifying_agency",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Certification Type",
      dataIndex: "certification_type",
      key: "certification_type",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Certification Date",
      dataIndex: "certification_date",
      key: "certification_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  useEffect(() => {
    fetchMWBE();
    fetchDetails();
    getApplicationNoRecordsApi();
  }, []);

  async function getApplicationNoRecordsApi() {
    try {
      const res = await getApplicationNoRecords(applicationId);
      setAppNoRcdData(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function updateApplicationApi(values) {
    try {
      const res = await updateApplicationNoRecords(applicationId, {
        no_mwbe_records: values?.no_mwbe_records,
      });
      message.success(res.data?.message);
    } catch (err) {
      console.log(err);
    } finally {
      getApplicationNoRecordsApi();
    }
  }

  function fetchDetails(id = applicationId) {
    getDetails(id)
      .then((res) => {
        let data = res.data?.[0];
        if (data) {
          data["DOI"] = data["DOI"] ? moment(data["DOI"]) : null;
          data.cf_doi = data.cf_doi ? moment(data.cf_doi) : null;
          form.setFieldsValue(data);
        }
      })
      .catch((error) => {
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Error fetching data, please contact administrator"
          );
        } else {
          message.error("Error fetching data, please contact administrator");
        }
      });
  }

  function fetchMWBE(id = applicationId) {
    getMWBE(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("get MWBE error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went wrong"
          );
        } else {
          message.error("get MWBE error");
        }
      });
  }

  function saveMWBE(values) {
    let data = { ...values, DOI: null };

    data.certification_date = moment(values.certification_date).format(
      dateFormat
    );
    data.application = applicationId;

    createMWBE(data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Saved MWBE Details");
        fetchMWBE(applicationId);
      })
      .catch((error) => {
        console.error("save mwbe error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to save MWBE details"
          );
        } else {
          message.error("save mwbe error");
        }
      });
  }

  function handleUpdateMWBE(values) {
    let data = values;
    data.certification_date = moment(values.certification_date).format(
      dateFormat
    );
    data.application = applicationId;

    updateMWBE(editTable.id, data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Updated MWBE Details");
        fetchMWBE(applicationId);
      })
      .catch((error) => {
        console.error("save MWBE error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to Update MWBE Details"
          );
        } else {
          message.error("save MWBE error");
        }
      });
  }

  function removeColumn(id) {
    deleteMWBE(id)
      .then((res) => {
        message.success(res.data.message || "Deleted MWBE Details");
        fetchMWBE(applicationId);
      })
      .catch((error) => {
        console.error("delete MWBE error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to delete MWBE details"
          );
        } else {
          message.error("delete MWBE error");
        }
      });
  }

  function renderCorporateInfo() {
    return (
      <Fragment>
        <Form.Item
          name="SIC_codes"
          label="SIC Codes"
          rules={[
            {
              required: true,
              message: "Please input sic codes!",
            },
          ]}
        >
          <Input placeholder="Enter comma separated values" />
        </Form.Item>

        {/* <Form.Item
          name="DOI"
          label="Date Incorporated/Formed"
          rules={[
            {
              required: true,
              message: "Please input date!",
            },
          ]}
        >
          <DatePicker
            format={dateFormat}
            allowClear={false}
            placeholder="Date"
            size="large"
            style={{ minWidth: "280px" }}
          />
        </Form.Item> */}
      </Fragment>
    );
  }

  function renderCorporateFiling() {
    return (
      <Fragment>
        <Form.Item
          name="cf_entity_name"
          label="Entity Name"
          rules={[
            {
              required: true,
              message: "Please input entity name!",
            },
          ]}
        >
          <Input placeholder="Entity Name" />
        </Form.Item>
        <Form.Item
          name="cf_cn"
          label="Company Number"
          rules={[
            {
              required: true,
              message: "Please input company number!",
            },
          ]}
        >
          <Input placeholder="Company Number" />
        </Form.Item>
        <Form.Item
          name="cf_status"
          label="Status"
          rules={[
            {
              required: true,
              message: "Please input status!",
            },
          ]}
        >
          <Input placeholder="Status" />
        </Form.Item>

        <Form.Item
          name="cf_doi"
          label="Incorporation Date"
          rules={[
            {
              required: true,
              message: "Please incorporation date!",
            },
          ]}
        >
          <DatePicker
            format={dateFormat}
            allowClear={false}
            placeholder="Date"
            size="large"
            style={{ minWidth: "280px" }}
          />
        </Form.Item>
        <Form.Item
          name="cf_com_type"
          label="Company Type"
          rules={[
            {
              required: true,
              message: "Please input company type!",
            },
          ]}
        >
          <Input placeholder="Company Type" />
        </Form.Item>
        <Form.Item
          name="cf_jurisdiction"
          label="Jurisdiction"
          rules={[
            {
              required: true,
              message: "Please input jurisdiction!",
            },
          ]}
        >
          <Input placeholder="Jurisdiction" />
        </Form.Item>
      </Fragment>
    );
  }

  function saveMWBEForm(values) {
    let data = values;
    data["SIC_codes"] = values["SIC_codes"]?.trim()?.split(",");
    data["DOI"] = moment(values["DOI"]).format(dateFormat);
    data.cf_doi = moment(values.cf_doi).format(dateFormat);
    data.application = applicationId;

    createDetails(data)
      .then((res) => {
        fetchDetails(applicationId);
        message.success("Successfully saved MWBE details");
      })
      .catch((error) => {
        console.error("create MWBE details error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("create MWBE details error");
        }
      });
  }

  return (
    <Fragment>
      <Form
        {...narrowLabelLayout}
        name="safety"
        form={form}
        className="form-safety"
        onFinish={saveMWBEForm}
        colon={false}
        scrollToFirstError
      >
        <Collapse
          activeKey={
            checkBox?.no_mwbe_records
              ? checked?.key
              : // : checkBox?.no_mwbe_records && checked?.key?.includes("1")
                // ? checked?.key
                // : !checkBox?.no_mwbe_records && checked?.key?.includes("1")
                // ? checked?.key
                ["1", "2", "3"]
          }
          onChange={(e) => {
            setChecked({ key: e });
          }}
        >
          <Panel
            header="MWBE Status"
            key="1"
            extra={
              <Checkbox
                checked={appNoRcd?.no_mwbe_records}
                onChange={(e) => {
                  setCheckBox({
                    ...checkBox,
                    no_mwbe_records: e.target.checked,
                  });
                  updateApplicationApi({ no_mwbe_records: e.target.checked });
                }}
              >
                No Information Identified
              </Checkbox>
            }
            collapsible={appNoRcd?.no_mwbe_records ? "disabled" : ""}
          >
            <div
              style={
                appNoRcd?.no_mwbe_records
                  ? {
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              <Row
                style={
                  appNoRcd?.no_mwbe_records ? { pointerEvents: "none" } : {}
                }
              >
                <Col>
                  <Button
                    onClick={() => openModal({ status: "create" })}
                    type="secondary"
                    style={{
                      marginBottom: 16,
                    }}
                    disabled={appNoRcd?.no_mwbe_records}
                  >
                    Add Details
                  </Button>
                </Col>
                <Col span={24}>
                  <Table
                    size="small"
                    columns={columns}
                    pagination={false}
                    dataSource={data}
                  />
                </Col>
              </Row>
            </div>
          </Panel>

          <Panel header="Corporate Information" key="2">
            {renderCorporateInfo()}
          </Panel>
          <Panel header="Corporate filing" key="3">
            {renderCorporateFiling()}
          </Panel>
        </Collapse>
        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="form-submit-btn"
            // loading={disabled}
          >
            SAVE
          </Button>
        </Form.Item>
      </Form>
      {visible && (
        <MWBEModel
          create={saveMWBE}
          setVisible={setVisible}
          update={handleUpdateMWBE}
          title="MWBE Status"
          editTable={editTable}
        />
      )}
    </Fragment>
  );
};

export default MWBE;
