import React, { Fragment, useState } from "react";
import { Table, Button, Row, Col, message, Space } from "antd";
import BankLinesModal from "./BankLinesModal";
import { removeBankLiens } from "../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../reusable/ModalWizz";
import { BankLiensColumns } from "../../../PQQ/tableColumns";

const BankLiens = ({ vendorId, bankLiensData, fetchBankLiens }) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  const columns = [
    ...BankLiensColumns,
    {
      title: "Action",
      key: "action",
      ellipsis: true,
      width: 100,
      fixed: "right",
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const refreshPage = () => {
    fetchBankLiens();
  };

  const openModal = (data) => {
    setEditTable(data);
    setVisible(true);
  };

  const removeColumn = (id) => {
    removeBankLiens({
      id: id,
      vendor_id: vendorId,
    })
      .then((response) => {
        refreshPage();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col span={20} offset={2}>
          <Button
            type="secondary"
            style={{
              marginBottom: 16,
            }}
            onClick={() => openModal({ status: "create" })}
          >
            Add Lien or Judgment
          </Button>
        </Col>
      </Row>
      <Col span={20} offset={2}>
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={bankLiensData}
          scroll={{ x: 800 }}
        />
      </Col>
      {visible && (
        <BankLinesModal
          setVisible={setVisible}
          title="Add Lien or Judgment"
          vendorId={vendorId}
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
};

export default BankLiens;
