import React, { Fragment, useContext, useEffect, useState } from "react";
import { isAdmin, useQuery } from "../../../helpers/utility";
import {
  adminApplicationList,
  getAllAdmins,
  getApplicationById,
} from "../../../services/applications.services";
import {
  Badge,
  Button,
  Row,
  Col,
  message,
  Skeleton,
  Typography,
  Empty,
  Space,
} from "antd";
import Comments from "../../PQQ/Comments/Comments";
import PQQContent from "../../PQQ/PQQContent";
import CardWizz from "../../../blocks/Card/Card";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { SelectedVendorDetailsContext } from "../../../context/SelectedVendorContext";
import { useHistory } from "react-router";
import { applicationStatusValues, USER } from "../../../constants/defaultKeys";
import AdminRemarks from "./AdminRemarks";
import { getItem } from "../../../helpers/localStorage";

const { Text } = Typography;

const AdminPQQ = () => {
  const { vendorDetails } = useContext(SelectedVendorDetailsContext);
  const vendorId = vendorDetails?.id;
  const isUserAdmin = isAdmin();
  const userDetails = JSON.parse(getItem(USER));

  const history = useHistory();
  const query = useQuery();
  const applicationId = query.get("form");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [listCol, setListCol] = useState(24);
  const [showDrawer, setShowDrawer] = useState(false);
  const [adminsList, setAdminsList] = useState([]);

  useEffect(() => {
    if (applicationId) {
      fetchApplicationById();
    } else {
      fetchAdminApplicationList();
    }
    fetchAdminsList();
  }, [, applicationId, refresh]);

  function fetchAdminsList() {
    getAllAdmins()
      .then((response) => {
        setAdminsList(response.data);
      })
      .catch((error) => console.error(error));
  }

  function fetchApplicationById() {
    setLoading(true);
    getApplicationById(applicationId)
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }

  function fetchAdminApplicationList() {
    adminApplicationList({
      start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      status: applicationStatusValues
        .map((eachStatus) => eachStatus.value)
        .slice(0, 6),
    })
      .then((response) => {
        const res = response.data.data;
        if (res.length !== 0) {
          setData(res[0]);
          history.push(`/admin/pqq?form=${res[0].application_id}`);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("get admin application list error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get admin application list error");
        }
      });
  }

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const openComments = () => {
    setListCol(listCol === 24 ? 16 : 24);
  };

  function viewVendorProfile() {
    history.push(
      `/admin/pqq/${applicationId}/vendor/${data.vendor_id}/view-profile/basic-details`
    );
  }

  function closeDrawer() {
    setShowDrawer(false);
  }

  function generateReport() {
    history.push(
      `/admin/pqq/generate-report?id=${data.id}&form=${applicationId}&vendorId=${data.vendor_id}`
    );
  }

  return (
    <Fragment>
      <Row type="flex" justify="center">
        {loading ? (
          <Skeleton active />
        ) : !data ? (
          <Empty description="No Application Found" />
        ) : (
          <>
            <Col span={listCol}>
              <Row align="middle">
                <Col sm={12} lg={14}>
                  <Text
                    title="PQQ Id"
                    strong
                    style={{ fontSize: "16px", marginRight: "20px" }}
                  >
                    {data?.application_id}
                  </Text>
                  <Space>
                    <Button onClick={viewVendorProfile}>
                      View Vendor Profile
                    </Button>
                    {data.status !== "Pending" &&
                      data.status !== "Submitted" &&
                      !userDetails?.is_client_admin && (
                        <Button onClick={generateReport}>
                          Generate Report
                        </Button>
                      )}
                  </Space>
                </Col>
                <Col
                  sm={12}
                  lg={10}
                  style={{ textAlign: "right", marginBottom: "10px" }}
                >
                  <Badge count={data && data.num_unread_comments}>
                    <Button
                      style={{ marginLeft: "20px" }}
                      size="large"
                      type="primary"
                      onClick={openComments}
                    >
                      Comments
                      <ArrowRightOutlined
                        className={
                          listCol === 24 &&
                          data.num_unread_comments > 0 &&
                          "pqq-comments-arrow-pulse"
                        }
                      />
                    </Button>
                  </Badge>
                </Col>
              </Row>
              <Row gutter={[0, 32]}>
                <Col span={24}>
                  <CardWizz
                    title={
                      <>
                        <Text title="Customer">{data?.customer}</Text> /
                        <Text title="Project">{data?.project}</Text> /
                        <Text title="Bid Id">{data?.bid_no}</Text>
                      </>
                    }
                  >
                    <PQQContent
                      application={data && data}
                      vendorId={vendorId}
                      isUserAdmin={isUserAdmin}
                      vendorDetails={vendorDetails && vendorDetails}
                      adminsList={adminsList}
                      refresh={refreshPage}
                      setShowDrawer={setShowDrawer}
                    />
                  </CardWizz>
                </Col>
              </Row>
            </Col>
            {listCol === 16 && (
              <>
                <Col span={7} offset={1}>
                  <div className="pqq-comments">
                    <Comments
                      application={data && data}
                      fetchData={fetchApplicationById}
                      usersList={adminsList}
                    />
                  </div>
                </Col>
              </>
            )}
          </>
        )}
        {showDrawer && (
          <AdminRemarks
            data={data}
            applicationId={applicationId}
            adminsList={adminsList}
            closeDrawer={closeDrawer}
            refreshPage={refreshPage}
          />
        )}
      </Row>
    </Fragment>
  );
};

export default AdminPQQ;
