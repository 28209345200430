import React, { useState, Fragment } from "react";
import { Steps, Row, Col } from "antd";
import { ADMIN_FORM_STEPS } from "./AdminFormSteps";
import { useHistory } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const AdminForm = () => {
  const steps = ADMIN_FORM_STEPS;
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();

  function onStepChange(current) {
    setCurrentStep(current);
  }

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <span
            onClick={() => history.goBack()}
            className="privacy-policy-back"
          >
            <ArrowLeftOutlined /> Back
          </span>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row type="flex" className="steps-container" justify="start">
        <Col span={4}>
          <Steps
            direction="vertical"
            current={currentStep}
            onChange={onStepChange}
          >
            {steps.map((element, index) => {
              if (element.step_name !== "profile-completed") {
                return (
                  <Steps.Step
                    key={index}
                    title={element.title}
                    disable={element.disable}
                  />
                );
              }
            })}
          </Steps>
        </Col>
        <Col span={20}>
          <div>
            {React.createElement(steps?.[currentStep]?.component, {
              currentStep,
              onStepChange,
            })}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AdminForm;
