import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Table, Space, message } from "antd";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import NYCLicencesModel from "./NYCLicencesModel";
import {
  createNYCLicence,
  deleteNYCLicence,
  getNYCLicence,
  updateNYCLicence,
} from "../../../../../services/adminForm.services";
import moment from "moment";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useQuery } from "../../../../../helpers/utility";

const dateFormat = "YYYY-MM-DD";

const NYCLicences = () => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [data, setData] = useState([]);
  const query = useQuery();
  const applicationId = query.get("id");

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Registered",
      dataIndex: "registered",
      key: "registered",
      ellipsis: true,
      width: 100,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: "Issued To",
      dataIndex: "issued_to",
      key: "issued_to",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Issued Date",
      dataIndex: "issued_date",
      key: "issued_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Contractor Id",
      dataIndex: "contractor_id",
      key: "contractor_id",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      ellipsis: true,
      width: 100,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: "Expiration Date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Is City Employee",
      dataIndex: "is_city_employee",
      key: "is_city_employee",
      ellipsis: true,
      width: 100,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: "Business Name",
      dataIndex: "business_name",
      key: "business_name",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchNYCLicence();
  }, []);

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  function fetchNYCLicence(id = applicationId) {
    getNYCLicence(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("get nyc licence error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("get nyc licence error");
        }
      });
  }

  function saveNYCLicences(values) {
    let data = values;
    data.issued_date = moment(values.issued_date).format(dateFormat);
    data.expiration_date = moment(values.expiration_date).format(dateFormat);
    data.application = applicationId;

    createNYCLicence(data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Saved NYC Licence");
        fetchNYCLicence(applicationId);
      })
      .catch((error) => {
        console.error("save nyc licence error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to save NYC License"
          );
        } else {
          message.error("save nyc licence error");
        }
      });
  }

  function handleUpdateNYCLicences(values) {
    let data = values;
    data.issued_date = moment(values.issued_date).format(dateFormat);
    data.expiration_date = moment(values.expiration_date).format(dateFormat);
    data.application = applicationId;

    updateNYCLicence(editTable.id, data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Updated NYC Licence");
        fetchNYCLicence(applicationId);
      })
      .catch((error) => {
        console.error(
          "save nyc licence error",
          error || "Failed to Update NYC Licence"
        );
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("save nyc licence error");
        }
      });
  }

  function removeColumn(id) {
    deleteNYCLicence(id)
      .then((res) => {
        message.success(res.data.message || "Deleted NYC Licence");
        fetchNYCLicence(applicationId);
      })
      .catch((error) => {
        console.error("delete nyc licence error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to delete NYC Licence"
          );
        } else {
          message.error("delete nyc licence error");
        }
      });
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Button
            onClick={() => openModal({ status: "create" })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Details
          </Button>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <NYCLicencesModel
          create={saveNYCLicences}
          setVisible={setVisible}
          update={handleUpdateNYCLicences}
          title="NYC License"
          editTable={editTable}
        />
      )}
    </Fragment>
  );
};

export default NYCLicences;
