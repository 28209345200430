import React, { Fragment, useContext, useState } from "react";
import { Col, message, Row } from "antd";
import ApplicationsList from "./ApplicationsList/ApplicationsList";
import WelcomeCard from "./WelcomeCard/WelcomeCard";
import { USER } from "../../constants/defaultKeys";
import { setItem } from "../../helpers/localStorage";
import IntroPage from "../IntroPage/IntroPage";
import ActivityFeed from "./ActivityFeed/ActivityFeed";
import { updateSkipTour } from "../../services/applications.services";
import { getUserDetails } from "../../helpers/utility";
import { SelectedVendorDetailsContext } from "../../context/SelectedVendorContext";

const Dashboard = () => {
  const userDetails = getUserDetails();
  const [skipTour, setSkipTour] = useState(userDetails.skip_tour);
  const { vendorDetails } = useContext(SelectedVendorDetailsContext);
  const vendorId = vendorDetails?.id;
  const isSubscribed = vendorDetails?.is_subscribed;

  function handleSkipTour() {
    updateSkipTour({ skip_tour: true })
      .then((res) => {
        setSkipTour(true);
        setItem(USER, JSON.stringify({ ...userDetails, skip_tour: true }));
      })
      .catch((error) => {
        console.error("skip tour error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("skip tour error");
        }
      });
  }

  return (
    <Fragment>
      {!userDetails.is_admin && !skipTour ? (
        <IntroPage
          handleSkipTour={handleSkipTour}
          vendorId={vendorId}
          vendorDetails={vendorDetails}
        />
      ) : (
        <Row gutter={[8, 0]}>
          <Col span={16}>
            <ApplicationsList
              vendorId={vendorId}
              vendorDetails={vendorDetails}
            />
          </Col>
          <Col span={8}>
            <Row gutter={[0, 8]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <WelcomeCard
                  progressPercent={vendorDetails?.profile_completion_status}
                  vendorId={vendorId}
                  userDetails={userDetails}
                  isSubscribed={isSubscribed}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <ActivityFeed vendorId={vendorId} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};
export default Dashboard;
