import { Fragment, useState } from "react";
import { Typography, Button, Table, Space, message } from "antd";
import InsuranceModal from "./InsuranceModal";
import { removeChangeWorkersInsurance } from "../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../reusable/ModalWizz";
import { ChangesInInsuranceCarriersColumns } from "../../../PQQ/tableColumns";

const { Paragraph } = Typography;

const CompensationInsurance = ({
  vendorId,
  compensationInsuranceData,
  fetchCompensationInsurance,
}) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  const refreshPage = () => {
    fetchCompensationInsurance();
  };

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  const removeColumn = (id) => {
    removeChangeWorkersInsurance({
      id: id,
      vendor_id: vendorId,
    })
      .then((response) => {
        refreshPage();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    ...ChangesInInsuranceCarriersColumns,
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Paragraph>
        For General Liability, Excess Liability and Workers' Compensation,
        indicate changes in carriers within the past five years:
      </Paragraph>
      <Button
        type="secondary"
        style={{
          marginBottom: 16,
        }}
        onClick={() => openModal({ status: "create", id: null })}
      >
        Add Change
      </Button>
      <Table
        size="small"
        columns={columns}
        dataSource={compensationInsuranceData}
        pagination={false}
        scroll={{ x: 800 }}
      />
      {visible && (
        <InsuranceModal
          setVisible={setVisible}
          title="Compensation Insurance"
          value="compensation_insurance"
          vendorId={vendorId}
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
};

export default CompensationInsurance;
