import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;
const Guides = () => {
  return (
    <Row type="flex">
      <Col span={20} offset={1}>
        <Paragraph>
          - {""}
          <b>i4 Vendor Profile</b>
        </Paragraph>

        <Paragraph>
          - <b>Payment</b>
        </Paragraph>
        <Paragraph>- <b>PQQ Submission</b></Paragraph>
        <Paragraph>- <b>Account</b></Paragraph>
      </Col>
    </Row>
  );
};
export default Guides;
