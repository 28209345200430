import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  DatePicker,
  Collapse,
  Button,
  message,
  Select,
} from "antd";
import "./BasicDetails.css";
import moment from "moment";
import {
  mobileNumberRegex,
  nameValidation,
  taxValidation,
  urlValidation,
  zipCodeValidation,
} from "../../../../config/validation.config";
import {
  narrowLabelLayout,
  tailLayout,
} from "../../../../config/formLayout.config";
import {
  createBasicDetails,
  getBasicDetails,
} from "../../../../services/vendorProfile.services";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import RadioGroupWizz from "../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import { usStatesAndCities } from "../../../../constants/defaultKeys";
import {
  companyType,
  radioYesOrNo,
} from "../../../../config/radioGroupConstants";
import { profileCompletion } from "../../../../constants/defaultKeys";
const { Panel } = Collapse;
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const validateMessages = {
  required: "'${label}' is required",
};

const BasicDetails = (props) => {
  const vendorId = props.vendorId;
  const [form] = Form.useForm();
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );
  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );

  const [disabled, setDisabled] = useState(false);

  const mAddressBoolean =
    vendorProfileFormData.basic_details.mailing_address_same_as_primary;
  const showMailingAddressForm =
    mAddressBoolean === undefined ? false : !mAddressBoolean;
  const usStates =
    usStatesAndCities &&
    usStatesAndCities.map((item) => Object.keys(item))[0].sort();

  //Disable Date greater than today
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  useEffect(() => {
    vendorId && fetchBasicDetails();
  }, [vendorId]);

  function fetchBasicDetails() {
    getBasicDetails(vendorId)
      .then((res) => {
        if (Object.keys(res.data.data).length === 0) {
          setFormFieldValues({
            ...(!props?.isUserAdmin && vendorProfileFormData.basic_details),
            // ...vendorProfileFormData.basic_details,
            mailing_address_same_as_primary: !showMailingAddressForm,
          });
        } else {
          let formValues = {
            ...res.data.data,
            // ...vendorProfileFormData.basic_details,
            date_formed: moment(res.data.data.date_formed),
          };
          setFormFieldValues(formValues);
        }
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  }

  function setFormFieldValues(data) {
    setVendorProfileDataBasicDetails(data);
    form.setFieldsValue(data);
  }

  function setVendorProfileDataBasicDetails(changedValues) {
    vendorProfileFormData.basic_details = {
      ...vendorProfileFormData.basic_details,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function onFormValuesChange(changedValues) {
    let formValues = form.getFieldsValue();

    if (changedValues.p_state) {
      setFormFieldValues({ ...formValues, p_city: null });
    }

    if (changedValues.m_state) {
      setFormFieldValues({ ...formValues, m_city: null });
    }
    setVendorProfileDataBasicDetails(formValues);
  }

  function onFinish(values) {
    let requestBody = {
      ...values,
      date_formed: moment(values.date_formed).format(dateFormat),
      vendor_id: vendorId,
    };
    if (!showMailingAddressForm) {
      requestBody = {
        ...requestBody,
        DBA: values.DBA ? values.DBA : null,
        affiliated_companies: values.affiliated_companies
          ? values.affiliated_companies
          : null,
        fax: values.fax ? values.fax : null,
        website: values.website ? values.website : null,
        mailing_street_address: values.primary_address,
        m_city: values.p_city,
        m_state: values.p_state,
        m_zipcode: values.p_zipcode,
      };
    }
    setDisabled(true);
    saveBasicDetails(requestBody);
  }

  function saveBasicDetails(requestBody) {
    createBasicDetails(requestBody)
      .then((res) => {
        setDisabled(false);
        if (!vendorDetails.basic_details_status) {
          setVendorDetails({
            ...vendorDetails,
            basic_details_status: true,
            profile_completion_status:
              vendorDetails.profile_completion_status +
              profileCompletion.basic_details_status,
          });
        }
        message.success(res.data.message);
        // navigating user to next step on save
        props.onStepChange(props.currentStep + 1);
      })
      .catch((error) => {
        setDisabled(false);
        console.error("error", error);
        if (error && error.response) {
          if (error.response.status === 400) {
            message.error(
              "Something went wrong with the form being saved. Please contact administrator"
            );
          } else {
            message.error(
              error.response.data.message || error.response.message
            );
          }
        } else {
          message.error("Error creating basic details");
        }
      });
  }

  return (
    <Form
      name="basic_profile"
      form={form}
      {...narrowLabelLayout}
      validateMessages={validateMessages}
      onValuesChange={onFormValuesChange}
      onFinish={onFinish}
      colon={false}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1", "2", "3"]}>
        <Panel header="Business Info" key="1">
          <Form.Item
            name="employer_identification_number"
            label="Employer Identification Number"
            rules={taxValidation}
          >
            <Input placeholder="Tax ID: SSN or EIN" />
          </Form.Item>
          <Form.Item
            name="name_of_firm"
            label="Name of the firm"
            rules={[
              {
                message: "Please enter name of the firm",
                required: true,
              },
            ]}
            // rules={nameValidation}
          >
            <Input placeholder="Enter full name" />
          </Form.Item>
          <Form.Item
            name="type_of_entity"
            label="Company Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {RadioGroupWizz({ data: companyType })}
          </Form.Item>
          <Form.Item
            name="DBA"
            label="DBA or Trade name"
            // rules={nameValidation}
          >
            <Input placeholder="List all names under which firm has operated" />
          </Form.Item>
          <Form.Item
            name="affiliated_companies"
            label="Affiliated Companies"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="Enter values separated by commas" />
          </Form.Item>
          <Form.Item
            name="date_formed"
            label="Date Formed"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              placeholder={dateFormat}
              disabledDate={disabledDate}
              style={{ minWidth: 280 }}
            />
          </Form.Item>
        </Panel>
        <Panel header="Contact Info" key="2">
          <Form.Item
            name="primary_address"
            label="Primary Place of Business"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Street Address" />
          </Form.Item>
          <Form.Item
            name="p_state"
            label="State"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Please select a state">
              {usStates.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="p_city"
            label="City"
            // rules={nameValidation}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item name="p_zipcode" label="Zipcode" rules={zipCodeValidation}>
            <Input placeholder="5-digits" />
          </Form.Item>
          <Form.Item name="phone" label="Phone" rules={mobileNumberRegex}>
            <Input placeholder="10-digits without spaces" addonBefore="+1" />
          </Form.Item>
          <Form.Item
            name="fax"
            label="Fax"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="Fax" />
          </Form.Item>
          <Form.Item name="website" label="Website" rules={urlValidation}>
            <Input placeholder="Website" />
          </Form.Item>
        </Panel>
        <Panel header="Mailing Address" key="3">
          <Form.Item
            name="mailing_address_same_as_primary"
            label="Is mailing Address same as Primary Address?"
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 8 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>

          <Form.Item
            name="mailing_street_address"
            label="Mailing Street Address"
            hidden={!showMailingAddressForm}
            rules={[
              {
                required: showMailingAddressForm,
              },
            ]}
          >
            <Input placeholder="Mailing Street Address" />
          </Form.Item>
          <Form.Item
            name="m_state"
            label="State"
            hidden={!showMailingAddressForm}
            rules={[
              {
                required: showMailingAddressForm,
              },
            ]}
          >
            <Select placeholder="Please select a state">
              {usStates.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="m_city"
            label="City"
            hidden={!showMailingAddressForm}
            rules={[
              {
                required: showMailingAddressForm,
              },
            ]}
          >
            {/* <Select placeholder="Please select a city">
              {mCity &&
                mCity.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select> */}
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            name="m_zipcode"
            label="Zipcode"
            hidden={!showMailingAddressForm}
            rules={[
              {
                required: showMailingAddressForm,
                pattern: zipCodeValidation[0].pattern,
                message: zipCodeValidation[0].message,
              },
            ]}
          >
            <Input placeholder="5-digits" />
          </Form.Item>
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BasicDetails;
