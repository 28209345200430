import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import {
  createCivil,
  updateCivil,
} from "../../../../../services/vendorProfile.services";
import moment from "moment";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const CivilCasesModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  //Disable Date greater than today
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }
  useEffect(() => {
    if (editTable.form_status === "update") {
      setFormFieldValues(editTable);
    }
  }, [editTable]);

  function setFormFieldValues(data) {
    form.setFieldsValue({
      ...data,
      date_instituted: moment(data.date_instituted),
    });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.form_status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
        date_instituted: moment(values.date_instituted).format("YYYY-MM-DD"),
      };

      updateCivil(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
        date_instituted: moment(values.date_instituted).format("YYYY-MM-DD"),
      };

      createCivil(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="case_name"
              label="Case Name"
              rules={[
                {
                  message: "Please Case Name!",
                },
              ]}
            >
              <Input placeholder="Case Name" />
            </Form.Item>
            <Form.Item
              name="caption"
              label="Caption"
              rules={[
                {
                  message: "Please Caption/Parties!",
                },
              ]}
            >
              <Input placeholder="Caption/Parties" />
            </Form.Item>
            <Form.Item
              name="court"
              label="Court"
              rules={[
                {
                  required: true,
                  message: "Please input your panel or court!",
                },
              ]}
            >
              <Input placeholder="Court/Panel" />
            </Form.Item>
            <Form.Item
              name="case_no"
              label="Case No."
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Case No" />
            </Form.Item>
            <Form.Item
              name="nature_of_action"
              label="Nature of Action"
              rules={[
                {
                  required: true,
                  message: "Please input nature of action!",
                },
              ]}
            >
              <Input placeholder="Nature of Action" />
            </Form.Item>
            <Form.Item
              name="date_instituted"
              label="Date Instituted"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                disabledDate={disabledDate}
                placeholder="Date Instituted"
                size="large"
                style={{ minWidth: "280px" }}
              />
            </Form.Item>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please input your status!",
                },
              ]}
            >
              <Input placeholder="Status" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default CivilCasesModal;
