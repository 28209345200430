import axiosInstance from "../config/axios/axios.config";

export const editProfile = (payload) => {
  return axiosInstance.put("/accounts/edit-profile/", payload);
};

export const changePassword = (payload) => {
  return axiosInstance.put("/accounts/change-password/", payload);
};

// Admin
export const getAllUsers = () => {
  return axiosInstance.get("/accounts/all-users/");
};
export const downloadAllUsersData = () => {
  return axiosInstance.get("/accounts/all-users/?download_data=true", {
    responseType: "blob",
  });
};

export const editUser = (payload) => {
  return axiosInstance.put("/accounts/update-user/", payload);
};

export const getCustomersAndProjects = () => {
  return axiosInstance.get("/accounts/customers-projects/");
};

export const createVendor = (payload) => {
  return axiosInstance.post("/accounts/create-vendor/", payload);
};

export const adminInviteUser = (payload) => {
  return axiosInstance.post("/accounts/admin-invite-user/", payload);
};

export const inviteUser = (payload) => {
  return axiosInstance.post("/accounts/invite-user/", payload);
};

export const vendorToProjects = (payload) => {
  return axiosInstance.post("/accounts/assign-vendor-to-project/", payload);
};
