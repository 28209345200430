import React, { useEffect } from "react";
import DrawerWizz from "../../../../reusable/DrawerWizz";
import { Button, DatePicker, Form, Input, message } from "antd";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

function RelatedEntitiesModal({
  create,
  update,
  editTable,
  title,
  setVisible,
}) {
  const [form] = Form.useForm();
  console.log(editTable, "editTable");

  useEffect(() => {
    if (editTable.status === "update") {
      let formValues = editTable?.data;
      formValues.date_of_incorporated = moment(editTable.date_of_incorporated);
      form.setFieldsValue(formValues);
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
  };
  async function onFinish(values) {
    if (editTable.status === "update") {
      update(values);
    } else {
      create(values);
    }
  }
  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="Entity Name"
            rules={[
              {
                required: true,
                message: "Please input Entity Name!",
              },
            ]}
          >
            <Input placeholder="Entity Name" />
          </Form.Item>
          <Form.Item
            name="address"
            label="Entity Address"
            rules={[
              {
                required: true,
                message: "Please input Entity Address!",
              },
            ]}
          >
            <Input placeholder="Entity Address" />
          </Form.Item>
          <Form.Item
            name="type_of_entity"
            label="Type of Entity"
            rules={[
              {
                required: true,
                message: "Please input Type of Entity!",
              },
            ]}
          >
            <Input placeholder="Type of Entity" />
          </Form.Item>{" "}
          <Form.Item
            name="status"
            label="Entity Status"
            rules={[
              {
                required: true,
                message: "Please input Entity Status!",
              },
            ]}
          >
            <Input placeholder="Entity Status" />
          </Form.Item>
          <Form.Item
            name="date_of_incorporated"
            label="Date Incorporated"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              format={dateFormat}
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
}

export default RelatedEntitiesModal;
