import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Collapse,
  Divider,
  Upload,
  message,
} from "antd";
import {
  tailLayout,
  wideLabelLayout,
} from "../../../../config/formLayout.config";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import PastInsuranceCarriers from "./PastInsuranceCarriers";
import CompensationInsurance from "./CompensationInsurance";
import "./Insurance.css";
import {
  profileCompletion,
  uploadFileUrl,
} from "../../../../constants/defaultKeys";
import { UploadOutlined } from "@ant-design/icons";
import {
  createInsurance,
  getChangeWorkersInsurance,
  getInsurance,
  getWorkersInsurance,
} from "../../../../services/vendorProfile.services";
import { getTokenIfNotExpired } from "../../../../helpers/utility";

const { Panel } = Collapse;

const validateMessages = {
  required: "'${label}' is required!",
};

const uploadButtonProps = {
  accept: ".doc,.docx, .pdf",
  maxCount: 1,
  action: uploadFileUrl,
  name: "docs",
  onChange(info) {
    // if headers are set as default then "antd" is setting the header before loggin into the application,
    // so setting headers when file change is triggered
    uploadButtonProps["headers"] = {
      Authorization: `Bearer ${getTokenIfNotExpired()}`,
    };
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const Insurance = (props) => {
  const vendorId = props.vendorId;

  const [form] = Form.useForm();

  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );
  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );

  const [disabled, setDisabled] = useState(false);
  const [compensationInsuranceData, setCompensationInsuranceData] = useState(
    []
  );
  const [workersInsuranceData, setWorkersInsuranceData] = useState([]);

  const uploadedFileList = vendorProfileFormData.insurance.file_list;

  useEffect(() => {
    if (vendorId) {
      fetchInsurance();
      fetchWorkersInsurance();
      fetchCompensationInsurance();
    }
  }, [vendorId]);

  function fetchInsurance() {
    getInsurance(vendorId)
      .then((res) => {
        if (res.data.data.insurance_doc) {
          setVendorProfileDataInsurance([
            {
              uid: 0,
              name: uploadedFileList[0]?.name || res.data.data.insurance_doc,
              status: "done",
            },
          ]);
          form.setFieldsValue({
            contracts_in_process:
              uploadedFileList[0]?.name || res.data.data.insurance_doc,
          });
        }
      })
      .catch((error) => {
        console.error("get insurance error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting insurance");
        }
      });
  }

  function fetchCompensationInsurance() {
    getChangeWorkersInsurance(vendorId)
      .then((response) => setCompensationInsuranceData(response.data.data))
      .catch((error) => {
        console.error("get compensation insurance error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting compensation insurance");
        }
      });
  }

  function fetchWorkersInsurance() {
    getWorkersInsurance(vendorId)
      .then((response) => setWorkersInsuranceData(response.data.data))
      .catch((error) => {
        console.error("get workers insurance error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting workers insurance");
        }
      });
  }

  function setVendorProfileDataInsurance(changedValues) {
    vendorProfileFormData.insurance.file_list = changedValues;
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function onFinish(values) {
    if (workersInsuranceData.length === 0) {
      message.error("Please add atleast one Carrier");
      return;
    } else if (workersInsuranceData.length > 0) {
      let reqBody = {
        vendor_id: vendorId,
        insurance_doc: values.contracts_in_process || "",
      };

      setDisabled(true);
      createInsurance(reqBody)
        .then((res) => {
          setDisabled(false);
          // on saving details successfully, changing the step status to true using useContext API
          if (!vendorDetails.insurance_status) {
            setVendorDetails({
              ...vendorDetails,
              insurance_status: true,
              profile_completion_status:
                vendorDetails.profile_completion_status +
                profileCompletion.insurance_status,
            });
          }
          message.success(res.data.message);
          // navigating user to next step on save
          props.onStepChange(props.currentStep + 1);
        })
        .catch((error) => {
          setDisabled(false);
          console.error("create insurance error", error);
          if (error && error.response) {
            if (error.response.status === 400) {
              message.error(
                "Something went wrong with the form being saved. Please contact administrator"
              );
            } else {
              message.error(
                error.response.data.message || error.response.message
              );
            }
          } else {
            message.error("Error creating basic details");
          }
        });
    }
  }

  const normFile = (e) => {
    setVendorProfileDataInsurance(e.fileList);

    if (e && e.fileList && e.fileList.length > 0 && e.fileList[0].response) {
      return e?.fileList[0]?.response?.names[0];
    }
  };

  return (
    <Form
      name="insurance"
      form={form}
      className="form-insurance"
      {...wideLabelLayout}
      validateMessages={validateMessages}
      onFinish={onFinish}
      colon={false}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Insurance Program" key="1">
          <Row>
            <Col span={20} offset={2}>
              <Form.Item
                labelCol={{ span: 14 }}
                wrapperCol={{ span: 8 }}
                name="contracts_in_process"
                className="multiline-form-item"
                label="Sample insurance certificate indicating current coverage
                carriers and limits for the following: general liability,
                workers' compensation, auto liability, excess liability and
                professional liability"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
                valuePropName="contracts_in_process"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadButtonProps} fileList={uploadedFileList}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
              <PastInsuranceCarriers
                vendorId={vendorId}
                workersInsuranceData={workersInsuranceData}
                fetchWorkersInsurance={fetchWorkersInsurance}
              />
              <Divider />
              <CompensationInsurance
                vendorId={vendorId}
                compensationInsuranceData={compensationInsuranceData}
                fetchCompensationInsurance={fetchCompensationInsurance}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Insurance;
