import React, { useEffect, useState } from "react";
import { Checkbox, Collapse, message } from "antd";
import LiensAsDebtor from "./LiensAsDebtor/LiensAsDebtor";
import LiensAsCreditor from "./LiensAsCreditor/LiensAsCreditor";
import JudgmentsAsDebtor from "./JudgmentsAsDebtor/JudgmentsAsDebtor";
import JudgmentsAsCreditor from "./JudgmentsAsCreditor/JudgmentsAsCreditor";
import {
  getApplicationNoRecords,
  updateApplicationNoRecords,
} from "../../../../services/adminForm.services";
import { useQuery } from "../../../../helpers/utility";

const { Panel } = Collapse;

const NotableCourtRecords = () => {
  const query = useQuery();

  const applicationId = query.get("id");

  const [appNoRcd, setAppNoRcdData] = useState(null);
  const [checkBox, setCheckBox] = useState({
    no_ld_records: appNoRcd?.no_ld_records,
    no_lc_records: appNoRcd?.no_lc_records,
    no_jd_records: appNoRcd?.no_jd_records,
    no_jc_records: appNoRcd?.no_jc_records,
  });
  const [checked, setChecked] = useState({
    key: [],
  });
  useEffect(() => {
    getApplicationNoRecordsApi();
  }, [applicationId]);

  async function getApplicationNoRecordsApi() {
    try {
      const res = await getApplicationNoRecords(applicationId);
      setAppNoRcdData(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function updateApplicationApi(values) {
    try {
      const res = await updateApplicationNoRecords(applicationId, {
        no_ld_records: values?.no_ld_records,
        no_lc_records: values?.no_lc_records,
        no_jd_records: values?.no_jd_records,
        no_jc_records: values?.no_jc_records,
      });
      message.success(res.data?.message);
    } catch (err) {
      console.log(err);
    } finally {
      getApplicationNoRecordsApi();
    }
  }

  return (
    <Collapse
      activeKey={checkBox?.no_ld_records ? checked?.key : ["1", "2", "3", "4"]}
      onChange={(e) => {
        setChecked({ key: e });
      }}
    >
      <Panel
        header="Liens Filed Against Entity As Debtor"
        key="1"
        extra={
          <Checkbox
            checked={appNoRcd?.no_ld_records}
            onChange={(e) => {
              setCheckBox({
                ...checkBox,
                no_ld_records: e.target.checked,
              });
              updateApplicationApi({ no_ld_records: e.target.checked });
            }}
          >
            No Information Identified
          </Checkbox>
        }
        collapsible={appNoRcd?.no_ld_records ? "disabled" : ""}
      >
        {" "}
        <div
          style={
            appNoRcd?.no_ld_records
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <LiensAsDebtor disable={appNoRcd?.no_ld_records} />
        </div>
      </Panel>
      <Panel
        header="Liens Filed Against Entity As Creditor"
        key="2"
        extra={
          <Checkbox
            checked={appNoRcd?.no_lc_records}
            onChange={(e) => {
              setCheckBox({
                ...checkBox,
                no_lc_records: e.target.checked,
              });
              updateApplicationApi({ no_lc_records: e.target.checked });
            }}
          >
            No Information Identified
          </Checkbox>
        }
        collapsible={appNoRcd?.no_lc_records ? "disabled" : ""}
      >
        <div
          style={
            appNoRcd?.no_lc_records
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <LiensAsCreditor disable={appNoRcd?.no_lc_records} />
        </div>
      </Panel>
      <Panel
        header="Judgments Filed Against Entity As Debtor"
        key="3"
        extra={
          <Checkbox
            checked={appNoRcd?.no_jd_records}
            onChange={(e) => {
              setCheckBox({
                ...checkBox,
                no_jd_records: e.target.checked,
              });
              updateApplicationApi({ no_jd_records: e.target.checked });
            }}
          >
            No Information Identified
          </Checkbox>
        }
        collapsible={appNoRcd?.no_jd_records ? "disabled" : ""}
      >
        <div
          style={
            appNoRcd?.no_jd_records
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <JudgmentsAsDebtor disable={appNoRcd?.no_jd_records} />
        </div>
      </Panel>
      <Panel
        header="Judgments Filed Against Entity As Creditor"
        key="4"
        extra={
          <Checkbox
            checked={appNoRcd?.no_jc_records}
            onChange={(e) => {
              setCheckBox({
                ...checkBox,
                no_jc_records: e.target.checked,
              });
              updateApplicationApi({ no_jc_records: e.target.checked });
            }}
          >
            No Information Identified
          </Checkbox>
        }
        collapsible={appNoRcd?.no_jc_records ? "disabled" : ""}
      >
        {" "}
        <div
          style={
            appNoRcd?.no_jc_records
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <JudgmentsAsCreditor disable={appNoRcd?.no_jc_records} />
        </div>
      </Panel>
    </Collapse>
  );
};

export default NotableCourtRecords;
