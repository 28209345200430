import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, Typography, message } from "antd";
import { useHistory } from "react-router-dom";
import { getItem, setItem, clear } from "../../helpers/localStorage";
import {
  USER,
  TOKEN_KEY,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  VENDORS,
} from "../../constants/defaultKeys";
import { verifyOtp, verifyMobileNumber } from "../../services/auth.services";
import Logo from "../../assets/images/logoBlue.png";
import CardWizz from "../../blocks/Card/Card";
import { metaData } from "../../services/metaData.services";
import { getUserDetails } from "../../helpers/utility";

const { Title } = Typography;

const Mfa = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    let userDetails = getUserDetails();
    setMobileNumber(userDetails && userDetails.contact_number);
    setToken(getItem(TOKEN_KEY));
  }, []);

  const onFinish = (values) => {
    const data = { ...values, token: token };
    verifyOtp(data)
      .then((response) => {
        clear();
        const responseData = response.data.payload;
        setItem(ACCESS_TOKEN, responseData.access_token);
        setItem(REFRESH_TOKEN, responseData.refresh_token);
        setItem(USER, JSON.stringify(responseData));

        if (
          responseData.is_admin ||
          responseData.is_client_admin ||
          responseData.is_project_coordinator
        ) {
          history.push("/admin/dashboard");
        } else {
          metaData()
            .then((res) => {
              const vendors = res.data?.vendors || [];
              setItem(VENDORS, JSON.stringify({ vendors: vendors }));
              if (vendors.length) {
                history.push(
                  `/vendor/${res.data.vendors[0].vendor_id}/dashboard`
                );
              } else {
                history.push(`/improperly-configured`);
              }
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          message.error(error.response.data.message);
        } else {
          message.error("error verifying otp");
        }
        console.error("error verifying otp", error);
      });
  };

  const resendCode = () => {
    verifyMobileNumber({ token: token })
      .then((response) => {
        message.success("OTP sent successfully");
        form.resetFields();
      })
      .catch((error) => {
        message.error(error.response.data.message);
        console.error(error);
      });
  };

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <CardWizz bordered={false} style={{ textAlign: "left" }}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              <img src={Logo} alt="check logo" height="36px" />
            </div>

            <div style={{ textAlign: "center" }}>
              <Title level={5} style={{ color: "#666" }}>
                {" "}
                Enter 6-digit code sent to {mobileNumber && mobileNumber}
              </Title>
            </div>

            <Form name="basic" onFinish={onFinish} scrollToFirstError>
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Please input your verification code!",
                  },
                ]}
                help="Verification code is valid for 15 mins"
              >
                <Input
                  autoFocus={true}
                  size="large"
                  placeholder="OTP"
                  maxLength="6"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  className="login-form-button"
                >
                  Verify
                </Button>
              </Form.Item>
            </Form>
            <div style={{ textAlign: "center" }}>
              <span>Haven't received code yet? </span>
              <span onClick={resendCode} className="footer-links">
                Resend OTP
              </span>
            </div>
          </CardWizz>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Mfa;
