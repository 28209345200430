import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Typography, Tabs, Tag, Skeleton } from "antd";
import Avatar from "react-avatar";
import moment from "moment";
import { metaData } from "../../services/metaData.services";
import { colors } from "../../constants/defaultKeys";
import BasicDetails from "./BasicDetails";
import VerifyMobileNumber from "../Verification/VerifyMobileNumber";
import ChangePassword from "./ChangePassword";
import "./MyProfile.css";
import { isMobileOnly } from "react-device-detect";
import CardWizz from "../../blocks/Card/Card";

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const MyProfile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    metaData()
      .then((response) => {
        setUserDetails(response.data.user_details);
        setOrg(response.data.vendors);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={{ span: 18, offset: 3 }}
          xl={{ span: 18, offset: 3 }}
        >
          <CardWizz style={{ minHeight: "70vh" }}>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  align="middle"
                  style={{ padding: "30px" }}
                >
                  <Col
                    xs={8}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={2}
                    style={{ textAlign: "center" }}
                  >
                    <Avatar
                      round={true}
                      color={userDetails && colors[userDetails.user_id % 24]}
                      name={
                        userDetails?.first_name ? userDetails.first_name : "-"
                      }
                      size="50px"
                    />
                  </Col>
                  <Col xs={16} sm={18} md={20} lg={8} xl={8}>
                    <Title level={5}>
                      {userDetails?.first_name
                        ? userDetails.first_name
                        : "user"}
                    </Title>
                    <span style={{ fontSize: "11px" }}>
                      {userDetails && userDetails.last_logged_in && (
                        <Paragraph>
                          Last logged in{" "}
                          {userDetails?.last_logged_in
                            ? moment(userDetails.last_logged_in).format(
                                "MMM Do YYYY"
                              )
                            : "-"}
                        </Paragraph>
                      )}
                    </span>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={14}
                    xl={14}
                    style={
                      isMobileOnly
                        ? { textAlign: "left" }
                        : { textAlign: "right" }
                    }
                  >
                    {org && org.length !== 0 && (
                      <Fragment>
                        <Text>Member of </Text>
                        {org &&
                          org.map((item) => (
                            <Tag color={colors[item && item.vendor_id % 24]}>
                              {item.name}
                            </Tag>
                          ))}
                      </Fragment>
                    )}
                  </Col>
                </Row>
                <Tabs defaultActiveKey="1" centered>
                  <TabPane tab="Basic Details" key="1">
                    <Row type="flex" align="middle" style={{ padding: "20px" }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={{ span: 12, offset: 6 }}
                        xl={{ span: 12, offset: 6 }}
                      >
                        <BasicDetails user={userDetails} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Change Mobile Number" key="2">
                    <Row type="flex" align="middle" style={{ padding: "20px" }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={{ span: 12, offset: 6 }}
                        xl={{ span: 12, offset: 6 }}
                      >
                        <VerifyMobileNumber
                          path="/myprofile"
                          showMessage={true}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Change Password" key="3">
                    <Row type="flex" align="middle" style={{ padding: "20px" }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={{ span: 12, offset: 6 }}
                        xl={{ span: 12, offset: 6 }}
                      >
                        <ChangePassword />
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </>
            )}
          </CardWizz>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MyProfile;
