import React, { Fragment, useState, useEffect } from "react";
import { message, List, Skeleton } from "antd";
import { getActivity } from "../../../services/applications.services";
import TimeStamp from "../../../blocks/TimeStamp";
import "./ActivityFeed.css";
import CardWizz from "../../../blocks/Card/Card";

const ActivityFeed = ({ vendorId }) => {
  const [activityData, setActivityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    vendorId && fetchActivityData();
  }, [vendorId]);

  function fetchActivityData() {
    getActivity(vendorId)
      .then((res) => {
        let data = res.data.data;
        if (data.length === 0) {
          data = [
            {
              message: "No recent activity",
            },
          ];
        }
        setActivityData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("get activity data error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get activity data error");
        }
      });
  }

  return (
    <Fragment>
      <CardWizz title="Activity Feed" className="activity-feed-card">
        {isLoading ? (
          <Skeleton loading={isLoading} active />
        ) : (
          <List
            className="activity-feed-list"
            itemLayout="horizontal"
            dataSource={activityData && activityData}
            renderItem={(item) => (
              <List.Item
                bordered={false}
                actions={
                  item.timestamp && [
                    <span key={item.timestamp} style={{ fontSize: "12px" }}>
                      <TimeStamp timeStyle="round" value={item.timestamp} />
                    </span>,
                  ]
                }
              >
                <List.Item.Meta description={item.message} />
              </List.Item>
            )}
          />
        )}
      </CardWizz>
    </Fragment>
  );
};

export default ActivityFeed;
