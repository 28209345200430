import React, { useEffect, useState } from "react";
import { Button, Form, message, Select } from "antd";
import { vendorToProjects } from "../../../services/userProfile.services";
import DrawerWizz from "../../../reusable/DrawerWizz";

const { Option } = Select;
const validateMessages = {
  required: "'${label}' is required",
};

const AddVendorProjects = ({
  fetchAllUsers,
  setShowAssignDrawer,
  customersAndProjectsList,
}) => {
  const [form] = Form.useForm();
  const [projectsList, setProjectsList] = useState([]);

  function onFinish(values) {
    vendorToProjects(values)
      .then((res) => {
        fetchAllUsers();
        setShowAssignDrawer(false);
        message.success(res.data.message);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  }

  function onFormValuesChange(changedValues) {
    if (changedValues.customer_id) {
      let projects = customersAndProjectsList.projects.filter(
        (eachItem) => changedValues.customer_id === eachItem.customer_id
      );
      setProjectsList(projects);
    }
  }

  return (
    <DrawerWizz
      title="Assign Vendor"
      handleCancel={() => setShowAssignDrawer(false)}
      content={
        <Form
          form={form}
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={onFinish}
          onValuesChange={onFormValuesChange}
          colon={false}
          scrollToFirstError
        >
          <Form.Item
            name="customer_id"
            label="Customer"
            rules={[
              {
                required: true,
                message: "Customers is required!",
              },
            ]}
          >
            <Select
              placeholder="Select Customers"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              style={{ minWidth: "120px" }}
            >
              {customersAndProjectsList?.customers?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="project_id"
            label="Project"
            rules={[
              {
                required: true,
                message: "Project is required!",
              },
            ]}
          >
            <Select
              placeholder="Select Projects"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              style={{ minWidth: "120px" }}
            >
              {projectsList?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="vendor_id"
            label="Vendor"
            rules={[
              {
                required: true,
                message: "Vendor is required!",
              },
            ]}
          >
            <Select
              placeholder="Select Vendors"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              style={{ minWidth: "120px" }}
            >
              {customersAndProjectsList?.vendors?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Assign Vendor
            </Button>
          </Form.Item>
        </Form>
      }
    ></DrawerWizz>
  );
};

export default AddVendorProjects;
