import React, { useState } from "react";
import { Menu, Layout, Row, Col, Button } from "antd";
import Logo from "../../Logo/Logo";
import MobileLogo from "../../Logo/MobileLogo";
import { isMobileOnly } from "react-device-detect";
import "./Instructions.css";

import InitialScreen from "./Sections/VendorPortalIntro/InitialScreen";
import I4VendorProfile from "./Sections/VendorPortalIntro/I4VendorProfile";
import SubmitPayment from "./Sections/VendorPortalIntro/SubmitPayment";
import AttachAndCertifyPQQ from "./Sections/VendorPortalIntro/AttachAndCertifyPQQ";
import HelpAndSupport from "./Sections/VendorPortalIntro/HelpAndSupport";
import Overview from "./Sections/FAQ/Overview";
import Guides from "./Sections/FAQ/Guides";
import RequiredDocumnet from "./Sections/FAQ/RequiredDocumentation";
import Support from "./Sections/FAQ/Support";
import VendorProfile from "./Sections/FAQ/VendorProfile";
import Payment from "./Sections/FAQ/Payment";
import PQQSubmission from "./Sections/FAQ/PQQSubmission";
import Account from "./Sections/FAQ/Account";
const { SubMenu } = Menu;
const { Content, Sider, Header } = Layout;
const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

const Instructions = () => {
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [activeKey, setActiveKey] = useState("overview");

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleClick = (event) => {
    setActiveKey(event.key);
  };

  return (
    <Layout>
      <Header>
        <Row>
          <Col xs={3} sm={3} md={6} lg={6} xl={6}>
            <div className="menu-logo">
              {isMobileOnly ? <MobileLogo /> : <Logo />}
            </div>
          </Col>
          <Col
            xs={21}
            sm={21}
            md={18}
            lg={18}
            xl={18}
            style={{ textAlign: "right", color: "white" }}
          >
            <Button type="primary">
              <a
                href={window.location.origin}
                target="_blank"
                rel="noopener noreferrer"
              >
                Back Home Or Login
              </a>
            </Button>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider width={250} className="instructions-layout-sider" collapsible>
          <div className="logo" />
          <Menu
            className="Menu"
            mode="inline"
            openKeys={openKeys}
            theme="dark"
            onOpenChange={onOpenChange}
            defaultSelectedKeys={["overview"]}
            style={{ height: "100vh", borderRight: 0 }}
            onClick={handleClick}
          >
            <Menu.ItemGroup key="faq" title="FAQ Content">
              <Menu.Item key="overview">Overview</Menu.Item>

              <SubMenu key="Guides" title="Guides">
                <Menu.Item key="VendorProfile">i4 Vendor Profile</Menu.Item>
                <Menu.Item key="payment">Payment</Menu.Item>
                <Menu.Item key="ppqsubmission">PQQ Submission</Menu.Item>
                <Menu.Item key="account">Account</Menu.Item>
              </SubMenu>
              <Menu.Item key="RequiredDocumnet">
                Required Documentation
              </Menu.Item>
              <Menu.Item key="support">Support</Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Sider>
        <Content
          className="instructions-layout-content"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {activeKey === "overview" && <Overview />}
          {activeKey === "Guides" && <Guides />}
          {activeKey === "RequiredDocumnet" && <RequiredDocumnet />}
          {activeKey === "support" && <Support />}
          {activeKey === "VendorProfile" && <VendorProfile />}
          {activeKey === "payment" && <Payment />}
          {activeKey === "ppqsubmission" && <PQQSubmission />}
          {activeKey === "account" && <Account />}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Instructions;
