import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Button, Table, Space, message } from "antd";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import DynamicModel from "../DynamicModel";
import { DynamicTable } from "../DynamicTable";
import {
  deleteNCRecords,
  getNCRecords,
} from "../../../../../services/adminForm.services";
import { useQuery } from "../../../../../helpers/utility";

const JudgmentsAsCreditor = ({ disable }) => {
  const query = useQuery();
  const applicationId = query.get("id");

  const refData = { filed_as: "Creditor", record_type: "Judgment" };
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  useEffect(() => {
    setLoading(true);
    getNCRecords(applicationId, refData)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("something went wrong, please try after sometime");
        }
      });
  }, [refresh]);

  const columns = [
    ...DynamicTable,
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() =>
              openModal({ formData: record, status: "update", refData })
            }
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  const removeColumn = (id) => {
    deleteNCRecords(id)
      .then((response) => {
        message.success(response.data.message || "Removed Nc Records");
        refreshPage();
      })
      .catch((error) => {
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to remove Nc Records"
          );
        } else {
          message.error("something went wrong, please try after sometime");
        }
      });
  };

  return (
    <Fragment>
      <Row style={disable ? { pointerEvents: "none" } : {}}>
        <Col>
          <Button
            onClick={() => openModal({ status: "create", refData })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Details
          </Button>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            loading={loading}
            pagination={false}
            dataSource={data}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <DynamicModel
          applicationId={applicationId}
          setVisible={setVisible}
          title="Judgments Filed Against Entity As Creditor"
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
};

export default JudgmentsAsCreditor;
