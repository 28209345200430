import React, { useState, useEffect } from "react";
import {
  Button,
  Collapse,
  Comment,
  Divider,
  List,
  message,
  Skeleton,
  Tooltip,
  Typography,
  Mentions,
  Checkbox,
  Row,
  Col,
} from "antd";
import {
  createComment,
  createReply,
  deleteComment,
  updateComment,
} from "../../../services/comments.services";
import moment from "moment";
import {
  SendOutlined,
  CloseOutlined,
  FormOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Avatar from "react-avatar";
import { confirmModal } from "../../../reusable/ModalWizz";
import { colors } from "../../../constants/defaultKeys";
import {
  getComments,
  updateUnreadCommentsStatus,
} from "../../../services/comments.services";
import "./Comments.css";
import MentionUsers from "./MentionUsers";
import { getUsersList } from "../../../services/applications.services";
import { getUserDetails } from "../../../helpers/utility";

const { Panel } = Collapse;
const { Text } = Typography;
const { getMentions } = Mentions;
const Comments = (props) => {
  const userDetails = getUserDetails();
  const [comment, setComment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCommentBox, setShowCommentBox] = useState({});
  const [commentsData, setCommentsData] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [isAdminOnly, setIsAdminOnly] = useState(false);
  useEffect(() => {
    fetchComments(props.application.id);
    fetchUsersList();
    setLoading(false);
  }, [props?.data]);

  function fetchComments(applicationId) {
    getComments(applicationId)
      .then((res) => {
        if (userDetails?.is_admin) {
          setCommentsData(res.data);
        } else {
          let requiredComments = res?.data?.filter(
            (eachComment) => eachComment?.is_admin_only !== true
          );
          setCommentsData(requiredComments);
        }

        if (props.application.num_unread_comments > 0) {
          updateCommentsStatus(res.data);
        }
      })
      .catch((error) => {
        console.error("Error getting comments", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting comments");
        }
      });
  }

  function fetchUsersList() {
    getUsersList()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error) => {
        console.error("get users list error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get users list error");
        }
      });
  }

  function updateCommentsStatus(commentsData) {
    let unreadComments = commentsData.slice(
      0,
      props.application.num_unread_comments
    );
    let unreadCommentIds = unreadComments.map((eachComment) => eachComment.id);

    updateUnreadCommentsStatus({ ids: unreadCommentIds })
      .then((res) => {
        props.fetchData();
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error updating comments status");
        }
      });
  }

  function createAvatar(userName, userId) {
    return (
      <Avatar
        name={userName}
        title={userName}
        round={true}
        size="40px"
        color={colors[userId % 24]}
      />
    );
  }
  console.log(showCommentBox);
  function onChange(e) {
    setIsAdminOnly(e.target.checked);
  }
  function commentInputBox(type) {
    return (
      <Row>
        <Col span={24}>
          <MentionUsers
            value={comment}
            placeholder={`Add a ${type}`}
            handleOnChange={setComment}
            usersList={usersList}
          />
        </Col>
        <Col span={12}>
          {userDetails.is_admin && (
            <Checkbox
              disabled={
                showCommentBox.type === "reply" ||
                showCommentBox.action === "edit"
              }
              onChange={onChange}
            >
              For Admin Only
            </Checkbox>
          )}
        </Col>

        <Col span={12} style={{ textAlign: "end" }}>
          <CloseOutlined
            className="comments-comment-input-actions comments-comment-input-close"
            title="Cancel"
            onClick={() => {
              setComment(null);
              setShowCommentBox({});
            }}
          />
          <SendOutlined
            className="comments-comment-input-actions comments-comment-input-save"
            title="Add Comment"
            onClick={() => handleCommentsAndReplies(type)}
          />
        </Col>
      </Row>
    );
  }

  function handleDateTime(dateTime) {
    return (
      <Tooltip
        title={moment(dateTime).subtract().format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>{moment(dateTime).subtract().fromNow()}</span>
      </Tooltip>
    );
  }

  function handleCommentsAndReplies(type) {
    if (showCommentBox.action === "create") {
      if (type === "reply") {
        handleAddReply();
      } else if (type === "comment") {
        handleAddComment();
      }
    } else if (showCommentBox.action === "edit") {
      handleEditComment(type);
    }
  }

  function handleAddComment() {
    let requestBody = {
      tag: null,
      message: comment,
      reply_to: null,
      emails: getMentions(comment),
      is_admin_only: isAdminOnly,
    };
    createComment(showCommentBox.id, requestBody)
      .then((res) => {
        fetchComments(props.application.id);
        setShowCommentBox({});
        setComment(null);
        message.success(res.data.message);
      })
      .catch((error) => {
        console.error("error adding comment", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error adding comment");
        }
      });
  }

  function handleEditComment(type) {
    let requestBody = {
      tag: null,
      message: comment,
      reply_to: null,
      is_admin_only: isAdminOnly,
    };
    updateComment(showCommentBox.id, type, requestBody)
      .then((res) => {
        fetchComments(props.application.id);
        setShowCommentBox({});
        setComment(null);
        message.success(res.data.message);
      })
      .catch((error) => {
        console.error("error updating comment", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error updating comment");
        }
      });
  }

  function handleAddReply() {
    let requestBody = {
      tag: null,
      message: comment,
      reply_to: null,
      emails: getMentions(comment),
    };
    createReply(showCommentBox.id, requestBody)
      .then((res) => {
        fetchComments(props.application.id);
        setShowCommentBox({});
        setComment(null);
        message.success(res.data.message);
      })
      .catch((error) => {
        console.error("error adding reply", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error adding reply");
        }
      });
  }

  function handleDeleteComment(id, type) {
    deleteComment(id, type)
      .then((res) => {
        fetchComments(props.application.id);
        message.success(res.data.message);
      })
      .catch((error) => {
        console.error("error deleting comment", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error deleting comment");
        }
      });
  }

  return (
    <Skeleton loading={loading} active avatar>
      <List
        className="comments-comment-list"
        itemLayout="vertical"
        size="large"
        dataSource={commentsData && commentsData}
        renderItem={(item, index) => (
          <>
            <List.Item
              className="comments-comment-list-item"
              key={index}
              actions={
                !loading && [
                  showCommentBox.type === "reply" &&
                    showCommentBox.action === "create" &&
                    showCommentBox.id === item.id &&
                    commentInputBox("reply"),
                  !showCommentBox.type && (
                    <Button
                      type="link"
                      onClick={() => {
                        setComment(null);
                        setShowCommentBox({
                          type: "reply",
                          action: "create",
                          id: item.id,
                        });
                      }}
                    >
                      {" "}
                      Reply
                    </Button>
                  ),
                ]
              }
            >
              <Comment
                avatar={createAvatar(item.username, item.user_id)}
                className="comments-comment"
                key={item.id}
                author={item.username}
                datetime={handleDateTime(item.created_at)}
                content={
                  showCommentBox.type === "comment" &&
                  showCommentBox.action === "edit" &&
                  showCommentBox.id === item.id
                    ? commentInputBox("comment")
                    : item.message
                }
                actions={[
                  <EditOutlined
                    title="Edit comment"
                    onClick={() => {
                      setComment(item.message);
                      setShowCommentBox({
                        type: "comment",
                        action: "edit",
                        id: item.id,
                      });
                    }}
                  />,
                  <DeleteOutlined
                    title="Delete comment"
                    onClick={() =>
                      confirmModal({
                        title: `Are you sure to delete this comment?`,
                        onOk: () => handleDeleteComment(item.id, "comment"),
                      })
                    }
                  />,
                ]}
                children={
                  <Collapse ghost className="comments-comment-replies-collapse">
                    <Panel header={`${item.replies.length} replies`}>
                      {item.replies.map((eachReply, index) => (
                        <Comment
                          key={eachReply.id}
                          author={eachReply.username}
                          datetime={handleDateTime(eachReply.created_at)}
                          avatar={createAvatar(
                            eachReply.username,
                            eachReply.user_id
                          )}
                          content={
                            showCommentBox.type === "reply" &&
                            showCommentBox.action === "edit" &&
                            showCommentBox.id === eachReply.id
                              ? commentInputBox("reply")
                              : eachReply.message
                          }
                          actions={[
                            <EditOutlined
                              title="Edit reply"
                              onClick={() => {
                                setComment(eachReply.message);
                                setShowCommentBox({
                                  type: "reply",
                                  action: "edit",
                                  id: eachReply.id,
                                });
                              }}
                            />,
                            <DeleteOutlined
                              title="Delete reply"
                              onClick={() =>
                                confirmModal({
                                  title: `Are you sure to delete this reply?`,
                                  onOk: () =>
                                    handleDeleteComment(eachReply.id, "reply"),
                                })
                              }
                            />,
                          ]}
                        />
                      ))}
                    </Panel>
                  </Collapse>
                }
              />
            </List.Item>
            {/* not showing divider for last index */}
            {index !== commentsData.length - 1 &&
            // grouping comments if they are on same date
            !moment(commentsData[index]?.created_at, "YYYY-MM-DD").isSame(
              moment(commentsData[index + 1]?.created_at, "YYYY-MM-DD")
            ) ? (
              <Divider>
                <Text className="comments-comment-divider">
                  {moment(commentsData[index + 1]?.created_at).format(
                    "YYYY-MM-DD"
                  )}{" "}
                </Text>
              </Divider>
            ) : (
              <br />
            )}
          </>
        )}
      >
        {showCommentBox.action === "create" &&
        showCommentBox.type === "comment" ? (
          commentInputBox("comment")
        ) : (
          <Button
            type="primary"
            icon={<FormOutlined />}
            onClick={() => {
              setComment(null);
              setShowCommentBox({
                type: "comment",
                action: "create",
                id: props.application.id,
              });
            }}
          >
            Add Comment
          </Button>
        )}
      </List>
    </Skeleton>
  );
};

export default Comments;
