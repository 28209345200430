import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import moment from "moment";
import {
  updateBankruptcy,
  createBankruptcy,
} from "../../../../../services/adminForm.services";
import { useQuery } from "../../../../../helpers/utility";

const dateFormat = "YYYY-MM-DD";

const BankruptcyModel = ({ create, title, setVisible, editTable, refresh }) => {
  const [form] = Form.useForm();
  const query = useQuery();
  const applicationId = query.get("id");
  useEffect(() => {
    if (editTable?.formData)
      form.setFieldsValue({
        ...editTable?.formData,
        filing_date: moment(editTable.formData.filing_date),
        disposition_date: moment(editTable.formData.disposition_date),
      });
  }, [editTable]);

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    let data = values;
    data.filing_date = moment(values.filing_date).format(dateFormat);
    data.disposition_date = moment(values.disposition_date).format(dateFormat);
    let api =
      editTable.status == "update"
        ? updateBankruptcy(editTable.formData.id, data)
        : createBankruptcy({ ...values, application: applicationId });
    api
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Saved Bankruptcy Details");
        refresh();
      })
      .catch((error) => {
        console.error("save bankruptcy error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("save bankruptcy error");
        }
      });
  };

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="index_no"
            label="Index No"
            rules={[
              {
                required: true,
                message: "Please input index no!",
              },
            ]}
          >
            <Input placeholder="Index No" />
          </Form.Item>
          <Form.Item
            name="court"
            label="Court"
            rules={[
              {
                required: true,
                message: "Please input court!",
              },
            ]}
          >
            <Input placeholder="Court" />
          </Form.Item>
          <Form.Item
            name="plaintiff"
            label="Plaintiff"
            rules={[
              {
                required: true,
                message: "Please input plaintiff!",
              },
            ]}
          >
            <Input placeholder="Plaintiff" />
          </Form.Item>
          <Form.Item
            name="defendant"
            label="Defendant"
            rules={[
              {
                required: true,
                message: "Please input defendant!",
              },
            ]}
          >
            <Input placeholder="Defendant" />
          </Form.Item>
          <Form.Item
            name="filing_date"
            label="Filing Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item
            name="disposition_date"
            label="Disposition Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};
export default BankruptcyModel;
