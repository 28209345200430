import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { VENDORS } from "../../constants/defaultKeys";
import { clear, setItem } from "../../helpers/localStorage";

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    history.push("/");
    setItem(VENDORS, null);
    clear();
  }, []);

  return <Fragment></Fragment>;
};

export default Logout;
