import React from "react";
import { Row, Col, Typography } from "antd";
const { Title, Paragraph } = Typography;

const Overview = () => {
  return (
    <Row type="flex">
      <Col span={20} offset={1}>
        <Title level={3}>Vendor Pre-Qualification Process</Title>
        <Paragraph>
          Welcome to the Vendor Pre-Qualification and Evaluation process!{" "}
          "CUSTOMER" has implemented a process by which each vendor will have to
          fill out and submit a new Pre-Qualification Questionnaire (“PQQ”) to
          i4 Strategies.
        </Paragraph>
        <Paragraph>
          This process has been implemented to ensure contractors and vendors
          are capable of providing the level of skill and quality of service and
          product required while maintaining the highest standards in the
          industry.
        </Paragraph>
        <Paragraph>
          All contractors, subcontractors, professional service consultants, and
          suppliers seeking to do business with "CUSTOMER" must complete and
          submit a Pre-Qualification Questionnaire. This portal provides a
          streamlined way for you to do so.
        </Paragraph>
        <Paragraph>
          If any of the information within the PQQ submitted changes during the
          term of any contract, the applicant firm agrees to, within seven (7)
          days of such change, submit notice of such changes and any additional
          information required by Hudson Yards concerning such change. In
          addition, every firm must, with submission of any bid, submit a
          Certification of No Change or notify Hudson Yards of any change to its
          previously submitted PQQ.
        </Paragraph>
        <Paragraph>
          No bid will be considered without submission of Pre-Qualification
          Questionnaire ("PQQ"). The applicant firm agrees and hereby certifies
          that each submission of the PQQ or any information regarding any
          change thereto, shall act as a certification and guarantee by
          applicant firm that all information contained therein is accurate,
          current and complete.
        </Paragraph>
      </Col>
    </Row>
  );
};

export default Overview;
