import React from "react";
import CardWizz from "../../../blocks/Card/Card";
import ResultWizz from "../../../blocks/ResultWizz/ResultWizz";

const ProfileCompleted = (props) => {
  return (
    <CardWizz>
      <ResultWizz {...props} />
    </CardWizz>
  );
};

export default ProfileCompleted;
