import React, { Fragment, useContext, useState, useEffect } from "react";
import { Row, Col, Popover, Radio, Space, Button, Modal } from "antd";
import UserAvatar from "./UserAvatar";
import NavigationItems from "./NavigationItems/NavigationItems";
import { useQuery } from "../../helpers/utility";
import Logo from "../../Logo/Logo";
import MobileLogo from "../../Logo/MobileLogo";
import { useHistory } from "react-router-dom";
import "./Toolbar.css";
import { isMobileOnly, isDesktop } from "react-device-detect";
import { defaultFormValues } from "../../constants/defaultKeys";
import { VendorProfileFormDataContext } from "../../context/VendorProfileContext";
import { SelectedVendorDetailsContext } from "../../context/SelectedVendorContext";
import { getVendorDetails } from "../../services/vendorProfile.services";

const Toolbar = ({ vendors, vendorId }) => {
  let query = useQuery();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [navUrl, setnavUrl] = useState("/dashboard");
  const { setVendorProfileFormData } = useContext(VendorProfileFormDataContext);
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );

  const [showPopOver, setShowPopOver] = useState(false);

  useEffect(() => {
    const url = window.location.pathname;
    setnavUrl(url);
  }, [query]);

  useEffect(() => {
    if (vendorId) {
      getVendorDetails(vendorId)
        .then((response) => {
          setVendorDetails({ ...response.data.data });
          setIsModalVisible(
            !(
              response.data.data.is_subscribed &&
              response.data.data.profile_completion_status == 100
            )
          );
        })
        .catch((error) =>
          console.error("error getting vendor details in Toolbar.js", error)
        );
    }
  }, [vendorId]);

  const onChange = (e) => {
    const value = e.target.value;
    let selectedVendor = vendors.find((item) => item.vendor_id === value);
    setShowPopOver(false);
    history.push(`/vendor/${selectedVendor?.vendor_id}/dashboard`);
    setVendorProfileFormData({ ...defaultFormValues });
  };

  function handleIconClick() {
    vendorId &&
      vendorId !== "improperly-configured" &&
      history.push(`/vendor/${vendorDetails?.id}/dashboard`);
  }

  const changeOrg = (
    <Radio.Group onChange={onChange} value={vendorDetails?.id}>
      <Space direction="vertical">
        {vendors &&
          vendors.map((item) => (
            <Radio key={item.vendor_id} value={item.vendor_id}>
              {item.name}
            </Radio>
          ))}
      </Space>
    </Radio.Group>
  );

  return (
    <Fragment>
      <Row style={{ width: "100%" }} className="menu">
        <Col sm={8} md={6} lg={5}>
          <div className="menu-logo" onClick={handleIconClick}>
            {isDesktop ? <Logo /> : <MobileLogo />}
          </div>
        </Col>

        <Col sm={8} md={6} lg={8} className="menu-organization">
          {vendors && vendors.length !== 0 && (
            <>
              {isMobileOnly ? (
                <span
                  className={
                    navUrl.includes("/vendor-profile")
                      ? "active-org"
                      : "non-active-org"
                  }
                >
                  {vendorDetails?.name || ""}{" "}
                </span>
              ) : (
                <>
                  <span
                    className={
                      navUrl.includes("/vendor-profile")
                        ? "active-org"
                        : "non-active-org"
                    }
                  >
                    {vendorDetails?.name || ""}{" "}
                  </span>
                  {vendors && vendors.length > 1 && (
                    <span className="org-change">
                      <Popover
                        visible={showPopOver}
                        content={changeOrg}
                        trigger="click"
                        onVisibleChange={(visible) => setShowPopOver(visible)}
                      >
                        <Button
                          onClick={() => setShowPopOver(true)}
                          type="text"
                          style={{
                            color: "#fff",
                            fontSize: "10px",
                            padding: "2px 5px",
                          }}
                        >
                          Change Org
                        </Button>
                      </Popover>
                    </span>
                  )}
                </>
              )}
            </>
          )}
        </Col>

        <Col sm={0} md={6} lg={10}>
          {vendors && vendors.length !== 0 && (
            <NavigationItems navUrl={navUrl} vendorId={vendorDetails?.id} />
          )}
        </Col>

        <Col sm={6} md={2} lg={1} xl={1} className="menu-dropdown">
          <UserAvatar />
        </Col>
      </Row>
    </Fragment>
  );
};
export default Toolbar;
