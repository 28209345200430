import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

export const OwnershipDetailsColumns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
    fixed: "left",
    width: 100,
    ellipsis: true,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
    width: 100,
    ellipsis: true,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 100,
    ellipsis: true,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    width: 150,
    ellipsis: true,
  },
  {
    title: "Owner",
    dataIndex: "is_owner",
    key: "is_owner",
    width: 80,

    ellipsis: true,
    render: (record) =>
      record ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#cf1322" />
      ),
  },
  {
    title: "Ownership Type",
    dataIndex: "ownership_type",
    key: "ownership_type",
    width: 150,
    ellipsis: true,
  },
  {
    title: "Dob",
    dataIndex: "dob",
    key: "dob",
    width: 100,
    ellipsis: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 150,
    ellipsis: true,
  },
  {
    title: "Tax Id",
    dataIndex: "tax_id",
    key: "tax_id",
    width: 120,
    ellipsis: true,
  },

  {
    title: "Street",
    dataIndex: "address",
    key: "address",
    width: 150,
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    width: 150,
    ellipsis: true,
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
    width: 150,
    ellipsis: true,
  },
  {
    title: "Zipcode",
    dataIndex: "zipcode",
    key: "zipcode",
    width: 100,
    ellipsis: true,
  },
  {
    title: "Ownership Percent",
    dataIndex: "ownership_percent",
    key: "ownership_percent",
    width: 100,
    ellipsis: true,
    render: (value) => `${value} %`,
  },
  {
    title: "Ownership From",
    dataIndex: "ownership_from",
    key: "ownership_from",
    width: 140,
    ellipsis: true,
  },
  {
    title: "Ownership To",
    dataIndex: "ownership_to",
    key: "ownership_to",
    width: 140,
    ellipsis: true,
  },
];

export const LineOfCreditsColumns = [
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  },
  {
    title: "Available Amount",
    dataIndex: "available_amount",
    key: "available_amount",
    render: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  },
  {
    title: "Expiration Date",
    dataIndex: "expiration_date",
    key: "expiration_date",
  },
];

export const SuretyContactsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
];

export const BankLiensColumns = [
  {
    title: "Creditor",
    dataIndex: "creditor",
    key: "creditor",
    fixed: "left",
    ellipsis: true,
    width: 100,
  },
  {
    title: "Specify Lien or Judgment and Where Filed",
    dataIndex: "lien_location",
    key: "lien_location",
    ellipsis: true,
    width: 250,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    ellipsis: true,
    width: 70,
    render: (amount) => <>$ {amount}</>,
  },
  {
    title: "Date Filed",
    dataIndex: "date_filed",
    key: "date_files",
    ellipsis: true,
    width: 80,
  },
  {
    title: "Describe circumstances and current status",
    dataIndex: "current_status",
    key: "current_status",
    width: 250,
    ellipsis: true,
  },
];

export const CriminalLitigationColumns = [
  {
    title: "Crime Specified",
    dataIndex: "crime_specified",
    key: "crime_specified",
  },
  {
    title: "Agency or Court",
    dataIndex: "agency_court",
    key: "agency_court",
  },
  {
    title: "Case No",
    dataIndex: "case_no",
    key: "case_no",
  },
  {
    title: "Nature of Action",
    dataIndex: "nature_of_action",
    key: "nature_of_action",
  },
  {
    title: "Date Instituted",
    dataIndex: "date_instituted",
    key: "date_instituted",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

export const CivilLitigationColumns = [
  {
    title: "Case Name",
    dataIndex: "case_name",
    key: "case_name",
  },
  {
    title: "Caption/Parties",
    dataIndex: "caption",
    key: "caption",
  },
  {
    title: "Court/Panel",
    dataIndex: "court",
    key: "court",
  },
  {
    title: "Case No",
    dataIndex: "case_no",
    key: "case_no",
  },
  {
    title: "Nature Of Action",
    dataIndex: "nature_of_action",
    key: "nature_of_action",
  },
  {
    title: "Date Instituted",
    dataIndex: "date_instituted",
    key: "date_instituted",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

export const AdministrativeVoilationColumns = [
  {
    title: "Crime Specified",
    dataIndex: "crime_specified",
    key: "crime_specified",
  },
  {
    title: "Agency or Court",
    dataIndex: "agency_court",
    key: "agency_court",
  },
  {
    title: "Case No",
    dataIndex: "case_no",
    key: "case_no",
  },
  {
    title: "Nature Of Action",
    dataIndex: "nature_of_action",
    key: "nature_of_action",
  },
  {
    title: "Date Instituted",
    dataIndex: "date_instituted",
    key: "date_instituted",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

export const ContractsInProgressColumns = [
  {
    title: "Owner Name",
    dataIndex: "owner_name",
    key: "owner_name",
  },
  {
    title: "Construction Manager or GC",
    dataIndex: "construction_manager",
    key: "construction_manager",
  },
  {
    title: "Value of Contract",
    dataIndex: "value_of_contract",
    key: "value_of_contract",
    render: (value) => `$ ${value}`,
  },
  {
    title: "Percent Complete",
    dataIndex: "percent_complete",
    key: "percent_complete",
    render: (value) => `${value}%`,
  },
  {
    title: "Expected Completion Date",
    dataIndex: "expected_completion_date",
    key: "expected_completion_date",
  },
];

export const ServiceLicenseColumns = [
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "License Type",
    dataIndex: "license_type",
    key: "license_type",
  },
  {
    title: "Name On License",
    dataIndex: "name_on_license",
    key: "name_on_license",
  },
  {
    title: "License Number",
    dataIndex: "license_number",
    key: "license_number",
  },
  {
    title: "Exp Date",
    dataIndex: "exp_date",
    key: "exp_date",
  },
];

export const RevokedLicenseColumns = [
  {
    title: "License Type",
    dataIndex: "revoked_license_type",
    key: "revoked_license_type",
  },
  {
    title: "Name on License or Application",
    dataIndex: "revoked_application",
    key: "revoked_application",
  },
  {
    title: "License Number",
    dataIndex: "revoked_license_number",
    key: "revoked_license_number",
  },
  {
    title: "Date Revoked, Suspended or Denied",
    dataIndex: "exp_date",
    key: "exp_date",
  },
  {
    title: "Reason for Revocation, Suspension or Denial",
    dataIndex: "reason_for_revocation",
    key: "reason_for_revocation",
  },
];

export const CertificationColumns = [
  {
    title: "Certification Type",
    dataIndex: "certification_type",
    key: "certification_type",
  },
  {
    title: "Certification Agency",
    dataIndex: "certification_agency",
    key: "certification_agency",
  },
  {
    title: "Exp. Date",
    dataIndex: "certification_exp_date",
    key: "certification_exp_date",
  },
];

export const PastProjectsColumns = [
  {
    title: "Name Of Project",
    dataIndex: "name_of_project",
    key: "name_of_project",
  },
  {
    title: "Owner/Architect/ CM/GC",
    dataIndex: "project_key_contact",
    key: "project_key_contact",
  },
  {
    title: "Amount Of Contract",
    dataIndex: "amount_of_contract",
    key: "amount_of_contract",
    render: (value) => `$ ${value}`,
  },
  {
    title: "Scope",
    dataIndex: "scope",
    key: "scope",
  },
  {
    title: "% Subcontracted",
    dataIndex: "percent_subcontracted",
    key: "percent_subcontracted",
    render: (value) => `${value}%`,
  },
  {
    title: "Completed Date",
    dataIndex: "date_completed",
    key: "date_completed",
  },
];

export const ContractorReferencesColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Project",
    dataIndex: "project",
    key: "project",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
];

export const SupplierReferencesColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Project",
    dataIndex: "project",
    key: "project",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
];

export const TerminatedContractsColumns = [
  {
    title: "Entity with whom contracted",
    dataIndex: "entity_with_whom_contracted",
    key: "entity_with_whom_contracted",
  },
  {
    title: "Amount of Contract",
    dataIndex: "amount_of_contract",
    key: "amount_of_contract",
    render: (value) => `$ ${value}`,
  },
  {
    title: "Scope",
    dataIndex: "scope_of_contract",
    key: "scope_of_contract",
  },
  {
    title: "Reason",
    dataIndex: "reason_for_termination",
    key: "reason_for_termination",
  },
];

export const LiquidatedDamagesColumns = [
  {
    title: "Entity with whom contracted",
    dataIndex: "entity_with_whom_contracted",
    key: "entity_with_whom_contracted",
  },
  {
    title: "Amount of Contract",
    dataIndex: "amount_of_contract",
    key: "amount_of_contract",
    render: (value) => `$ ${value}`,
  },
  {
    title: "Scope of Contract",
    dataIndex: "scope_of_contract",
    key: "scope_of_contract",
  },
  {
    title: "Days Behind Schedule",
    dataIndex: "days_behind_schedule",
    key: "days_behind_schedule",
  },
  {
    title: "Amount Of LDS",
    dataIndex: "amount_of_lds",
    key: "amount_of_lds",
    render: (value) => `$ ${value}`,
  },
];

export const IntegrityMonitorColumns = [
  {
    title: "Name of Integrity Monitor",
    dataIndex: "name_of_monitor",
    key: "name_of_monitor",
  },
  {
    title: "Reason for Engagement",
    dataIndex: "reason_for_engagement",
    key: "reason_for_engagement",
  },
  {
    title: "Agency/Owner/CM/GC who Required and Project",
    dataIndex: "key_contact",
    key: "key_contact",
  },
  {
    title: "Date Engaged",
    dataIndex: "date_engaged",
    key: "date_engaged",
  },
  {
    title: "Status and Disposition",
    dataIndex: "status_disposition",
    key: "status_disposition",
  },
];

export const PrevDisqualificationsColumns = [
  {
    title: "Type of Action",
    dataIndex: "type_of_action",
    key: "type_of_action",
  },
  {
    title: "Agency/Owner/CM/GC",
    dataIndex: "key_contact",
    key: "key_contact",
  },
  {
    title: "Nature of Action",
    dataIndex: "nature_of_action",
    key: "nature_of_action",
  },
  {
    title: "Date Instituted",
    dataIndex: "date_instituted",
    key: "date_instituted",
  },
  {
    title: "Exp. Date",
    dataIndex: "expiry_date",
    key: "expiry_date",
  },
  {
    title: "Status and Disposition",
    dataIndex: "status_disposition",
    key: "status_disposition",
  },
];

export const DisqualifiedSubContractorColumns = [
  {
    title: "Name of Subcontractor",
    dataIndex: "name_of_subcontractor",
    key: "name_of_subcontractor",
  },
  {
    title: "Nature of Action",
    dataIndex: "nature_of_action",
    key: "nature_of_action",
  },
  {
    title: "Contract on which Subcontractor Was Utilized",
    dataIndex: "contract_details",
    key: "contract_details",
  },
  {
    title: "Explanation",
    dataIndex: "explanation",
    key: "explanation",
  },
];

export const WorkersInsuranceColumns = [
  {
    title: "Line Of Insurance",
    dataIndex: "line_of_insurance",
    key: "line_of_insurance",
  },
  {
    title: "Carrier",
    dataIndex: "carrier",
    key: "carrier",
  },
  {
    title: "Start Of Coverage",
    dataIndex: "start_of_coverage",
    key: "start_of_coverage",
  },
  {
    title: "End Of Coverage",
    dataIndex: "end_of_coverage",
    key: "end_of_coverage",
  },
];

export const ChangesInInsuranceCarriersColumns = [
  {
    title: "Line Of Insurance",
    dataIndex: "line_of_insurance",
    key: "line_of_insurance",
  },
  {
    title: "Carrier",
    dataIndex: "carrier",
    key: "carrier",
  },
  {
    title: "Start Of Coverage",
    dataIndex: "start_of_coverage",
    key: "start_of_coverage",
  },
  {
    title: "End Of Coverage",
    dataIndex: "end_of_coverage",
    key: "end_of_coverage",
  },
];
