import React, { useContext, useEffect, useState } from "react";
import { Form, Collapse, Upload, Button, message, Divider } from "antd";
import "./Ownership.css";
import {
  narrowLabelLayout,
  tailLayout,
} from "../../../../config/formLayout.config";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import {
  createOwnership,
  getOwnership,
  getOwnershipDetails,
} from "../../../../services/vendorProfile.services";
import {
  profileCompletion,
  uploadFileUrl,
} from "../../../../constants/defaultKeys";
import { UploadOutlined } from "@ant-design/icons";
import OwnershipDetails from "./OwnershipDetails/OwnershipDetails";
import { getTokenIfNotExpired } from "../../../../helpers/utility";
const { Panel } = Collapse;

const validateMessages = {
  required: "'${label}' is required!",
};

const uploadButtonProps = {
  accept: ".doc, .docx, .pdf, .csv",
  maxCount: 1,
  action: uploadFileUrl,
  name: "docs",
  onChange(info) {
    // if headers are set as default then antd is setting the header before loggin into the application,
    // so setting headers when file change is triggered
    uploadButtonProps["headers"] = {
      Authorization: `Bearer ${getTokenIfNotExpired()}`,
    };

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const Ownership = (props) => {
  const vendorId = props.vendorId;
  const [form] = Form.useForm();
  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );

  const [tableData, setTableDataData] = useState([]);
  const [percentOwnership, setPercentOwnership] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const ownershipDetailsData = vendorProfileFormData.ownership;
  const uploadedFileList = ownershipDetailsData.file_list;

  useEffect(() => {
    if (vendorId) {
      fetchOwnership();
      fetchOwnershipDetails();
    }
  }, [vendorId]);

  function setVendorProfileDataOwnership(changedValues) {
    vendorProfileFormData.ownership = {
      ...ownershipDetailsData,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function fetchOwnership() {
    getOwnership(vendorId)
      .then((res) => {
        if (res.data.ownership_chart && res.data.ownership_chart !== "-") {
          setVendorProfileDataOwnership({
            ...ownershipDetailsData,
            file_list: [
              {
                uid: 0,
                name:
                  uploadedFileList[0]?.response?.names?.[0] ||
                  uploadedFileList[0]?.name ||
                  res.data.ownership_chart,
                status: "done",
              },
            ],
          });
          form.setFieldsValue({
            ownership_chart:
              uploadedFileList?.[0].name || res.data.ownership_chart,
          });
        }
      })
      .catch((error) => {
        console.error("get ownership error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting ownership");
        }
      });
  }

  function fetchOwnershipDetails() {
    getOwnershipDetails(vendorId)
      .then((res) => {
        let percentOwnershipInitialValue = 0;
        setTableDataData(res.data.data);
        setPercentOwnership(
          res.data.data.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.ownership_percent,
            percentOwnershipInitialValue
          )
        );
      })
      .catch((error) => {
        console.error("get ownership details error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting ownership details");
        }
      });
  }

  function normFile(e) {
    setVendorProfileDataOwnership({ file_list: e.fileList });

    if (
      e &&
      e?.fileList &&
      e?.fileList?.length > 0 &&
      e?.fileList?.[0].response
    ) {
      return e?.fileList[0]?.response?.names?.[0];
    }
  }

  function onFinish(values) {
    if (Math.round(percentOwnership) < 99.5) {
      message.error("Sum of Percent Ownership cannot be less than 100");
    } else if (Math.round(percentOwnership) > 100.5) {
      message.error("Sum of Percent Ownership cannot be greater than 100");
    } else {
      setDisabled(true);

      let requestBody = {
        ...values,
        vendor_id: vendorId,
        ownership_chart: values.ownership_chart ? values.ownership_chart : "-",
      };
      createOwnership(requestBody)
        .then((res) => {
          setDisabled(false);
          // on saving details successfully, changing the step status to true using useContext API
          if (!vendorDetails.ownership_status) {
            setVendorDetails({
              ...vendorDetails,
              ownership_status: true,
              profile_completion_status:
                vendorDetails.profile_completion_status +
                profileCompletion.ownership_status,
            });
          }
          message.success(res.data.message);
          // navigating user to next step on save
          props.onStepChange(props.currentStep + 1);
        })
        .catch((error) => {
          setDisabled(false);
          console.error("create ownership error", error);
          if (error && error.response) {
            if (error.response.status === 400) {
              message.error(
                "Something went wrong with the form being saved. Please contact administrator"
              );
            } else {
              message.error(
                error.response.data.message || error.response.message
              );
            }
          } else {
            message.error("Error creating ownership");
          }
        });
    }
  }
  return (
    <Form
      name="bank_details"
      form={form}
      {...narrowLabelLayout}
      validateMessages={validateMessages}
      onFinish={onFinish}
      colon={false}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Ownership/Key Individuals" key="1">
          <OwnershipDetails
            tableData={tableData}
            vendorId={vendorId}
            ownershipDetailsData={ownershipDetailsData}
            fetchOwnershipDetails={fetchOwnershipDetails}
          />
          <Divider />
          <Form.Item
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 8 }}
            name="ownership_chart"
            className="multiline-form-item"
            label="You may upload an ownership organizational chart here"
            valuePropName="ownership_chart"
            getValueFromEvent={normFile}
          >
            <Upload {...uploadButtonProps} fileList={uploadedFileList}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Ownership;
