import React, { useState } from "react";
import DrawerWizz from "../../../reusable/DrawerWizz";
import { Select, Input, Button, Form, message, Upload } from "antd";
import {
  adminApplicationPriorityOptions,
  applicationStatusValues,
  informationRequiredOptions,
  uploadFileUrl,
} from "../../../constants/defaultKeys";
import { adminUpdateApplicationById } from "../../../services/applications.services";
import { UploadOutlined } from "@ant-design/icons";
import { getTokenIfNotExpired } from "../../../helpers/utility";
import { contractValidation } from "../../../config/validation.config";

const { Option } = Select;

const statusValues = applicationStatusValues.slice(2, 8);

const uploadButtonProps = {
  accept: ".doc, .docx, .pdf, .csv",
  maxCount: 1,
  action: uploadFileUrl,
  name: "docs",
  onChange(info) {
    // if headers are set as default then "antd" is setting the header before loggin into the application,
    // so setting headers when file change is triggered
    uploadButtonProps["headers"] = {
      Authorization: `Bearer ${getTokenIfNotExpired()}`,
    };
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const AdminRemarks = ({
  data,
  applicationId,
  adminsList,
  closeDrawer,
  refreshPage,
}) => {
  const [form] = Form.useForm();

  const [formData, setFormData] = useState(data && data);
  const [uploadedFile, setUploadedFile] = useState(
    data.certificate
      ? [
          {
            uid: 0,
            name: data?.certificate.name,
            status: "done",
          },
        ]
      : []
  );

  function onFormValuesChange(changedValues) {
    if (changedValues.status === "InformationRequired") {
      changedValues.information_required = [];
    }
    setFormData({ ...formData, ...changedValues });
  }

  function updateApplicationStatus(values) {
    let requestBody = {
      application_id: applicationId,
      status: values.status,
      information_required: values.information_required,
      remarks: values.remarks,
      priority: values.priority,
      assignee: values.assignee,
      certificate: values.certificate,
      contr_value: Number(values.contr_value),
    };

    //Value of certificate should be string
    if (Array.isArray(values.certificate)) {
      requestBody.certificate = values.certificate[0]?.name.name;
    }
    if (requestBody.status !== "InformationRequired") {
      requestBody.information_required = null;
    }
    adminUpdateApplicationById(requestBody)
      .then((res) => {
        message.success(res.data.message);
        closeDrawer(false);
        refreshPage();
      })
      .catch((error) => {
        console.error(error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error updating application status");
        }
      });
  }

  const normFile = (e) => {
    setUploadedFile(e.fileList);
    if (e && e.fileList && e.fileList.length > 0 && e.fileList[0].response) {
      return e?.fileList[0]?.response?.names[0];
    }
  };

  return (
    <DrawerWizz
      title="Remarks"
      handleCancel={() => {
        setFormData(null);
        closeDrawer();
      }}
      content={
        <Form
          layout="vertical"
          initialValues={{
            ...data,
            certificate: data.certificate
              ? [
                  {
                    uid: 0,
                    name: data.certificate,
                    status: "done",
                  },
                ]
              : null,
            information_required: data.information_required
              ? data.information_required
              : [],
            priority: data.priority
              ? data.priority
              : adminApplicationPriorityOptions[2].value,
            assignee: adminsList.find(
              (item) =>
                item.first_name + " " + item.last_name === formData.assignee
            )?.id,
          }}
          onValuesChange={onFormValuesChange}
          onFinish={updateApplicationStatus}
          preserve={false}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="status"
            label="Application Status"
            rules={[
              {
                required: true,
                message: "Please select status!",
              },
            ]}
          >
            <Select disabled={data.status === "Pending"}>
              {statusValues.map((eachValue) => (
                <Option key={eachValue.value} value={eachValue.value}>
                  {eachValue.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            name="certificate"
            className="multiline-form-item"
            label="Upload File"
            valuePropName="certificate"
            getValueFromEvent={normFile}
          >
            <Upload {...uploadButtonProps} fileList={uploadedFile}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          {formData?.status === "InformationRequired" && (
            <Form.Item
              name="information_required"
              label="Information Required"
              rules={[
                {
                  required: true,
                  message: "Please select information required!",
                },
              ]}
            >
              <Select mode="multiple">
                {informationRequiredOptions.map((eachValue) => (
                  <Option key={eachValue.value} value={eachValue.value}>
                    {eachValue.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="priority"
            label="Priority"
            rules={[
              {
                required: true,
                message: "Please select priority!",
              },
            ]}
          >
            <Select>
              {adminApplicationPriorityOptions.map((eachValue) => (
                <Option key={eachValue.value} value={eachValue.value}>
                  {eachValue.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="assignee"
            label="Assign To"
            rules={[
              {
                required: true,
                message: "Please select assigned to!",
              },
            ]}
          >
            {/* `${eachValue?.first_name} ${eachValue?.last_name}` */}
            <Select>
              {adminsList.map(
                (eachValue) =>
                  eachValue.first_name !== "" &&
                  eachValue.last_name !== "" && (
                    <Option key={eachValue.id} value={eachValue.id}>
                      {eachValue.first_name} {eachValue.last_name}
                    </Option>
                  )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="contr_value"
            label="Contract Value"
            rules={contractValidation}
          >
            <Input
              prefix={"$"}
              controls={false}
              style={{
                width: 200,
              }}
            />
          </Form.Item>
          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};

export default AdminRemarks;
