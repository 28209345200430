import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Table, Space, message } from "antd";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import LitigationModel from "./LitigationModel";
import {
  createLitigations,
  deleteLitigations,
  getLitigations,
  updateLitigations,
} from "../../../../../services/adminForm.services";
import { useQuery } from "../../../../../helpers/utility";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const Litigation = ({ selectedOwner }) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [data, setData] = useState([]);

  const query = useQuery();
  const applicationId = query.get("id");

  const columns = [
    {
      title: "Case Name",
      dataIndex: "case_name",
      key: "case_name",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Court",
      dataIndex: "court",
      key: "court",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Index No",
      dataIndex: "index_no",
      key: "index_no",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Date Filed",
      dataIndex: "date_filed",
      key: "date_filed",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Type Of Action",
      dataIndex: "type_of_action",
      key: "type_of_action",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  function openModal(data) {
    setVisible(true);
    setEditTable(data);
  }

  useEffect(() => {
    fetchLitigations();
  }, [selectedOwner]);

  function fetchLitigations(id = applicationId) {
    getLitigations({
      applicationId: id,
      ownerId: selectedOwner,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("get litigations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("get litigations error");
        }
      });
  }

  function saveLitigations(values) {
    let data = values;
    data.date_filed = moment(values.date_filed).format(dateFormat);
    data.application = applicationId;
    data.ownership = selectedOwner;

    createLitigations(data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Saved Litigations");
        fetchLitigations(applicationId);
      })
      .catch((error) => {
        console.error("save litigations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to save Litigations"
          );
        } else {
          message.error("save litigations error");
        }
      });
  }

  function handleUpdateLitigations(values) {
    let data = values;
    data.date_filed = moment(values.date_filed).format(dateFormat);
    data.application = applicationId;

    updateLitigations(editTable.id, data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Updated Litigations");
        fetchLitigations(applicationId);
      })
      .catch((error) => {
        console.error("save litigations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to update Litigations"
          );
        } else {
          message.error("save litigations error");
        }
      });
  }

  function removeColumn(id) {
    deleteLitigations(id)
      .then((res) => {
        message.success(res.data.message || "Removed Litigations");
        fetchLitigations(applicationId);
      })
      .catch((error) => {
        console.error("delete litigations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to remove litigations"
          );
        } else {
          message.error("delete litigations error");
        }
      });
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Button
            onClick={() => openModal({ status: "create" })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Details
          </Button>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <LitigationModel
          create={saveLitigations}
          update={handleUpdateLitigations}
          title="Litigation"
          editTable={editTable}
          setVisible={setVisible}
        />
      )}
    </Fragment>
  );
};

export default Litigation;
