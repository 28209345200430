import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Typography,
  message,
  Row,
  Col,
  Divider,
  Skeleton,
  Input,
  Form,
  Space,
  Tag,
  Collapse,
  List,
} from "antd";
import {
  getVendorProfile,
  AttachVendorProfile,
  UpdateVendorApplication,
} from "../../services/applications.services";
import ResultWizz from "../../blocks/ResultWizz/ResultWizz";
import { useHistory } from "react-router";
import PQQDescriptions from "./PQQDescriptions";
import AlertWizz from "../../blocks/AlertWizz/AlertWizz";
import {
  adminApplicationPriorityOptions,
  applicationStatusValues,
  USER,
} from "../../constants/defaultKeys";
import { FilePdfOutlined } from "@ant-design/icons";
import { isUserClientOrProjectAdmin } from "../../helpers/utility";
import { getItem } from "../../helpers/localStorage";
import { getPaymentURL } from "../../services/payment.services";

const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

const PQQContent = (props) => {
  const {
    application,
    vendorId,
    vendorDetails,
    refresh,
    isUserAdmin,
    setShowDrawer,
    openPQQForm,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = JSON.parse(getItem(USER));

  useEffect(() => {
    if (!application?.application_id) return;
    getVendorProfile(application?.application_id)
      .then((res) => {
        setData(res.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("error getting e vendor profile", err);
        setIsLoading(false);
      });
  }, [, application]);

  const attachApp = () => {
    setIsLoading(true);
    AttachVendorProfile(vendorId, application.id)
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
        refresh();
      })
      .catch((error) => {
        console.error("attach vendor profile error", error);
        setIsLoading(false);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("attach vendor profile error");
        }
      });
  };

  const updateAttachApp = (values) => {
    const formData = {
      vendor_id: vendorId,
      application_id: application.id,
      bid_nos: application.bid_no,
      approve_and_submit: values.approve_and_submit,
      submitted_by: values.submitted_by,
    };
    setIsLoading(true);
    if (vendorDetails?.is_approver) {
      UpdateVendorApplication(formData)
        .then((response) => {
          refresh();
        })
        .catch((error) => {
          message.error(error.response.data.message);
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    } else {
      message.error("You're not authorized to attach profile");
    }
  };

  async function handlePayment() {
    try {
      setIsLoading(true);
      const res = await getPaymentURL({
        vendor_id: vendorId,
        pqq: application?.application_id,
      });
      window.location.href = res.data.url;
    } catch (error) {
      console.error("error", error);
      if (error && error.response) {
        message.error(error.response.data.message || error.response.message);
      } else {
        message.error("get payment URL error");
      }
    } finally {
      setIsLoading(false);
    }
  }

  function appStatusProps() {
    switch (application.status) {
      case applicationStatusValues[0].value:
        return {
          status: "info",
          title: "Thanks for attaching your latest i4Profile",
          subTitle:
            "Please review the PQQ below and click certify and submit at the bottom of the application. If you wish to edit any of the fields, please update your i4Profile and reattach it.",
        };
      case applicationStatusValues[1].value:
        return {
          status: "success",
          title: "Thanks for submitting application",
          subTitle:
            "We may require additional information as part of the i4 verification process. We'll start reviewing your PQQ submission and send notifications to your email if any additional information is required. If you like to change your i4Profile and resubmit your application, you can do it using the button below the PQQ details.",
        };
      case applicationStatusValues[2].value:
      case applicationStatusValues[3].value:
        return {
          status: "success",
          title: "Your application is under review",
          subTitle:
            "We've started reviewing your application and will reach out to you if any additional information is required. Please note that you won't be able to make any edits to your PQQ application.",
        };
      case applicationStatusValues[4].value:
        return {
          status: "warning",
          title: "Additional Information Required",
          subTitle:
            "Please review comments on the PQQ application and edit your i4Profile. Once you change your i4Profile as advised on comments, please reattach your application. Please reach out to us for any questions.",
        };
      case applicationStatusValues[5].value:
        return {
          status: "success",
          title: "Congratulations! your PQQ is approved",
          subTitle:
            "We're glad to let you know your PQQ has been approved. We have forwarded the client about your application status. Thanks for providing us the opportunity to verify your profile",
        };
      case applicationStatusValues[6].value:
        return {
          status: "error",
          title: "Sorry! your PQQ is rejected",
          subTitle:
            "We're sorry to let you know your PQQ has been rejected. Your application did not meet the eligibility criteria set by the client. Thanks for providing us the opportunity to verify your profile",
        };
      // NEED TO CHANGE COMPLETE STATUS MESSAGE AND ACTIONS
      case applicationStatusValues[7].value:
        return {
          completed: true,
          status: "success",
          title: "Your PQQ is Completed",
          subTitle:
            "This is to inform you that your PQQ is Completed. Thanks for providing us the opportunity to verify your profile ",
          // "We're glad to let you know your PQQ has been Completed. We have forwarded the client about your application status. Thanks for providing us the opportunity to verify your profile",
        };
      default:
        return "";
    }
  }

  function resultProps() {
    switch (isUserAdmin) {
      case true:
        return application.status === applicationStatusValues[0].value
          ? {
              title: "Vendor has not attached i4Profile to the PQQ yet",
              subTitle:
                "You may please review his i4Profile by using the button above.",
            }
          : {
              title: "Something wrong with loading the details",
              subTitle: "Please contact web development team for help",
            };
      default:
        return application?.length === 0
          ? {
              completed: true,

              status: "success",
              title: "Congratulations on completing your i4 profile!",
              subTitle:
                "Please create a new PQQ below. Once created you will be able to certify and submit for review",
              extra: (
                <>
                  <Button
                    size="large"
                    type="primary"
                    onClick={openPQQForm}
                    disabled={
                      !(
                        vendorDetails?.is_subscribed ||
                        vendorDetails?.profile_completion_status == 100
                      )
                    }
                  >
                    Create PQQ
                  </Button>
                </>
              ),
              // title: "You don't have any PQQ's",
              // subTitle: "Please create a PQQ to see the PQQ details",
            }
          : vendorDetails?.profile_completion_status !== 100
          ? {
              title: "Please complete your i4 Profile to apply",
              subTitle:
                "Click the below button to complete filling your i4 Profile",
              extra: (
                <Button
                  type="primary"
                  onClick={() =>
                    history.push(
                      `/vendor/${vendorId}/vendor-profile/basic-details`
                    )
                  }
                >
                  Click Here!
                </Button>
              ),
            }
          : !vendorDetails?.is_approver
          ? {
              title: "You need to be an approver to apply",
              subTitle: "Please contact i4Strategies admin for approver role",
            }
          : application?.status === applicationStatusValues[0].value
          ? {
              title: "Apply with your i4Profile",
              subTitle:
                "Please make sure you complete your organization profile before proceeding",
              extra: (
                <Button type="primary" onClick={attachApp}>
                  Proceed to apply
                </Button>
              ),
            }
          : application?.status === applicationStatusValues[7].value
          ? {
              title: "Your application review is completed ",
            }
          : {
              title: "Something went wrong",
              subTitle:
                "Not able to fetch your application details. Please reach out to support for help",
              extra: <Button type="primary">Contact us</Button>,
            };
    }
  }

  function discardAndReattach() {
    switch (application.status) {
      case applicationStatusValues[0].value:
      case applicationStatusValues[1].value:
      case applicationStatusValues[4].value:
        return (
          <Button danger style={{ marginTop: "20px" }} onClick={attachApp}>
            Discard and reattach latest i4Profile
          </Button>
        );
      default:
        return true;
    }
  }

  function certifyBlock() {
    switch (application.is_approved_and_submitted) {
      case false:
        return (
          <Fragment>
            <Divider />
            <Paragraph>
              By checking the box below, I hereby certify that I am an
              authorized representative of the above-name experience the best of
              my knowledge, the information contained in the above completed
              pre-qualification questionnaire submitted on the date specified
              above (or submitted separately) is full, complete and accurate;
              and to the best of my knowledge, that information continues to be
              full, complete and accurate.
            </Paragraph>
            <Divider />
            <Form
              name="pqqSubmitForm"
              colon={false}
              form={form}
              onFinish={updateAttachApp}
              scrollToFirstError
            >
              <Form.Item
                name="approve_and_submit"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Please check this box to continue to submit"
                            )
                          ),
                  },
                ]}
              >
                <Checkbox disabled={!vendorDetails?.is_subscribed}>
                  I Hereby Certify the Above Statement
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="submitted_by"
                label="Submitted by"
                rules={[
                  {
                    required: true,
                    message: "Please input name!",
                  },
                ]}
              >
                <Input
                  disabled={!vendorDetails?.is_subscribed}
                  style={{ maxWidth: 400 }}
                />
              </Form.Item>
              <Form.Item>
                {!application.is_paid ? (
                  <Button type="primary" size="large" onClick={handlePayment}>
                    Pay Now
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={!vendorDetails?.is_subscribed}
                  >
                    Submit
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Fragment>
        );
      default:
        return true;
    }
  }

  function fileViewer(file) {
    return (
      <Text>
        {file?.url ? (
          <a href={file?.url} target="_blank" rel="noreferrer">
            View Report <FilePdfOutlined />
          </a>
        ) : (
          "-"
        )}
      </Text>
    );
  }

  function remarksBlock() {
    return (
      <AlertWizz
        message={
          <List
            itemLayout="horizontal"
            dataSource={application && [application]}
            renderItem={(item) => (
              <List.Item>
                <Row style={{ width: "100%" }} type="flex">
                  <Col sm={8} md={10} lg={12} xl={10}>
                    <p>Status</p>
                    <Tag
                      title="PQQ Status"
                      color={
                        applicationStatusValues.find(
                          (eachStatus) =>
                            eachStatus?.value === item?.status &&
                            eachStatus?.color
                        )?.color
                      }
                    >
                      {
                        applicationStatusValues.find(
                          (eachStatus) => eachStatus?.value === item?.status
                        )?.label
                      }
                    </Tag>
                    {item?.status === "InformationRequired" && (
                      <Text ellipsis>
                        ({item.information_required?.join(", ")})
                      </Text>
                    )}
                  </Col>
                  <Col sm={8} md={7} lg={6} xl={5}>
                    <p>Priority</p>
                    <Tag
                      title="Priority"
                      color={
                        adminApplicationPriorityOptions.find(
                          (eachStatus) =>
                            eachStatus?.value === item?.priority &&
                            eachStatus?.color
                        )?.color
                      }
                    >
                      {
                        adminApplicationPriorityOptions.find(
                          (eachStatus) => eachStatus?.value === item?.priority
                        )?.label
                      }
                    </Tag>
                  </Col>
                  <Col sm={8} md={7} lg={6} xl={5}>
                    <p>Assignee</p>
                    <Tag title="Assignee" color="default">
                      {item.assignee}
                    </Tag>
                  </Col>
                  {!userDetails?.is_preconstruction_role && (
                    <Col sm={8} md={7} lg={6} xl={4}>
                      <p>Report</p>
                      {fileViewer(item.certificate)}
                    </Col>
                  )}
                </Row>
              </List.Item>
            )}
          />
        }
        description={
          <Row style={{ width: "100%" }} type="flex">
            <Col span={24}>Remarks: {application?.remarks}</Col>
          </Row>
        }
        type="success"
        action={
          (!isUserClientOrProjectAdmin() ||
            !userDetails?.is_preconstruction_role) && (
            <Space direction="vertical">
              <Button
                size="small"
                type="ghost"
                onClick={() => setShowDrawer(true)}
              >
                Edit
              </Button>
            </Space>
          )
        }
      />
    );
  }

  function PQQDescriptionBlock() {
    return (
      <Collapse defaultActiveKey={["1"]} ghost>
        <Panel header="i4Profile" key="1">
          <PQQDescriptions data={data && data} />
        </Panel>
      </Collapse>
    );
  }
  return (
    <Row>
      <Col span={20} offset={2}>
        {isLoading ? (
          <Skeleton active />
        ) : data ? (
          isUserAdmin ? (
            <>
              {remarksBlock()}
              {PQQDescriptionBlock()}
            </>
          ) : (
            <Fragment>
              <Row>
                <Col span={18} offset={3}>
                  <ResultWizz {...appStatusProps()} />
                </Col>
              </Row>
              {PQQDescriptionBlock()}
              {discardAndReattach()}
              {certifyBlock()}
            </Fragment>
          )
        ) : (
          <ResultWizz {...resultProps()} />
        )}
      </Col>
    </Row>
  );
};

export default PQQContent;
