import React, { Fragment } from "react";
import { Col, Row, Tabs } from "antd";
import { useHistory } from "react-router-dom";

const { TabPane } = Tabs;

const MobileNavigationItems = ({ showProfileCompletedForm, queryParams }) => {
  const history = useHistory();
  function onTabChange(key) {
    if (key === "vendor-profile") {
      if (showProfileCompletedForm) {
        history.push(
          `/vendor/${queryParams.vendor_id}/vendor-profile/profile-completed`
        );
      } else {
        history.push(
          `/vendor/${queryParams.vendor_id}/vendor-profile/basic-details`
        );
      }
    } else {
      history.push(`/vendor/${queryParams.vendor_id}/${key}`);
    }
  }

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          <Tabs
            tabPosition="bottom"
            className="bottom-nav"
            size="small"
            // defaultActiveKey={String(this.props.selectedTabKey)}
            onChange={onTabChange}
            tabBarStyle={{
              background: "#0b2239",
              color: "#ffffff",
              fontWeight: "600",
              margin: "0px",
              borderTop: "1px solid #eeeeee",
            }}
          >
            <TabPane tab="Home" key="dashboard" />
            <TabPane tab="i4 Profile" key="vendor-profile" />
            <TabPane tab="Payment" key="get-certified" />
            <TabPane tab="PQQ" key="pqq" />
          </Tabs>
        </Col>
      </Row>
    </Fragment>
  );
};
export default MobileNavigationItems;
