import { Result } from "antd";
import "./ResultWizz.css";

const ResultWizz = (props) => {
  return (
    <Result
      className={props?.completed ? "result-completed" : "result-props"}
      {...props}
    />
  );
};

export default ResultWizz;
