import React from "react";
import { Col, Row, Statistic, Skeleton } from "antd";
import Card from "../../../blocks/Card/Card";

const AdminCards = ({ listData, loading, shouldHideCard }) => {
  return (
    <Row gutter={[12, 12]}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}
        hidden={shouldHideCard.open_pqq}
      >
        <Card title="Open PQQs" className="device-health admin-stats-cards">
          {loading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <Row
              gutter={16}
              type="flex"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col span={24}>
                <Statistic value={listData?.pending_applications_count} />
              </Col>
            </Row>
          )}
        </Card>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 5 }}
        lg={{ span: 5 }}
        hidden={shouldHideCard.pqq_inprocess}
      >
        <Card
          title="PQQs In Process"
          className="days-activity admin-stats-cards"
        >
          {loading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <Row
              gutter={16}
              type="flex"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col span={24}>
                <Statistic value={listData?.pqqs_raised} />
              </Col>
            </Row>
          )}
        </Card>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 5 }}
        lg={{ span: 5 }}
        hidden={shouldHideCard.total_vendors}
      >
        <Card
          title="Total Vendors"
          className="resolution-metrics admin-stats-cards"
        >
          {loading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <Row
              gutter={16}
              type="flex"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col span={24}>
                <Statistic value={listData?.total_vendors_count} />
              </Col>
            </Row>
          )}
        </Card>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 5 }}
        lg={{ span: 5 }}
        hidden={shouldHideCard.revenues}
      >
        <Card title="Revenue" className="revenues admin-stats-cards">
          {loading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <Row
              gutter={16}
              type="flex"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col span={24}>
                <Statistic value={listData?.total_revenue} prefix="$" />
              </Col>
            </Row>
          )}
        </Card>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 5 }}
        lg={{ span: 5 }}
        hidden={shouldHideCard.users_loggedd_in}
      >
        <Card
          title="Users Logged In"
          className="resolution-metrics admin-stats-cards"
        >
          {loading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <Row
              gutter={16}
              type="flex"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col span={24}>
                <Statistic value={listData?.users_logged_in_last_7_days} />
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default AdminCards;
