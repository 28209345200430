import React, { Fragment, useState } from "react";
import { Empty, Button } from "antd";
import CreateApplicationModal from "../Dashboard/ApplicationsList/CreateApplicationModal";
import { isAdmin } from "../../helpers/utility";

const EmptyPQQ = ({ vendorDetails }) => {
  const [visible, setVisible] = useState(false);

  const openPQQForm = () => {
    setVisible(true);
  };

  return (
    <Fragment>
      <Empty description="No Applications Found">
        {!isAdmin() && (
          <Button type="primary" onClick={openPQQForm}>
            Create New PQQ
          </Button>
        )}
      </Empty>
      {visible && (
        <CreateApplicationModal
          setVisible={setVisible}
          vendorDetails={vendorDetails}
        />
      )}
    </Fragment>
  );
};

export default EmptyPQQ;
