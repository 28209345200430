import React, { useState, useEffect } from "react";
import { message } from "antd";
import { ApplicationList } from "../../../services/applications.services";
import "../Dashboard.css";
import "./ApplicationsList.css";
import VendorApplicationsList from "./VendorApplicationsList";

const ApplicationsList = ({ vendorId, vendorDetails }) => {
  const [listData, setListData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (vendorId !== undefined) {
      ApplicationList(vendorId)
        .then((response) => {
          setListData(response?.data?.applications);
          setIsLoading(false);
        })
        .catch((error) => {
          message.error(error.response.data.message);
          setIsLoading(false);
        });
    }
  }, [vendorId]);

  return (
    <VendorApplicationsList
      loading={isLoading}
      listData={listData && listData}
      vendorId={vendorId}
      vendorDetails={vendorDetails && vendorDetails}
    />
  );
};

export default ApplicationsList;
