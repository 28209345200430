import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import {
  createDisqualifiedSubcontractors,
  updateDisqualifiedSubcontractors,
} from "../../../../../services/vendorProfile.services";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const SCDisqualificationsModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, [editTable]);

  function setFormFieldValues(data) {
    form.setFieldsValue({ ...data });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
      };

      updateDisqualifiedSubcontractors(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
      };

      createDisqualifiedSubcontractors(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="name_of_subcontractor"
              label="Name Of Subcontractor"
              rules={[
                {
                  required: true,
                  message: "Please input your name of subcontractor!",
                },
              ]}
            >
              <Input placeholder="Name Of Subcontractor" />
            </Form.Item>
            <Form.Item
              name="nature_of_action"
              label="Nature Of Action"
              rules={[
                {
                  required: true,
                  message: "Please input your nature of action !",
                },
              ]}
            >
              <Input placeholder="Nature Of Action" />
            </Form.Item>
            <Form.Item
              name="contract_details"
              label="Contract Details"
              rules={[
                {
                  required: true,
                  message: "Please input your contract details!",
                },
              ]}
            >
              <Input placeholder="Contract Details" />
            </Form.Item>
            <Form.Item
              name="explanation"
              label="Explanation"
              rules={[
                {
                  required: true,
                  message: "Please input your explanation!",
                },
              ]}
            >
              <Input placeholder="Explanation" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default SCDisqualificationsModal;
