export const companyType = [
  {
    label: "Corporation",
    value: "corporation",
  },
  {
    label: "Limited Liability Company",
    value: "limited_liability_company",
  },
  {
    label: "Sole Proprietor",
    value: "sole_proprietor",
  },
  {
    label: "Partnership",
    value: "partnership",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const radioYesOrNo = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const ownershipType = [
  {
    label: "Entity",
    value: "entity",
  },
  {
    label: "Individual",
    value: "individual",
  },
];
