import {
    Modal,
} from "antd";

export function infoModal({title, onOk, content}) {
    return (
        Modal.info({
            width: "500px",
            title,
            content,
            onOk
        })
    )
}

export function successModal({title, onOk, content}) {
    return (
        Modal.success({
            width: "500px",
            title,
            content,
            onOk
        })
    )
}

export function errorModal({title, onOk, content}) {
    return (
        Modal.error({
            width: "500px",
            title,
            content,
            onOk
        })
    )
}

export function warningModal({title, onOk, content}) {
    return (
        Modal.warning({
            width: "500px",
            title,
            content,
            onOk
        })
    )
}

export function confirmModal({title, onOk, content}) {
    return (
        Modal.confirm({
            width: "500px",
            title,
            content,
            onOk
        })
    )
}

export function ModalDialog({ title, handleCancel, content }) {
    return (
      <Modal
        title={title}
        visible={true}
        width={650}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
      >
        {content}
      </Modal>
    );
  };