import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Input,
  Skeleton,
  message,
  Row,
  Space,
  Table,
} from "antd";
import AddVendorDrawer from "./AddVendorDrawer";
import AddUserDrawer from "./AddUserDrawer";
import {
  getAllUsers,
  getCustomersAndProjects,
  downloadAllUsersData,
} from "../../../services/userProfile.services";
import {
  SearchOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "./Users.css";
import Highlighter from "react-highlight-words";
import ManageUserDrawer from "./ManageUserDrawer";
import mail from "../../../assets/verification/mail.png";
import Phone from "../../../assets/verification/phone.png";
import Person from "../../../assets/verification/person.png";
import Per from "../../../assets/verification/person-1.png";
import Pho from "../../../assets/verification/phone-1.png";
import M from "../../../assets/verification/mail-1.png";
import { downloadDataFiles } from "../../../helpers/utility";
import AddVendorProjects from "./AddVendorProjects";

const Users = () => {
  let searchInput = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [showAddVendorDrawer, setShowAddVendorDrawer] = useState(false);
  const [showAssignDrawer, setShowAssignDrawer] = useState(false);
  const [showAddUserDrawer, setShowAddUserDrawer] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [showManageUserDrawer, setShowManageUserDrawer] = useState(false);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isUsersDataDownloading, setUsersDataDownloading] = useState(false);

  const getColumnSearchProps = (dataIndex, name) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${name}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? dataIndex === "vendors"
          ? record[dataIndex]?.find((item) =>
              item?.vendor_name
                ?.toString()
                ?.toLowerCase()
                ?.includes(value.toLowerCase())
            )?.vendor_name
          : record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }

  const columns = [
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
      width: 200,
      fixed: "left",
      ...getColumnSearchProps("username", "Email"),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 140,
      ...getColumnSearchProps("first_name", "First Name"),
      render: (value) => (value ? value : `-`),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: 140,
      ...getColumnSearchProps("last_name", "Last Name"),
      render: (value) => (value ? value : `-`),
    },
    {
      title: "Mobile",
      dataIndex: "contact_number",
      key: "contact_number",
      width: 140,
      ...getColumnSearchProps("contact_number", "Mobile"),
      render: (value) => (value ? value : `-`),
    },
    {
      title: "Vendor(s)",
      dataIndex: "vendors",
      key: "vendors",
      width: 200,
      ...getColumnSearchProps("vendors", "Vendor Name"),
      render: (_, record, index) => (
        <ul key={index}>
          {record?.vendors.length !== 0 ? (
            <>
              {record.vendors.map((item) => (
                <li>{item.vendor_name}</li>
              ))}
            </>
          ) : (
            "-"
          )}
        </ul>
      ),
    },
    {
      title: "Verification",
      width: 160,
      render: (_, record) => handleVerifiedIcons(record),
    },
    {
      title: "Is Active?",
      dataIndex: "is_active",
      key: "is_active",
      width: 120,
      sorter: (a, b) => a.is_active - b.is_active,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="users-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="users-list-close-circle" />
        ),
    },
    {
      title: "Is Signed Up?",
      dataIndex: "is_signedup",
      key: "is_signedup",
      filters: [
        { text: "invited", value: true },
        { text: "signed up", value: false },
      ],
      width: 120,
      onFilter: (value, record) => record.is_signedup === value,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="users-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="users-list-close-circle" />
        ),
    },
    // {
    //   title: "Is Approver?",
    //   dataIndex: "is_approver",
    //   key: "is_approver",
    //   width: 130,
    //   sorter: (a, b) => a.is_approver - b.is_approver,
    //   render: (value) =>
    //     value ? (
    //       <CheckCircleOutlined className="users-list-check-circle" />
    //     ) : (
    //       <CloseCircleOutlined className="users-list-close-circle" />
    //     ),
    // },
    {
      title: "Is Admin?",
      dataIndex: "is_admin",
      key: "is_admin",
      width: 120,
      sorter: (a, b) => a.is_admin - b.is_admin,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="users-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="users-list-close-circle" />
        ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <Button type="link" onClick={() => handleManageUser(record)}>
          Manage
        </Button>
      ),
    },
  ];

  function handleVerifiedIcons(data) {
    let iconsArray = [];

    if (data.is_i4_verified) {
      iconsArray.push(handleIcons("i4 verified", "users-verified-icon"));
    } else {
      iconsArray.push(
        handleIcons("i4 not verified", "users-not-verified-icon")
      );
    }

    if (data.is_email_verified) {
      iconsArray.push(handleIcons("Email verified", "users-verified-icon"));
    } else {
      iconsArray.push(
        handleIcons("Email not verified", "users-not-verified-icon")
      );
    }

    if (data.is_mobile_verified) {
      iconsArray.push(handleIcons("Mobile verified", "users-verified-icon"));
    } else {
      iconsArray.push(
        handleIcons("Mobile not verified", "users-not-verified-icon")
      );
    }

    return iconsArray;
  }

  function handleIcons(badgeContentTitle, badgeContentClassName) {
    let badgeIcon, badgeContentIcon;

    if (badgeContentTitle === "Mobile verified") {
      badgeIcon = (
        <img
          style={{ width: "20px", height: "20px" }}
          src={Phone}
          alt="phone"
          title={badgeContentTitle}
          className={badgeContentClassName}
        />
      );
    } else if (badgeContentTitle === "Mobile not verified") {
      badgeIcon = (
        <img
          src={Pho}
          alt="phone"
          style={{ width: "20px", height: "20px" }}
          title={badgeContentTitle}
          className={badgeContentClassName}
        />
      );
    }
    if (badgeContentTitle === "Email verified") {
      badgeIcon = (
        <img
          src={mail}
          alt="mail"
          style={{ width: "25px", height: "25px" }}
          title={badgeContentTitle}
          className={badgeContentClassName}
        />
      );
    } else if (badgeContentTitle === "Email not verified") {
      badgeIcon = (
        <img
          src={M}
          alt="mail"
          style={{ width: "25px", height: "25px" }}
          title={badgeContentTitle}
          className={badgeContentClassName}
        />
      );
    }
    if (badgeContentTitle === "i4 verified") {
      badgeIcon = (
        <img
          style={{ width: "25px", height: "25px" }}
          src={Person}
          alt="person"
          title={badgeContentTitle}
          className={badgeContentClassName}
        />
      );
    } else if (badgeContentTitle === "i4 not verified") {
      badgeIcon = (
        <img
          src={Per}
          alt="person"
          style={{ width: "25px", height: "25px" }}
          title={badgeContentTitle}
          className={badgeContentClassName}
        />
      );
    }

    return (
      <span style={{ margin: "5px" }}>
        <Badge count={badgeIcon}>{badgeContentIcon}</Badge>
      </span>
    );
  }

  useEffect(() => {
    fetchCustomersProjects();
    fetchAllUsers();
  }, [refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  function fetchCustomersProjects() {
    getCustomersAndProjects()
      .then((res) => {
        setDropdownValues(res.data.data);
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get customers and projects error");
        }
      });
  }

  function fetchAllUsers() {
    getAllUsers()
      .then((res) => {
        setIsLoading(false);
        setUserData(res.data.data);
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get all users error");
        }
      });
  }

  function handleManageUser(data) {
    setSelectedUserData(data);
    setShowManageUserDrawer(true);
  }
  function downloadData() {
    setUsersDataDownloading(true);
    downloadDataFiles({
      api: downloadAllUsersData,
      fileName: "users-list",
      fileType: "xlsx",
    })
      .then(() => {
        setUsersDataDownloading(false);
      })
      .catch((err) => {
        console.log(err);
        setUsersDataDownloading(false);
        message.error("Download users-list failed,please try later");
      });
  }
  // console.log(userData);
  return (
    <Fragment>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Row gutter={[0, 16]}>
          <Col span={24} style={{ textAlign: "end" }}>
            <Space>
              <Button type="primary" onClick={() => setShowAssignDrawer(true)}>
                Assign Vendor
              </Button>
              <Button
                type="primary"
                onClick={() => setShowAddVendorDrawer(true)}
              >
                Add Vendor
              </Button>
              <Button type="primary" onClick={() => setShowAddUserDrawer(true)}>
                Invite User
              </Button>
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                title="Export in excel"
                onClick={downloadData}
              ></Button>
            </Space>
          </Col>
          <Col span={24}>
            <Table
              className="users-table"
              columns={columns}
              pagination={{
                pageSize: 20,
              }}
              dataSource={userData}
              scroll={{ x: 800 }}
            />
          </Col>
          {showAssignDrawer && (
            <AddVendorProjects
              fetchAllUsers={fetchAllUsers}
              setShowAssignDrawer={setShowAssignDrawer}
              customersAndProjectsList={dropdownValues}
            />
          )}
          {showAddVendorDrawer && (
            <AddVendorDrawer
              fetchAllUsers={fetchAllUsers}
              setShowAddVendorDrawer={setShowAddVendorDrawer}
              customersAndProjectsList={dropdownValues}
            />
          )}
          {showAddUserDrawer && (
            <AddUserDrawer
              fetchAllUsers={fetchAllUsers}
              setShowAddUserDrawer={setShowAddUserDrawer}
              vendorsList={dropdownValues.vendors}
            />
          )}
          {showManageUserDrawer && (
            <ManageUserDrawer
              selectedUserData={selectedUserData}
              vendorsList={dropdownValues.vendors}
              setShowManageUserDrawer={setShowManageUserDrawer}
              fetchAllUsers={fetchAllUsers}
              refresh={refreshPage}
            />
          )}
        </Row>
      )}
    </Fragment>
  );
};

export default Users;
