import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
} from "antd";
import moment from "moment";
import RadioGroupWizz from "../../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import {
  ownershipType,
  radioYesOrNo,
} from "../../../../../config/radioGroupConstants";
import {
  emailValidation,
  mobileNumberRegex,
  ssnValidation,
  taxValidation,
  zipCodeValidation,
} from "../../../../../config/validation.config";
import { usStatesAndCities } from "../../../../../constants/defaultKeys";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import {
  createOwnershipDetails,
  updateOwnershipDetails,
} from "../../../../../services/vendorProfile.services";

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;

const OwnershipDetailsModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const usStates =
    usStatesAndCities &&
    usStatesAndCities.map((item) => Object.keys(item))[0].sort();

  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    ownership_type: "individual",
    is_owner: true,
    ownership_to: moment(),
    present: true,
  });

  //Disable Date greater than today
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    } else {
      setFormFieldValues(formData);
    }
  }, []);

  function setFormFieldValues(data) {
    setFormData({ ...data });
    form.setFieldsValue({ ...data });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFormChange = (changedValues) => {
    setFormData({ ...formData, ...changedValues });
  };

  const handleDateCheckbox = (e) => {
    if (e.target.checked) {
      setFormFieldValues({
        ...form.getFieldsValue(),
        ownership_to: moment(),
      });
    } else {
      setFormFieldValues({ ...form.getFieldsValue(), ownership_to: null });
    }
  };

  const onFinish = (values) => {
    // if (values.is_owner) {
    //   values = {
    //     ...values,
    //   };
    // }
    if (editTable.status === "update") {
      const formData = {
        ...values,
        id: editTable.id,
        vendor_id: vendorId,
        is_owner: true,
        dob: moment(values.dob).format(dateFormat),
        ownership_from: moment(values.ownership_from).format(dateFormat),
        ownership_to: values.ownership_to
          ? moment(values.ownership_to).format(dateFormat)
          : null,
      };

      updateOwnershipDetails(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else if (editTable.status === "create") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        is_owner: true,
        dob: moment(values.dob).format(dateFormat),
        ownership_from: moment(values.ownership_from).format(dateFormat),
        ownership_to: values.ownership_to
          ? moment(values.ownership_to).format(dateFormat)
          : null,
      };

      createOwnershipDetails(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  function handleTaxValidation() {
    if (form.getFieldValue("ownership_type") === "individual") {
      return ssnValidation;
    } else if (form.getFieldValue("ownership_type") === "entity") {
      return taxValidation;
    }
  }
  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            form={form}
            layout="vertical"
            colon={false}
            onValuesChange={onFormChange}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name!",
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name!",
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item name="phone" label="Phone" rules={mobileNumberRegex}>
              <Input placeholder="Phone" addonBefore="+1" />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={emailValidation}>
              <Input placeholder="Email" />
            </Form.Item>
            {(!formData.is_owner ||
              formData.ownership_type === "individual") && (
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Please input your title!",
                  },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>
            )}
            {/* <Form.Item
              className="multiline-form-item"
              name="is_owner"
              label="Owner"
            >
              {RadioGroupWizz({ data: radioYesOrNo })}
            </Form.Item> */}
            {/* {formData.is_owner && (
              <> */}
            <Form.Item
              className="multiline-form-item"
              name="ownership_type"
              label="Select ownership type"
            >
              {RadioGroupWizz({ data: ownershipType })}
            </Form.Item>
            <Form.Item
              name="tax_id"
              label="Tax ID"
              rules={handleTaxValidation()}
            >
              <Input placeholder="Tax ID" />
            </Form.Item>
            <Form.Item
              name="address"
              label="Street"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Street Address" />
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Please select a State">
                {usStates.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="City" />
            </Form.Item>
            <Form.Item name="zipcode" label="Zipcode" rules={zipCodeValidation}>
              <Input placeholder="5-digits" />
            </Form.Item>
            <Form.Item
              name="dob"
              label="D.O.B"
              rules={[
                {
                  required: true,
                  message: "Please input D.O.B!",
                },
              ]}
            >
              <DatePicker
                placeholder="D.O.B"
                disabledDate={disabledDate}
                style={{ minWidth: 280 }}
              />
            </Form.Item>
            <Form.Item
              name="ownership_percent"
              label="Percent Ownership"
              rules={[
                {
                  required: true,
                  message: "Please input Ownership Percent!",
                },
              ]}
            >
              <InputNumber
                placeholder="Percent Ownership"
                style={{ minWidth: 280 }}
                max={100}
              />
            </Form.Item>
            <Form.Item
              name="ownership_from"
              label="Ownership From"
              rules={[
                {
                  required: true,
                  message: "Please input Ownership From Date!",
                },
              ]}
            >
              <DatePicker
                placeholder="Ownership from"
                style={{ minWidth: 280 }}
              />
            </Form.Item>
            <Form.Item
              name="ownership_to"
              label="Ownership To"
              rules={[
                {
                  required: true,
                  validator: async (_, ownership_to) => {
                    if (
                      moment(ownership_to).isBefore(
                        form.getFieldValue("ownership_from")
                      )
                    ) {
                      return Promise.reject(
                        new Error(
                          `"Ownership To" Date cannot be before "Ownership From" Date`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <DatePicker
                placeholder="Ownership To"
                style={{ minWidth: 280 }}
                disabled={form.getFieldValue("present")}
              />
            </Form.Item>
            <Form.Item name="present" valuePropName="checked">
              <Checkbox onChange={handleDateCheckbox}>Present</Checkbox>
            </Form.Item>
            {/* </>
            )} */}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default OwnershipDetailsModal;
