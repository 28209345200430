import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  Form,
  Row,
  Col,
  Typography,
  Button,
  Collapse,
  Divider,
  InputNumber,
  DatePicker,
  Input,
  message,
} from "antd";
import {
  tailLayout,
  wideLabelLayout,
} from "../../../../config/formLayout.config";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import PastProjects from "./PastProjects/PastProjects";
import ContractorReferences from "./ContractorReferences/ContractorReferences";
import PerformanceHistory from "./PerformanceHistory/PerformanceHistory";
import SupplierReferences from "./SupplierReferences/SupplierReferences";
import { getPrevious3Yrs } from "../../../../helpers/utility";
import LiquidatedDamage from "./PerformanceHistory/LiquidatedDamage";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import "./Experience.css";
import {
  createExperience,
  getContractorReference,
  getExperience,
  getLiquidatedDamages,
  getPastProjects,
  getSupplierReference,
  getTerminatedContracts,
} from "../../../../services/vendorProfile.services";
import moment from "moment";
import RadioGroupWizz from "../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import { radioYesOrNo } from "../../../../config/radioGroupConstants";
import { maxNumValidation } from "../../../../config/validation.config";
import { profileCompletion } from "../../../../constants/defaultKeys";

const { Panel } = Collapse;
const { Paragraph } = Typography;
const dateFormat = "YYYY";

const Experience = (props) => {
  const vendorId = props.vendorId;

  const [form] = Form.useForm();

  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );

  const [disabled, setDisabled] = useState(false);
  const [pastProjectsData, setPastProjectsData] = useState([]);
  const [contractorReferenceData, setContractorReferenceData] = useState([]);
  const [supplierReferenceData, setSupplierReferenceData] = useState([]);
  const [terminatedContractsData, setTerminatedContractsData] = useState([]);
  const [liquidatedDamagesData, setLiquidatedDamagesData] = useState([]);

  const showDefaultedTable =
    vendorProfileFormData.experience.any_failed_or_terminated_projects;
  const showAssessedTable =
    vendorProfileFormData.experience.any_liquidated_damages;
  const prev3yrs = getPrevious3Yrs();

  useEffect(() => {
    if (vendorId) {
      fetchExperience();
      fetchPastProjects();
      fetchContractorReference();
      fetchSupplierReference();
      fetchTerminatedContracts();
      fetchLiquidatedDamages();
    }
  }, []);

  function fetchExperience() {
    getExperience(vendorId)
      .then((response) => {
        let data = response.data.data;
        if (Object.keys(data).length === 0) {
          setFormFieldValues({
            ...vendorProfileFormData.experience,
            prev_1yr: moment().subtract(1, "year"),
            prev_2yr: moment().subtract(2, "year"),
            prev_3yr: moment().subtract(3, "year"),
            any_failed_or_terminated_projects: showDefaultedTable
              ? showDefaultedTable
              : false,
            any_liquidated_damages: showAssessedTable
              ? showAssessedTable
              : false,
          });
        } else {
          let formValues = {
            ...data,
            ...vendorProfileFormData.experience,
            prev_1yr: moment().subtract(1, "year"),
            prev_2yr: moment().subtract(2, "year"),
            prev_3yr: moment().subtract(3, "year"),
          };
          setFormFieldValues(formValues);
        }
      })
      .catch((error) => {
        console.error("get experience error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting experience");
        }
      });
  }

  function fetchPastProjects() {
    getPastProjects(vendorId)
      .then((response) => setPastProjectsData(response.data.data))
      .catch((error) => {
        console.error("get past projects error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting past projects");
        }
      });
  }

  function fetchTerminatedContracts() {
    getTerminatedContracts(vendorId)
      .then((response) => setTerminatedContractsData(response.data.data))
      .catch((error) => {
        console.error("get terminated contracts error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting terminated contracts");
        }
      });
  }

  function fetchLiquidatedDamages() {
    getLiquidatedDamages(vendorId)
      .then((response) => setLiquidatedDamagesData(response.data.data))
      .catch((error) => {
        console.error("get liquidated damages error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting liquidated damages");
        }
      });
  }

  function fetchContractorReference() {
    getContractorReference(vendorId)
      .then((response) => setContractorReferenceData(response.data.data))
      .catch((error) => {
        console.error("get Contractor reference error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting Contractor reference");
        }
      });
  }

  function fetchSupplierReference() {
    getSupplierReference(vendorId)
      .then((response) => setSupplierReferenceData(response.data.data))
      .catch((error) => {
        console.error("get Supplier Reference error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting Supplier Reference");
        }
      });
  }

  function setFormFieldValues(data) {
    setVendorProfileDataExperience(data);
    form.setFieldsValue(data);
  }

  function setVendorProfileDataExperience(changedValues) {
    vendorProfileFormData.experience = {
      ...vendorProfileFormData.experience,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function onFormValuesChange() {
    setVendorProfileDataExperience(form.getFieldsValue());
  }

  async function onFinish(values) {
    if (pastProjectsData.length === 0) {
      message.error("Please add atleast one Past Project");
      return;
    } else if (showDefaultedTable && terminatedContractsData.length === 0) {
      message.error("Please add atleast one Terminated Contract");
      return;
    } else if (showAssessedTable && liquidatedDamagesData.length === 0) {
      message.error("Please add atleast one Liquidated Damage");
      return;
    } else if (contractorReferenceData.length < 1) {
      message.error("Please add atleast one Contractor References");
      return;
    } else if (supplierReferenceData.length < 1) {
      message.error("Please add atleast one Supplier References");
      return;
    } else {
      let reqBody = {
        ...values,
        vendor_id: vendorId,
        prev_1yr: values["prev_1yr"].format(dateFormat),
        prev_2yr: values["prev_2yr"].format(dateFormat),
        prev_3yr: values["prev_3yr"].format(dateFormat),
      };
      setDisabled(true);

      createExperience(reqBody)
        .then((res) => {
          setDisabled(false);
          if (!vendorDetails.experience_status) {
            setVendorDetails({
              ...vendorDetails,
              experience_status: true,
              profile_completion_status:
                vendorDetails.profile_completion_status +
                profileCompletion.experience_status,
            });
          }
          message.success(res.data.message);
          props.onStepChange(props.currentStep + 1);
        })
        .catch((error) => {
          setDisabled(false);
          console.error(error);
          if (error && error.response) {
            if (error.response.status === 400) {
              message.error(
                "Something went wrong with the form being saved. Please contact administrator"
              );
            } else {
              message.error(
                error.response.data.message || error.response.message
              );
            }
          } else {
            message.error("Error creating Experience");
          }
        });
    }
  }

  const get3yrSubContracts = (
    <Form.Item
      label="State the percentage of gross revenues obtained through subcontracted work over the past three years and the type of work typically subcontracted:
    "
      className="multiline-form-item"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      {prev3yrs.map((eachYear, index) => (
        <Fragment>
          <Row gutter={[8, 0]}>
            <Col span={6}>
              {index == 0 && (
                <Paragraph
                  className="header-label"
                  style={{ minHeight: "40px" }}
                >
                  Year
                </Paragraph>
              )}
              <Form.Item
                name={`prev_${index + 1}yr`}
                className="formitem-no-margin"
              >
                <DatePicker picker="year" disabled />
              </Form.Item>
            </Col>
            <Col span={9}>
              {index == 0 && (
                <Paragraph
                  className="header-label mandatory-field-mark"
                  style={{ minHeight: "40px" }}
                >
                  % Gross Revenue Earned from Subcontracting
                </Paragraph>
              )}
              <Form.Item
                className="formitem-no-margin"
                name={`prev_${index + 1}yr_percent_revenue_subcontracting`}
                rules={maxNumValidation}
              >
                <InputNumber
                  min={0}
                  max={100}
                  style={{ width: 100 }}
                  placeholder="% Revenue"
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              {index == 0 && (
                <Paragraph
                  className="header-label mandatory-field-mark"
                  style={{ minHeight: "40px" }}
                >
                  Type of Work Subcontracted
                </Paragraph>
              )}
              <Form.Item
                className="formitem-no-margin"
                name={`prev_${index + 1}yr_type_of_work_subcontracted`}
                rules={[
                  {
                    required: true,
                    message: `Type of work subcontracted is required!`,
                  },
                ]}
              >
                <Input placeholder="Type of Work Subcontracted" />
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      ))}
    </Form.Item>
  );

  const get3yrContractStats = (
    <Form.Item
      label="Indicate average and maximum contract size performed over the past three years:
    "
      className="multiline-form-item"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      {prev3yrs.map((eachYear, index) => (
        <Fragment>
          <Row gutter={[8, 0]}>
            <Col span={6}>
              {index == 0 && (
                <Paragraph className="header-label">Year</Paragraph>
              )}
              <Form.Item
                name={`prev_${index + 1}yr`}
                className="formitem-no-margin"
              >
                <DatePicker picker="year" disabled />
              </Form.Item>
            </Col>
            <Col span={9}>
              {index == 0 && (
                <Paragraph className="header-label mandatory-field-mark">
                  Average Contract Size
                </Paragraph>
              )}
              <Form.Item
                className="formitem-no-margin"
                name={`prev_${index + 1}yr_avg_contract_size`}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ width: 100 }}
                  placeholder="Average Contract Size"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              {index == 0 && (
                <Paragraph className="header-label mandatory-field-mark">
                  Maximum Contract Size
                </Paragraph>
              )}
              <Form.Item
                className="formitem-no-margin"
                name={`prev_${index + 1}yr_max_contract`}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: 100 }}
                  placeholder="Maximum Contract Size"
                  min={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      ))}
    </Form.Item>
  );

  return (
    <Form
      name="experience"
      form={form}
      {...wideLabelLayout}
      onValuesChange={onFormValuesChange}
      onFinish={onFinish}
      colon={false}
      initialValues={{
        prev_1yr: prev3yrs[0],
        prev_2yr: prev3yrs[1],
        prev_3yr: prev3yrs[2],
        any_liquidated_damages: false,
        any_failed_or_terminated_projects: false,
      }}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1", "2", "3", "4", "5"]}>
        <Panel header="Subcontracting" key="1">
          {get3yrSubContracts}
        </Panel>
        <Panel header="Contract Size" key="2">
          {get3yrContractStats}
        </Panel>
        <Panel header="Past Projects" key="3">
          <PastProjects
            vendorId={vendorId}
            pastProjectsData={pastProjectsData}
            fetchPastProjects={fetchPastProjects}
          />
        </Panel>
        <Panel header="References" key="4">
          <ContractorReferences
            vendorId={vendorId}
            contractorReferenceData={contractorReferenceData}
            fetchContractorReference={fetchContractorReference}
          />
          <Divider />
          <SupplierReferences
            vendorId={vendorId}
            supplierReferenceData={supplierReferenceData}
            fetchSupplierReference={fetchSupplierReference}
          />
        </Panel>
        <Panel header="Performance History" key="5">
          <Form.Item
            name="any_failed_or_terminated_projects"
            className="multiline-form-item"
            label="Has any contract within the past five years on which the applicant
              firm has been defaulted or otherwise failed to complete or which was
              terminated for cause?"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          {showDefaultedTable && (
            <PerformanceHistory
              vendorId={vendorId}
              terminatedContractsData={terminatedContractsData}
              fetchTerminatedContracts={fetchTerminatedContracts}
            />
          )}
          <Divider />
          <Form.Item
            name="any_liquidated_damages"
            className="multiline-form-item"
            label="Has any contract within the past five years on which the applicant
              firm has been assessed liquidated damages?"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          {showAssessedTable && (
            <LiquidatedDamage
              vendorId={vendorId}
              liquidatedDamagesData={liquidatedDamagesData}
              fetchLiquidatedDamages={fetchLiquidatedDamages}
            />
          )}
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Experience;
