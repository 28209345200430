import React, { useEffect, useState } from "react";
import { Checkbox, Collapse, message } from "antd";
import Bankruptcy from "./Bankruptcy/Bankruptcy";
import TaxWarrantLiens from "./TaxWarrantLiens/TaxWarrantLiens";
import UCCFiling from "./UCCFilings/UCCFilings";
import {
  getApplicationNoRecords,
  updateApplicationNoRecords,
} from "../../../../services/adminForm.services";
import { useQuery } from "../../../../helpers/utility";

const { Panel } = Collapse;

const TWLFB = () => {
  const query = useQuery();
  const applicationId = query.get("id");
  const [appNoRcd, setAppNoRcdData] = useState(null);
  const [checkBox, setCheckBox] = useState({
    no_twl_records: appNoRcd?.no_twl_records,
    no_ucc_records: appNoRcd?.no_ucc_records,
    no_bankruptcy_records: appNoRcd?.no_bankruptcy_records,
  });
  const [checked, setChecked] = useState({
    key: [],
  });
  useEffect(() => {
    getApplicationNoRecordsApi();
  }, [applicationId]);

  async function getApplicationNoRecordsApi() {
    try {
      const res = await getApplicationNoRecords(applicationId);
      setAppNoRcdData(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function updateApplicationApi(values) {
    try {
      const res = await updateApplicationNoRecords(applicationId, {
        no_twl_records: values?.no_twl_records,
        no_ucc_records: values?.no_ucc_records,
        no_bankruptcy_records: values?.no_bankruptcy_records,
      });
      message.success(res.data?.message);
    } catch (err) {
      console.log(err);
    } finally {
      getApplicationNoRecordsApi();
    }
  }

  return (
    <Collapse
      activeKey={checkBox?.no_twl_records ? checked?.key : ["1", "2", "3"]}
      onChange={(e) => {
        setChecked({ key: e });
      }}
    >
      <Panel
        header="Tax Warrant Liens"
        key="1"
        extra={
          <Checkbox
            checked={appNoRcd?.no_twl_records}
            onChange={(e) => {
              setCheckBox({
                ...checkBox,
                no_twl_records: e.target.checked,
              });
              updateApplicationApi({ no_twl_records: e.target.checked });
            }}
          >
            No Information Identified
          </Checkbox>
        }
        collapsible={appNoRcd?.no_twl_records ? "disabled" : ""}
      >
        <div
          style={
            appNoRcd?.no_twl_records
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <TaxWarrantLiens disable={appNoRcd?.no_twl_records} />
        </div>
      </Panel>
      <Panel
        header="UCC Filing"
        key="2"
        extra={
          <Checkbox
            checked={appNoRcd?.no_ucc_records}
            onChange={(e) => {
              setCheckBox({ ...checkBox, no_ucc_records: e.target.checked });
              updateApplicationApi({ no_ucc_records: e.target.checked });
            }}
          >
            No Information Identified
          </Checkbox>
        }
        collapsible={appNoRcd?.no_ucc_records ? "disabled" : ""}
      >
        <div
          style={
            appNoRcd?.no_ucc_records
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <UCCFiling disable={appNoRcd?.no_ucc_records} />
        </div>
      </Panel>
      <Panel
        header="Bankruptcy"
        key="3"
        extra={
          <Checkbox
            checked={appNoRcd?.no_bankruptcy_records}
            onChange={(e) => {
              setCheckBox({
                ...checkBox,
                no_bankruptcy_records: e.target.checked,
              });
              updateApplicationApi({ no_bankruptcy_records: e.target.checked });
            }}
          >
            No Information Identified
          </Checkbox>
        }
        collapsible={appNoRcd?.no_bankruptcy_records && "disabled"}
      >
        <div
          style={
            appNoRcd?.no_bankruptcy_records && checked?.key?.includes("3")
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <Bankruptcy disable={appNoRcd?.no_bankruptcy_records} />
        </div>
      </Panel>
    </Collapse>
  );
};

export default TWLFB;
