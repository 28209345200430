import React, { Fragment, useState, useEffect, useRef } from "react";
import { Table, Row, Col, Input, Space, Button, Skeleton, message } from "antd";
import RecordPayment from "./RecordPayment";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  transactionsList,
  downloadTransactionListData,
} from "../../../services/payment.services";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { downloadDataFiles } from "../../../helpers/utility";

const Subscriptions = () => {
  let searchInput = useRef(null);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const [isTransactionDataDownloading, setTransactionDataDownloading] =
    useState(false);
  useEffect(() => {
    setLoading(true);
    transactionsList()
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [, refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, name) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${name}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      ...getColumnSearchProps("vendor_name", "Vendor Name"),
    },
    {
      title: "Reference Id",
      dataIndex: "transaction_id",
      key: "transaction_id",
      ...getColumnSearchProps("transaction_id", "Reference Id"),
      render: (id, record) => (
        <span>{record.transaction_id !== "" ? id : "-"}</span>
      ),
    },
    {
      title: "Transaction Status",
      dataIndex: "status",
      key: "tatus",
      filters: [
        {
          text: "Failed",
          value: "Failed",
        },
        {
          text: "Success",
          value: "Success",
        },
        {
          text: "Offline payment is under process",
          value: "Offline payment is under process",
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Transaction Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => (
        <span>{record.amount !== 0.0 ? "$" + amount : "$" + amount}</span>
      ),
    },
    {
      title: "Mode Of Payment",
      dataIndex: "is_offline_transaction",
      key: "is_offline_transaction",
      filters: [
        {
          text: "Online",
          value: false,
        },
        {
          text: "Offline",
          value: true,
        },
      ],
      onFilter: (value, record) => record.is_offline_transaction === value,
      render: (_, record) => (
        <span>{record.is_offline_transaction ? "Offline" : "Online"}</span>
      ),
    },

    {
      title: "Date Processed",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (date, record) => (
        <span>
          {record.timestamp ? moment(date).format("YYYY-MM-DD, H:mm") : "-"}
        </span>
      ),
    },
  ];

  function downloadData() {
    setTransactionDataDownloading(true);
    downloadDataFiles({
      api: downloadTransactionListData,
      fileName: "transaction-list",
      fileType: "xlsx",
    })
      .then(() => {
        setTransactionDataDownloading(false);
      })
      .catch((err) => {
        console.log(err);
        setTransactionDataDownloading(false);
        message.error("Download transaction-list failed,please try later");
      });
  }

  return (
    <Fragment>
      {loading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 8]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <RecordPayment refresh={refreshPage} />
            <Button
              title="Export in excel"
              size="large"
              type="primary"
              loading={isTransactionDataDownloading}
              onClick={downloadData}
              icon={<DownloadOutlined />}
              style={{ marginLeft: "10px" }}
            ></Button>
          </Col>
          <Col span={24}>
            <Table columns={columns} dataSource={data} scroll={{ x: 800 }} />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default Subscriptions;
