import axiosInstance from "../config/axios/axios.config";

export const ApplicationList = (id) => {
  return axiosInstance.get(`/core/get-vendor-applications/?vendor_id=${id}`);
};

export const CreateVendorApplication = (payload) => {
  return axiosInstance.post(`/core/create-vendor-application/`, payload);
};

export const UpdateVendorApplication = (payload) => {
  return axiosInstance.put(`/core/update-vendor-application/`, payload);
};

export const AttachVendorProfile = (vendorId, applicationId) => {
  const params = { vendor_id: vendorId, application_id: applicationId };
  return axiosInstance.get(`/core/attach-vendor-profile/`, { params });
};

export const adminApplicationList = (queryParams) => {
  let queryString = `/core/get-vendor-applications-admin/`;
  return axiosInstance.get(queryString, {
    params: {
      ...queryParams,
    },
  });
};

export const downloadPqqData = (queryParams) => {
  let queryString = `/core/get-vendor-applications-admin/`;
  return axiosInstance.get(queryString, {
    responseType: "blob",
    params: queryParams,
  });
};

export const getApplicationById = (id) => {
  return axiosInstance.get(
    `/core/get-vendor-application/?application_id=${id}`
  );
};

export const adminUpdateApplicationById = (payload) => {
  return axiosInstance.put(`/core/admin-update-vendor-application/`, payload);
};

export const getActivity = (id) => {
  return axiosInstance.get(`/core/get-activity/?vendor_id=${id}`);
};

export const getVendorProfile = (id) => {
  return axiosInstance.get(`/core/get-vendor-profile/?application_id=${id}`);
};

export const adminVendorMapping = (id, payload) => {
  return axiosInstance.put(
    `/accounts/update-vendor-and-user-mappings/${id}/`,
    payload
  );
};

export const downloadVendorProfile = (id) => {
  return axiosInstance.get(
    `/core/download-vendor-profile/?application_id=${id}`,
    {
      responseType: "blob",
    }
  );
};

export const updateSkipTour = (payload) => {
  return axiosInstance.put(`/core/skip-tour/`, payload);
};

export const getAllAdmins = () => {
  return axiosInstance.get(`/accounts/get-admins-list/`);
};

export const getUsersList = () => {
  return axiosInstance.get(`/accounts/get-users-list/`);
};

export const getVendorsListAdmin = () => {
  return axiosInstance.get(`/core/get-vendors-admin/`);
};

export const downloadVendorsAdminListData = () => {
  return axiosInstance.get(`/core/get-vendors-admin/?download_data=true`, {
    responseType: "blob",
  });
};

export const deletePQQApi = (pqq_id) => {
  return axiosInstance.delete(`/core/delete-pqq/${pqq_id}/`);
};
