import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Table, Space, message } from "antd";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import OtherLicencesModel from "./OtherLicencesModel";
import {
  createOtherLicence,
  deleteOtherLicence,
  getOtherLicence,
  updateOtherLicence,
} from "../../../../../services/adminForm.services";
import { useQuery } from "../../../../../helpers/utility";
import moment from "moment";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const dateFormat = "YYYY-MM-DD";

const OtherLicences = () => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const query = useQuery();
  const applicationId = query.get("id");

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Issued To",
      dataIndex: "issued_to",
      key: "issued_to",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Issued Date",
      dataIndex: "issued_date",
      key: "issued_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Contractor Id",
      dataIndex: "contractor_id",
      key: "contractor_id",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      ellipsis: true,
      width: 100,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: "Expiration Date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  useEffect(() => {
    fetchOtherLicence();
  }, [, refresh]);

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  function fetchOtherLicence(id = applicationId) {
    getOtherLicence(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("get nyc licence error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went wrong"
          );
        } else {
          message.error("get nyc licence error");
        }
      });
  }

  function saveOtherLicences(values) {
    let data = values;
    data.issued_date = moment(values.issued_date).format(dateFormat);
    data.expiration_date = moment(values.expiration_date).format(dateFormat);
    data.application = applicationId;

    createOtherLicence(data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Created Other Licence");
        fetchOtherLicence(applicationId);
      })
      .catch((error) => {
        console.error("save other licence error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to create Other Licence"
          );
        } else {
          message.error("save other licence error");
        }
      });
  }

  function handleUpdateOtherLicences(values) {
    let data = values;
    data.issued_date = moment(values.issued_date).format(dateFormat);
    data.expiration_date = moment(values.expiration_date).format(dateFormat);
    data.application = applicationId;

    updateOtherLicence(editTable.id, data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Updated Other Licence");
        fetchOtherLicence(applicationId);
      })
      .catch((error) => {
        console.error("save nyc licence error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to Update Other Licence"
          );
        } else {
          message.error("save nyc licence error");
        }
      });
  }

  function removeColumn(id) {
    deleteOtherLicence(id)
      .then((res) => {
        message.success(res.data.message || "Removed Other Licence");
        fetchOtherLicence(applicationId);
        refreshPage();
      })
      .catch((error) => {
        console.error("delete nyc licence error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to remove Other Licence"
          );
        } else {
          message.error("delete nyc licence error");
        }
      });
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Button
            onClick={() => openModal({ status: "create" })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Details
          </Button>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <OtherLicencesModel
          update={handleUpdateOtherLicences}
          refresh={refreshPage}
          create={saveOtherLicences}
          setVisible={setVisible}
          title="Other License"
          editTable={editTable}
        />
      )}
    </Fragment>
  );
};

export default OtherLicences;
