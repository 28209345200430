import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  DatePicker,
  Divider,
  Button,
  message,
  Collapse,
  Row,
  Col,
  Typography,
  InputNumber,
  Upload,
} from "antd";
import moment from "moment";
import { tailLayout } from "../../../../config/formLayout.config";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import "./Safety.css";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import {
  createSafetyRecord,
  getSafetyRecord,
} from "../../../../services/vendorProfile.services";
import RadioGroupWizz from "../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import { radioYesOrNo } from "../../../../config/radioGroupConstants";
import {
  profileCompletion,
  uploadFileUrl,
} from "../../../../constants/defaultKeys";
import { UploadOutlined } from "@ant-design/icons";
import {
  emailValidation,
  mobileNumberRegex,
} from "../../../../config/validation.config";
import { getTokenIfNotExpired } from "../../../../helpers/utility";

const { Panel } = Collapse;
const { Paragraph } = Typography;

const validateMessages = {
  required: "'${label}' is required!",
};

const safetyPolicyQuestions = [
  {
    name: "has_safety_policy",
    label: "Does your firm have a Safety policy and program?",
  },
  {
    name: "has_return_to_work_policy",
    label: "Does your firm have a return to work policy and program?",
  },
  {
    name: "has_drug_test_policy",
    label: "Does your firm have a drug testing policy?",
  },
];

const uploadButtonProps = {
  accept: ".doc, .docx, .pdf, .csv",
  maxCount: 1,
  action: uploadFileUrl,
  name: "docs",
  onChange(info) {
    // if headers are set as default then "antd" is setting the header before loggin into the application,
    // so setting headers when file change is triggered
    uploadButtonProps["headers"] = {
      Authorization: `Bearer ${getTokenIfNotExpired()}`,
    };
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const Safety = (props) => {
  const vendorId = props.vendorId;
  const [form] = Form.useForm();

  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );

  const [disabled, setDisabled] = useState(false);

  const safetyData = vendorProfileFormData.safety;
  const showSafetyPolicyUpload = safetyData.has_safety_policy;
  const showWorkPolicyUpload = safetyData.has_return_to_work_policy;
  const showDrugTestUpload = safetyData.has_drug_test_policy;

  useEffect(() => {
    vendorId && getSafetyRecordFunc();
  }, [vendorId]);

  function getSafetyRecordFunc() {
    getSafetyRecord(vendorId)
      .then((res) => {
        if (Object.keys(res.data.data).length === 0) {
          setFormFieldValues({
            ...safetyData,
            has_safety_policy: showSafetyPolicyUpload
              ? showSafetyPolicyUpload
              : false,
            has_return_to_work_policy: showWorkPolicyUpload
              ? showWorkPolicyUpload
              : false,
            has_drug_test_policy: showDrugTestUpload
              ? showDrugTestUpload
              : false,
            broker_letter:
              safetyData.broker_letter_file_list[0]?.response?.names[0] || null,
            osha_300_a_forms:
              safetyData.osha_300_a_forms_file_list[0]?.response?.names[0] ||
              [],
            safety_director_resume:
              safetyData.safety_director_resume_file_list[0]?.response
                ?.names[0] || null,
            has_safety_policy_doc:
              safetyData.has_safety_policy_file_list[0]?.response?.names[0] ||
              null,
            has_return_to_work_policy_doc:
              safetyData.has_return_to_work_policy_file_list[0]?.response
                ?.names[0] || null,
            has_drug_test_policy_doc:
              safetyData.has_drug_test_policy_file_list[0]?.response
                ?.names[0] || null,
            prev_1yr: moment().subtract(1, "year"),
            prev_2yr: moment().subtract(2, "year"),
            prev_3yr: moment().subtract(3, "year"),
          });
        } else {
          let fileList = {
            broker_letter_file_list: res.data.data.broker_letter
              ? [
                  {
                    uid: 0,
                    name:
                      safetyData.broker_letter_file_list[0]?.response
                        ?.names[0] || res.data.data.broker_letter,
                    status: "done",
                  },
                ]
              : [],
            osha_300_a_forms_file_list:
              res.data.data.osha_300_a_forms.length > 0
                ? handleOsha300FormFileValues(res.data.data.osha_300_a_forms)
                : safetyData.osha_300_a_forms_file_list,
            safety_director_resume_file_list: res.data.data
              .safety_director_resume
              ? [
                  {
                    uid: 0,
                    name:
                      safetyData.safety_director_resume_file_list[0]?.response
                        ?.names[0] ||
                      safetyData.safety_director_resume_file_list[0]?.name ||
                      res.data.data.safety_director_resume,
                    status: "done",
                  },
                ]
              : [],
            has_safety_policy_file_list: res.data.data.has_safety_policy_doc
              ? [
                  {
                    uid: 0,
                    name:
                      safetyData.has_safety_policy_file_list[0]?.response
                        ?.names[0] ||
                      safetyData.has_safety_policy_file_list[0]?.name ||
                      res.data.data.has_safety_policy_doc,
                    status: "done",
                  },
                ]
              : [],
            has_return_to_work_policy_file_list: res.data.data
              .has_return_to_work_policy_doc
              ? [
                  {
                    uid: 0,
                    name:
                      safetyData.has_return_to_work_policy_file_list[0]
                        ?.response?.names[0] ||
                      safetyData.has_return_to_work_policy_file_list[0]?.name ||
                      res.data.data.has_return_to_work_policy_doc,
                    status: "done",
                  },
                ]
              : [],
            has_drug_test_policy_file_list: res.data.data
              .has_drug_test_policy_doc
              ? [
                  {
                    uid: 0,
                    name:
                      safetyData.has_drug_test_policy_file_list[0]?.response
                        ?.names[0] ||
                      safetyData.has_drug_test_policy_file_list[0]?.name ||
                      res.data.data.has_drug_test_policy_doc,
                    status: "done",
                  },
                ]
              : [],
          };
          let formValues = {
            ...res.data.data,
            ...safetyData,
            broker_letter:
              safetyData.broker_letter_file_list[0]?.response?.names[0] ||
              res.data.data.broker_letter,
            osha_300_a_forms:
              safetyData.osha_300_a_forms_file_list[0]?.response?.names[0] ||
              res.data.data.osha_300_a_forms,
            safety_director_resume:
              safetyData.safety_director_resume_file_list[0]?.response
                ?.names[0] || res.data.data.safety_director_resume,
            has_safety_policy_doc:
              safetyData.has_safety_policy_file_list[0]?.response?.names[0] ||
              res.data.data.has_safety_policy_doc,
            has_return_to_work_policy_doc:
              safetyData.has_return_to_work_policy_file_list[0]?.response
                ?.names[0] || res.data.data.has_return_to_work_policy_doc,
            has_drug_test_policy_doc:
              safetyData.has_drug_test_policy_file_list[0]?.response
                ?.names[0] || res.data.data.has_drug_test_policy_doc,
            prev_1yr: moment().subtract(1, "year"),
            prev_2yr: moment().subtract(2, "year"),
            prev_3yr: moment().subtract(3, "year"),
          };
          setFormFieldValues(formValues, fileList);
        }
      })
      .catch((error) => console.error(error));
  }

  function handleOsha300FormFileValues(data) {
    return data.map((eachFile, index) => ({
      uid: index,
      name:
        safetyData.osha_300_a_forms_file_list[index]?.response?.names[0] ||
        eachFile,
      status: "done",
      response: {
        names: [
          safetyData.osha_300_a_forms_file_list[index]?.response?.names[0] ||
            eachFile,
        ],
      },
    }));
  }

  function setFormFieldValues(data, fileList = safetyData) {
    setVendorProfileDataSafety({ ...data, ...fileList });
    form.setFieldsValue(data);
  }

  function setVendorProfileDataSafety(changedValues) {
    vendorProfileFormData.safety = {
      ...safetyData,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function onFormValuesChange(changedValues) {
    let key = Object.keys(changedValues)[0];
    if (
      key !== "broker_letter" &&
      key !== "osha_300_a_forms" &&
      key !== "safety_director_resume" &&
      key !== "has_safety_policy_doc" &&
      key !== "has_return_to_work_policy_doc" &&
      key !== "has_drug_test_policy_doc"
    ) {
      setVendorProfileDataSafety(form.getFieldsValue());
    }
  }

  const normFile = (e, field) => {
    vendorProfileFormData.safety[field] = e.fileList;
    setVendorProfileFormData({ ...vendorProfileFormData });

    if (
      e &&
      e.fileList &&
      e.fileList.length > 0 &&
      e.fileList[e.fileList.length - 1].response
    ) {
      if (field === "osha_300_a_forms_file_list") {
        return e.fileList.map((eachFile) => eachFile.response.names[0]);
      } else {
        return e?.fileList[0]?.response?.names[0];
      }
    }
  };

  function onFinish(values) {
    setDisabled(true);
    let brokerLetter = values.broker_letter ? values.broker_letter : null;
    let oshaForm = Array.isArray(values.osha_300_a_forms)
      ? [...values.osha_300_a_forms]
      : [values.osha_300_a_forms];
    let safetyDirectorResume = values.safety_director_resume
      ? values.safety_director_resume
      : null;
    let hasSafetyPolicyDoc =
      values.has_safety_policy && values.has_safety_policy_doc
        ? values.has_safety_policy_doc
        : null;
    let hasReturnToWorkPolicyDoc =
      values.has_return_to_work_policy && values.has_return_to_work_policy_doc
        ? values.has_return_to_work_policy_doc
        : null;
    let hasDrugTestPolicyDoc =
      values.has_drug_test_policy && values.has_drug_test_policy_doc
        ? values.has_drug_test_policy_doc
        : null;

    let requestBody = {
      ...values,
      vendor_id: vendorId,
      prev_1yr: moment().subtract(1, "year").format("YYYY"),
      prev_2yr: moment().subtract(2, "year").format("YYYY"),
      prev_3yr: moment().subtract(3, "year").format("YYYY"),
      broker_letter: brokerLetter,
      osha_300_a_forms: oshaForm,
      safety_director_resume: safetyDirectorResume,
      has_safety_policy_doc: hasSafetyPolicyDoc,
      has_return_to_work_policy_doc: hasReturnToWorkPolicyDoc,
      has_drug_test_policy_doc: hasDrugTestPolicyDoc,
    };
    saveSafetyRecord(requestBody);
  }

  function saveSafetyRecord(requestBody) {
    createSafetyRecord(requestBody)
      .then((res) => {
        setDisabled(false);
        // on saving details successfully, changing the step status to true using useContext API
        if (!vendorDetails.safety_status) {
          setVendorDetails({
            ...vendorDetails,
            safety_status: true,
            profile_completion_status:
              vendorDetails.profile_completion_status +
              profileCompletion.safety_status,
          });
        }
        message.success(res.data.message);
        props.onStepChange(props.currentStep + 1);
      })
      .catch((error) => {
        setDisabled(false);
        console.error("create safety error", error);
        if (error && error.response) {
          if (error.response.status === 400) {
            message.error(
              "Something went wrong with the form being saved. Please contact administrator"
            );
          } else {
            message.error(
              error.response.data.message || error.response.message
            );
          }
        } else {
          message.error("Error creating safety details");
        }
      });
  }

  function renderEmr() {
    let formItems = [];
    for (let i = 1; i < 4; i++) {
      formItems.push(
        <Row key={i} type="flex" gutter={[8, 0]}>
          <Col span={8}>
            {i == 1 && <Paragraph className="header-label">Year</Paragraph>}
            <Form.Item name={`prev_${i}yr`} className="formitem-no-margin">
              <DatePicker picker="year" disabled allowClear={false} />
            </Form.Item>
          </Col>
          <Col span={16}>
            {i === 1 && (
              <Paragraph className="header-label mandatory-field-mark">
                EMR
              </Paragraph>
            )}
            <Form.Item
              className="formitem-no-margin"
              name={`prev_${i}yr_emr`}
              rules={[
                {
                  required: true,
                  message: "EMR is required",
                },
              ]}
            >
              <InputNumber
                style={{ minWidth: 150 }}
                placeholder="EMR"
                type="number"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return formItems;
  }

  function renderApplicantsFirm() {
    let formItems = [];
    for (let i = 1; i < 4; i++) {
      formItems.push(
        <Row key={i} gutter={[8, 0]}>
          <Col span={6}>
            {i == 1 && <Paragraph className="header-label">Year</Paragraph>}
            <Form.Item name={`prev_${i}yr`} className="formitem-no-margin">
              <DatePicker picker="year" disabled allowClear={false} />
            </Form.Item>
          </Col>
          <Col span={9}>
            {i == 1 && (
              <Paragraph className="header-label">
                Lost Time Incidence Rate
              </Paragraph>
            )}
            <Form.Item
              className="formitem-no-margin"
              name={`prev_${i}yr_lost_time_incidence_rate`}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                min={0}
                style={{ width: 250 }}
                placeholder="Lost Time Incidence Rate"
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            {i == 1 && (
              <Paragraph className="header-label">
                Recordable Incidence Rate
              </Paragraph>
            )}
            <Form.Item
              className="formitem-no-margin"
              name={`prev_${i}yr_recordable_incidence_rate`}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                min={0}
                style={{ width: 250 }}
                placeholder="Recordable Incidence Rate"
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return formItems;
  }

  const safetyPolicyItems = (
    <Fragment>
      {safetyPolicyQuestions.map((eachQusn, index) => (
        <Fragment key={index}>
          <Form.Item
            className="wrap-label"
            labelCol={{ span: 12 }}
            key={index}
            name={eachQusn.name}
            label={eachQusn.label}
            rules={[
              {
                required: false,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          <Form.Item
            wrapperCol={{ span: 10 }}
            key={`${eachQusn.name}_doc`}
            name={`${eachQusn.name}_doc`}
            label="Please upload policy document"
            valuePropName={`${eachQusn.name}_doc`}
            getValueFromEvent={(e) => normFile(e, `${eachQusn.name}_file_list`)}
            hidden={!safetyData[eachQusn.name]}
            rules={[
              {
                required: safetyData[eachQusn.name],
                message: "Please upload a file!",
              },
            ]}
          >
            <Upload
              {...uploadButtonProps}
              fileList={safetyData[`${eachQusn.name}_file_list`]}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Fragment>
      ))}
    </Fragment>
  );

  return (
    <Form
      name="safety"
      form={form}
      className="form-safety"
      validateMessages={validateMessages}
      onValuesChange={onFormValuesChange}
      onFinish={onFinish}
      colon={false}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1", "2"]}>
        <Panel header="Safety Record" key="1">
          <Form.Item
            labelCol={{ span: 12, offset: 2 }}
            wrapperCol={{ span: 10 }}
            name="contracts_in_process"
            className="multiline-form-item"
            label="Indicate below the applicant firm's EMR for the most recent
            three years:"
            rules={[
              {
                required: false,
              },
            ]}
          >
            {renderEmr()}
          </Form.Item>
          <Divider />
          <Row>
            <Col span={20} offset={2}>
              <Form.Item
                name="broker_letter"
                className="multiline-form-item"
                label="Broker letter indicating the applicant firm's emr for the most recent three years"
                valuePropName="broker_letter"
                getValueFromEvent={(e) =>
                  normFile(e, "broker_letter_file_list")
                }
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Upload
                  {...uploadButtonProps}
                  fileList={safetyData.broker_letter_file_list}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={20} offset={2}>
              <Paragraph>
                Indicate the applicant firms’ lost time and recordable incidence
                rates for the most recent three years:
              </Paragraph>
              {renderApplicantsFirm()}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={20} offset={2}>
              <Form.Item
                name="osha_300_a_forms"
                className="multiline-form-item"
                label="Applicant firm's osha 300a forms for the most recent three years:"
                valuePropName="osha_300_a_forms"
                getValueFromEvent={(e) =>
                  normFile(e, "osha_300_a_forms_file_list")
                }
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Upload
                  {...uploadButtonProps}
                  maxCount={3}
                  fileList={safetyData.osha_300_a_forms_file_list}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
        <Panel header="Safety Program" key="2">
          <Row>
            <Col span={20} offset={2}>
              <Paragraph>
                Safety Director or other qualified person responsible for safety
                at the applicant firm:
              </Paragraph>
              <Form.Item
                className="multiline-form-item"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Row gutter={[8, 0]}>
                  <Col span={6}>
                    <Paragraph className="header-label mandatory-field-mark">
                      Name
                    </Paragraph>
                    <Form.Item
                      name="safety_director_name"
                      rules={[
                        {
                          required: true,
                          message: "Name is required",
                        },
                      ]}
                    >
                      <Input placeholder="Safety director name" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Paragraph className="header-label mandatory-field-mark">
                      Title
                    </Paragraph>
                    <Form.Item
                      name="safety_director_title"
                      rules={[
                        {
                          required: true,
                          message: "Title is required",
                        },
                      ]}
                    >
                      <Input placeholder="Safety director title" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Paragraph className="header-label mandatory-field-mark">
                      Phone
                    </Paragraph>
                    <Form.Item
                      name="safety_director_phone"
                      rules={mobileNumberRegex}
                    >
                      <Input placeholder="" addonBefore="+1" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Paragraph className="header-label mandatory-field-mark">
                      Email
                    </Paragraph>
                    <Form.Item name="email" rules={emailValidation}>
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 12 }}
                name="safety_director_resume"
                className="multiline-form-item"
                label="Resume for the above-identified individual:"
                valuePropName="safety_director_resume"
                getValueFromEvent={(e) =>
                  normFile(e, "safety_director_resume_file_list")
                }
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Upload
                  {...uploadButtonProps}
                  fileList={safetyData.safety_director_resume_file_list}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
              <Divider />

              {safetyPolicyItems}
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Safety;
