import React from "react";
import { Route, Redirect } from "react-router-dom";
import {
  isAuthenticated,
  getUserDetails,
  getSelectedVendor,
} from "../helpers/utility";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const userDetails = getUserDetails();
  const selectedVendorDetails = getSelectedVendor();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && restricted ? (
          userDetails.is_admin ? (
            <Redirect to="/admin/dashboard" />
          ) : (
            <Redirect
              to={`/vendor/${selectedVendorDetails.vendor_id}/dashboard`}
            />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
