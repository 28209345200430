export const mobileNumberRegex = [
  {
    required: true,
    message:
      "Please enter a valid 10-digit number without any spaces or dashes",
    pattern: new RegExp("^[0-9]{10}$"),
  },
];
export const nameValidation = [
  {
    pattern: new RegExp("^[A-Za-z ]+$"),
    message: "Name should not contain special characters",
    required: true,
  },
];
export const emailValidation = [
  {
    required: true,
    pattern: new RegExp(
      "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,13})+$"
    ),
    message: "Please enter a valid email address.",
  },
];

export const passwordValidation = [
  {
    required: true,
    pattern: new RegExp("^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"),
    message: "Please enter a valid password",
  },
];

export const numberValidation = [
  {
    required: true,
    pattern: new RegExp("^[0-9]*$"),
    message: "Please enter valid digits only",
  },
];

export const phoneValidation = [
  {
    required: true,
    pattern: new RegExp("^(\\D?[0-9]{3}\\D?)[0-9]{3}-[0-9]{4}$"),
    message: "Please enter a valid phone number, Eg:-(xxx)xxx-xxxx",
  },
];
export const grossRevenueValidation = [
  {
    required: true,
    pattern: new RegExp("^[0-9]+$"),
    message: "Please enter a valid Number",
  },
];
export const maxNumValidation = [
  {
    required: true,
    pattern: new RegExp("^[0-9][0-9]?$|^100$"),
    message: "Please enter a number between 0 to 100",
  },
];

export const currencyFormatter = (value) =>
  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const currencyParser = (value) => value.replace(/\$\s?|(,*)/g, "");

export const taxValidation = [
  {
    required: true,
    pattern: new RegExp("^(?:\\d{2}-\\d{7})$"),
    message: "Please enter a valid Tax ID in XX-XXXXXXX format",
  },
];

export const bradStreetValidation = [
  {
    pattern: new RegExp("^(?:\\d{2}-\\d{3}-\\d{4})$"),
    message:
      "Please enter a valid Dun & Bradstreet Number in XX-XXX-XXXX format",
  },
];

export const ssnValidation = [
  {
    required: true,
    pattern: new RegExp("^\\d{3}-\\d{2}-\\d{4}$"),
    message: "Please enter a valid Tax ID in XXX-XX-XXXX format",
  },
];

export const zipCodeValidation = [
  {
    required: true,
    pattern: new RegExp("^\\d{5}$"),
    message: "Please enter a valid 5 digit zip code",
  },
];

export const naicsCodeValidation = [
  {
    required: true,
    pattern: new RegExp("^\\d{6}$"),
    message: "Please enter a valid 6 digit NAICS code",
  },
];

export const zip4CodeValidation = [
  {
    required: true,
    pattern: new RegExp("^\\d{5}-\\d{4}$"),
    message: "Please enter a valid zip4 code, Eg:-xxxxx-xxxx",
  },
];

export const urlValidation = [
  {
    pattern: new RegExp(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    ),
    message: "Please enter a valid URL",
  },
];

export const contractValidation = [
  {
    pattern: new RegExp(/^-?\d*(\.\d*)?$/),
    message: "Please enter numbers",
  },
];
