import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Button,
  message,
  Row,
  Col,
  Typography,
  Descriptions,
  Skeleton,
  List,
  Table,
} from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import {
  getPaymentFailed,
  getPaymentSuccess,
  getPaymentURL,
  getMySubscription,
} from "../../../../services/payment.services";
import ResultWizz from "../../../../blocks/ResultWizz/ResultWizz";
import { useQuery } from "../../../../helpers/utility";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import CardWizz from "../../../../blocks/Card/Card";
import OfflinePayment from "./OfflinePayment";
import moment from "moment";

const { Title } = Typography;

const GetCerfied = () => {
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );
  const vendorId = vendorDetails?.id;
  const query = useQuery();
  const paymentStatus = query.get("status");
  const sessionId = query.get("session_id");

  const [subscriptionData, setSubscriptionData] = useState([]);
  const [transactionId, setTransactionId] = useState(null);
  const [formType, setFormType] = useState(null);
  const [loadingPaymentStatus, setLoadingPaymentStatus] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (vendorId) {
      if (paymentStatus === "success" && sessionId) {
        setLoadingPaymentStatus(true);
        handleSuccessResponse({
          session_id: sessionId,
          vendor_id: vendorId,
        });
      } else if (paymentStatus === "failed") {
        handleFailedResponse({
          session_id: sessionId,
          vendor_id: vendorId,
        });
      }
    }
  }, [vendorId]);

  useEffect(() => {
    if (vendorDetails && vendorDetails?.is_subscribed) {
      setLoading(true);

      getMySubscription(vendorId)
        .then((response) => {
          console.log("response", response.data?.data);
          setSubscriptionData(response.data?.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("error", error);
          if (error && error.response) {
            message.error(
              error.response.data.message || error.response.message
            );
          } else {
            message.error("Something went wrong, please try after sometime");
          }
        });
    }
  }, [vendorId, vendorDetails]);

  function handleSuccessResponse(reqBody) {
    getPaymentSuccess(reqBody)
      .then((res) => {
        setTransactionId(res.data.url.payment_intent);
        setLoadingPaymentStatus(false);
        setFormType(res.data.url.payment_status);
        if (!vendorDetails?.is_subscribed) {
          setVendorDetails({ ...vendorDetails, is_subscribed: true });
        }
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get payment success error");
        }
      });
  }

  function handleFailedResponse(reqBody) {
    getPaymentFailed(reqBody)
      .then((res) => {
        setTransactionId(res.data.url.payment_intent);
        setLoadingPaymentStatus(false);
        setFormType(res.data.url.payment_status);
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get payment failed error");
        }
      });
  }

  function handlePayment() {
    let data = vendorDetails?.recertify ? "1" : "0";
    getPaymentURL({
      vendor_id: vendorId,
      recertify: data,
    })
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get payment URL error");
        }
      });
  }

  function showModal() {
    setIsModalVisible(true);
  }
  function handleResultForm() {
    return !formType && loadingPaymentStatus
      ? {
          title: "Please wait while we are getting your payment status!",
        }
      : formType === "paid"
      ? {
          status: "success",
          title: "Payment successful",
          subTitle: `Transaction Id: ${transactionId}`,
        }
      : formType === "unpaid"
      ? {
          status: "error",
          title: "Sorry, Your Payment has failed!",
          extra: [
            <Button type="primary" loading={!vendorId} onClick={handlePayment}>
              Retry Payment
            </Button>,
            <Button type="primary" onClick={() => setFormType(null)}>
              Cancel
            </Button>,
          ],
        }
      : subscriptionData.length === 0
      ? {
          title: "No Payments Found",
          status: "404",
        }
      : {
          title: `${
            vendorDetails?.recertify ? "To recertify" : ""
          } Vendors must provide payment to i4 Strategies in the amount of ${
            vendorDetails?.recertify
              ? "$750 + 2.9% transaction fee"
              : "$1250 +  2.9% Stripe Fee"
          }`,
          subTitle:
            "This will cover the initial Pre-Qualification process. An additional $750 will be required annually for a recertification. Pre-Qualification Questionnaires will remain pending until payment is received and processed.",
          extra: [
            <Button type="primary" loading={!vendorId} onClick={handlePayment}>
              Pay Online
            </Button>,
            <Button loading={!vendorId} onClick={showModal}>
              Make Offline Payment
            </Button>,
          ],
        };
  }

  const columns = [
    {
      title: "Payment Made By",
      dataIndex: "paid_by",
      key: "paid_by",
      sorter: (a, b) => a.paid_by - b.paid_by,
    },
    {
      title: "Payment For PQQ",
      dataIndex: "pqq_id",
      key: "pqq_id",
      render: (text) => text || "-",
      sorter: (a, b) => {
        return a.pqq_id - b.pqq_id;
      },
    },
    {
      title: "Payment Made On",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => (text ? moment(text).format("MMM Do YYYY") : "-"),
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
    },
    {
      title: "Payment Of",
      dataIndex: "amount",
      key: "amount",
      render: (text) => text || "-",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Mode of payment",
      dataIndex: "is_offline_transaction",
      key: "is_offline_transaction",
      render: (isOffline) => (isOffline ? "Offline" : "Online"),
      sorter: (a, b) => a.is_offline_transaction - b.is_offline_transaction,
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      render: (text) => text || "-",
      sorter: (a, b) => {
        if (!a.transaction_id) return 1;
        if (!b.transaction_id) return -1;
        return a.transaction_id?.localeCompare(b.transaction_id);
      },
    },
  ];
  return (
    <Fragment>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <CardWizz>
            {vendorDetails?.is_subscribed ? (
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Title level={3}>
                    <CheckCircleTwoTone
                      twoToneColor="#16E872"
                      style={{ fontSize: "32px" }}
                    />{" "}
                    Your payment has been received.
                  </Title>
                </Col>
                <Col span={24}>
                  <Table
                    pagination={false}
                    bordered={true}
                    dataSource={subscriptionData}
                    columns={columns}
                    loading={loading}
                  />
                </Col>
              </Row>
            ) : (
              <ResultWizz {...handleResultForm()} />
            )}
          </CardWizz>
        </>
      )}
      {isModalVisible && (
        <OfflinePayment
          vendorId={vendorId}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </Fragment>
  );
};

export default GetCerfied;
