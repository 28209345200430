import React from "react";
import { Row, Col, Typography } from "antd";
const { Title, Paragraph } = Typography;

const AttachAndCertifyPQQ = () => {
  return (
    <Row type="flex">
      <Col spna={20} offset={1}>
        <Title level={3}>
          Following completion of the i4 Vendor Profile and submitting payment,
          you may attach your profile to a PQQ submission to a project and bid
          ID.{" "}
        </Title>
        <Paragraph>
          Note: only Approvers are able to attach, certify, and submit a PQQ
          submission.
        </Paragraph>
      </Col>
    </Row>
  );
};
export default AttachAndCertifyPQQ;
