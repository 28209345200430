import React from "react";
import DrawerWizz from "../../../reusable/DrawerWizz";
import ManageUserTabs from "./ManageUserTabs/ManageUserTabs";

const ManageUserDrawer = ({
  selectedUserData,
  vendorsList,
  setShowManageUserDrawer,
  fetchAllUsers,
  refresh,
}) => {
  return (
    <DrawerWizz
      title="Manage User"
      handleCancel={() => setShowManageUserDrawer(false)}
      content={
        <ManageUserTabs
          vendorsList={vendorsList}
          selectedUserData={selectedUserData}
          setShowManageUserDrawer={setShowManageUserDrawer}
          fetchAllUsers={fetchAllUsers}
          refresh={refresh}
        />
      }
    />
  );
};

export default ManageUserDrawer;
