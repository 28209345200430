import React, { Fragment, useContext } from "react";
import { Dropdown, Menu } from "antd";
import Avatar from "react-avatar";
import { colors } from "../../constants/defaultKeys";
import { clear } from "../../helpers/localStorage";
import { Link, useHistory } from "react-router-dom";
import { getUserDetails } from "../../helpers/utility";
import { SelectedVendorDetailsContext } from "../../context/SelectedVendorContext";

const UserAvatar = () => {
  const history = useHistory();

  const userDetails = getUserDetails();
  const { setVendorDetails } = useContext(SelectedVendorDetailsContext);

  const handleClick = () => {
    history.push("/logout");
    setVendorDetails(null);
    clear();
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/myprofile">My Profile</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <div onClick={handleClick}>Logout</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Avatar
          className="memu-avatar"
          color={userDetails?.first_name && colors[userDetails?.user_id % 24]}
          round={true}
          name={userDetails?.first_name}
          size="40px"
        />
      </Dropdown>
    </Fragment>
  );
};

export default UserAvatar;
