import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Form,
  Button,
  Collapse,
  Row,
  Col,
  Typography,
  Input,
  Divider,
  message,
  Upload,
} from "antd";
import {
  fullRowLayout,
  tailLayout,
  wideLabelLayout,
} from "../../../../config/formLayout.config";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import { UploadOutlined } from "@ant-design/icons";
import IntegrityMonitors from "./IntegrityMonitors/IntegrityMonitors";
import Disqualifications from "./Disqualifications/Disqualifications";
import SCDisqualifications from "./SCDisqualifications/SCDisqualifications";
import "./Integrity.css";
import RadioButton from "../../../../blocks/RadioButton/RadioButton";
import {
  createIntegrity,
  getDisqualifiedSubcontractors,
  getIntegrity,
  getIntegrityMonitor,
  getPrevDisQualification,
} from "../../../../services/vendorProfile.services";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import {
  profileCompletion,
  uploadFileUrl,
} from "../../../../constants/defaultKeys";
import {
  emailValidation,
  mobileNumberRegex,
} from "../../../../config/validation.config";
import RadioGroupWizz from "../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import { radioYesOrNo } from "../../../../config/radioGroupConstants";
import { getTokenIfNotExpired } from "../../../../helpers/utility";

const { Panel } = Collapse;
const { Paragraph } = Typography;

const validateMessages = {
  required: "'${label}' is required!",
};

const uploadButtonProps = {
  accept: ".doc,.docx, .pdf, .csv",
  maxCount: 1,
  action: uploadFileUrl,
  name: "docs",
  onChange(info) {
    // if headers are set as default then "antd" is setting the header before loggin into the application,
    // so setting headers when file change is triggered
    uploadButtonProps["headers"] = {
      Authorization: `Bearer ${getTokenIfNotExpired()}`,
    };
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const integrityQuestions = [
  {
    name: "engage_services_general",
    label:
      "Has the applicant firm voluntarily engaged the services of an Integrity Monitor, independent private inspector general, or integrity compliance consultant ('Integrity Monitor') in connection with the performance of any contract or with respect to its business practices generally?",
  },
  {
    name: "engage_services_certification",
    label:
      "Has the applicant firm been required to engage the services of an Integrity Monitor, required to submit a Certification in connection with the award of any contract, or otherwise been the subject of audits and/or investigations performed by an Integrity Monitor?",
  },
];

const Integrity = (props) => {
  const vendorId = props.vendorId;
  const [form] = Form.useForm();
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );
  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );

  const [disabled, setDisabled] = useState(false);
  const [integrityMonitorData, setIntegrityMonitorData] = useState([]);
  const [previousDisqualificationData, setPreviousDisqualificationData] =
    useState([]);
  const [disqualifiedSubcontractorsData, setDisqualifiedSubcontractorsData] =
    useState([]);

  const integrityData = vendorProfileFormData.integrity;
  const showCodeOfConduct = integrityData.has_code_of_conduct;
  const showInstance = integrityData.has_prev_disqualifications;
  const showSubcontractor = integrityData.has_disqualified_subContractor;
  const showTable =
    integrityData.engage_services_certification ||
    integrityData.engage_services_general;
  const uploadedFileList = integrityData.file_list;

  useEffect(() => {
    if (vendorId) {
      fetchIntegrity();
      fetchIntegrityMonitor();
      fetchPrevDisQualification();
      fetchDisqualifiedSubcontractors();
    }
  }, []);

  function fetchIntegrity() {
    getIntegrity(vendorId)
      .then((res) => {
        const data = res.data.data;
        if (Object.keys(data).length === 0) {
          setFormFieldValues({
            ...integrityData,
            code_of_conduct_doc: uploadedFileList[0]?.response.names[0] || null,
            has_code_of_conduct:
              (uploadedFileList.length > 0 && showCodeOfConduct) || true,
            has_prev_disqualifications: showInstance || false,
            has_disqualified_subContractor: showSubcontractor || false,
            engage_services_certification:
              integrityData.engage_services_certification || false,
            engage_services_general:
              integrityData.engage_services_general || false,
          });
        } else {
          let fileList;
          if (data.code_of_conduct_doc) {
            fileList = {
              file_list: [
                {
                  uid: 0,
                  name:
                    uploadedFileList[0]?.response?.names[0] ||
                    data.code_of_conduct_doc,
                  status: "done",
                },
              ],
            };
          }
          setFormFieldValues(
            {
              ...data,
              code_of_conduct_doc:
                uploadedFileList[0]?.name || res.data.data.code_of_conduct_doc,
              ...integrityData,
            },
            fileList
          );
        }
      })
      .catch((error) => {
        console.error("get integrity error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting integrity");
        }
      });
  }

  function fetchIntegrityMonitor() {
    getIntegrityMonitor(vendorId)
      .then((res) => setIntegrityMonitorData(res.data.data))
      .catch((error) => {
        console.error("get integrity monitor error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting integrity monitor");
        }
      });
  }

  function fetchPrevDisQualification() {
    getPrevDisQualification(vendorId)
      .then((res) => setPreviousDisqualificationData(res.data.data))
      .catch((error) => {
        console.error("get previous disqualification error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting previous disqualification");
        }
      });
  }

  function fetchDisqualifiedSubcontractors() {
    getDisqualifiedSubcontractors(vendorId)
      .then((res) => setDisqualifiedSubcontractorsData(res.data.data))
      .catch((error) => {
        console.error("get disqualified subcontractors error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting disqualified subcontractors");
        }
      });
  }

  function setFormFieldValues(data, fileList = integrityData) {
    setVendorProfileDataIntegrity({ ...data, ...fileList });
    form.setFieldsValue(data);
  }

  function setVendorProfileDataIntegrity(changedValues) {
    vendorProfileFormData.integrity = {
      ...integrityData,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function onFormValuesChange(changedValues) {
    let key = Object.keys(changedValues)[0];
    if (key !== "code_of_conduct_doc") {
      setVendorProfileDataIntegrity(form.getFieldsValue());
    }
  }

  const onFinish = async (values) => {
    if (showTable && integrityMonitorData.length === 0) {
      message.error("Please add atleast one Integrity Monitor");
      return;
    } else if (showInstance && previousDisqualificationData.length === 0) {
      message.error("Please add atleast one Previous Disqualifications");
      return;
    } else if (
      showSubcontractor &&
      disqualifiedSubcontractorsData.length === 0
    ) {
      message.error(
        "Please add atleast one Suspensions/ Debarments/ Disqualification"
      );
      return;
    } else {
      setDisabled(true);
      let formData = {
        ...values,
        vendor_id: vendorId,
        compliance_officer_phone:
          values.compliance_officer_phone &&
          values.compliance_officer_phone.trim() !== ""
            ? values.compliance_officer_phone
            : null,
        code_of_conduct_doc: showCodeOfConduct
          ? values.code_of_conduct_doc
          : null,
      };
      createIntegrity(formData)
        .then((response) => {
          setDisabled(false);
          if (!vendorDetails.integrity_status) {
            setVendorDetails({
              ...vendorDetails,
              integrity_status: true,
              profile_completion_status:
                vendorDetails.profile_completion_status +
                profileCompletion.integrity_status,
            });
          }
          message.success(response.data.message);
          props.onStepChange(props.currentStep + 1);
        })
        .catch((error) => {
          setDisabled(false);
          if (error && error.response) {
            if (error.response.status === 400) {
              message.error(
                "Something went wrong with the form being saved. Please contact administrator"
              );
            } else {
              message.error(
                error.response.data.message || error.response.message
              );
            }
          } else {
            message.error("Error creating integrity");
          }
        });
    }
  };

  const normFile = (e) => {
    setVendorProfileDataIntegrity({ file_list: e.fileList });

    if (e && e.fileList && e.fileList.length > 0 && e.fileList[0].response) {
      return e?.fileList[0]?.response?.names[0];
    }
  };

  const integrityItems = (
    <Fragment>
      {integrityQuestions.map((eachQusn) => (
        <Form.Item
          className="multiline-form-item"
          name={eachQusn.name}
          key={eachQusn.name}
          label={eachQusn.label}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {RadioButton()}
        </Form.Item>
      ))}
    </Fragment>
  );

  return (
    <Form
      name="integrity"
      form={form}
      className="form-integrity"
      {...wideLabelLayout}
      validateMessages={validateMessages}
      onValuesChange={onFormValuesChange}
      onFinish={onFinish}
      colon={false}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1", "2", "3"]}>
        <Panel header="Compliance Program" key="1">
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 8 }}
            className="multiline-form-item"
            name="has_code_of_conduct"
            label="Does firm have a code of conduct or compliance program?"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 10 }}
            className="multiline-form-item"
            name="code_of_conduct_doc"
            getValueFromEvent={normFile}
            hidden={!showCodeOfConduct}
            // rules={[
            //   {
            //     required: showCodeOfConduct,
            //     message: "This field is required!",
            //   },
            // ]}
          >
            <Upload {...uploadButtonProps} fileList={uploadedFileList}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Divider />
          <Row>
            <Col span={20} offset={2}>
              <Paragraph>Compliance Officer/Director or equivalent</Paragraph>
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                {...fullRowLayout}
              >
                <Row gutter={[8, 0]}>
                  <Col span={6}>
                    <Paragraph className="header-label">Name</Paragraph>
                    <Form.Item name="compliance_officer_name">
                      <Input placeholder="Compliance officer name" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Paragraph className="header-label">Title</Paragraph>
                    <Form.Item name="compliance_officer_title">
                      <Input placeholder="Compliance officer title" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Paragraph className="header-label">Phone</Paragraph>
                    <Form.Item
                      name="compliance_officer_phone"
                      rules={[{ ...mobileNumberRegex[0], required: false }]}
                    >
                      <Input
                        placeholder="Compliance officer number"
                        addonBefore="+1"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Paragraph className="header-label">Email</Paragraph>
                    <Form.Item
                      name="compliance_officer_email"
                      rules={[{ ...emailValidation[0], required: false }]}
                    >
                      <Input placeholder="Compliance officer email" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
        <Panel header="Integrity Monitor" key="2">
          {integrityItems}
          {showTable && (
            <IntegrityMonitors
              vendorId={vendorId}
              integrityMonitorData={integrityMonitorData}
              fetchIntegrityMonitor={fetchIntegrityMonitor}
            />
          )}
        </Panel>
        <Panel header="Suspensions, Debarments, Disqualification" key="3">
          <Form.Item
            name="has_prev_disqualifications"
            className="multiline-form-item"
            label="Is there any instance in which the applicant firm or a Key Individual
              has, within the past five years, been suspended, debarred,
              disqualified, had its prequalification revoked or otherwise been
              declared non-responsible or ineligible to bid or perform work?"
            rules={[
              {
                required: false,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          {showInstance && (
            <Disqualifications
              vendorId={vendorId}
              previousDisqualificationData={previousDisqualificationData}
              fetchPrevDisQualification={fetchPrevDisQualification}
            />
          )}
          <Divider />
          <Form.Item
            name="has_disqualified_subContractor"
            className="multiline-form-item"
            label="Has any subcontractor used by the applicant firm to perform work
              within the past five years which has been suspended, debarred,
              disqualified, had its prequalification revoked or otherwise been
              declared non-responsible or ineligible to bid or perform work?"
            rules={[
              {
                required: false,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          {showSubcontractor && (
            <SCDisqualifications
              vendorId={vendorId}
              disqualifiedSubcontractorsData={disqualifiedSubcontractorsData}
              fetchDisqualifiedSubcontractors={fetchDisqualifiedSubcontractors}
            />
          )}
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Integrity;
