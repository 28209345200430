import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import {
  createPrevDisQualification,
  updatePrevDisQualification,
} from "../../../../../services/vendorProfile.services";
import moment from "moment";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const DisqualificationsModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  //Disable Date greater than today
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, [editTable]);

  function setFormFieldValues(data) {
    form.setFieldsValue({
      ...data,
      date_instituted: moment(data.date_instituted),
      expiry_date: moment(data.expiry_date),
    });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
        date_instituted: moment(values.date_instituted).format("YYYY-MM-DD"),
        expiry_date: moment(values.expiry_date).format("YYYY-MM-DD"),
      };

      updatePrevDisQualification(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
        date_instituted: moment(values.date_instituted).format("YYYY-MM-DD"),
        expiry_date: moment(values.expiry_date).format("YYYY-MM-DD"),
      };

      createPrevDisQualification(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="type_of_action"
              label="Type Of Action"
              rules={[
                {
                  required: true,
                  message: "Please input your action type!",
                },
              ]}
            >
              <Input placeholder="Type Of Action" />
            </Form.Item>
            <Form.Item
              name="key_contact"
              label="Key Contact"
              rules={[
                {
                  required: true,
                  message: "Please input your  key contact !",
                },
              ]}
            >
              <Input placeholder="Key Contact" />
            </Form.Item>
            <Form.Item
              name="nature_of_action"
              label="Nature Of Action"
              rules={[
                {
                  required: true,
                  message: "Please input your  nature of action!",
                },
              ]}
            >
              <Input placeholder="Nature Of Action" />
            </Form.Item>
            <Form.Item
              name="date_instituted"
              label="Date Instituted"
              rules={[
                {
                  required: true,
                  validator: async (_, date_instituted) => {
                    if (
                      moment(date_instituted).isAfter(
                        form.getFieldValue("expiry_date")
                      )
                    ) {
                      return Promise.reject(
                        new Error(
                          `"Date Instituted" cannot be after "Expiration Date"`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                disabledDate={disabledDate}
                placeholder="Date Instituted"
                size="large"
                style={{ minWidth: "280px" }}
              />
            </Form.Item>
            <Form.Item
              name="expiry_date"
              label="Expiry Date"
              rules={[
                {
                  required: true,
                  validator: async (_, expiry_date) => {
                    if (
                      moment(expiry_date).isBefore(
                        form.getFieldValue("date_instituted")
                      )
                    ) {
                      return Promise.reject(
                        new Error(
                          `"Expiration Date" cannot be before "Date Instituted"`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder="Exp. Date"
                size="large"
                style={{ minWidth: "280px" }}
              />
            </Form.Item>
            <Form.Item
              name="status_disposition"
              label="Status Disposition"
              rules={[
                {
                  required: true,
                  message: "Please input your  status disposition!",
                },
              ]}
            >
              <Input placeholder="Status Disposition" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default DisqualificationsModal;
