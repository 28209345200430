import React, { useEffect, useState } from "react";
import DrawerWizz from "../../../reusable/DrawerWizz";
import { Form, Input, Button, message, Select, Switch } from "antd";
import { emailValidation } from "../../../config/validation.config";
import { createVendor } from "../../../services/userProfile.services";

const { Option } = Select;
const validateMessages = {
  required: "'${label}' is required",
};

const AddVendorDrawer = ({
  fetchAllUsers,
  setShowAddVendorDrawer,
  customersAndProjectsList,
}) => {
  const [form] = Form.useForm();

  const [projectsList, setProjectsList] = useState([]);

  useEffect(() => {
    setFormFieldValues({ is_approver: true });
  }, []);

  function setFormFieldValues(data) {
    form.setFieldsValue(data);
  }

  function onFormValuesChange(changedValues) {
    if (changedValues.customers && changedValues.customers.length === 0) {
      setFormFieldValues({ ...form.getFieldsValue(), projects: [] });
    } else if (changedValues.customers) {
      let projects = customersAndProjectsList.projects.filter((eachItem) =>
        changedValues.customers.includes(eachItem.customer_id)
      );
      setProjectsList(projects);
    }
  }

  function onFinish(values) {
    createVendor(values)
      .then((res) => {
        message.success(res.data.message);
        fetchAllUsers();
        setShowAddVendorDrawer(false);
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("create vendor error");
        }
      });
  }

  return (
    <DrawerWizz
      title="Add Vendor"
      handleCancel={() => setShowAddVendorDrawer(false)}
      content={
        <Form
          name="add_vendor"
          form={form}
          layout="vertical"
          validateMessages={validateMessages}
          onValuesChange={onFormValuesChange}
          onFinish={onFinish}
          colon={false}
          scrollToFirstError
        >
          <Form.Item
            name="vendor_name"
            label="Vendor Name"
            rules={[
              {
                required: true,
                message: "Vendor Name is required!",
              },
            ]}
          >
            <Input placeholder="Vendor Name" />
          </Form.Item>
          <Form.Item
            name="vendor_code"
            label="Code"
            rules={[
              {
                required: true,
                message: "Code is required!",
              },
            ]}
          >
            <Input placeholder="Code" />
          </Form.Item>
          <Form.Item
            name="customers"
            label="Select Customers"
            rules={[
              {
                required: true,
                message: "Customers is required!",
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              placeholder="Select Customers"
              style={{ minWidth: "120px" }}
            >
              {customersAndProjectsList.customers.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="projects"
            label="Projects"
            rules={[
              {
                required: true,
                message: "Projects is required!",
                type: "array",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              mode="multiple"
              placeholder="Select Projects"
              style={{ minWidth: "120px" }}
            >
              {projectsList.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="email" label="User Email" rules={emailValidation}>
            <Input placeholder="User Email" />
          </Form.Item>
          <Form.Item
            name="is_approver"
            label="Is Approver?"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Switch className="add-vendor" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Vendor
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};

export default AddVendorDrawer;
