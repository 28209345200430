import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const OSHAViolationsModel = ({
  create,
  update,
  title,
  editTable,
  setVisible,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      let formValues = editTable;
      formValues.open_date = moment(editTable.open_date);
      formValues.close_date = moment(editTable.close_date);
      form.setFieldsValue(formValues);
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    // refresh();
  };

  function onFinish(values) {
    if (editTable.status === "update") {
      update(values);
    } else {
      create(values);
    }
  }

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="nr"
            label="NR"
            rules={[
              {
                required: true,
                message: "Please input your nr!",
              },
            ]}
          >
            <Input placeholder="NR" />
          </Form.Item>

          <Form.Item
            name="open_date"
            label="Open Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              dateFormat={dateFormat}
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item
            name="inspection"
            label="Inspection"
            rules={[
              {
                required: true,
                message: "Please input your inspection!",
              },
            ]}
          >
            <Input placeholder="Inspection" />
          </Form.Item>
          <Form.Item
            name="saftey_or_health"
            label="Safety or Health"
            rules={[
              {
                required: true,
                message: "Please input your Safety or Health!",
              },
            ]}
          >
            <Input placeholder="Safety or Health" />
          </Form.Item>

          <Form.Item
            name="emphasis"
            label="Emphasis"
            rules={[
              {
                required: true,
                message: "Please input your emphasis!",
              },
            ]}
          >
            <Input placeholder="Emphasis" />
          </Form.Item>
          <Form.Item
            name="county"
            label="County"
            rules={[
              {
                required: true,
                message: "Please input your county!",
              },
            ]}
          >
            <Input placeholder="County" />
          </Form.Item>
          <Form.Item
            name="insepction_type"
            label="Inspection Type"
            rules={[
              {
                required: true,
                message: "Please input your Inspection Type!",
              },
            ]}
          >
            <Input placeholder="Inspection Type" />
          </Form.Item>
          <Form.Item
            name="disposition"
            label="Disposition"
            rules={[
              {
                required: true,
                message: "Please input your disposition!",
              },
            ]}
          >
            <Input placeholder="Disposition" />
          </Form.Item>

          <Form.Item
            name="close_date"
            label="Close Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              dateFormat={dateFormat}
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};

export default OSHAViolationsModel;
