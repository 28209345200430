import { Fragment } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { setPassword } from "../../services/auth.services";
import Logo from "../../assets/images/Editedlogo.png";
import CardWizz from "../../blocks/Card/Card";

const SetPassword = () => {
  const query = useParams();
  const history = useHistory();

  const onFinish = (values) => {
    const data = { ...values, token: query && query.token };
    setPassword(data)
      .then((response) => {
        message.success(response.data.message);
        history.push("/");
      })
      .catch((error) => {
        message.error(error.response.data.message);
        console.error(error);
      });
  };

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <CardWizz bordered={false}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              <img src={Logo} alt="check logo" height="36px" />
            </div>
            <Form name="setpassword" onFinish={onFinish} scrollToFirstError>
              <Form.Item
                name="new_password"
                rules={[
                  {
                    required: true,
                    message:
                      "Use 8 or more characters with a mix of letters, numbers & symbols.",
                    pattern: new RegExp(
                      "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                    ),
                  },
                ]}
              >
                <Input.Password
                  autoFocus={true}
                  placeholder="New Password"
                  size="large"
                  type="password"
                />
              </Form.Item>
              <Form.Item
                name="new_password_confirm"
                rules={[
                  { required: true, message: "Please input your Password!" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The new passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Confirm Password"
                  size="large"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  className="login-form-button"
                >
                  <span>Submit</span>
                </Button>
              </Form.Item>
            </Form>
          </CardWizz>
        </Col>
      </Row>
    </Fragment>
  );
};
export default SetPassword;
