import React, { Fragment, useState } from "react";
import { Row, Col, Typography, Button, Table, Space, message } from "antd";
import OwnershipDetailsModal from "./OwnershipDetailsModal";
import { deleteOwnershipDetails } from "../../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import moment from "moment";

const { Paragraph } = Typography;

const OwnershipDetails = (props) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  // Ownership table columns
  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Owner",
      dataIndex: "is_owner",
      key: "is_owner",
      width: 80,
      render: (record) =>
        record ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#cf1322" />
        ),
    },
    {
      title: "Ownership Type",
      dataIndex: "ownership_type",
      key: "ownership_type",
      ellipsis: true,
      width: 150,
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Tax ID",
      dataIndex: "tax_id",
      key: "tax_id",
      ellipsis: true,
      width: 120,
    },
    {
      title: "Street",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      width: 250,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      ellipsis: true,
      width: 150,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Zipcode",
      dataIndex: "zipcode",
      key: "zipcode",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Percent Ownership",
      dataIndex: "ownership_percent",
      key: "ownership_percent",
      ellipsis: true,
      width: 100,
      render: (value) => `${value} %`,
    },
    {
      title: "Ownership Form",
      dataIndex: "ownership_from",
      key: "ownership_from",
      ellipsis: true,
      width: 140,
    },
    {
      title: "Ownership To",
      dataIndex: "ownership_to",
      key: "ownership_to",
      ellipsis: true,
      width: 140,
      render: (_, record) => (record.present ? "Present" : record.ownership_to),
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const openModal = (data) => {
    setVisible(true);

    setEditTable({
      ...data,
      dob: data.dob && moment(data.dob),
      ownership_from: data.ownership_from && moment(data.ownership_from),
      ownership_to: data.present
        ? moment()
        : data.ownership_to && moment(data.ownership_to),
    });
  };

  const removeColumn = (id) => {
    deleteOwnershipDetails({
      id: id,
      vendor_id: props.vendorId,
    })
      .then((response) => {
        props.fetchOwnershipDetails(props.vendorId);
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col span={22} offset={1}>
          <Paragraph> Please Enter Details of: </Paragraph>
          <Paragraph>(i) Principals, directors, officers</Paragraph>
          <Paragraph>
            (ii) Shareholders of 5% or more of the applicant firm’s issued and
            outstanding stock
          </Paragraph>
          <Paragraph>
            (iii) Any individual who exercises control or directs the firm’s
            overall policy-making, financial decisions or operations of the
            applicant firm
          </Paragraph>
          <Paragraph className="mandatory-field-mark">
            Add ownership details
          </Paragraph>

          <Button
            onClick={() => openModal({ status: "create" })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            {" "}
            Add Details
          </Button>
        </Col>
        <Col span={22} offset={1}>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={props.tableData}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <OwnershipDetailsModal
          setVisible={setVisible}
          title="Add Ownership Details"
          vendorId={props.vendorId}
          editTable={editTable}
          refresh={() => props.fetchOwnershipDetails(props.vendorId)}
        />
      )}
    </Fragment>
  );
};

export default OwnershipDetails;
