import React, { useState } from "react";
import { Table, Row, Col, Button, Space, message } from "antd";
import ContractsInProgressModal from "./ContractsInProgressModal";
import { removeContracts } from "../../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import { ContractsInProgressColumns } from "../../../../PQQ/tableColumns";

const ContractsInProgress = (props) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  const refreshPage = () => {
    props.fetchContracts(props.vendorId);
  };

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  const removeColumn = (id) => {
    removeContracts({
      id: id,
      vendor_id: props.vendorId,
    })
      .then((response) => {
        refreshPage();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    ...ContractsInProgressColumns,
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col span={22} offset={1}>
        <Button
          onClick={openModal}
          type="secondary"
          style={{
            marginBottom: 16,
          }}
        >
          Add Contract
        </Button>
      </Col>
      <Col span={22} offset={1}>
        <Table
          size="small"
          columns={columns}
          dataSource={props.contractsData}
          pagination={false}
          scroll={{ x: 800 }}
        />
      </Col>
      {visible && (
        <ContractsInProgressModal
          setVisible={setVisible}
          title="Add Contract"
          vendorId={props.vendorId}
          tableData={props.contractsData}
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Row>
  );
};

export default ContractsInProgress;
