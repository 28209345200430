import React from "react";
import { GetVendorId, getAssignedVendors } from "../../helpers/utility";

import Toolbar from "./Toolbar";
const ToolbarHOC = () => {
  const vendorId = GetVendorId();
  const vendors = getAssignedVendors();
  return <Toolbar vendors={vendors} vendorId={vendorId} />;
};

export default ToolbarHOC;
