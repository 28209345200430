import MWBE from "./MWBE/MWBE";
import TWLFB from "./TWLFB/TWLFB";
import Safety from "./Safety/Safety";
import BackgroundInfo from "./BackgroundInfo/BackgroundInfo";
import BusinessLicences from "./BusinessLicences/BusinessLicences";
import NotableCourtRecords from "./NotableCourtRecords/NotableCourtRecords";
import GenerateReport from "./GenerateReport/GenerateReport";
import SummaryOfFindings from "./SummaryOfFindings/SummaryOfFindings";
import OtherViolations from "./OtherViolations/OtherViolations";
import FinancialRatios from "./FinancialRatios/FinancialRatios";
import RelatedEntities from "./RelatedEntities/RelatedEntities";

export const ADMIN_FORM_STEPS = [
  {
    title: "Corporate Information",
    subTitle: null,
    status: "wait",
    description: null,
    component: MWBE,
    stepId: "basic_details_status",
    step_name: "basic-details",
  },
  {
    title: "Financial Ratios",
    subTitle: null,
    status: "wait",
    description: null,
    component: FinancialRatios,
    stepId: "financial_ratiostatus",
    step_name: "Financial-Ratios",
  },
  {
    title: "Business License",
    subTitle: null,
    status: "wait",
    description: null,
    component: BusinessLicences,
    stepId: "ownership_status",
    step_name: "ownership",
    disable: false,
  },
  {
    title: "Related Entities",
    subTitle: null,
    status: "wait",
    description: null,
    component: RelatedEntities,
    stepId: "related_entities",
    step_name: "Related-Entities",
  },
  {
    title: "Background Info For Key Individual Or Owner",
    subTitle: null,
    status: "wait",
    description: null,
    component: BackgroundInfo,
    stepId: "financial_status",
    step_name: "financial-reports",
    disable: false,
  },
  {
    title: "Tax Warrant, Liens, Fillings & Bankruptcy",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: TWLFB,
    stepId: "bank_status",
    step_name: "bank-details",
    disable: false,
  },
  {
    title: "Notable Court Records",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: NotableCourtRecords,
    stepId: "litigation_status",
    step_name: "litigation",
    disable: false,
  },
  {
    title: "Safety",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: Safety,
    stepId: "safety_status",
    step_name: "safety",
    disable: false,
  },
  {
    title: "Other Violations and Misconduct",
    subTitle: null,
    status: "wait",
    description: null,
    component: OtherViolations,
    stepId: "other_violations_and_misconduct",
    step_name: "other-violations-and-misconduct",
  },
  {
    title: "Summary of Findings",
    subTitle: null,
    status: "wait",
    description: null,
    component: SummaryOfFindings,
    stepId: "summary_of_findings",
    step_name: "summary-of-findings",
  },
  {
    title: "Generate Report",
    subTitle: null,
    status: "wait",
    description: null,
    component: GenerateReport,
    stepId: "generate_reoprt",
    step_name: "generate-report",
  },
];
