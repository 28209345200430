import React, { Fragment, useEffect, useState, useContext } from "react";
import { Collapse } from "antd";
import NYCLicences from "./NYCLicences/NYCLicences";
import OtherLicences from "./OtherLicences/OtherLicences";

const { Panel } = Collapse;

const BusinessLicences = () => {
  return (
    <Collapse defaultActiveKey={["1", "2"]}>
      <Panel header="NYC License" key="1">
        <NYCLicences />
      </Panel>
      <Panel header="Other License" key="2">
        <OtherLicences />
      </Panel>
    </Collapse>
  );
};

export default BusinessLicences;
