import React, { Fragment, useState } from "react";
import { Table, Button, Row, Col, Space, message } from "antd";
import SuretyContactsModal from "./SuretyContactsModal";
import { removeSuretyContact } from "../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../reusable/ModalWizz";
import { SuretyContactsColumns } from "../../../PQQ/tableColumns";

const SuretyContacts = ({
  vendorId,
  suretyContactData,
  fetchSuretyContact,
}) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  // Surety contacts table columns
  const columns = [
    ...SuretyContactsColumns,
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const refreshPage = () => {
    fetchSuretyContact();
  };

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  const removeColumn = (id) => {
    removeSuretyContact({
      id: id,
      vendor_id: vendorId,
    })
      .then((response) => {
        refreshPage();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col span={20} offset={2}>
          <Button
            type="secondary"
            style={{
              marginBottom: 16,
            }}
            onClick={() => openModal({ status: "create" })}
          >
            Add Entity/Individual
          </Button>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={suretyContactData}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <SuretyContactsModal
          setVisible={setVisible}
          title="Add Individual"
          vendorId={vendorId}
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
};

export default SuretyContacts;
