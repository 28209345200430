import { Table, Descriptions, Typography } from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  FilePdfOutlined,
} from "@ant-design/icons";
import {
  OwnershipDetailsColumns,
  LineOfCreditsColumns,
  SuretyContactsColumns,
  BankLiensColumns,
  CriminalLitigationColumns,
  CivilLitigationColumns,
  AdministrativeVoilationColumns,
  ContractsInProgressColumns,
  ServiceLicenseColumns,
  RevokedLicenseColumns,
  CertificationColumns,
  PastProjectsColumns,
  ContractorReferencesColumns,
  TerminatedContractsColumns,
  LiquidatedDamagesColumns,
  IntegrityMonitorColumns,
  PrevDisqualificationsColumns,
  DisqualifiedSubContractorColumns,
  WorkersInsuranceColumns,
  ChangesInInsuranceCarriersColumns,
  SupplierReferencesColumns,
} from "./tableColumns";

import "./PQQ.css";
import DescriptionsItem from "antd/lib/descriptions/Item";

const { Text } = Typography;
const labelStyle = {
  background: "#f5f5f5",
  fontWeight: 500,
  color: "#000000",
};
const PQQDescriptions = ({ data }) => {
  const fileViewer = (file) => {
    return (
      <Text>
        {file?.url ? (
          <a href={file?.url} target="_blank" rel="noreferrer">
            {file?.name} <FilePdfOutlined />
          </a>
        ) : (
          "-"
        )}
      </Text>
    );
  };
  return (
    <div>
      <Descriptions
        title="Basic Details"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}
        labelStyle={labelStyle}
      >
        <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="Code">{data?.code}</Descriptions.Item>
        <Descriptions.Item label="EMP ID Number">
          {data?.basic_details?.employer_identification_number}
        </Descriptions.Item>
        <Descriptions.Item label="Name of the Firm">
          {data?.basic_details?.name_of_firm}
        </Descriptions.Item>
        <Descriptions.Item label="Entity Type">
          {data?.basic_details?.type_of_entity}
        </Descriptions.Item>
        <Descriptions.Item label="DBA">
          {data?.basic_details?.DBA}
        </Descriptions.Item>
        <Descriptions.Item label="Affiliated Companies">
          {data?.basic_details?.affiliated_companies}
        </Descriptions.Item>
        <Descriptions.Item label="Date Formed">
          {data?.basic_details?.date_formed}
        </Descriptions.Item>
        <Descriptions.Item label="Phone">
          {data?.basic_details?.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Fax">
          {data?.basic_details?.fax}
        </Descriptions.Item>
        <Descriptions.Item label="Website">
          {data?.basic_details?.website}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Primary Address" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Street Address" span={3}>
          {data?.basic_details?.primary_address}
        </Descriptions.Item>
        <Descriptions.Item label="City" span={1}>
          {data?.basic_details?.p_city}
        </Descriptions.Item>
        <Descriptions.Item label="State" span={1}>
          {data?.basic_details?.p_state}
        </Descriptions.Item>
        <Descriptions.Item label="Zipcode" span={1}>
          {data?.basic_details?.p_zipcode}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Mailing Address" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Street Address" span={3}>
          {data?.basic_details?.mailing_street_address}
        </Descriptions.Item>
        <Descriptions.Item label="City" span={1}>
          {data?.basic_details?.m_city}
        </Descriptions.Item>
        <Descriptions.Item label="State" span={1}>
          {data?.basic_details?.m_state}
        </Descriptions.Item>
        <Descriptions.Item label="Zipcode" span={1}>
          {data?.basic_details?.m_zipcode}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Ownership Details" bordered>
        <Descriptions.Item label="Chart File" span={3} labelStyle={labelStyle}>
          {fileViewer(data?.ownership?.ownership_chart)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Ownership Details Table" bordered></Descriptions>
      <Table
        dataSource={data?.ownership_details}
        columns={OwnershipDetailsColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Financial Reports" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Bradstreet Number" span={3}>
          {data?.financial_reports?.bradstreet_number}
        </Descriptions.Item>
        <Descriptions.Item label="Prev 1yr" span={1}>
          {data?.financial_reports?.prev_1yr}
        </Descriptions.Item>
        <Descriptions.Item label="Prev 2yr" span={1}>
          {data?.financial_reports?.prev_2yr}
        </Descriptions.Item>
        <Descriptions.Item label="prev_3yr" span={1}>
          {data?.financial_reports?.prev_3yr}
        </Descriptions.Item>
        <Descriptions.Item label="Prev 1yr Revenue" span={1}>
          $ {data?.financial_reports?.prev_1yr_revenue}
        </Descriptions.Item>
        <Descriptions.Item label="Prev 2yr Revenue" span={1}>
          $ {data?.financial_reports?.prev_2yr_revenue}
        </Descriptions.Item>
        <Descriptions.Item label="Prev 3yr Revenue" span={1}>
          $ {data?.financial_reports?.prev_3yr_revenue}
        </Descriptions.Item>
        <Descriptions.Item label="Financial Statement" span={3}>
          {fileViewer(data?.financial_reports?.financial_statement)}
        </Descriptions.Item>
        <Descriptions.Item label="Contracts In Process" span={3}>
          {fileViewer(data?.financial_reports?.contracts_in_process)}
        </Descriptions.Item>
        <Descriptions.Item label="Tax Return" span={3}>
          {fileViewer(data?.financial_reports?.tax_return)}
        </Descriptions.Item>
        <Descriptions.Item label="Tax Payer Certificate" span={3}>
          {fileViewer(data?.financial_reports?.tax_payer_certificate)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Bank Details" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Applicant Bank" span={1}>
          {data?.bank_details?.applicant_bank}
        </Descriptions.Item>
        <Descriptions.Item label="Reference Namer" span={1}>
          {data?.bank_details?.reference_name}
        </Descriptions.Item>
        <Descriptions.Item label="Reference Phone" span={1}>
          {data?.bank_details?.reference_phone}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Line of Credits" bordered></Descriptions>
      <Table
        dataSource={data?.line_of_credit}
        columns={LineOfCreditsColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />

      <Descriptions title="Surety Info" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Surety Name" span={1}>
          {data?.bank_details?.surety_name}
        </Descriptions.Item>
        <Descriptions.Item label="Broker Name" span={1}>
          {data?.bank_details?.broker_name}
        </Descriptions.Item>
        <Descriptions.Item label="Broker Phone" span={1}>
          {data?.bank_details?.broker_phone}
        </Descriptions.Item>

        <Descriptions.Item label="Bonding Capacity Single Job" span={1}>
          {data?.bank_details?.bonding_capacity_single_job}
        </Descriptions.Item>
        <Descriptions.Item label="Bonding Capacity Aggregate Job" span={1}>
          {data?.bank_details?.bonding_capacity_aggregate_job}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Surety Contacts" bordered></Descriptions>

      <Table
        dataSource={data?.surety_contacts}
        columns={SuretyContactsColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Bank Liens" bordered></Descriptions>
      <Table
        dataSource={data?.bank_liens}
        columns={BankLiensColumns}
        pagination={false}
        size="small"
        scroll={{
          x: 700,
        }}
      />

      <Descriptions title="Criminal Litigations" bordered></Descriptions>
      <Table
        dataSource={data?.criminal_litigation}
        columns={CriminalLitigationColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Civil Litigations" bordered></Descriptions>
      <Table
        dataSource={data?.criminal_litigation}
        columns={CivilLitigationColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Administrative Voilations" bordered></Descriptions>
      <Table
        dataSource={data?.administrative_voilation}
        columns={AdministrativeVoilationColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />

      <Descriptions title="Employees" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Number of Employees in Home Office" span={3}>
          {data?.additional_details?.employees_home_office}
        </Descriptions.Item>
        <Descriptions.Item
          label="Number of Employees in Field Supervisory"
          span={3}
        >
          {data?.additional_details?.employees_field_supervisory}
        </Descriptions.Item>
        <Descriptions.Item label="Number of Employees in Craft" span={3}>
          {data?.additional_details?.employees_craft}
        </Descriptions.Item>
        <Descriptions.Item
          label="Average Number of Employees over past three years in Home Office"
          span={3}
        >
          {data?.additional_details?.avg_employees_home_office}
        </Descriptions.Item>
        <Descriptions.Item
          label="Average Number of Employees over past three years in Field Supervisory"
          span={3}
        >
          {data?.additional_details?.avg_employees_field_supervisory}
        </Descriptions.Item>
        <Descriptions.Item
          label="Average Number of Employees over past three years in Craft"
          span={3}
        >
          {data?.additional_details?.avg_employees_craft}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Contracts in Progress" bordered></Descriptions>
      <Table
        dataSource={data?.contracts_in_progress}
        columns={ContractsInProgressColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Service Licenses" bordered></Descriptions>
      <Table
        dataSource={data?.service_license}
        columns={ServiceLicenseColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Revoked Licenses" bordered></Descriptions>
      <Table
        dataSource={data?.revoked_license}
        columns={RevokedLicenseColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Certifications" bordered></Descriptions>
      <Table
        dataSource={data?.certifications}
        columns={CertificationColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />

      <Descriptions title="Experience" bordered labelStyle={labelStyle}>
        <Descriptions.Item span={3}>
          <Text level={5} align="left" type="flex">
            Previous Year Percent Revenue Subcontracting
          </Text>
        </Descriptions.Item>

        <Descriptions.Item label={data?.experience?.prev_1yr}>
          % {data?.experience?.prev_1yr_percent_revenue_subcontracting}
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_2yr}>
          % {data?.experience?.prev_2yr_percent_revenue_subcontracting}
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_3yr}>
          % {data?.experience?.prev_3yr_percent_revenue_subcontracting}
        </Descriptions.Item>
        <Descriptions.Item span={3}>
          Previous Year Type Of Work Subcontracted
        </Descriptions.Item>

        <Descriptions.Item label={data?.experience?.prev_1yr}>
          {data?.experience?.prev_1yr_type_of_work_subcontracted}
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_2yr}>
          {data?.experience?.prev_2yr_type_of_work_subcontracted}
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_3yr}>
          {data?.experience?.prev_3yr_type_of_work_subcontracted}
        </Descriptions.Item>
        <Descriptions.Item span={3}>
          Previous Year Avg Contract Size
        </Descriptions.Item>

        <Descriptions.Item label={data?.experience?.prev_1yr}>
          $ {data?.experience?.prev_1yr_avg_contract_size}
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_2yr}>
          $ {data?.experience?.prev_2yr_avg_contract_size}
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_3yr}>
          $ {data?.experience?.prev_3yr_avg_contract_size}
        </Descriptions.Item>
        <Descriptions.Item span={3}>
          Previous Year Max Contract
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_1yr}>
          $ {data?.experience?.prev_1yr_max_contract}
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_2yr}>
          $ {data?.experience?.prev_2yr_max_contract}
        </Descriptions.Item>
        <Descriptions.Item label={data?.experience?.prev_3yr}>
          $ {data?.experience?.prev_3yr_max_contract}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Past Projects" bordered></Descriptions>
      <Table
        dataSource={data?.past_projects}
        columns={PastProjectsColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Contractor References" bordered></Descriptions>
      <Table
        dataSource={data?.contractor_references}
        columns={ContractorReferencesColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Supplier References" bordered></Descriptions>
      <Table
        dataSource={data?.supplier_references}
        columns={SupplierReferencesColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Terminated Contracts" bordered></Descriptions>
      <Table
        dataSource={data?.terminated_contracts}
        columns={TerminatedContractsColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Liquidated Damages" bordered></Descriptions>
      <Table
        dataSource={data?.liquidated_damages}
        columns={LiquidatedDamagesColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Integrity" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Has Code Of Conduct">
          <Text>
            {data?.integrity?.has_code_of_conduct ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <CloseCircleTwoTone twoToneColor="#f5222d" />
            )}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Code Of Conduct Doc" span={2}>
          {fileViewer(data?.integrity?.code_of_conduct_doc)}
        </Descriptions.Item>
        <Descriptions.Item label="Compliance Officer Name" span={2}>
          {data?.integrity?.compliance_officer_name}
        </Descriptions.Item>
        <Descriptions.Item label="Compliance Officer Title" span={1}>
          {data?.integrity?.compliance_officer_title}
        </Descriptions.Item>
        <Descriptions.Item label="Compliance Officer Email" span={2}>
          {data?.integrity?.compliance_officer_email}
        </Descriptions.Item>
        <Descriptions.Item label="Engage Services General">
          {data?.integrity?.engage_services_general ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#f5222d" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Engage Services Certification">
          {data?.integrity?.engage_services_certification ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#f5222d" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Compliance Officer Phone">
          {data?.integrity?.compliance_officer_phone}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Integrity Monitor" bordered></Descriptions>
      <Table
        dataSource={data?.integrity_monitor}
        columns={IntegrityMonitorColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Previous Disqualifications" bordered></Descriptions>
      <Table
        dataSource={data?.prev_disqualifications}
        columns={PrevDisqualificationsColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Disqualified Subcontractor" bordered></Descriptions>
      <Table
        dataSource={data?.disqualified_subcontractor}
        columns={DisqualifiedSubContractorColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />

      <Descriptions title="Insurance" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Insurance Doc">
          {fileViewer(data?.insurance?.insurance_doc)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Worker's Insurance"></Descriptions>
      <Table
        dataSource={data?.work_insurance_details}
        columns={WorkersInsuranceColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <Descriptions title="Changes In Insurance Carriers"></Descriptions>
      <Table
        dataSource={data?.change_in_insurance_carriers}
        columns={ChangesInInsuranceCarriersColumns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <br />
      <Descriptions title="Safety" bordered labelStyle={labelStyle}>
        <Descriptions.Item span={3}>Previous Year EMR</Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_1yr} span={1}>
          {data?.safety?.prev_1yr_emr}
        </Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_2yr} span={1}>
          {data?.safety?.prev_2yr_emr}
        </Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_3yr} span={1}>
          {data?.safety?.prev_3yr_emr}
        </Descriptions.Item>
        <Descriptions.Item span={3}>
          Recordable Incidence Rate
        </Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_1yr}>
          {data?.safety?.prev_1yr_recordable_incidence_rate}
        </Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_2yr}>
          {data?.safety?.prev_2yr_recordable_incidence_rate}
        </Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_3yr}>
          {data?.safety?.prev_3yr_recordable_incidence_rate}
        </Descriptions.Item>
        <Descriptions.Item span={3}>Lost Time Incidence Rate</Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_1yr}>
          {data?.safety?.prev_1yr_lost_time_incidence_rate}
        </Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_1yr}>
          {data?.safety?.prev_2yr_lost_time_incidence_rate}
        </Descriptions.Item>
        <Descriptions.Item label={data?.safety?.prev_1yr}>
          {data?.safety?.prev_3yr_lost_time_incidence_rate}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions title="" bordered labelStyle={labelStyle}>
        <Descriptions.Item label="Broker Letter" span={3}>
          {fileViewer(data?.safety?.broker_letter)}
        </Descriptions.Item>
        <Descriptions.Item label="Osha 300 A Forms" span={3}>
          {fileViewer(data?.safety?.osha_300_a_forms)}
        </Descriptions.Item>
        <Descriptions.Item label="Safety Director Name" span={3}>
          {data?.safety?.safety_director_name}
        </Descriptions.Item>
        <Descriptions.Item label="Safety Director Title" span={3}>
          {data?.safety?.safety_director_title}
        </Descriptions.Item>
        <Descriptions.Item label="Safety Director Phone" span={3}>
          {data?.safety?.safety_director_phone}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={3}>
          {data?.safety?.email}
        </Descriptions.Item>
        <Descriptions.Item label="Safety Director Resume" span={3}>
          {fileViewer(data?.safety?.safety_director_resume)}
        </Descriptions.Item>
        <Descriptions.Item label="Has Safety Policy" span={3}>
          {data?.safety?.has_safety_policy ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#f5222d" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Has Return To Work Policy" span={3}>
          {data?.safety?.has_return_to_work_policy ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#f5222d" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Has Drug Test Policy" span={3}>
          {data?.safety?.has_drug_test_policy ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#f5222d" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Safety Policy Doc" span={3}>
          {fileViewer(data?.safety?.has_safety_policy_doc)}
        </Descriptions.Item>
        <Descriptions.Item label="Return To Work Policy Doc" span={3}>
          {fileViewer(data?.safety?.has_return_to_work_policy_doc)}
        </Descriptions.Item>
        <Descriptions.Item label="Drug Test Policy Doc" span={3}>
          {fileViewer(data?.safety?.has_drug_test_policy_doc)}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default PQQDescriptions;
