import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import moment from "moment";
import {
  workersInsurance,
  changeWorkersInsurance,
  updateWorkersInsurance,
  updateChangeWorkersInsurance,
} from "../../../../services/vendorProfile.services";
import DrawerWizz from "../../../../reusable/DrawerWizz";

const InsuranceModal = ({
  setVisible,
  title,
  value,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  //Disable Date greater than today
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (value && editTable && editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, [editTable, value]);

  function setFormFieldValues(data) {
    form.setFieldsValue({
      ...data,
      start_of_coverage: moment(data.start_of_coverage),
      end_of_coverage: moment(data.end_of_coverage),
    });
  }

  const onFinish = (values) => {
    const createFormValues = {
      ...values,
      vendor_id: vendorId,
      start_of_coverage: values["start_of_coverage"].format("YYYY-MM-DD"),
      end_of_coverage: values["end_of_coverage"].format("YYYY-MM-DD"),
    };

    const updateFormValues = {
      ...values,
      vendor_id: vendorId,
      id: editTable.id,
      start_of_coverage: values["start_of_coverage"].format("YYYY-MM-DD"),
      end_of_coverage: values["end_of_coverage"].format("YYYY-MM-DD"),
    };

    //create
    if (value === "past_insurance_carriers" && editTable.status === "create") {
      workersInsurance(createFormValues)
        .then((res) => {
          setVisible(false);
          message.success(res.data.message);
          refresh();
        })
        .catch((error) => message.error(error.response.data.message));
    }
    if (value === "compensation_insurance" && editTable.status === "create") {
      changeWorkersInsurance(createFormValues)
        .then((res) => {
          setVisible(false);
          message.success(res.data.message);
          refresh();
        })
        .catch((error) => message.error(error.response.data.message));
    }

    //update
    if (value === "past_insurance_carriers" && editTable.status === "update") {
      updateWorkersInsurance(updateFormValues)
        .then((res) => {
          setVisible(false);
          message.success(res.data.message);
          refresh();
        })
        .catch((error) => message.error(error.response.data.message));
    }
    if (value === "compensation_insurance" && editTable.status === "update") {
      updateChangeWorkersInsurance(updateFormValues)
        .then((res) => {
          setVisible(false);
          message.success(res.data.message);
          refresh();
        })
        .catch((error) => message.error(error.response.data.message));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="line_of_insurance"
              label="Line of Insurance"
              rules={[
                {
                  required: true,
                  message: "Please input your line of insurance!",
                },
              ]}
            >
              <Input placeholder="Line of Insurance" />
            </Form.Item>
            <Form.Item
              name="carrier"
              label="Carrier"
              rules={[
                {
                  required: true,
                  message: "Please input your carrier!",
                },
              ]}
            >
              <Input placeholder="Carrier" />
            </Form.Item>
            <Form.Item
              name="start_of_coverage"
              label="Start of Coverage"
              rules={[
                {
                  required: true,
                  message: "Please select start of coverage!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                disabledDate={disabledDate}
                placeholder="Start Date"
                size="large"
                style={{ minWidth: "280px" }}
              />
            </Form.Item>
            <Form.Item
              name="end_of_coverage"
              label="End of Coverage"
              rules={[
                {
                  required: true,
                  validator: async (_, end_of_coverage) => {
                    if (
                      moment(end_of_coverage).isBefore(
                        form.getFieldValue("start_of_coverage")
                      )
                    ) {
                      return Promise.reject(
                        new Error(
                          `"End of coverage" cannot be before "Start of Coverage"`
                        )
                      );
                    } else if (!end_of_coverage) {
                      return Promise.reject(
                        new Error(`Please select end of coverage!`)
                      );
                    }
                  },
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder="End Date"
                size="large"
                style={{ minWidth: "280px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default InsuranceModal;
