import React from "react";
import { Collapse } from "antd";
import OSHAViolations from "./OSHAViolations/OSHAViolations";

const { Panel } = Collapse;

const Safety = () => {
  return <OSHAViolations />;
};

export default Safety;
