import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  List,
  Row,
  Col,
  Button,
  Empty,
  Badge,
  Avatar,
  Typography,
  Modal,
  Skeleton,
  Tag,
  message,
} from "antd";
import CreateApplicationModal from "./CreateApplicationModal";
import { useHistory } from "react-router";
import CardWizz from "../../../blocks/Card/Card";
import moment from "moment";
import { applicationStatusValues } from "../../../constants/defaultKeys";
import {
  getPaymentURL,
  payOfflinePayment,
} from "../../../services/payment.services";
const { Text } = Typography;

const VendorApplicationsList = ({
  listData,
  vendorId,
  loading,
  vendorDetails,
}) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [isCertficationModal, setIsCertificationModal] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [areAllPQQPaid, setAllPQQPaid] = useState(true);
  useEffect(() => {
    if (vendorDetails && listData) {
      if (listData.length === 0) {
        setIsModalVisible(true);
      } else if (vendorDetails?.profile_completion_status != 100) {
        setIsProfileModal(true);
      } else if (!vendorDetails?.is_subscribed) {
        setIsPaymentModal(true);
      } else if (!vendorDetails?.has_submitted_pqq) {
        setIsCertificationModal(true);
      }
      listData.applications?.forEach((application) => {
        if (!application.is_paid) {
          setAllPQQPaid(false);
          return false;
        }
        return true;
      });
    }
  }, [vendorId, listData, vendorDetails]);

  function handlePayment() {
    let applicationId;
    for (let i = 0; i < listData.length; i++) {
      if (Number(listData[i].ven_id) === vendorId) {
        applicationId = listData[i].application_id;
        break;
      }
    }
    getPaymentURL({
      vendor_id: vendorId,
      pqq: applicationId,
    })
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("get payment URL error");
        }
      });
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const openPQQForm = () => {
    setVisible(true);
  };
  const handleProfileOk = () => {
    setIsProfileModal(false);
    history.push(`/vendor/${vendorDetails?.id}/vendor-profile/basic-details`);
  };
  const handlePaymentOk = () => {
    setIsPaymentModal(false);
    history.push(`/vendor/${vendorDetails?.id}/get-certified`);
  };
  const handleCertOk = () => {
    setIsCertificationModal(false);
    history.push(`/vendor/${vendorDetails?.id}/PQQ`);
  };
  function badgeProps(status) {
    let props = applicationStatusValues.find(
      (eachItem) => status === eachItem.value
    );

    return {
      status: props?.status,
      color: props?.color,
      text: props?.label,
    };
  }

  function handleOfflinePayment() {
    let applicationId;
    for (let i = 0; i < listData.length; i++) {
      if (Number(listData[i].ven_id) === vendorId) {
        applicationId = listData[i].application_id;
        break;
      }
    }
    payOfflinePayment({
      vendor_id: vendorId,
      pay_offline: true,
      pqq: applicationId,
    })
      .then((response) => {
        message.success(response.data.message);
        // setIsModalVisible(false);
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Something went wrong, Please try after sometime");
        }
      });
  }
  return (
    <Fragment>
      <CardWizz
        className="applications-list-card"
        title="PQQ List"
        extra={
          <Button
            type="primary"
            onClick={openPQQForm}
            disabled={listData?.length === 0 && vendorDetails?.is_subscribed}
          >
            Create New PQQ
          </Button>
        }
      >
        {loading ? (
          <Skeleton active size="small" />
        ) : (
          <>
            {String(listData) && listData?.length !== 0 ? (
              <List
                className="applications-list"
                itemLayout="horizontal"
                dataSource={String(listData) && listData}
                renderItem={(item) => (
                  <div
                    className="pqqListItems"
                    onClick={() =>
                      history.push(
                        `/vendor/${item.ven_id}/PQQ?form=${item.application_id}`
                      )
                    }
                  >
                    <List.Item className="applications-listitem">
                      <Row
                        style={{ width: "100%" }}
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={[16, 16]}
                      >
                        <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                          <p>Project Name</p>
                          <Text>
                            {item.project_name ? item.project_name : "-"}
                          </Text>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                          <p>Customer Name</p>
                          <Text>
                            {item.customer_name ? item.customer_name : "-"}
                          </Text>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                          <p>Comments</p>
                          <Avatar
                            style={{
                              backgroundColor: "#234983",
                            }}
                            size="small"
                          >
                            {item.num_comments}
                          </Avatar>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                          <p>Status</p> <Badge {...badgeProps(item.status)} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                          <p>Last Updated</p>{" "}
                          <Text>
                            {moment(item?.last_updated).format("YYYY-MM-DD")}
                          </Text>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                          <p>Payment Status</p>{" "}
                          {item?.is_paid ? (
                            <Tag color="green">Paid</Tag>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                type="primary"
                                onClick={handleOfflinePayment}
                                ghost
                              >
                                Pay Offline
                              </Button>
                              <Button type="primary" onClick={handlePayment}>
                                Pay Online
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </List.Item>
                  </div>
                )}
              />
            ) : (
              <Empty
                description="No Applications Found"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </>
        )}
      </CardWizz>
      {visible && (
        <CreateApplicationModal
          setVisible={setVisible}
          vendorId={vendorId}
          vendorDetails={vendorDetails}
        />
      )}

      {isModalVisible && !areAllPQQPaid && (
        <Modal
          title="Create PQQ"
          centered
          onCancel={handleCancel}
          footer={[
            <Button type="primary" onClick={openPQQForm}>
              Create New PQQ
            </Button>,
          ]}
          visible={isModalVisible}
        >
          <h4>
            Please create and submit your Pre-qualification Questionnaire for
            review
          </h4>
        </Modal>
      )}
      {isProfileModal && (
        <Modal
          title="Complete your profile"
          // maskClosable={false}
          centered
          closable={false}
          onOk={handleProfileOk}
          onCancel={() => setIsProfileModal(false)}
          visible={isProfileModal}
        >
          <h4>Please Complete your Pre-qualification Questionnaire</h4>
        </Modal>
      )}
      {/* {isPaymentModal && (
        <Modal
          title="Complete your payment"
          centered
          closable={false}
          onCancel={() => setIsPaymentModal(false)}
          onOk={handlePaymentOk}
          visible={isPaymentModal}
        >
          <h4>Please complete your payment</h4>
        </Modal>
      )} */}
      {isCertficationModal && (
        <Modal
          title="Certify your PQQ"
          centered
          onCancel={() => setIsCertificationModal(false)}
          // onOk={handleCertOk}
          footer={[
            <Button type="primary" onClick={handleCertOk}>
              Certify
            </Button>,
          ]}
          visible={isCertficationModal}
        >
          <h4>Please certify your Pre-qualification Questionnaire</h4>
        </Modal>
      )}
    </Fragment>
  );
};

export default VendorApplicationsList;
