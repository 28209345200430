import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import { useHistory, useParams } from "react-router";
import { SelectedVendorDetailsContext } from "../../../context/SelectedVendorContext";
import { getVendorDetails } from "../../../services/vendorProfile.services";
import Organization from "../../Organization/Organization";
import { ArrowLeftOutlined } from "@ant-design/icons";

const AdminViewVendorProfile = () => {
  const queryParams = useParams();
  const vendorId = queryParams?.vendor_id;
  const history = useHistory();
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getVendorDetails(vendorId)
      .then((res) => {
        setVendorDetails({ ...res.data.data });
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("error getting vendor details");
      });
  }, []);

  return isLoading ? (
    <Spin />
  ) : (
    <Fragment>
      <Row>
        <Col span={24}>
          <span
            onClick={() => history.goBack()}
            className="privacy-policy-back"
          >
            <ArrowLeftOutlined /> Back
          </span>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Organization />
    </Fragment>
  );
};

export default AdminViewVendorProfile;
