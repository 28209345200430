import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Col,
  message,
  Progress,
  Row,
  Table,
  Skeleton,
  Button,
  Input,
  Space,
  Tag,
  Typography,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  getVendorsListAdmin,
  downloadVendorsAdminListData,
} from "../../../services/applications.services";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { normalizedFind } from "../../../helpers/utility";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadDataFiles } from "../../../helpers/utility";
import {
  adminApplicationPriorityOptions,
  applicaitonTableStatus,
  applicationStatusValues,
  vendorTableInfo,
} from "../../../constants/defaultKeys";
import moment from "moment";

import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
import "./AdminVendors.css";
import TimeStamp from "../../../blocks/TimeStamp";

const { Text } = Typography;

const AdminVendors = () => {
  let searchInput = useRef(null);
  const history = useHistory();

  const [isLoading, setisLoading] = useState(true);
  const [vendorsList, setvendorsList] = useState([]);
  const [filterValues, setFilterValues] = useState({
    vendorNameFilter: [],
    latesPQQFilter: [],
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isVendorListDownloading, setVendorListDownloading] = useState(false);
  const userDetails = JSON.parse(getItem(USER));
  function handleOnClick(record) {
    if (record.application_id != "") {
      history.push(
        `/admin/pqq/${record.application_id}/vendor/${record.id}/view-profile/basic-details`
      );
    } else {
      // message.error("No Application Found");
      history.push(`/vendor/${record.id}/vendor-profile/basic-details`);
    }

    history.push(
      userDetails?.is_admin &&
        record.bypass_approve &&
        `/admin/pqq/${record.application_id || undefined}/vendor/${
          record.id
        }/view-profile/basic-details`
    );
  }

  const getColumnSearchProps = (dataIndex, placeHolderText) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${placeHolderText}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);

              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          fontSize: "20px",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "name",
      key: "name",
      width: 180,
      fixed: "left",
      filters: filterValues.vendorNameFilter,
      ...getColumnSearchProps("name", "Vendor Name"),
      render: (_, record) => (
        <a
          onClick={() => handleOnClick(record)}
          className={record.application_id === null ? "disabled-link " : null}
          style={
            record.application_id === null ? { pointerEvents: "none" } : {}
          }
        >
          {record.name}
        </a>
      ),
    },
    {
      title: "Latest PQQ",
      dataIndex: "application_id",
      key: "application_id",
      width: 180,
      // filterSearch: true,
      // filters: filterValues.latesPQQFilter,
      // onFilter: (value, record) => record.application_id.indexOf(value) === 0,
      ...getColumnSearchProps("application_id", "Latest PQQ"),
      render: (_, record) =>
        record.application_id && record.application_id !== "" ? (
          <Link
            to={`/admin/pqq?form=${record.application_id}`}
            // to={
            //   userDetails?.is_admin && record.bypass_approve
            //     ? `/admin/pqq?form=${record.application_id}`
            //     : "#"
            // }
            // className={
            //   userDetails?.is_admin && !record.bypass_approve
            //     ? "disabled-link "
            //     : null
            // }
          >
            {record.application_id}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Customers",
      dataIndex: "customers_list",
      key: "customers_list",
      width: 180,
      render: (value) => value?.join(", "),
    },
    {
      title: "Status",
      dataIndex: "application_status",
      key: "application_status",
      width: 180,
      filters: [
        {
          text: "Approved",
          value: "Approved",
        },
        {
          text: "Final Review",
          value: "FinalReview",
        },
        {
          text: "Completed",
          value: "Completed",
        },
        {
          text: "Information Required",
          value: "InformationRequired",
        },
        {
          text: "Submitted",
          value: "Submitted",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Rejected",
          value: "Rejected",
        },
        {
          text: "Recertify",
          value: "Recertify",
        },
      ],
      onFilter: (value, record) => record.status == value,
      render: (_, record) =>
        record?.status ? (
          <Tag
            color={
              applicaitonTableStatus.find(
                (item) => item?.value === record?.status && item?.color
              )?.color
            }
          >
            {record?.status}
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      title: (
        <>
          <span>Priority</span>
          {vendorTableInfo()}
        </>
      ),
      dataIndex: "priority",
      key: "application_priority",
      width: 180,
      render: (_, record) =>
        record?.application_priority ? (
          <Tag
            color={
              adminApplicationPriorityOptions.find(
                (eachStatus) =>
                  eachStatus?.value === record?.application_priority &&
                  eachStatus?.color
              )?.color
            }
          >
            {record?.application_priority}
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "Recertify",
      dataIndex: "recertify",
      key: "recertify",
      width: 150,
      sorter: (a, b) => a.recertify - b.recertify,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: (
        <>
          <span>Is Submitted </span>
          {vendorTableInfo()}
        </>
      ),
      dataIndex: "submitted",
      key: "submitted",
      width: 180,
      sorter: (a, b) => a.submitted - b.submitted,
      render: (_, record) =>
        record.submitted ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: (
        <>
          <span>Is Completed</span>
          {vendorTableInfo()}
        </>
      ),
      dataIndex: "completed",
      key: "completed",
      width: 180,
      sorter: (a, b) => a.completed - b.completed,
      render: (_, record) =>
        record?.completed ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: "Sent to Bill",
      dataIndex: "sent_to_bill",
      key: "sent_to_bill",
      width: 180,
      sorter: (a, b) => a.sent_to_bill - b.sent_to_bill,
      render: (_, record) =>
        record.sent_to_bill ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: "Payment Recieved",
      dataIndex: "is_subscribed",
      key: "is_subscribed",
      width: 180,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
      sorter: (a, b) => a.is_subscribed - b.is_subscribed,
    },
    {
      title: (
        <>
          <span>Contract Value</span>
          {vendorTableInfo()}
        </>
      ),
      dataIndex: "contract_value",
      key: "contract_value",
      width: 180,
      sorter: (a, b) => a.contract_value - b.contract_value,
      render: (_, record) =>
        record.contract_value ? record.contract_value : "-",
    },
    {
      title: (
        <>
          <span>Note</span>
          {vendorTableInfo()}
        </>
      ),
      dataIndex: "note",
      key: "note",
      width: 180,
      render: (_, record) =>
        record?.note ? <Text ellipsis={true}>{record?.note}</Text> : "-",
    },
    {
      title: "Projects",
      dataIndex: "project_names",
      key: "project_names",
      width: 180,
      render: (value) => (value !== "" ? value : "-"),
    },
    {
      title: (
        <>
          <span>Bid No</span>
          {vendorTableInfo()}
        </>
      ),
      dataIndex: "bid_no",
      key: "bid_no",
      width: 180,
      render: (value) => (value && value !== "" ? value : "-"),
      sorter: (a, b) => a.bid_no.length - b.bid_no.length,
    },
    {
      title: "Profile Completion%",
      dataIndex: "profile_percent",
      key: "profile_percent",
      width: 180,
      render: (value) => (
        <Progress
          percent={value}
          size="small"
          status={value < 100 && "active"}
        />
      ),
      sorter: (a, b) => a.profile_percent - b.profile_percent,
    },
    {
      title: "MWBE Certified",
      dataIndex: "mwbe_certified",
      key: "mwbe_certified",
      width: 180,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
      sorter: (a, b) => a.mwbe_certified - b.mwbe_certified,
    },
    {
      title: "Approval Date",
      dataIndex: "last_updated",
      key: "last_updated",
      width: 150,
      sorter: (a, b) =>
        moment(a.last_updated).unix() - moment(b.last_updated).unix(),
      render: (value, record) =>
        value && record?.status === "Approved"
          ? moment(value).format("MMM Do YYYY")
          : "-",
    },
  ];

  useEffect(() => {
    getVendorsListAdmin()
      .then((res) => {
        setvendorsList(res.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error updating application status");
        }
      });
  }, []);

  useEffect(() => {
    let filtersArray = filterValues;
    vendorsList.map((app) => {
      if (
        app.name &&
        !normalizedFind(filtersArray, "vendorNameFilter", app.name)
      ) {
        filtersArray.vendorNameFilter.push({
          text: app.name,
          value: app.name,
        });
      }
      if (
        app.application_id &&
        !normalizedFind(filtersArray, "latesPQQFilter", app.application_id)
      ) {
        filtersArray?.latesPQQFilter?.push({
          text: app.application_id,
          value: app.application_id,
        });
      }
    });
    setFilterValues(filtersArray);
  }, [vendorsList]);

  function downloadData() {
    setVendorListDownloading(true);
    downloadDataFiles({
      api: downloadVendorsAdminListData,
      fileName: "vendor-list",
      fileType: "xlsx",
    })
      .then(() => {
        setVendorListDownloading(false);
      })
      .catch((err) => {
        console.log(err);
        setVendorListDownloading(false);
        message.error("Download vendor-list failed,please try later");
      });
  }
  return (
    <Fragment>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[0, 16]}>
          <Col span={24} style={{ display: "flex", flexDirection: "column" }}>
            {!userDetails?.is_client_admin && (
              <Button
                title="Export in excel"
                type="primary"
                size="large"
                onClick={downloadData}
                loading={isVendorListDownloading}
                icon={<DownloadOutlined />}
                style={{ marginBottom: "10px", alignSelf: "flex-end" }}
              ></Button>
            )}
            <Table
              showSorterTooltip={false}
              className="admin-applications-list"
              columns={columns}
              dataSource={vendorsList}
              scroll={{ x: 800 }}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default AdminVendors;
