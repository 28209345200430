import React from "react";
import { Button, message, Modal, Typography } from "antd";
import { payOfflinePayment } from "../../../../services/payment.services";

const { Title } = Typography;

const OfflinePayment = ({ vendorId, isModalVisible, setIsModalVisible }) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const payOffline = () => {
    payOfflinePayment({ vendor_id: vendorId, pay_offline: true })
      .then((response) => {
        message.success(response.data.message);
        setIsModalVisible(false);
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Something went wrong, Please try after sometime");
        }
      });
  };

  return (
    <Modal
      title="Offline Payment Instructions"
      visible={isModalVisible}
      footer={false}
      onCancel={handleCancel}
    >
      <Title level={5}>Available options for offline payment method</Title>
      <ul>
        <li>Bank transfer</li>
        <li>Cash</li>
        <li>Check</li>
      </ul>
      <Button type="primary" onClick={payOffline}>
        Request Callback
      </Button>
    </Modal>
  );
};

export default OfflinePayment;
