import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, message, InputNumber } from "antd";
import {
  createTerminatedContracts,
  updateTerminatedContracts,
} from "../../../../../services/vendorProfile.services";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const PerformanceHistoryModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, [editTable]);

  function setFormFieldValues(data) {
    form.setFieldsValue({ ...data });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
      };

      updateTerminatedContracts(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
      };

      createTerminatedContracts(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="entity_with_whom_contracted"
              label="Entity with whom Contracted"
              rules={[
                {
                  required: true,
                  message: "Please input your entity with whom contracted!",
                },
              ]}
            >
              <Input placeholder="Entity with whom contracted" />
            </Form.Item>
            <Form.Item
              name="amount_of_contract"
              label="Amount of Contract"
              rules={[
                {
                  required: true,
                  message: "Please input your  amount of contract !",
                },
              ]}
            >
              <InputNumber
                min={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ minWidth: "470px" }}
                placeholder="Amount Of Contract"
              />
            </Form.Item>
            <Form.Item
              name="scope_of_contract"
              label="Scope Of Contract"
              rules={[
                {
                  required: true,
                  message: "Please input your  scope of contract!",
                },
              ]}
            >
              <Input placeholder="Scope Of Contract" />
            </Form.Item>
            <Form.Item
              name="reason_for_termination"
              label="Reason For Termination"
              rules={[
                {
                  required: true,
                  message: "Please input your  reason for termination!",
                },
              ]}
            >
              <Input placeholder="Reason For Termination" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default PerformanceHistoryModal;
