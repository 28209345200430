import React, { useState } from "react";
import { Table, Row, Col, Button, Space, message } from "antd";
import CivilCasesModal from "./CivilCasesModal";
import { removeCivil } from "../../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import { CivilLitigationColumns } from "../../../../PQQ/tableColumns";

const CivilCases = (props) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  const openModal = (action) => {
    setVisible(true);
    setEditTable(action);
  };

  const refreshPage = () => {
    props.fetchCivilCases(props.vendorId);
  };

  const removeColumn = (id) => {
    removeCivil({
      id: id,
      vendor_id: props.vendorId,
    })
      .then((response) => {
        refreshPage();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    ...CivilLitigationColumns,
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, form_status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col span={22} offset={1}>
        <Button
          onClick={openModal}
          type="secondary"
          style={{
            marginBottom: 16,
          }}
        >
          Add Litigation
        </Button>
      </Col>
      <Col span={22} offset={1}>
        <Table
          size="small"
          columns={columns}
          dataSource={props.data}
          pagination={false}
          scroll={{ x: 800 }}
        />
      </Col>
      {visible && (
        <CivilCasesModal
          setVisible={setVisible}
          title="Add Civil Litigation "
          vendorId={props.vendorId}
          tableData={props.data}
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Row>
  );
};

export default CivilCases;
