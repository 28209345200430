import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import {
  createIntegrityMonitor,
  updateIntegrityMonitor,
} from "../../../../../services/vendorProfile.services";
import moment from "moment";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const IntegrityMonitorsModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  //Disable Date greater than today
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, [editTable]);

  function setFormFieldValues(data) {
    form.setFieldsValue({
      ...data,
      date_engaged: moment(data.date_engaged),
    });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
        date_engaged: moment(values.date_engaged).format("YYYY-MM-DD"),
      };

      updateIntegrityMonitor(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
        date_engaged: moment(values.date_engaged).format("YYYY-MM-DD"),
      };

      createIntegrityMonitor(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="name_of_monitor"
              label="Name Of Monitor"
              rules={[
                {
                  required: true,
                  message: "Please input your name of monitor!",
                },
              ]}
            >
              <Input placeholder="Name Of Monitor" />
            </Form.Item>
            <Form.Item
              name="reason_for_engagement"
              label="Reason For Engagement"
              rules={[
                {
                  required: true,
                  message: "Please input your  reason for engagement!",
                },
              ]}
            >
              <Input placeholder="Reason For Engagement" />
            </Form.Item>
            <Form.Item
              name="key_contact"
              label="Key Contact"
              rules={[
                {
                  required: true,
                  message: "Please input your  key contact!",
                },
              ]}
            >
              <Input placeholder="Key Contact" />
            </Form.Item>
            <Form.Item
              name="date_engaged"
              label="Date Engaged"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                disabledDate={disabledDate}
                placeholder="Date"
                size="large"
                style={{ minWidth: "280px" }}
              />
            </Form.Item>
            <Form.Item
              name="status_disposition"
              label="Status Disposition"
              rules={[
                {
                  required: true,
                  message: "Please input your  status disposition!",
                },
              ]}
            >
              <Input placeholder="Status Disposition" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default IntegrityMonitorsModal;
