import React, { Fragment, useState, useEffect } from "react";
import { Row, Col } from "antd";
import UserAvatar from "../../../blocks/UserAvatar";
import AdminNavigationItems from "./AdminNavigationItems/AdminNavigationItems";
import { useQuery } from "../../../helpers/utility";
import Logo from "../../../Logo/Logo";
import MobileLogo from "../../../Logo/MobileLogo";
import { useHistory } from "react-router-dom";
import "./AdminToolbar.css";
import { isDesktop } from "react-device-detect";

const AdminToolbar = () => {
  const history = useHistory();
  let query = useQuery();
  const [navUrl, setnavUrl] = useState("admin/dashboard");

  useEffect(() => {
    const url = window.location.pathname;
    setnavUrl(url);
  }, [query]);

  return (
    <Fragment>
      <Row style={{ width: "100%" }} className="menu">
        <Col sm={8} md={6} lg={5}>
          <div
            className="admin-menu-logo"
            onClick={() => history.push("/admin/dashboard")}
          >
            {isDesktop ? <Logo /> : <MobileLogo />}
          </div>
        </Col>
        <Col sm={0} md={12} lg={18}>
          <AdminNavigationItems navUrl={navUrl} />
        </Col>
        <Col sm={6} md={2} lg={1} xl={1} className="admin-menu-dropdown">
          <UserAvatar />
        </Col>
      </Row>
    </Fragment>
  );
};
export default AdminToolbar;
