import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import {
  createContractorReference,
  updateContractorReference,
} from "../../../../../services/vendorProfile.services";
import {
  emailValidation,
  mobileNumberRegex,
} from "../../../../../config/validation.config";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const ContractorReferencesModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, [editTable]);

  function setFormFieldValues(data) {
    form.setFieldsValue({ ...data });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
      };

      updateContractorReference(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
      };

      createContractorReference(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input name!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="company"
              label="Company"
              rules={[
                {
                  required: true,
                  message: "Please input company!",
                },
              ]}
            >
              <Input placeholder="Company" />
            </Form.Item>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
            <Form.Item
              name="project"
              label="Project"
              rules={[
                {
                  required: true,
                  message: "Please input project!",
                },
              ]}
            >
              <Input placeholder="Project" />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please input address!",
                },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={emailValidation}>
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item name="phone" label="Phone" rules={mobileNumberRegex}>
              <Input placeholder="Phone" addonBefore="+1" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default ContractorReferencesModal;
