import { Fragment, useState } from "react";
import { Form, Input, Button, Row, Col, message, Typography } from "antd";
import { Link } from "react-router-dom";
import { resetPassword } from "../../services/auth.services";
import Logo from "../../assets/images/logoBlue.png";
import CardWizz from "../../blocks/Card/Card";

const { Paragraph } = Typography;

const ForgotPassword = () => {
  const [forgotMessageCard, setForgotMessageCard] = useState(false);
  const [username, setUsername] = useState(null);

  const onFinish = (values) => {
    setUsername(values.username);
    resetPassword(values)
      .then(() => setForgotMessageCard(true))
      .catch((error) => {
        message.error(error.response.data.message);
        console.error(error);
      });
  };

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <CardWizz bordered={false}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              <img src={Logo} alt="check logo" height="52px" />
            </div>
            {forgotMessageCard ? (
              <>
                <Paragraph>
                  We've sent a reset password email to {username && username}.
                  Please click the reset password link in the email to set your
                  new password.
                </Paragraph>
                <Paragraph>Didn't receive the email yet?</Paragraph>
                <Paragraph>
                  Please check your spam folder, or{" "}
                  <span
                    onClick={() => setForgotMessageCard(false)}
                    className="footer-links"
                  >
                    try again
                  </span>
                </Paragraph>
              </>
            ) : (
              <>
                <Form name="login" onFinish={onFinish} scrollToFirstError>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                    help="We'll email you the link to reset your password."
                  >
                    <Input
                      autoFocus={true}
                      size="large"
                      type="email"
                      placeholder="Email"
                    />
                  </Form.Item>

                  <Form.Item style={{ float: "right" }}></Form.Item>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      className="login-form-button"
                    >
                      Request reset link
                    </Button>
                  </Form.Item>
                </Form>
                <div style={{ textAlign: "center" }}>
                  <Link to="/" className="footer-links">
                    Back to login
                  </Link>
                </div>
              </>
            )}
          </CardWizz>
        </Col>
      </Row>
    </Fragment>
  );
};
export default ForgotPassword;
