import React, { useState } from "react";
import { Table, Row, Col, Typography, Button, Space, message } from "antd";
import PastProjectsModal from "./PastProjectsModal";
import { removePastProjects } from "../../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import { PastProjectsColumns } from "../../../../PQQ/tableColumns";

const { Paragraph } = Typography;

const PastProjects = (props) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  const refreshPage = () => {
    props.fetchPastProjects();
  };

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  const removeColumn = (id) => {
    removePastProjects({
      id: id,
      vendor_id: props.vendorId,
    })
      .then((response) => {
        refreshPage();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    ...PastProjectsColumns,
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col span={22} offset={1}>
        <Paragraph className="mandatory-field-mark">
          List major construction projects the applicant firm is currently
          performing or has completed within the past five years which are
          similar to the project(s) to be performed by the applicant firm.
        </Paragraph>
        <Button
          onClick={openModal}
          type="secondary"
          style={{
            marginBottom: 16,
          }}
        >
          Add Project
        </Button>
      </Col>
      <Col span={22} offset={1}>
        <Table
          size="small"
          columns={columns}
          dataSource={props.pastProjectsData}
          pagination={false}
          scroll={{ x: 800 }}
        />
      </Col>
      {visible && (
        <PastProjectsModal
          setVisible={setVisible}
          title="Add Past Projects"
          vendorId={props.vendorId}
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Row>
  );
};

export default PastProjects;
