import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  Divider,
  Button,
  message,
  Collapse,
  Row,
  Col,
  Typography,
  InputNumber,
} from "antd";
import {
  mobileNumberRegex,
  nameValidation,
  numberValidation,
} from "../../../../config/validation.config";
import {
  getBankDetails,
  createBankDetails,
  getLineOfCredit,
  getSuretyContact,
  getBankLiens,
} from "../../../../services/vendorProfile.services";
import {
  narrowLabelLayout,
  tailLayout,
} from "../../../../config/formLayout.config";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import LineOfCredits from "./LineOfCredits";
import SuretyContacts from "./SuretyContacts";
import BankLiens from "./BankLiens";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import { radioYesOrNo } from "../../../../config/radioGroupConstants";
import RadioGroupWizz from "../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import { profileCompletion } from "../../../../constants/defaultKeys";

const { Panel } = Collapse;
const { Paragraph } = Typography;

const validateMessages = {
  required: "'${label}' is required!",
};

const BankDetails = (props) => {
  const vendorId = props.vendorId;

  const [form] = Form.useForm();
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );
  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );

  const [disabled, setDisabled] = useState(false);
  const [lineOfCreditData, setLineOfCreditData] = useState([]);
  const [suretyContactData, setSuretyContactData] = useState([]);
  const [bankLiensData, setBankLiensData] = useState([]);

  const initialFormValues = {
    surety_bonds_available: false,
    liens_or_judgment_filed: false,
  };

  useEffect(() => {
    // Setting form initial values on first render
    if (vendorId) {
      getBankDetailsFunc();
      fetchLineOfCredit();
      fetchSuretyContact();
      fetchBankLiens();
    }
  }, [vendorId]);

  function getBankDetailsFunc() {
    getBankDetails(vendorId)
      .then((res) => {
        if (Object.keys(res.data.data).length === 0) {
          setFormFieldValues({
            ...initialFormValues,
            ...vendorProfileFormData.bank_details,
          });
        } else {
          let formValues = {
            ...res.data.data,
            ...vendorProfileFormData.bank_details,
          };
          setFormFieldValues(formValues);
        }
      })
      .catch((error) => {
        console.error("get bank details error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting bank details");
        }
      });
  }

  function fetchLineOfCredit() {
    getLineOfCredit(vendorId)
      .then((res) => {
        setLineOfCreditData(res.data.data);
      })
      .catch((error) => {
        console.error("Error getting line of credits", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting line of credits");
        }
      });
  }

  function fetchSuretyContact() {
    getSuretyContact(vendorId)
      .then((res) => {
        setSuretyContactData(res.data.data);
      })
      .catch((error) => {
        console.error("Error getting surety contacts", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting surety contacts");
        }
      });
  }

  function fetchBankLiens() {
    getBankLiens(vendorId)
      .then((res) => {
        setBankLiensData(res.data.data);
      })
      .catch((error) => {
        console.error("Error getting bank liens", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting bank liens");
        }
      });
  }
  function setFormFieldValues(data) {
    setVendorProfileDataBankDetails(data);
    form.setFieldsValue(data);
  }

  function setVendorProfileDataBankDetails(changedValues) {
    vendorProfileFormData.bank_details = {
      ...vendorProfileFormData.bank_details,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function onFormValuesChange(changedValues) {
    let formValues = form.getFieldsValue();
    setVendorProfileDataBankDetails(formValues);
  }

  async function onFinish(values) {
    if (lineOfCreditData.length === 0) {
      message.error("Please add atleast one Line of Credit");
      return;
    } else if (
      values.surety_bonds_available &&
      suretyContactData.length === 0
    ) {
      message.error("Please add atleast one Individual");
      return;
    } else if (values.liens_or_judgment_filed && bankLiensData.length === 0) {
      message.error("Please add atleast one Lien or Judgment");
      return;
    } else {
      setDisabled(true);
      saveBankDetails({ ...values, vendor_id: vendorId });
    }
  }

  function saveBankDetails(requestBody) {
    createBankDetails(requestBody)
      .then((res) => {
        setDisabled(false);
        // on saving details successfully, changing the step status to true using useContext API
        if (!vendorDetails.bank_status) {
          setVendorDetails({
            ...vendorDetails,
            bank_status: true,
            profile_completion_status:
              vendorDetails.profile_completion_status +
              profileCompletion.bank_status,
          });
        }
        message.success(res.data.message);
        // navigating user to next step on save
        props.onStepChange(props.currentStep + 1);
      })
      .catch((error) => {
        setDisabled(false);
        console.error("error creating bank details", error);
        if (error && error.response) {
          if (error.response.status === 400) {
            message.error(
              "Something went wrong with the form being saved. Please contact administrator"
            );
          } else {
            message.error(
              error.response.data.message || error.response.message
            );
          }
        } else {
          message.error("Error creating bank details");
        }
      });
  }

  return (
    <Form
      name="bank_details"
      form={form}
      {...narrowLabelLayout}
      validateMessages={validateMessages}
      onValuesChange={onFormValuesChange}
      onFinish={onFinish}
      colon={false}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1", "2", "3"]}>
        <Panel header="Bank Info" key="1">
          <Form.Item
            name="applicant_bank"
            label="Applicant Firm's Bank"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Applicant Firm's Bank" />
          </Form.Item>
          <Form.Item
            name="reference_name"
            label="Reference Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Reference Name" />
          </Form.Item>
          <Form.Item
            name="reference_phone"
            label="Reference Phone"
            rules={mobileNumberRegex}
          >
            <Input placeholder="10-digits without spaces" addonBefore="+1" />
          </Form.Item>
          <Divider />
          <LineOfCredits
            vendorId={vendorId}
            lineOfCreditData={lineOfCreditData}
            fetchLineOfCredit={fetchLineOfCredit}
          />
        </Panel>
        <Panel header="Surety Info" key="3">
          <Form.Item
            name="surety_name"
            label="Surety Name"
            // rules={nameValidation}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="broker_name"
            label="Agent/Broker Name"
            // rules={nameValidation}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="broker_phone"
            label="Agent/Broker Phone No"
            rules={mobileNumberRegex}
          >
            <Input placeholder="10-digits without spaces" addonBefore="+1" />
          </Form.Item>
          <Form.Item
            label="Bonding Capacity"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <Paragraph className="header-label mandatory-field-mark">
                  Single Job
                </Paragraph>
                <Form.Item
                  name="bonding_capacity_single_job"
                  rules={numberValidation}
                >
                  <InputNumber
                    min={0}
                    placeholder=""
                    style={{ minWidth: "100px" }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Paragraph className="header-label mandatory-field-mark">
                  Aggregate
                </Paragraph>
                <Form.Item
                  name="bonding_capacity_aggregate_job"
                  rules={numberValidation}
                >
                  <InputNumber
                    min={0}
                    placeholder=""
                    style={{ minWidth: "100px" }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Divider />
          <Form.Item
            labelCol={{ span: 12, offset: 2 }}
            wrapperCol={{ span: 10 }}
            name="surety_bonds_available"
            className="multiline-form-item"
            label="Has any entity or individual upon whose indemnity the surety
              relies in writing bonds for the applicant firm?"
            rules={[
              {
                required: false,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          {vendorProfileFormData.bank_details.surety_bonds_available && (
            <SuretyContacts
              vendorId={vendorId}
              suretyContactData={suretyContactData}
              fetchSuretyContact={fetchSuretyContact}
            />
          )}
        </Panel>
        <Panel header="Liens/Judgments" key="3">
          <Form.Item
            labelCol={{ span: 12, offset: 2 }}
            wrapperCol={{ span: 10 }}
            name="liens_or_judgment_filed"
            className="multiline-form-item"
            label="Are there any liens or judgment filed against the applicant firm within
              the past three years?"
            rules={[
              {
                required: false,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          {vendorProfileFormData.bank_details.liens_or_judgment_filed && (
            <BankLiens
              vendorId={vendorId}
              bankLiensData={bankLiensData}
              fetchBankLiens={fetchBankLiens}
            />
          )}
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BankDetails;
