import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Col,
  message,
  Progress,
  Row,
  Table,
  Tag,
  Button,
  Modal,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import AdminTableFilters from "./AdminTableFilters";
import { Link } from "react-router-dom";
import TimeStamp from "../../../blocks/TimeStamp";
import AdminCards from "./AdminCards";
import { confirmModal } from "../../../reusable/ModalWizz";
import {
  deletePQQApi,
  downloadPqqData,
} from "../../../services/applications.services";
import { adminUpdateApplicationById } from "../../../services/applications.services";
import {
  adminApplicationPriorityOptions,
  adminDashboardMetricsPermissions,
  USER,
} from "../../../constants/defaultKeys";
import {
  downloadDataFiles,
  getColumnSearchProps,
  isUserClientOrProjectAdmin,
  normalizedFind,
} from "../../../helpers/utility";
import { getItem } from "../../../helpers/localStorage";
import moment from "moment";

const AdminApplicationsList = ({
  listData,
  applicationStatusValues,
  fetchAdminApplicationList,
  setIsLoading,
  isLoading,
  setSelectedDates,
  selectedDates,
  refresh,
}) => {
  let searchInput = useRef(null);

  const userDetails = JSON.parse(getItem(USER));
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filterValues, setFilterValues] = useState({
    applicationIdFilters: [],
    vendorNameFilters: [],
    customerNameFilters: [],
    numCommentsFilters: [],
    porjectNameFilters: [],
    bindNoFilters: [],
    statusFilters: [],
  });
  const [isPqqDataDownloading, setPqqDataDownloading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState({
    isOpen: false,
    id: "",
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = () => {
    setSearchText("");
  };

  // AdminApplicationsList table columns
  const columns = [
    {
      title: "PQQ ID",
      dataIndex: "application_id",
      key: "application_id",
      width: 180,
      fixed: "left",
      ...getColumnSearchProps({
        dataIndex: "application_id",
        name: "Application ID",
        handleSearch,
        handleReset,
        searchInput,
        searchText,
        searchedColumn,
      }),
      render: (_, record) => (
        <Link to={`/admin/pqq?form=${record.application_id}`}>
          {record.application_id}
        </Link>
      ),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      width: 150,
      fixed: "left",
      ...getColumnSearchProps({
        dataIndex: "vendor_name",
        name: "Vendor Name",
        handleSearch,
        handleReset,
        searchInput,
        searchText,
        searchedColumn,
      }),
    },
    {
      title: "Profile Complete %",
      dataIndex: "profile_percent",
      key: "profile_percent",
      width: 180,
      fixed: "left",
      render: (value) => (
        <Progress
          percent={value}
          size="small"
          status={value < 100 && "active"}
        />
      ),
    },
    {
      title: "Payment Received?",
      dataIndex: "is_subscribed",
      key: "is_subscribed",
      width: 150,
      fixed: "left",
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 200,
      filters: filterValues.customerNameFilters,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.customer_name.indexOf(value) === 0,
      sorter: (a, b) => a.customer_name.length - b.customer_name.length,
    },
    {
      title: "Certified By",
      dataIndex: "created_by_user",
      key: "created_by_user",
      ellipsis: true,
      width: 200,
      render: (name, record) => (
        <span>{record.created_by_user ? name : "-"}</span>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: 150,
      render: (value) => (
        <Tag
          title="Priority"
          color={
            adminApplicationPriorityOptions.find(
              (eachStatus) => eachStatus?.value === value && eachStatus?.color
            )?.color
          }
        >
          {
            adminApplicationPriorityOptions.find(
              (eachStatus) => eachStatus?.value === value
            )?.label
          }
        </Tag>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignee",
      key: "assignee",
      ellipsis: true,
      width: 200,
      render: (value) => <span>{value ? value : "-"}</span>,
    },
    {
      title: "Comments",
      dataIndex: "num_comments",
      key: "num_comments",
      width: 150,
      sorter: (a, b) => a.num_comments - b.num_comments,
      render: (value) => (
        <Avatar
          style={{
            backgroundColor: "#234983",
          }}
          size="small"
        >
          {value}
        </Avatar>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      width: 200,
      filters: filterValues.porjectNameFilters,
      onFilter: (value, record) => record.project_name.indexOf(value) === 0,
      sorter: (a, b) => a.project_name.length - b.project_name.length,
    },
    {
      title: "Bid No",
      dataIndex: "bid_no",
      key: "bid_no",
      width: 150,
      sorter: (a, b) => a.bid_no - b.bid_no,
      render: (value) => (value && value !== "" ? value : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 160,
      filters: filterValues.statusFilters,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => a.status.length - b.status.length,
      render: (value) =>
        applicationStatusValues.filter(
          (eachStatus) => eachStatus.value === value
        )[0]?.label,
    },
    {
      title: "MWBE Certified",
      dataIndex: "fradulent_minority_business",
      key: "fradulent_minority_business",
      width: 160,
      render: (value) =>
        value ? (
          <CheckCircleOutlined className="admin-applicaitons-list-check-circle" />
        ) : (
          <CloseCircleOutlined className="admin-applicaitons-list-close-circle" />
        ),
    },
    {
      title: "Last Updated",
      dataIndex: "last_updated",
      key: "last_updated",
      width: 150,
      // fixed: "right",
      sorter: (a, b) =>
        moment(a.last_updated).unix() - moment(b.last_updated).unix(),
      render: (value) => <TimeStamp timeStyle="round" value={value} />,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 150,
      render: (value, record) => (
        <DeleteOutlined
          style={{ color: "red" }}
          onClick={() => setIsModalVisible({ isOpen: true, id: record?.id })}
        />
      ),
    },
  ];

  async function deletePQQ() {
    try {
      const res = await deletePQQApi(isModalVisible?.id);
      if (res) {
        message.success(res?.data?.message);
        setIsModalVisible({ isOpen: !isModalVisible?.isOpen });

        refresh();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }
  useEffect(() => {
    createFilterData();
  }, [, filterValues]);

  function createFilterData() {
    let filtersArray = filterValues;

    listData?.data.map((eachApplication) => {
      if (
        !normalizedFind(
          filtersArray,
          "applicationIdFilters",
          eachApplication.application_id
        )
      ) {
        filtersArray.applicationIdFilters.push({
          text: eachApplication.application_id,
          value: eachApplication.application_id,
        });
      }
      if (
        !normalizedFind(
          filtersArray,
          "customerNameFilters",
          eachApplication.vendor_name
        )
      ) {
        filtersArray.customerNameFilters.push({
          text: eachApplication.vendor_name,
          value: eachApplication.vendor_name,
        });
      }
      if (
        !normalizedFind(
          filtersArray,
          "customerNameFilters",
          eachApplication.customer_name
        )
      ) {
        filtersArray.customerNameFilters.push({
          text: eachApplication.customer_name,
          value: eachApplication.customer_name,
        });
      }
      if (
        !normalizedFind(
          filtersArray,
          "numCommentsFilters",
          eachApplication.num_comments
        )
      ) {
        filtersArray.numCommentsFilters.push({
          text: eachApplication.num_comments,
          value: eachApplication.num_comments,
        });
      }
      if (
        !normalizedFind(
          filtersArray,
          "porjectNameFilters",
          eachApplication.project_name
        )
      ) {
        filtersArray.porjectNameFilters.push({
          text: eachApplication.project_name,
          value: eachApplication.project_name,
        });
      }
      if (
        eachApplication.bid_no &&
        eachApplication.bid_no !== "" &&
        !normalizedFind(filtersArray, "bindNoFilters", eachApplication.bid_no)
      ) {
        filtersArray.bindNoFilters.push({
          text: eachApplication.bid_no,
          value: eachApplication.bid_no,
        });
      }
      if (
        !normalizedFind(filtersArray, "statusFilters", eachApplication.status)
      ) {
        let status = applicationStatusValues.filter(
          (eachStatus) => eachStatus.value === eachApplication.status
        );
        filtersArray.statusFilters.push({
          text: status[0].label,
          value: status[0].value,
        });
      }
    });
    setFilterValues(filtersArray);
  }

  function handleMarkAsPriority(checked, data) {
    let title = `Are you sure to mark this PQQ as priority?`;
    if (!checked) {
      title = `Are you sure to remove this PQQ as priority?`;
    }
    confirmModal({
      title: title,
      onOk: () => updateApplicationStatus(checked, data),
    });
  }

  function updateApplicationStatus(checked, values) {
    let requestBody = {
      application_id: values.application_id,
      status: values.status,
      information_required: values.information_required,
      remarks: values.remarks,
      is_prioritized: checked,
    };
    adminUpdateApplicationById(requestBody)
      .then((res) => {
        message.success(res.data.message);
        fetchAdminApplicationList();
      })
      .catch((error) => {
        console.error(error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error updating application status");
        }
      });
  }

  function setTableColumns() {
    return isUserClientOrProjectAdmin()
      ? columns.filter(
          (eachColumn) =>
            !(
              eachColumn.dataIndex === "priority" ||
              eachColumn.dataIndex === "assignee"
            )
        )
      : columns;
  }
  function downloadData() {
    setPqqDataDownloading(true);
    downloadDataFiles({
      api: downloadPqqData,
      fileType: "xlsx",
      fileName: "pqq-data",
      apiParams: {
        ...selectedDates,
        status: applicationStatusValues
          .map((eachStatus) => eachStatus.value)
          .slice(0, 7),
        download_data: true,
      },
    })
      .then(() => {
        setPqqDataDownloading(false);
      })
      .catch((err) => {
        console.log(err);
        setPqqDataDownloading(false);
        message.error("Download pqq-list failed,please try later");
      });
  }

  return (
    <>
      <Row gutter={[0, 16]}>
        {!(
          userDetails?.is_client_admin || userDetails?.is_project_coordinator
        ) && (
          <Col span={24}>
            <AdminCards
              listData={listData}
              shouldHideCard={adminDashboardMetricsPermissions}
            />
          </Col>
        )}

        <Col span={23} style={{ textAlign: "right" }}>
          <AdminTableFilters
            setIsLoading={setIsLoading}
            applicationStatusValues={applicationStatusValues}
            fetchAdminApplicationList={fetchAdminApplicationList}
            setSelectedDates={setSelectedDates}
          />
        </Col>
        {!userDetails?.is_client_admin && (
          <Col span={1}>
            <Button
              title="Export in excel"
              type="primary"
              size="large"
              loading={isPqqDataDownloading}
              onClick={downloadData}
              icon={<DownloadOutlined />}
            ></Button>
          </Col>
        )}
        <Col span={24}>
          <Table
            loading={isLoading}
            className="admin-applications-list"
            columns={setTableColumns()}
            pagination={{
              pageSize: 10,
            }}
            dataSource={listData?.data}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {isModalVisible?.isOpen && (
        <Modal
          title="Delete PQQ"
          centered
          onOk={deletePQQ}
          onCancel={() =>
            setIsModalVisible({ isOpen: !isModalVisible?.isOpen })
          }
          visible={isModalVisible?.isOpen}
        >
          Are you sure you want to delete PQQ?
        </Modal>
      )}
    </>
  );
};

export default AdminApplicationsList;
