import React, { Fragment, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  message,
  InputNumber,
  Select,
} from "antd";
import {
  createBankLiens,
  updateBankLiens,
} from "../../../../services/vendorProfile.services";
import moment from "moment";
import { numberValidation } from "../../../../config/validation.config";
import DrawerWizz from "../../../../reusable/DrawerWizz";

const { Option } = Select;

const dateFormat = "YYYY-MM-DD";

const currentStatusOptions = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Satisfied",
    value: "satisfied",
  },
  {
    label: "Filed In Error",
    value: "filed_in_error",
  },
];

const BankLinesModal = ({
  vendorId,
  title,
  setVisible,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  //Disable Date greater than today
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  useEffect(() => {
    if (editTable && editTable.status === "update") {
      form.setFieldsValue({
        ...editTable,
        date_filed: moment(editTable.date_filed),
      });
    }
  }, [editTable]);

  function handleCancel() {
    setVisible(false);
  }

  function onFinish(values) {
    let requestBody = {
      ...values,
      vendor_id: vendorId,
      date_filed: moment(values.date_filed).format(dateFormat),
    };

    if (editTable && editTable.status === "create") {
      saveBankLiens(requestBody);
    }
    if (editTable && editTable.status === "update") {
      requestBody = { ...requestBody, id: editTable.id };
      updateBankLiensFunc(requestBody);
    }
  }

  function saveBankLiens(requestBody) {
    createBankLiens(requestBody)
      .then((res) => {
        message.success(res.data.message);
        setVisible(false);
        refresh();
      })
      .catch((error) => {
        console.error("create bank liens error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error creating bank liens");
        }
      });
  }

  function updateBankLiensFunc(requestBody) {
    updateBankLiens(requestBody)
      .then((res) => {
        message.success(res.data.message);
        setVisible(false);
        refresh();
      })
      .catch((error) => {
        console.error("update bank liens error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error updating bank liens");
        }
      });
  }

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="creditor"
              label="Creditor"
              rules={[
                {
                  required: true,
                  message: "Please input your creditor!",
                },
              ]}
            >
              <Input placeholder="Creditor" />
            </Form.Item>
            <Form.Item
              name="lien_location"
              label="Lien Location"
              rules={[
                {
                  required: true,
                  message: "Please input your lien location!",
                },
              ]}
            >
              <Input placeholder="Lien Location" />
            </Form.Item>
            <Form.Item name="amount" label="Amount" rules={numberValidation}>
              <InputNumber
                placeholder="Amount"
                style={{ minWidth: 280 }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>

            <Form.Item
              name="date_filed"
              label="Date Filed"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder="Date Filed"
                size="large"
                disabledDate={disabledDate}
                style={{ minWidth: 280 }}
              />
            </Form.Item>
            <Form.Item
              name="current_status"
              label="Current Status"
              rules={[
                {
                  required: true,
                  message: "Please select your current status!",
                },
              ]}
            >
              <Select placeholder="Current Status">
                {currentStatusOptions.map((eachOption) => (
                  <Option key={eachOption.value} value={eachOption.value}>
                    {eachOption.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default BankLinesModal;
