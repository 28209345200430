import React, { Fragment } from "react";
import { Form, Input, Button, message } from "antd";
import { changePassword } from "../../services/userProfile.services";

const ChangePassword = () => {
  const onFinish = (values) => {
    changePassword(values)
      .then((response) => message.success(response.data.message))
      .catch((error) => {
        message.error(error.response.data.message);
        console.error(error);
      });
  };
  return (
    <Fragment>
      <Form
        name="setpassword"
        onFinish={onFinish}
        className="changePassword"
        scrollToFirstError
      >
        <Form.Item
          name="old_password"
          rules={[
            {
              required: true,
              message: "Please input your old password",
            },
          ]}
        >
          <Input.Password
            autoFocus={true}
            placeholder="Old Password"
            type="password"
          />
        </Form.Item>
        <Form.Item
          name="new_password"
          rules={[
            {
              required: true,
              message:
                "Use 8 or more characters with a mix of letters, numbers & symbols.",
              pattern: new RegExp(
                "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
              ),
            },
          ]}
        >
          <Input.Password placeholder="New Password" type="password" />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          rules={[
            { required: true, message: "Please input your Password!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The new passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password type="password" placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            className="login-form-button"
          >
            <span>Submit</span>
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default ChangePassword;
