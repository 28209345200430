import React from "react";
import logoImage from "../assets/images/logoWhite.png";
import "./Logo.css";

const Logo = (props) => {
  return (
    <img
      src={logoImage}
      alt="i4-strategies"
      className="desktop-logo"
      {...props}
    />
  );
};

export default Logo;
