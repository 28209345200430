import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Collapse, message } from "antd";
import { CaretRightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import VerifyMobileNumber from "./VerifyMobileNumber";
import { setItem } from "../../helpers/localStorage";
import "./Verification.css";
import { metaData } from "../../services/metaData.services";
import { getUserDetails } from "../../helpers/utility";
import { VENDORS } from "../../constants/defaultKeys";
import { resendVerifyEmail } from "../../services/auth.services";

const { Panel } = Collapse;

const Verification = () => {
  const userDetails = getUserDetails();
  const [user, setUser] = useState(null);
  const emailNotVeriied = (
    <p>
      <a onClick={handleEmailVerification}>Click here</a>
      <span> to verfiy your email</span>
    </p>
  );
  async function handleEmailVerification() {
    try {
      const result = await resendVerifyEmail({ email: user?.email });
      console.log(result);
      message.success(
        "An email has send to your mail please verify and login again"
      );
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    userMetaData();
    setUser(userDetails);
  }, []);

  function userMetaData() {
    metaData()
      .then((response) => {
        const vendors = response.data.vendors;
        setItem(VENDORS, vendors, true);
      })
      .catch((error) => console.error(error));
  }

  return (
    <Fragment>
      <Row
        type="flex"
        justify="center"
        align="middle"
        className="login"
        style={{ textAlign: "left" }}
      >
        <Col
          lg={{ span: 14, offset: 5 }}
          md={{ span: 20, offset: 2 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          style={{ marginLeft: "0px" }}
        >
          <Collapse
            defaultActiveKey={[userDetails?.is_email_verified ? "yes" : "no"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              key="no"
              header="Email Verification"
              extra={
                user &&
                user.is_email_verified && (
                  <CheckCircleOutlined
                    style={{ fontSize: "24px", color: "#006d77" }}
                  />
                )
              }
            >
              {user?.is_email_verified
                ? "Your email has been verified!"
                : emailNotVeriied}
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={[userDetails?.is_i4_verified ? "yes" : "no"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              key="no"
              header="i4Strategies Verification"
              extra={
                user &&
                user.is_i4_verified && (
                  <CheckCircleOutlined
                    style={{ fontSize: "24px", color: "#006d77" }}
                  />
                )
              }
            >
              i4Strategies verification is not completed yet, Our admin will
              contact you soon for verification.
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={[userDetails?.is_mobile_verified ? "yes" : "no"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              header="Mobile Number Verification"
              key="no"
              extra={
                user &&
                user.is_mobile_verified && (
                  <CheckCircleOutlined
                    style={{ fontSize: "24px", color: "#006d77" }}
                  />
                )
              }
            >
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={{ span: 12, offset: 6 }}
                  xl={{ span: 12, offset: 6 }}
                >
                  <VerifyMobileNumber />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Verification;
