import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button, Form, Select, Input, message } from "antd";
import { recordPayment } from "../../../services/payment.services";
import { getAllVendors } from "../../../services/payment.services";
const { Option } = Select;
const { TextArea } = Input;

const RecordPayment = ({ refresh, paymentData }) => {
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    getAllVendors()
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Something went wrong, Please try after sometime");
        }
      });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    recordPayment(values)
      .then((response) => {
        message.success(response.data.message);
        refresh();
        setIsModalVisible(false);
      })
      .catch((error) => message.error(error.response.data.message));
  };

  return (
    <Fragment>
      <Button type="primary" onClick={showModal}>
        Record Offline Payment
      </Button>

      <Modal
        title="Record Offline Payment"
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{ amount: 1250 }}
          scrollToFirstError
        >
          <Form.Item
            name="id"
            label="Vendors"
            rules={[
              {
                required: true,
                message: "Please choose vendor",
              },
            ]}
          >
            <Select
              placeholder="Select Vendor"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {data &&
                data.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Transaction Id"
            name="transaction_ref"
            rules={[
              {
                required: true,
                message: "Please enter transaction id",
              },
            ]}
          >
            <Input placeholder="Please enter transaction id" />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: "Please add amount",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Remarks"
            name="remarks"
            rules={[
              {
                required: true,
                message: "Please write remarks",
              },
            ]}
          >
            <TextArea placeholder="Please write remarks" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default RecordPayment;
