import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import {
  createServiceLicense,
  updateServiceLicense,
} from "../../../../../services/vendorProfile.services";
import moment from "moment";
import { numberValidation } from "../../../../../config/validation.config";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const ServiceLicenseModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, []);

  function setFormFieldValues(data) {
    form.setFieldsValue({
      ...data,
      exp_date: moment(data.exp_date),
    });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
        exp_date: moment(values.exp_date).format("YYYY-MM-DD"),
      };

      updateServiceLicense(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
        exp_date: moment(values.exp_date).format("YYYY-MM-DD"),
      };

      createServiceLicense(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            onFinish={onFinish}
            layout="vertical"
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: "Please input your state!",
                },
              ]}
            >
              <Input placeholder="State" />
            </Form.Item>
            <Form.Item
              name="license_type"
              label="License Type"
              rules={[
                {
                  required: true,
                  message: "Please input your  license type!",
                },
              ]}
            >
              <Input placeholder="License Type" />
            </Form.Item>
            <Form.Item
              name="name_on_license"
              label="Name on License"
              rules={[
                {
                  required: true,
                  message: "Please input your  name on license!",
                },
              ]}
            >
              <Input placeholder="Name on License" />
            </Form.Item>
            <Form.Item
              name="license_number"
              label="License Number"
              rules={numberValidation}
            >
              <Input placeholder="License Number" />
            </Form.Item>
            <Form.Item
              name="exp_date"
              label="Exp. Date"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder="Date"
                size="large"
                style={{ minWidth: "200px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default ServiceLicenseModal;
