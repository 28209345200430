import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Button,
  Space,
  message,
} from "antd";
import InviteMember from "./InviteMember";
import { SelectedVendorDetailsContext } from "../../context/SelectedVendorContext";
import VendorProfile from "./VendorProfile/VendorProfile";
import {
  ApplicationList,
  downloadVendorProfile,
} from "../../services/applications.services";
import { FilePdfOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../helpers/utility";
import CreateApplicationModal from "../Dashboard/ApplicationsList/CreateApplicationModal";

const { Title } = Typography;

const Organization = () => {
  let query = useQuery();
  const applicationId = query.get("form");

  const history = useHistory();
  const [form] = Form.useForm();
  const { vendorDetails } = useContext(SelectedVendorDetailsContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [createPqqVisible, setCreatePQQ] = useState(false);

  const openPQQForm = () => {
    setCreatePQQ(true);
  };

  const vendorId = vendorDetails?.id;
  useEffect(() => {
    fetchApplicationsList();
  }, [vendorId]);

  const showProfileCompletedForm =
    vendorDetails &&
    (vendorDetails.profile_completion_status === 100 ||
      (vendorDetails.basic_details_status &&
        vendorDetails.insurance_status &&
        vendorDetails.financial_status &&
        vendorDetails.bank_status &&
        vendorDetails.litigation_status &&
        vendorDetails.additional_status &&
        vendorDetails.experience_status &&
        vendorDetails.integrity_status &&
        vendorDetails.safety_status &&
        vendorDetails.ownership_status));

  const onClick = () => {
    setVisible(true);
  };

  function downloadProfile() {
    setIsDownloading(true);
    downloadVendorProfile(applicationId)
      .then((res) => res.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${vendorDetails.name}-Profile.pdf`;
        a.click();
      })
      .catch((error) => {
        console.error("error downloding vendor profile", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("error downloding vendor profile");
        }
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }
  function fetchApplicationsList() {
    if (vendorId === undefined) return;
    setLoading(true);
    ApplicationList(vendorId)
      .then((response) => {
        const res = response?.data?.applications;
        if (res.length !== 0) {
          // setData(res[0]);
          history.push(
            `/vendor/${vendorId}/vendor-profile/${
              vendorDetails?.profile_completion_status !== 100
                ? "basic-details"
                : "submit-pqq"
            }?form=${applicationId || res[0].application_id}`
          );
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }

  return (
    <Row gutter={[32, 32]} type="flex" justify="center" align="middle">
      <Col span={8}>
        {showInput ? (
          <Form
            name="editVendorName"
            layout="inline"
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="vendor"
              rules={[
                {
                  required: true,
                  message: "Vendor name is required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
            <Button onClick={() => setShowInput(false)}>Cancel</Button>
          </Form>
        ) : (
          <Title level={4}>{vendorDetails?.name} </Title>
        )}
      </Col>
      <Col span={16} style={{ textAlign: "right" }}>
        <Space>
          {/* <Button
            size="large"
            type="primary"
            onClick={openPQQForm}
            disabled={
              !(
                vendorDetails?.is_subscribed ||
                vendorDetails?.profile_completion_status === 100
              )
            }
          >
            Create PQQ
          </Button> */}
          {vendorDetails?.is_approver && (
            <Button type="primary" size="large" onClick={onClick}>
              Invite
            </Button>
          )}
          <Button
            type="primary"
            size="large"
            loading={isDownloading}
            icon={<FilePdfOutlined />}
            onClick={downloadProfile}
          >
            Download Profile
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <VendorProfile
          vendorId={vendorId}
          vendorDetails={vendorDetails}
          showProfileCompletedForm={showProfileCompletedForm}
        />
      </Col>
      {visible && <InviteMember setVisible={setVisible} vendorId={vendorId} />}
      {createPqqVisible && (
        <CreateApplicationModal
          setVisible={setCreatePQQ}
          vendorDetails={vendorDetails && vendorDetails}
          vendorId={vendorId}
        />
      )}
    </Row>
  );
};

export default Organization;
