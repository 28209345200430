import React from "react";
import { Row, Col, Typography } from "antd";
const { Title, Paragraph } = Typography;

const SubmitPayment = () => {
  return (
    <Row type="flex">
      <Col spna={20} offset={1}>
        <Title level={3}>
          Vendors must provide payment to i4 Strategies in the amount of $1250.
          This will cover the initial Pre-Qualification process. An additional
          $750 + 2.9% transaction fee will be required annually for a
          recertification. Pre-Qualification Questionnaires will remain pending
          until payment is received and processed.
        </Title>
        <Paragraph></Paragraph>
      </Col>
    </Row>
  );
};
export default SubmitPayment;
