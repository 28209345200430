import React, { Fragment } from "react";

import PQQ from "../../../PQQ/PQQ";

function PqqModal() {
  return (
    <Fragment>
      <PQQ />
    </Fragment>
  );
}

export default PqqModal;
