import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import {
  updateCertification,
  createCertification,
} from "../../../../../services/vendorProfile.services";
import moment from "moment";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const CertificationModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, []);

  function setFormFieldValues(data) {
    form.setFieldsValue({
      ...data,
      certification_exp_date: moment(data.certification_exp_date),
    });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
        certification_exp_date: moment(values.certification_exp_date).format(
          "YYYY-MM-DD"
        ),
      };

      updateCertification(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
        certification_exp_date: moment(values.certification_exp_date).format(
          "YYYY-MM-DD"
        ),
      };

      createCertification(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="certification_type"
              label="Certification Type"
              rules={[
                {
                  required: true,
                  message: "Please input your certification type!",
                },
              ]}
            >
              <Input placeholder="Certification Type" />
            </Form.Item>
            <Form.Item
              name="certification_agency"
              label="Certification Agency"
              rules={[
                {
                  required: true,
                  message: "Please input your  certification agency!",
                },
              ]}
            >
              <Input placeholder="Certification Agency" />
            </Form.Item>
            <Form.Item
              name="certification_exp_date"
              label="Certification Expiry Date"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder="Date"
                size="large"
                style={{ minWidth: "280px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default CertificationModal;
