import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Form,
  Divider,
  Button,
  Collapse,
  Row,
  Col,
  Typography,
  InputNumber,
  message,
} from "antd";
import ContractsInProgress from "./ContractsInProgress/ContractsInProgress";
import ServiceLicense from "./ServiceLicense/ServiceLicense";
import RevokedLicense from "./RevokedLicense/RevokedLicense";
import Certification from "./Certification/Certifications";
import {
  tailLayout,
  wideLabelLayout,
} from "../../../../config/formLayout.config";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import RadioButton from "../../../../blocks/RadioButton/RadioButton";
import {
  createAdditionalDetails,
  getAdditionalDetails,
  getCertifications,
  getContracts,
  getRevokedLicense,
  getServiceLicense,
} from "../../../../services/vendorProfile.services";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import "./AdditionalDetails.css";
import RadioGroupWizz from "../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import { radioYesOrNo } from "../../../../config/radioGroupConstants";
import { profileCompletion } from "../../../../constants/defaultKeys";
const { Panel } = Collapse;
const { Paragraph } = Typography;

const validateMessages = {
  required: "'${label}' is required!",
};

const certificationQuestions = [
  // {
  //   name: "fradulent_minority_business",
  //   label:
  //     "Has your firm been the subject of any criminal investigation, felony indictment or conviction concerning fraudulent Minority-Owned Business Enterprise, Woman-Owned Business Enterprise or a Disadvantaged Business Enterprise participation?",
  // },
  {
    name: "revocation_disadvantaged_business",
    label:
      "Has your firm had a denial, decertification, revocation or forfeiture of any certification of Minority-Owned Business Enterprise, Woman-Owned Business Enterprise or Federal certification of Disadvantaged Business Enterprise status?",
  },
  {
    name: "formal_monitored_agreement",
    label:
      "Has your firm entered into a formal monitoring agreement, consent decree or stipulation settlement as specified by, or agreed to with, any government entity?",
  },
];

const AdditionalDetails = (props) => {
  const vendorId = props.vendorId;

  const [form] = Form.useForm();
  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );

  const [disabled, setDisabled] = useState(false);
  const [contractsData, setContractsData] = useState([]);
  const [licenseData, setLicenseData] = useState([]);
  const [revokedLicenseData, setRevokedLicenseData] = useState([]);
  const [certificationData, setCertificationData] = useState([]);

  const additionalDetailsData = vendorProfileFormData.additional_details;
  const showLicensetable = additionalDetailsData.has_service_license;
  const showRevokedLicensetable = additionalDetailsData.has_revoked_license;

  useEffect(() => {
    if (vendorId) {
      fetchAdditionalDetails();
      fetchContracts();
      fetchServiceLicense();
      fetchRevokedLicense();
      fetchCertifications();
    }
  }, [vendorId]);

  function fetchAdditionalDetails() {
    getAdditionalDetails(vendorId)
      .then((response) => {
        const data = response.data.data;
        if (Object.keys(data).length === 0) {
          setFormFieldValues({
            ...additionalDetailsData,
            has_service_license: showLicensetable ? true : false,
            has_revoked_license: showRevokedLicensetable ? true : false,
            possess_or_application_pending:
              additionalDetailsData.possess_or_application_pending
                ? true
                : false,
          });
        } else {
          let formValues = {
            ...data,
            ...additionalDetailsData,
          };
          setFormFieldValues(formValues);
        }
      })
      .catch((error) => {
        console.error("get additional details error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting additional details");
        }
      });
  }

  function fetchContracts() {
    getContracts(vendorId)
      .then((res) => {
        setContractsData(res.data.data);
      })
      .catch((error) => {
        console.error("get contracts error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting contracts");
        }
      });
  }

  function fetchServiceLicense() {
    getServiceLicense(vendorId)
      .then((res) => {
        setLicenseData(res.data.data);
      })
      .catch((error) => {
        console.error("get service license error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting service license");
        }
      });
  }

  function fetchRevokedLicense() {
    getRevokedLicense(vendorId)
      .then((res) => {
        setRevokedLicenseData(res.data.data);
      })
      .catch((error) => {
        console.error("get revoked license error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting revoked license");
        }
      });
  }

  function fetchCertifications() {
    getCertifications(vendorId)
      .then((res) => {
        setCertificationData(res.data.data);
      })
      .catch((error) => {
        console.error("get service license error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error getting service license");
        }
      });
  }

  function setFormFieldValues(data) {
    setVendorProfileDataSafety(data);
    form.setFieldsValue(data);
  }

  function setVendorProfileDataSafety(changedValues) {
    vendorProfileFormData.additional_details = {
      ...additionalDetailsData,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  function onFormValuesChange() {
    setVendorProfileDataSafety(form.getFieldsValue());
  }

  function onFinish(values) {
    const formData = { ...values, vendor_id: vendorId };

    if (showLicensetable && licenseData.length === 0) {
      message.error("Please add atleast one Service License");
      return;
    } else if (showRevokedLicensetable && revokedLicenseData.length === 0) {
      message.error("Please add atleast one Revoked License");
      return;
    } else {
      setDisabled(true);

      createAdditionalDetails(formData)
        .then((response) => {
          setDisabled(false);
          if (!vendorDetails.additional_status) {
            setVendorDetails({
              ...vendorDetails,
              additional_status: true,
              profile_completion_status:
                vendorDetails.profile_completion_status +
                profileCompletion.additional_status,
            });
          }
          message.success(response.data.message);
          // navigating user to next step on save
          props.onStepChange(props.currentStep + 1);
        })
        .catch((error) => {
          setDisabled(false);
          console.error(error);
          if (error && error.response) {
            if (error.response.status === 400) {
              message.error(
                "Something went wrong with the form being saved. Please contact administrator"
              );
            } else {
              message.error(
                error.response.data.message || error.response.message
              );
            }
          } else {
            message.error("Error creating bank details");
          }
        });
    }
  }

  const certificationItems = (
    <Fragment>
      {certificationQuestions.map((eachQusn) => (
        <Form.Item
          name={eachQusn.name}
          label={eachQusn.label}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {RadioButton()}
        </Form.Item>
      ))}
    </Fragment>
  );
  return (
    <Form
      name="additional_details"
      form={form}
      className="form-additional-details"
      {...wideLabelLayout}
      validateMessages={validateMessages}
      onValuesChange={onFormValuesChange}
      onFinish={onFinish}
      colon={false}
      initialValues={{
        has_service_license: false,
        has_revoked_license: false,
        revocation_disadvantaged_business: false,
        union_agreement: false,
        possess_or_application_pending: false,
        formal_monitored_agreement: false,
      }}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1", "2", "3", "4", "5"]}>
        <Panel header="Employees" key="1">
          <Form.Item
            label="Number of Employees"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Row gutter={[12, 0]}>
              <Col span={8}>
                <Paragraph className="header-label mandatory-field-mark">
                  Home Office
                </Paragraph>
                <Form.Item
                  name="employees_home_office"
                  rules={[
                    { required: true, message: "Enter home office details" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ minWidth: "100px" }}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Paragraph className="header-label mandatory-field-mark">
                  Field Supervisory
                </Paragraph>
                <Form.Item
                  name="employees_field_supervisory"
                  rules={[
                    {
                      message: "Enter field supervisory details",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ minWidth: "100px" }}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Paragraph className="header-label mandatory-field-mark">
                  Craft
                </Paragraph>
                <Form.Item
                  name="employees_craft"
                  rules={[
                    {
                      message: "Enter craft details",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ minWidth: "100px" }}
                    placeholder=""
                  />
                </Form.Item>
              </Col>{" "}
            </Row>
          </Form.Item>
          <Form.Item
            label="Average Number of Employees over past three years"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Row gutter={[8, 0]}>
              <Col span={8}>
                <Paragraph className="header-label mandatory-field-mark">
                  Home Office
                </Paragraph>
                <Form.Item
                  name="avg_employees_home_office"
                  rules={[
                    { required: true, message: "Enter home office details" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ minWidth: "100px" }}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Paragraph className="header-label mandatory-field-mark">
                  Field Supervisory
                </Paragraph>
                <Form.Item
                  name="avg_employees_field_supervisory"
                  rules={[
                    {
                      message: "Enter field supervisory details",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ minWidth: "100px" }}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Paragraph className="header-label mandatory-field-mark">
                  Craft
                </Paragraph>
                <Form.Item
                  name="avg_employees_craft"
                  rules={[
                    {
                      message: "Enter craft details",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ minWidth: "100px" }}
                    placeholder=""
                  />
                </Form.Item>
              </Col>{" "}
            </Row>
          </Form.Item>
        </Panel>
        <Panel header="Contracts In Progress" key="2">
          <ContractsInProgress
            vendorId={vendorId}
            contractsData={contractsData}
            fetchContracts={fetchContracts}
          />
        </Panel>
        <Panel header="Unions" key="3">
          <Form.Item
            name="union_agreement"
            label="Is the applicant firm entered into a collective bargaining agreement (union)"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
        </Panel>
        <Panel header="Licenses" key="4">
          <Form.Item
            name="has_service_license"
            className="multiline-form-item"
            label="Does the applicant firm provide services under any other license, registration or certificate (whether held in name of applicant
              firm or employee or other individual)?"
            rules={[
              {
                required: false,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          {showLicensetable && (
            <ServiceLicense
              vendorId={vendorId}
              licenseData={licenseData}
              fetchServiceLicense={fetchServiceLicense}
            />
          )}
          <Divider />
          <Form.Item
            name="has_revoked_license"
            className="multiline-form-item"
            label="Has any license, registration or certification possessed by the
            applicant firm or any Key Individual been revoked or suspended? Or has any initial or renewal license,
            registration or certification applicant been denied within the past five
            years?"
            rules={[
              {
                required: false,
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          {showRevokedLicensetable && (
            <RevokedLicense
              vendorId={vendorId}
              revokedLicenseData={revokedLicenseData}
              fetchRevokedLicense={fetchRevokedLicense}
            />
          )}
        </Panel>
        <Panel header="Certifications" key="5">
          <Form.Item
            name="possess_or_application_pending"
            label="Does the applicant firm currently possess, or have any application 
            pending for, any business opportunity program, including Minority-Owned 
            Business Enterprise, Women-Owned Business Enterprise, Disadvantaged 
            Business Enterprise, Veteran-Owned Business Enterprise, and Small Business Enterprise?"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {RadioButton()}
          </Form.Item>
          {form.getFieldValue("possess_or_application_pending") && (
            <>
              {certificationItems}
              <Divider />
              <Certification
                vendorId={vendorId}
                certificationData={certificationData}
                fetchCertifications={fetchCertifications}
              />
            </>
          )}
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AdditionalDetails;
