import React from "react";
import { Row, Col, Typography } from "antd";
const { Title, Paragraph } = Typography;

const HelpAndSupport = () => {
  return (
    <Row type="flex">
      <Col spna={20} offset={1}>
        <Title level={3}>
          Any questions or concerns can be directed to{" "}
          <a
            href="https://info@i4strategies.com."
            target="_blank"
            rel="noopener noreferrer"
          >
            info@i4strategies.com.
          </a>{" "}
        </Title>
        <Paragraph>
          Additionally, you may click the "bulb icon" in the upper right corner
          for additional information on how to use the portal.
        </Paragraph>
      </Col>
    </Row>
  );
};
export default HelpAndSupport;
