import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { inviteUser } from "../../services/userProfile.services";

const InviteMember = ({ setVisible, vendorId }) => {
  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    inviteUser({ ...values, vendor_id: vendorId })
      .then((res) => {
        setVisible(false);
        message.success(res.data.message);
      })
      .catch((error) => {
        console.error("error inviting user", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("error inviting user");
        }
      });
  };

  return (
    <Modal
      title="Invite User"
      visible={true}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        name="nest-messages"
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "Email is required",
              required: true,
            },
          ]}
        >
          <Input size="large" placeholder="E-mail" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Invite
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteMember;
