import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Row,
  Space,
  Table,
  message,
} from "antd";
import { narrowLabelLayout } from "../../../../config/formLayout.config";

import { useQuery } from "../../../../helpers/utility";
import RelatedEntitiesModal from "./RelatedEntitiesModal";
import {
  createEntitiesApi,
  deleteEntitieApi,
  getApplicationNoRecords,
  getEntitiesApi,
  updateApplicationNoRecords,
  updateEntitieApi,
} from "../../../../services/adminForm.services";
import moment from "moment";

const { Panel } = Collapse;
const dateFormat = "YYYY-MM-DD";

function RelatedEntities(props) {
  const [form] = Form.useForm();
  const query = useQuery();
  const applicationId = query.get("id");
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState({ status: "", data: null });
  const [entitieData, setEntitieDataa] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [appNoRcd, setAppNoRcdData] = useState(null);
  const [checkBox, setCheckBox] = useState({
    has_related_entities: appNoRcd?.has_related_entities,
  });
  const [checked, setChecked] = useState({
    key: [],
  });

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  useEffect(() => {
    getApplicationNoRecordsApi();
    getEntities();
  }, [refresh]);

  async function saveRelatedEntitie(values) {
    try {
      const res = await createEntitiesApi({
        application: applicationId,
        ...values,
        date_of_incorporated: moment(values.date_of_incorporated).format(
          dateFormat
        ),
      });
      if (res) {
        message.success(res?.data?.message);
        refreshPage();
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function updateEntitie(values) {
    try {
      const res = await updateEntitieApi({
        record_id: editTable?.data?.id,
        application: applicationId,
        ...values,
        date_of_incorporated: moment(values.date_of_incorporated).format(
          dateFormat
        ),
      });
      if (res) {
        message.success(res?.data?.message);
        setVisible(false);
        refreshPage();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getEntities() {
    try {
      const res = await getEntitiesApi(applicationId);
      if (res) {
        setEntitieDataa(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function deleteEntitie(id) {
    try {
      const res = await deleteEntitieApi(id);
      if (res) {
        message.success(res?.data?.message);
        refreshPage();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getApplicationNoRecordsApi() {
    try {
      const res = await getApplicationNoRecords(applicationId);
      setAppNoRcdData(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function updateApplicationApi(values) {
    try {
      const res = await updateApplicationNoRecords(applicationId, {
        has_related_entities: values?.has_related_entities,
      });
      message.success(res.data?.message);
    } catch (err) {
      console.log(err);
    } finally {
      getApplicationNoRecordsApi();
    }
  }

  return (
    <Fragment>
      <Form
        {...narrowLabelLayout}
        name="safety"
        form={form}
        className="form-safety"
        onFinish={() => console.log("object")}
        colon={false}
        scrollToFirstError
      >
        <Collapse defaultActiveKey={["1"]}>
          <Panel
            header="Based on Latest Financial Audit Year"
            key="1"
            extra={
              <Checkbox
                checked={appNoRcd?.has_related_entities}
                onChange={(e) => {
                  setCheckBox({
                    ...checkBox,
                    has_related_entities: e.target.checked,
                  });
                  updateApplicationApi({
                    has_related_entities: e.target.checked,
                  });
                }}
              >
                No Information Identified
              </Checkbox>
            }
            collapsible={appNoRcd?.has_related_entities ? "disabled" : ""}
          >
            <div
              style={
                appNoRcd?.has_related_entities
                  ? {
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Button
                    onClick={() => setVisible(!visible)}
                    type="secondary"
                    style={{
                      marginBottom: 16,
                    }}
                    disabled={appNoRcd?.has_related_entities}
                  >
                    Add Entities
                  </Button>
                </Col>
                <Col span={24}>
                  <Table
                    size="small"
                    columns={[
                      {
                        title: "Entity Name",
                        dataIndex: "name",
                        key: "name",
                        ellipsis: true,
                      },
                      {
                        title: "Entity Address",
                        dataIndex: "address",
                        key: "address",
                        ellipsis: true,
                      },
                      {
                        title: "Type of Entity",
                        dataIndex: "type_of_entity",
                        key: "type_of_entity",
                        ellipsis: true,
                      },
                      {
                        title: "Date Incorporated",
                        dataIndex: "date_of_incorporated",
                        key: "date_of_incorporated",
                        ellipsis: true,
                      },
                      {
                        title: "Entity Status",
                        dataIndex: "status",
                        key: "status",
                        ellipsis: true,
                      },
                      {
                        title: "Action",
                        key: "action",
                        width: 160,
                        render: (record) => (
                          <Space size="middle">
                            <span
                              style={
                                appNoRcd?.has_related_entities
                                  ? {
                                      pointerEvents: "none",
                                      cursor: "not-allowed",
                                    }
                                  : {}
                              }
                              className="footer-links"
                              onClick={() => {
                                setVisible(!visible);
                                setEditTable({
                                  status: "update",
                                  data: record,
                                });
                              }}
                            >
                              Edit
                            </span>
                            <span
                              style={
                                appNoRcd?.has_related_entities
                                  ? {
                                      pointerEvents: "none",
                                      cursor: "not-allowed",
                                    }
                                  : {}
                              }
                              className="footer-links"
                              onClick={() => deleteEntitie(record?.id)}
                            >
                              Delete
                            </span>
                          </Space>
                        ),
                      },
                    ]}
                    pagination={false}
                    dataSource={entitieData}
                  />
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
      </Form>
      {visible && (
        <RelatedEntitiesModal
          create={saveRelatedEntitie}
          setVisible={setVisible}
          update={updateEntitie}
          title="Add Related Entities"
          editTable={editTable}
        />
      )}
    </Fragment>
  );
}

export default RelatedEntities;
