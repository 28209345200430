import React, { useEffect, useState } from "react";
import { Form, Button, Switch, Row, Col, Select, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { adminVendorMapping } from "../../../../services/applications.services";

const { Option } = Select;

const VendorMapping = ({
  vendorsList,
  selectedUserData,
  setShowManageUserDrawer,
  refresh,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [newVendorList, setNewVendorList] = useState([]);
  const [removedFileds, setRemovedFileds] = useState([]);

  useEffect(() => {
    let selected = selectedUserData?.vendors.map((item, idx) => {
      return {
        ...item,
        key: idx,
        name: idx,
        fieldKey: idx,
        restField: { isListField: true },
      };
    });
    let separatedVendors = vendorsList.filter(
      ({ id: id1 }) => !selected.some(({ vendor_id: id2 }) => id1 === id2)
    );

    setData(selected);
    setNewVendorList(separatedVendors);
    form.setFieldsValue({ vendor: selected });
  }, [selectedUserData]);

  const onFinish = (values) => {
    const removedData = values?.vendor.filter(
      (item) => !removedFileds.includes(item.vendor_id)
    );
    const users = values?.users !== undefined ? values.users : [];
    const formData = [...users, ...removedData];

    adminVendorMapping(selectedUserData.id, formData)
      .then((res) => {
        message.success(res.data.message);
        setShowManageUserDrawer(false);
        refresh();
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("manage vendor error");
        }
      });
  };

  const removeVendor = (val) => {
    const remove = data.filter((id) => id.vendor_id !== val);
    setData(remove);
    setRemovedFileds([...removedFileds, val]);
  };

  return (
    <Form form={form} name="form" onFinish={onFinish} scrollToFirstError>
      <Form.List name="vendor">
        {() => (
          <>
            {data.map((item) => (
              <Row gutter={[16, 16]}>
                <Col span={14}>
                  <Form.Item
                    {...item}
                    name={[item.name, "vendor_id"]}
                    label="Vendor"
                    fieldKey={[item.fieldKey, "vendor_id"]}
                    rules={[
                      {
                        required: true,
                        message: "Vendor is required!",
                      },
                    ]}
                  >
                    <Select placeholder="Select Vendor" disabled>
                      <Option key={item.vendor_id} value={item.vendor_id}>
                        {item.vendor_name}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    {...item}
                    name={[item.name, "approver"]}
                    fieldKey={[item.fieldKey, "approver"]}
                    label="Is Approver?"
                    valuePropName="checked"
                  >
                    <Switch className="add-vendor" />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <span
                    onClick={() => removeVendor(item.vendor_id)}
                    style={{ cursor: "pointer" }}
                  >
                    <MinusCircleOutlined />
                  </span>
                </Col>
              </Row>
            ))}
          </>
        )}
      </Form.List>

      <Form.List name="users">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row
                type="flex"
                justify="center"
                align="middle"
                gutter={[16, 16]}
              >
                <Col span={14}>
                  <Form.Item
                    {...restField}
                    name={[name, "vendor_id"]}
                    fieldKey={[fieldKey, "vendor_id"]}
                    label="Vendor"
                    rules={[
                      {
                        required: true,
                        message: "Vendor is required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Vendor"
                      showSearch
                      optionFilterProp="vendor_id"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {newVendorList.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7} style={{ textAlign: "center" }}>
                  <Form.Item
                    {...restField}
                    name={[name, "approver"]}
                    fieldKey={[fieldKey, "approver"]}
                    label="Is Approver?"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Switch className="add-vendor" />
                  </Form.Item>
                </Col>
                <Col span={3} style={{ marginTop: "-20px" }}>
                  <span
                    onClick={() => remove(name)}
                    style={{ cursor: "pointer" }}
                  >
                    <MinusCircleOutlined />
                  </span>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Vendor Mapping
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default VendorMapping;
