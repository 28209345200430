import { getItem, clear } from "./localStorage";
import { ACCESS_TOKEN, USER, VENDORS } from "../constants/defaultKeys";
import { useLocation, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export const isAuthenticated = () => {
  let isTokenAvailable = false;
  const access_token = getItem(ACCESS_TOKEN);
  if (access_token) {
    isTokenAvailable = true;
  }
  return isTokenAvailable;
};

export const getTokenIfNotExpired = () => {
  const token = getItem(ACCESS_TOKEN);
  const user = getItem(USER, true);
  if (token && user) {
    const date = new Date();
    const expiredTime = user.exp;
    if (expiredTime < date.getTime() / 1000) {
      window.location.href = "/";
      clear();
      return null;
    }
  }
  return token;
};

export const getMessageFromResponse = (response) => {
  const message = response && response.data && response.data.message;

  return message || "Something went wrong, Please try after sometime";
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getPrevious3Yrs = () => {
  const previousyear = moment().subtract(1, "year");
  const lastPreviousYear = moment().subtract(2, "year");
  const lastLastPreviousYear = moment().subtract(3, "year");
  return [previousyear, lastPreviousYear, lastLastPreviousYear];
};

export const GetRouteParams = (routeUrl) => {
  const { params } = useRouteMatch(routeUrl);
  return params;
};

export const getSelectedVendor = (vendorId) => {
  const vendors = JSON.parse(getItem(VENDORS))?.vendors || [];
  let selectedVendor = vendors?.filter(
    (eachVendor) => eachVendor.vendor_id === parseInt(vendorId)
  );
  return selectedVendor && selectedVendor[0];
};

export const isAdmin = () => {
  const userDetails = JSON.parse(getItem(USER));
  return (
    userDetails?.is_admin ||
    userDetails?.is_client_admin ||
    userDetails?.is_project_coordinator
  );
};

export const isUserClientOrProjectAdmin = () => {
  const userDetails = JSON.parse(getItem(USER));
  return userDetails?.is_client_admin || userDetails?.is_project_coordinator;
};

export const getUserDetails = () => {
  return JSON.parse(getItem(USER), true);
};

export const getAssignedVendors = () => {
  return JSON.parse(getItem(VENDORS))?.vendors || [];
};

export const GetVendorId = () => {
  const { params } = useRouteMatch("/vendor/:vendor_id") || {};
  const vendors = JSON.parse(getItem(VENDORS))?.vendors || [];
  return params?.vendor_id || (vendors && vendors[0]?.vendor_id);
};

export function normalizedFind(filtersArray, filterName, filterValue) {
  return filtersArray[filterName].find(
    (eachStatus) => filterValue === eachStatus.value
  );
}

export async function downloadDataFiles({
  api,
  fileName,
  fileType,
  apiParams,
}) {
  api(apiParams)
    .then((response) => {
      let blob = response.data;
      let url = window.URL.createObjectURL(blob);
      let anchorTag = document.createElement("a");
      anchorTag.href = url;
      anchorTag.download = `${fileName}.${fileType}`;
      anchorTag.click();
    })
    .catch((error) => {
      console.log(error);
    });
}

export const getColumnSearchProps = ({
  searchInput,
  dataIndex,
  name,
  handleSearch,
  handleReset,
  searchText,
  searchedColumn,
}) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Search ${name}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            handleReset();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
});
