import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, message, InputNumber } from "antd";
import {
  createViolation,
  updateViolation,
} from "../../../../../services/vendorProfile.services";
import moment from "moment";
import DrawerWizz from "../../../../../reusable/DrawerWizz";

const AdministrativeCasesModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  //Disable Date greater than today
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  useEffect(() => {
    if (editTable.form_status === "update") {
      setFormFieldValues(editTable);
    }
  }, [editTable]);

  function setFormFieldValues(data) {
    form.setFieldsValue({
      ...data,
      date_instituted: moment(data.date_instituted),
    });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.form_status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
        date_instituted: moment(values.date_instituted).format("YYYY-MM-DD"),
      };

      updateViolation(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
        date_instituted: moment(values.date_instituted).format("YYYY-MM-DD"),
      };

      createViolation(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="crime_specified"
              label="Crime Specified"
              rules={[
                {
                  required: true,
                  message: "Please  specify (1)-(3) above!",
                },
              ]}
            >
              <InputNumber
                style={{ minWidth: "300px" }}
                placeholder="Specify (1)-(3) above"
                min={1}
                max={3}
              />
            </Form.Item>
            <Form.Item
              name="agency_court"
              label="Agency Court"
              rules={[
                {
                  required: true,
                  message: "Please input your  agency or court!",
                },
              ]}
            >
              <Input placeholder="Agency or Court" />
            </Form.Item>
            <Form.Item
              name="case_no"
              label="Case No."
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Case No" />
            </Form.Item>
            <Form.Item
              name="nature_of_action"
              label="Nature of Action"
              rules={[
                {
                  required: true,
                  message: "Please input nature of action!",
                },
              ]}
            >
              <Input placeholder="Nature of Action" />
            </Form.Item>
            <Form.Item
              name="date_instituted"
              label="Date Instituted"
              rules={[
                {
                  required: true,
                  message: "Please input date instituted!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                disabledDate={disabledDate}
                placeholder="Date Instituted"
                size="large"
                style={{ minWidth: "280px" }}
              />
            </Form.Item>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please input your status!",
                },
              ]}
            >
              <Input placeholder="Status" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default AdministrativeCasesModal;
