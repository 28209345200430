import React, { Fragment, useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Typography,
  message,
  Empty,
} from "antd";
import { CreateVendorApplication } from "../../../services/applications.services";
import { useHistory } from "react-router-dom";

const { Paragraph } = Typography;
const { Option } = Select;

const CreateApplicationModal = ({ setVisible, vendorDetails, refresh }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const vendorId = vendorDetails?.id;

  useEffect(() => {
    if (vendorDetails) {
      let defaultCustomer =
        (vendorDetails?.customers?.length > 0 &&
          vendorDetails.customers.find((value) => value?.name === "RXR")) ||
        vendorDetails.customers[0];
      // let defaultCustomer =
      //   vendorDetails?.customers?.length > 1 && vendorDetails.customers[0];
      let defaultProject =
        defaultCustomer && defaultCustomer.projects.length > 0
          ? defaultCustomer.projects[0]
          : [];
      setCustomers(defaultCustomer);
      setProjects(defaultProject);
      setFormFieldValues(defaultCustomer, defaultProject);
    }
  }, [, vendorDetails]);

  function setFormFieldValues(customer, project) {
    form.setFieldsValue({
      customer_id: customer && customer.id,
      project_id: project && project.id,
    });
  }

  const handleCancel = () => {
    // history.push("/dashboard");
    setVisible(false);
  };

  const onFinish = (values) => {
    if (projects.length === 0) {
      message.error(
        "Cannot create PQQ. No projects found for the selected customer!"
      );
    } else {
      const formData = { ...values, vendor_id: vendorId };
      if (values.bid_nos === undefined) {
        formData.bid_nos = "-";
      }

      CreateVendorApplication(formData)
        .then((response) => {
          const data = response.data.data;
          setVisible(false);
          message.success("PQQ created successfully");
          history.push(
            `/vendor/${vendorId}/vendor-profile/${
              vendorDetails?.profile_completion_status !== 100
                ? "basic-details"
                : "submit-pqq"
            }?form=${data?.application_id}`
          );
          // history.push({
          //   pathname: `/vendor/${vendorId}/vendor-profile/basic-details`,
          //   search: `?form=${data.application_id}`,
          // });
          // refresh();
        })
        .catch((error) => {
          console.error(error);
          if (error && error.response) {
            message.error(
              error.response.data.message || error.response.message
            );
          } else {
            message.error("Error creating PQQ");
          }
        });
    }
  };

  //Select Change
  function handleCustomerChange(value) {
    let selectedCustomer = vendorDetails.customers.find(
      (item) => item.id == value
    );
    let requiredProjects =
      selectedCustomer.projects && selectedCustomer.projects[0];
    setCustomers(selectedCustomer);
    setProjects(requiredProjects);
    setFormFieldValues(selectedCustomer, requiredProjects);
  }

  function handleProjectChange(value) {
    setProjects(value);
  }

  return (
    <Fragment>
      <Modal
        title="Create New PQQ"
        visible={true}
        footer={null}
        maskClosable={false}
        width="600px"
        onCancel={handleCancel}
      >
        {vendorDetails && vendorDetails?.customers?.length > 0 ? (
          <Form
            name="pqqForm"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="customer_id"
              rules={[
                {
                  required: true,
                  message: "Please input customer name!",
                },
              ]}
            >
              <Select
                size="large"
                onChange={handleCustomerChange}
                placeholder="Customer Name"
              >
                {vendorDetails &&
                  vendorDetails.customers.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {customers && projects && projects.length !== 0 ? (
              <>
                <Form.Item
                  name="project_id"
                  rules={[
                    {
                      required: true,
                      message: "Please input your project name!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    onChange={handleProjectChange}
                    placeholder="Project Name"
                  >
                    {customers &&
                      customers.projects &&
                      customers.projects.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item name="bid_nos">
                  <Input size="large" placeholder="BID (optional)" />
                </Form.Item>
              </>
            ) : (
              <Paragraph style={{ color: "#ff4d4f" }}>
                No projects found for this customer
              </Paragraph>
            )}

            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Empty description="No Customers Found!" />
        )}
      </Modal>
    </Fragment>
  );
};

export default CreateApplicationModal;
