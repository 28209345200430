import axiosInstance from "../config/axios/axios.config";

export const createPaymentToken = (payload) => {
  return axiosInstance.post("/payments/create-page-token/", payload);
};

export const getPaymentURL = (payload) => {
  return axiosInstance.post("/payments/get-payment-url/", payload);
};

export const getPaymentSuccess = (queryParam) => {
  return axiosInstance.get(
    `/payments/success/?session_id=${queryParam.session_id}&vendor_id=${queryParam.vendor_id}`
  );
};

export const getPaymentFailed = (queryParam) => {
  return axiosInstance.get(
    `/payments/cancelled/?session_id=${queryParam.session_id}&vendor_id=${queryParam.vendor_id}`
  );
};

export const payOfflinePayment = (payload) => {
  return axiosInstance.post("/payments/pay-offline/", payload);
};

export const transactionsList = () => {
  return axiosInstance.get("/payments/transaction-list/");
};

export const recordPayment = (payload) => {
  return axiosInstance.post("payments/record-payment/", payload);
};

export const getMySubscription = (id) => {
  return axiosInstance.get(`accounts/get-my-subscription/?vendor_id=${id}`);
};

export const getAllVendors = () => {
  return axiosInstance.get("accounts/get-vendors-list/");
};

export const downloadTransactionListData = () => {
  return axiosInstance.get(`/payments/transaction-list/?download_data=true`, {
    responseType: "blob",
  });
};
