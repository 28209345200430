import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const LitigationModel = ({ create, update, title, editTable, setVisible }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      let formValues = editTable;
      formValues.date_filed = moment(editTable.date_filed);
      form.setFieldsValue(formValues);
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    // refresh();
  };

  function onFinish(values) {
    if (editTable.status === "update") {
      update(values);
    } else {
      create(values);
    }
  }

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="case_name"
            label="Case Name"
            rules={[
              {
                required: true,
                message: "Please input case name!",
              },
            ]}
          >
            <Input placeholder="Case Name" />
          </Form.Item>
          <Form.Item
            name="court"
            label="Court"
            rules={[
              {
                required: true,
                message: "Please input court!",
              },
            ]}
          >
            <Input placeholder="Court" />
          </Form.Item>
          <Form.Item
            name="index_no"
            label="Index No"
            rules={[
              {
                required: true,
                message: "Please input index no!",
              },
            ]}
          >
            <Input placeholder="Index No" />
          </Form.Item>
          <Form.Item
            name="date_filed"
            label="Filed Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              format={dateFormat}
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item
            name="type_of_action"
            label="Type Of Action"
            rules={[
              {
                required: true,
                message: "Please input type of action!",
              },
            ]}
          >
            <Input placeholder="Type Of Action" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};
export default LitigationModel;
