import React, { useState } from "react";
import { Form, Button, DatePicker, Select } from "antd";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
const rangeConfig = {
  rules: [
    {
      type: "array",
      required: true,
      message: "Please select Date!",
    },
  ],
};
const AdminTableFilters = ({
  applicationStatusValues,
  fetchAdminApplicationList,
  setIsLoading,
  setSelectedDates,
}) => {
  function disabledDate(current) {
    return current && current.valueOf() > Date.now();
  }

  const onFinish = (values) => {
    const formData = {
      status: values.status,
      start_date: values["date_range"][0].format("YYYY-MM-DD"),
      end_date: values["date_range"][1].format("YYYY-MM-DD"),
    };

    setIsLoading(true);
    setSelectedDates({
      start_date: formData.start_date,
      end_date: formData.end_date,
    });
    fetchAdminApplicationList(formData);
  };
  return (
    <Form
      style={{ display: "flex", justifyContent: "flex-end" }}
      name="adminFilters"
      onFinish={onFinish}
      layout="inline"
      initialValues={{
        status: applicationStatusValues
          .map((eachStatus) => eachStatus.value)
          .slice(0, 8),
        date_range: [moment().subtract(90, "days"), moment()],
      }}
      scrollToFirstError
    >
      <Form.Item
        name="status"
        rules={[{ required: true, message: "Please choose required fields" }]}
      >
        <Select mode="multiple" maxTagCount={2}>
          {applicationStatusValues.map(
            (eachStatus) =>
              eachStatus.value && (
                <Option value={eachStatus.value} key={eachStatus.value}>
                  {eachStatus.label}
                </Option>
              )
          )}
        </Select>
      </Form.Item>

      <Form.Item name="date_range" {...rangeConfig}>
        <RangePicker
          format="YYYY-MM-DD"
          disabledDate={disabledDate}
          style={{ maxWidth: "250px" }}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Go
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AdminTableFilters;
