import axiosInstance from "../config/axios/axios.config";

// CREATE
export const createLitigations = (payload) => {
  return axiosInstance.post(
    `/core/admin-form/create-admin-litigations/`,
    payload
  );
};

export const createBankruptcy = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-bankruptcy/`, payload);
};

export const createMWBE = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-mwbe/`, payload);
};

export const createNCRecords = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-nc-records/`, payload);
};

export const createNYCLicence = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-nycl/`, payload);
};

export const createOshaVoilations = (payload) => {
  return axiosInstance.post(
    `/core/admin-form/create-osha-voilations/`,
    payload
  );
};

export const createOtherLicence = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-other-licence/`, payload);
};

export const createTwlRecords = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-twl-records/`, payload);
};

export const createUccFilings = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-ucc-filings/`, payload);
};

export const createUccLiens = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-ucc-liens/`, payload);
};

export const createLiens = (payload) => {
  return axiosInstance.post(
    `/core/admin-form/create-ownerliens-bankruptcy-judgements/`,
    payload
  );
};

export const createDetails = (payload) => {
  return axiosInstance.post(`/core/admin-form/create-details/`, payload);
};

// GET
export const getLitigationsById = (id) => {
  return axiosInstance.get(
    `/core/admin-form/get-update-admin-litigations/${id}/`
  );
};

export const getBankruptcyById = (id) => {
  return axiosInstance.get(`/core/admin-form/get-update-bankruptcy/${id}/`);
};

export const getMWBEById = (id) => {
  return axiosInstance.get(`/core/admin-form/get-update-mwbe/${id}/`);
};

export const getNCRecordsById = (id) => {
  return axiosInstance.get(`/core/admin-form/get-update-nc-records/${id}/`);
};

export const getNYCLicenceById = (id) => {
  return axiosInstance.get(`/core/admin-form/get-update-nycl/${id}/`);
};

export const getOshaVoilationsById = (id) => {
  return axiosInstance.get(`/core/admin-form/osha-voilations/${id}/`);
};

export const getOtherLicenceById = (id) => {
  return axiosInstance.get(`/core/admin-form/get-update-other-licence/${id}/`);
};

export const getTwlRecordsById = (id) => {
  return axiosInstance.get(`/core/admin-form/get-update-twl-records/${id}/`);
};

export const getUccFilingsById = (id) => {
  return axiosInstance.get(`/core/admin-form/get-update-ucc-filings/${id}/`);
};

export const getUccLiensById = (id) => {
  return axiosInstance.get(`/core/admin-form/get-update-ucc-liens/${id}/`);
};

export const getLiensById = (id) => {
  return axiosInstance.get(
    `/core/admin-form/get-ownerliens-bankruptcy-judgements/${id}/`
  );
};

// UPDATE
export const updateLitigations = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/get-update-admin-litigations/${id}/`,
    payload
  );
};

export const updateBankruptcy = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/get-update-bankruptcy/${id}/`,
    payload
  );
};

export const updateMWBE = (id, payload) => {
  return axiosInstance.put(`/core/admin-form/get-update-mwbe/${id}/`, payload);
};

export const updateNCRecords = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/get-update-nc-records/${id}/`,
    payload
  );
};

export const updateNYCLicence = (id, payload) => {
  return axiosInstance.put(`/core/admin-form/get-update-nycl/${id}/`, payload);
};

export const updateOshaVoilations = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/get-update-osha-voilations/${id}/`,
    payload
  );
};

export const updateOtherLicence = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/get-update-other-licence/${id}/`,
    payload
  );
};

export const updateTwlRecords = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/get-update-twl-records/${id}/`,
    payload
  );
};

export const updateUccFilings = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/get-update-ucc-filings/${id}/`,
    payload
  );
};

export const updateUccLiens = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/get-update-ucc-liens/${id}/`,
    payload
  );
};

export const updateLiens = (id, payload) => {
  return axiosInstance.put(
    `/core/admin-form/update-ownerliens-bankruptcy-judgements/${id}/`,
    payload
  );
};

export const updateDetails = (id, payload) => {
  return axiosInstance.put(`/core/admin-form/update-details/${id}/`, payload);
};

// DELETE
export const deleteLitigations = (payload) => {
  return axiosInstance.delete(
    `/core/admin-form/delete-admin-litigations/${payload}/`
  );
};

export const deleteBankruptcy = (payload) => {
  return axiosInstance.delete(`/core/admin-form/delete-bankruptcy/${payload}/`);
};

export const deleteMWBE = (payload) => {
  return axiosInstance.delete(`/core/admin-form/delete-mwbe/${payload}/`);
};

export const deleteNCRecords = (payload) => {
  return axiosInstance.delete(`/core/admin-form/delete-nc-records/${payload}/`);
};

export const deleteNYCLicence = (payload) => {
  return axiosInstance.delete(`/core/admin-form/delete-nycl/${payload}/`);
};

export const deleteOshaVoilations = (payload) => {
  return axiosInstance.delete(
    `/core/admin-form/delete-osha-voilations/${payload}/`
  );
};

export const deleteOtherLicence = (payload) => {
  return axiosInstance.delete(
    `/core/admin-form/delete-other-licence/${payload}/`
  );
};

export const deleteTwlRecords = (payload) => {
  return axiosInstance.delete(
    `/core/admin-form/delete-twl-records/${payload}/`
  );
};

export const deleteUccFilings = (payload) => {
  return axiosInstance.delete(
    `/core/admin-form/delete-ucc-filings/${payload}/`
  );
};

export const deleteUccLiens = (payload) => {
  return axiosInstance.delete(`/core/admin-form/delete-ucc-liens/${payload}/`);
};

export const deleteLiens = (payload) => {
  return axiosInstance.delete(
    `/core/admin-form/delete-ownerliens-bankruptcy-judgements/${payload}/`
  );
};

// GET ALL
export const getLitigations = (payload) => {
  return axiosInstance.get(
    `/core/admin-form/list-admin-litigations/${payload.applicationId}/${payload.ownerId}/`
  );
};

export const getBankruptcy = (payload) => {
  return axiosInstance.get(`/core/admin-form/list-bankruptcy/${payload}/`);
};

export const getMWBE = (payload) => {
  return axiosInstance.get(`/core/admin-form/list-mwbe/${payload}/`);
};

export const getNCRecords = (id, payload) => {
  return axiosInstance.get(
    `/core/admin-form/list-nc-records/${id}/${payload?.filed_as}/${payload?.record_type}/`
  );
};

export const getNYCLicence = (payload) => {
  return axiosInstance.get(`/core/admin-form/list-nycl/${payload}/`);
};

export const getOshaVoilations = (payload) => {
  return axiosInstance.get(`/core/admin-form/list-osha-voilations/${payload}/`);
};

export const getOtherLicence = (payload) => {
  return axiosInstance.get(`/core/admin-form/list-other-licence/${payload}/`);
};

export const getTwlRecords = (payload) => {
  return axiosInstance.get(`/core/admin-form/list-twl-records/${payload}/`);
};

export const getUccFilings = (payload) => {
  return axiosInstance.get(`/core/admin-form/list-ucc-filings/${payload}/`);
};

export const getUccLiens = (payload) => {
  return axiosInstance.get(
    `/core/admin-form/list-ucc-liens/${payload.applicationId}/${payload.ownerId}/`
  );
};

export const getLiens = (payload) => {
  return axiosInstance.get(
    `/core/admin-form/get-ownerliens-bankruptcy-judgements/${payload.id}/${payload.detailsType}/${payload.ownership}/`
  );
};

export const getDetails = (payload) => {
  return axiosInstance.get(`/core/admin-form/get-details/${payload}/`);
};

export const updateApplicationNoRecords = (id, payload) => {
  return axiosInstance.put(
    `core/admin-form/update-application-with-no-records/${id}/`,
    payload
  );
};
// Download
//dev-prequalification-admin.i4strategies.com/api/core/admin-form/get-application-no-records/372/
export const getApplicationNoRecords = (id) => {
  return axiosInstance.get(
    `/core/admin-form/get-application-no-records/${id}/`
  );
};
export const downloadGeneratedReport = (payload) => {
  return axiosInstance.get(
    `/core/download-vendor-profile-admin/?id=${payload}`,
    {
      responseType: "blob",
    }
  );
};

export const updateUserAddress = (userId, payload) => {
  return axiosInstance.put(
    `/core/admin-form/update-user-address/${userId}/`,
    payload
  );
};

export const updateFinancialRatios = (payload) => {
  return axiosInstance.post(`core/admin-form/fr/`, payload);
};
export const getFinancialRatios = (applicationId) => {
  return axiosInstance.get(`core/admin-form/fr/?application=${applicationId}`);
};

export const createEntitiesApi = (payload) => {
  return axiosInstance.post(`core/admin-form/related-entities/`, payload);
};

export const deleteEntitieApi = (id) => {
  return axiosInstance.delete(`core/admin-form/delete-related-entities/${id}/`);
};

export const getEntitiesApi = (application_id) => {
  return axiosInstance.get(
    `core/admin-form/related-entities/?application=${application_id}`
  );
};

export const updateEntitieApi = (payload) => {
  return axiosInstance.patch(`core/admin-form/related-entities/`, payload);
};
