import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import "./AdminNavigationItems.css";
import {
  adminToolbarPermissions,
  USER,
} from "../../../../constants/defaultKeys";
import { getItem } from "../../../../helpers/localStorage";

const AdminNavigationItems = ({ navUrl }) => {
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState("dashboard");
  const userDetails = JSON.parse(getItem(USER));

  useEffect(() => {
    let key = navUrl.split("/")[2];
    setSelectedKey(key);
  }, [navUrl]);

  function handleClick(e) {
    setSelectedKey(e.key);
    if (e.key === "instructions") {
      window.open("/instructions", "_blank");
    } else {
      history.push(`/admin/${e.key}`);
    }
  }
  // function openInstructions() {
  //   let win = window.open("/instructions", "_blank");
  //   win.focus();
  // }

  return (
    <Fragment>
      <Menu
        onClick={(e) => handleClick(e)}
        selectedKeys={[selectedKey]}
        mode="horizontal"
        className="admin-menu-items"
      >
        <Menu.Item
          key="dashboard"
          // hidden={adminToolbarPermissions.dashboard}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="pqq"
          // hidden={adminToolbarPermissions.pqq}
        >
          PQQ
        </Menu.Item>
        <Menu.Item key="users" hidden={userDetails?.is_client_admin}>
          Users
        </Menu.Item>
        <Menu.Item key="subscriptions" hidden={userDetails?.is_client_admin}>
          Payments
        </Menu.Item>
        <Menu.Item
          key="vendors"
          // hidden={adminToolbarPermissions.instructions}
        >
          Vendors
        </Menu.Item>
        <Menu.Item
          key="instructions"
          // hidden={adminToolbarPermissions.instructions}
        >
          Help
        </Menu.Item>
      </Menu>
    </Fragment>
  );
};

export default AdminNavigationItems;
