import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
} from "antd";
import Liens from "./Liens/Liens";
import UCCLiens from "./UCCLiens/UCCLiens";
import Litigation from "./Litigation/Litigation";
import { useQuery } from "../../../../helpers/utility";
import { getOwnershipDetails } from "../../../../services/vendorProfile.services";
import { updateUserAddress } from "../../../../services/adminForm.services";

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
const BackgroundInfo = () => {
  const query = useQuery();
  const [form] = Form.useForm();
  let id = query.get("vendorId");
  const [owners, setOwners] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [updateAddressLoading, setUpdateddressLoading] = useState(false);
  useEffect(() => {
    if (id) {
      setLoading(true);
      getOwnershipDetails(id)
        .then((response) => {
          setOwners(response.data.data);
          setSelectedOwner(response.data.data?.[0]?.id);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          message.error(error.response?.data.message);
        });
    }
  }, [id]);

  function handleChange(value) {
    setSelectedOwner(value);
  }

  async function updateAddress(values) {
    try {
      setUpdateddressLoading(true);
      const res = await updateUserAddress(selectedOwner, values);
      const selectedUserDetails = owners.find(
        (eachUser) => eachUser.id == selectedOwner
      );
      selectedUserDetails.admin_address = res?.data?.admin_address;
      // this udpates the owners state with new address with respect to owner id,
      //this is done inorder to prevent a new fecth request which would have got updated user address
      message.success("Address updated successfully");
    } catch (err) {
      console.error(err);
      message.error("Failed to updated error, please try again");
    } finally {
      setUpdateddressLoading(false);
      form.resetFields(["admin_address"]);
    }
  }
  return (
    <Fragment>
      {loading || !owners ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 16]}>
          <Col>
            <Select
              size="large"
              onChange={handleChange}
              style={{ minWidth: 200 }}
              placeholder="Choose Owner"
              defaultValue={selectedOwner && selectedOwner}
            >
              {owners?.map((owner) => (
                <Option value={owner?.id} key={owner?.id}>
                  {`${owner?.first_name} ${owner?.last_name}`}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Form onFinish={updateAddress} form={form}>
              <Form.Item
                name="admin_address"
                rules={[{ required: true, message: "Required!" }]}
              >
                <TextArea
                  key={selectedOwner}
                  defaultValue={
                    owners?.find((eachDoc) => eachDoc.id == selectedOwner)
                      ?.admin_address
                  }
                  autoSize={{ minRows: 2 }}
                  placeholder="Update address"
                  showCount
                  maxLength={500}
                ></TextArea>
              </Form.Item>
              <Button
                style={{
                  marginBottom: "10px",
                }}
                type="primary"
                loading={updateAddressLoading}
                disabled={updateAddressLoading}
                htmlType="submit"
              >
                Save
              </Button>
            </Form>
            <Collapse defaultActiveKey={["1", "2", "3"]}>
              <Panel header="Litigation" key="1">
                <Litigation selectedOwner={selectedOwner && selectedOwner} />
              </Panel>
              <Panel header="Liens" key="2">
                <Liens selectedOwner={selectedOwner && selectedOwner} />
              </Panel>
              <Panel header="UCCLiens" key="2">
                <UCCLiens selectedOwner={selectedOwner && selectedOwner} />
              </Panel>
            </Collapse>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default BackgroundInfo;
