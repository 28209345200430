import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Table, Space, message } from "antd";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import LiensModel from "./LiensModel";
import moment from "moment";
import { useQuery } from "../../../../../helpers/utility";
import {
  createLiens,
  deleteLiens,
  getLiens,
  updateLiens,
} from "../../../../../services/adminForm.services";

const dateFormat = "YYYY-MM-DD";

const JudgementsLiens = ({ selectedOwner }) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const query = useQuery();
  const applicationId = query.get("id");

  const columns = [
    {
      title: "Creditor",
      dataIndex: "creditor",
      key: "creditor",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      ellipsis: true,
      width: 100,
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Property",
      dataIndex: "property",
      key: "property",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Filing No",
      dataIndex: "filing_no",
      key: "filing_no",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Filing Date",
      dataIndex: "filing_date",
      key: "filing_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Release Date",
      dataIndex: "release_date",
      key: "release_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  useEffect(() => {
    fetchLiens();
  }, [selectedOwner, refresh]);

  function fetchLiens() {
    getLiens({
      id: applicationId,
      detailsType: "judgements",
      ownership: selectedOwner,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("get Judgment liens error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("get Judgment liens error");
        }
      });
  }

  function saveLiens(values) {
    let data = values;
    data.filing_date = moment(values.filing_date).format(dateFormat);
    data.release_date = moment(values.release_date).format(dateFormat);
    data.details_type = "judgements";
    data.application = applicationId;
    data.ownership = selectedOwner;

    createLiens(data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Saved Judgements Details");
        refreshPage();
      })
      .catch((error) => {
        console.error("save Judgment liens error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to save Judgement Details"
          );
        } else {
          message.error("save Judgment lines error");
        }
      });
  }

  function handleUpdateLiens(values) {
    let data = values;
    data.filing_date = moment(values.filing_date).format(dateFormat);
    data.release_date = moment(values.release_date).format(dateFormat);
    data.application = applicationId;
    data.details_type = "judgements";

    updateLiens(editTable.id, data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Updated Judgement Details");
        refreshPage();
      })
      .catch((error) => {
        console.error("save Judgment liens error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to update Judgement Details"
          );
        } else {
          message.error("save Judgment liens error");
        }
      });
  }

  function removeColumn(id) {
    deleteLiens(id)
      .then((res) => {
        message.success(res.data.message || "Deleted Judgement Details");
        fetchLiens(applicationId, "judgements");
      })
      .catch((error) => {
        console.error("delete Judgment liens error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to remove judgement details"
          );
        } else {
          message.error("delete Judgment liens error");
        }
      });
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Button
            onClick={() => openModal({ status: "create" })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Judgment Details
          </Button>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={data}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <LiensModel
          create={saveLiens}
          update={handleUpdateLiens}
          setVisible={setVisible}
          title="Judgment Liens"
          editTable={editTable}
        />
      )}
    </Fragment>
  );
};

export default JudgementsLiens;
