import React, { Fragment, useState, useContext, useEffect } from "react";
import { Form, Collapse, Button, Typography, message } from "antd";
import "./Litigation.css";
import {
  wideLabelLayout,
  tailLayout,
} from "../../../../config/formLayout.config";
import RadioButton from "../../../../blocks/RadioButton/RadioButton";
import CriminalCases from "./CriminalCases/CriminalCases";
import CivilCases from "./CivilCases/CivilCases";
import AdministrativeCases from "./AdministrativeCases/AdministrativeCases";
import {
  createLitigation,
  getCivil,
  getCriminalCases,
  getLitigations,
  getViolation,
} from "../../../../services/vendorProfile.services";
import { SelectedVendorDetailsContext } from "../../../../context/SelectedVendorContext";
import { VendorProfileFormDataContext } from "../../../../context/VendorProfileContext";
import RadioGroupWizz from "../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import { radioYesOrNo } from "../../../../config/radioGroupConstants";
import { profileCompletion } from "../../../../constants/defaultKeys";
const { Panel } = Collapse;
const { Paragraph } = Typography;

const criminalQuestions = [
  {
    id: 1,
    name: "Charged by Indictment",
    value: "charged_by_indictment",
    label:
      "(1) been formally charged, by indictment or information, with a felony or misdemeanor?",
  },
  {
    id: 2,
    name: "Convicted by State",
    value: "convicted_by_state",
    label:
      "(2) been convicted, after trial or by plea, of any felony under state or federal law?",
  },
  {
    id: 3,
    name: "Convicted Business Crime",
    value: "convicted_business_crime",
    label:
      "(3) been convicted, after trial or by plea, of any misdemeanor involving business-related crimes?",
  },
  {
    id: 4,
    name: "Under Investigation",
    value: "under_investigation",
    label:
      "(4) been under investigation involving any alleged violation of criminal law relating to business activities? Investigation includes an appearance before a grand jury, subpoenas, search warrants, questioning of employees by a public agency, whether or not the firm or individual is the subject or target of the investigation.",
  },
  {
    id: 5,
    name: "Non Prosecution Agreement",
    value: "non_prosecution_agreement",
    label:
      "(5) entered into a consent decree, non-prosecution agreement, or deferred prosecution agreement?",
  },
];

const civilQuestions = [
  {
    id: 1,
    name: "Insurance Defense",
    value: "insurance_defense",
    label:
      "(1) been a party in civil litigation or arbitration, excluding personal injury or property damage actions in which the applicant firm or Key Individual was provided a defense (without any reservation of rights) by an insurance carrier?",
  },
];

const administrativeQuestions = [
  {
    id: 1,
    name: "Fair Labor Violation",
    value: "fair_labor_violation",
    label:
      "(1) A violation of any federal, state or local labor law or regulation, including but not limited to prevailing wage rates and fair labor standards?",
  },
  {
    id: 2,
    name: "Environmental Violation",
    value: "environmental_violation",
    label:
      "(2) A violation of federal, state or local environmental protection laws or regulations?",
  },
  {
    id: 3,
    name: "Building Code Violation",
    value: "building_code_violation",
    label: "(3) A building code violation?",
  },
];

const Litigation = (props) => {
  const vendorId = props.vendorId;
  const [form] = Form.useForm();
  const [litigationData, setLitigationData] = useState([]);
  const [civilData, setCivilData] = useState([]);
  const [administrativeData, setAdministrativeData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const { vendorProfileFormData, setVendorProfileFormData } = useContext(
    VendorProfileFormDataContext
  );
  const { vendorDetails, setVendorDetails } = useContext(
    SelectedVendorDetailsContext
  );

  const initialFormValues = {
    building_code_violation: true,
    charged_by_indictment: true,
    convicted_business_crime: true,
    convicted_by_state: true,
    environmental_violation: true,
    fair_labor_violation: true,
    insurance_defense: true,
    non_prosecution_agreement: true,
    under_investigation: true,
  };

  const litigation = vendorProfileFormData.litigation;
  const showCriminalTable =
    litigation.charged_by_indictment ||
    litigation.convicted_by_state ||
    litigation.convicted_business_crime ||
    litigation.under_investigation ||
    litigation.non_prosecution_agreement;

  const showCivilTable = litigation.insurance_defense;

  const showAdministrativeTable =
    litigation.fair_labor_violation ||
    litigation.environmental_violation ||
    litigation.building_code_violation;

  useEffect(() => {
    if (vendorId) {
      getLitigations(vendorId)
        .then((response) => {
          const data = response.data.data;
          if (Object.keys(data).length === 0) {
            setFormFieldValues({
              ...initialFormValues,
              ...litigation,
            });
          } else {
            let formValues = {
              ...data,
              ...litigation,
            };
            setFormFieldValues(formValues);
          }
        })
        .catch((error) => console.error(error));
      fetchCriminalCases(vendorId);
      fetchCivilCases(vendorId);
      fetchViolationCases(vendorId);
    }
  }, [vendorId]);

  function fetchCriminalCases(vendorId) {
    getCriminalCases(vendorId)
      .then((response) => setLitigationData(response.data.data))
      .catch((error) => console.error(error));
  }

  function fetchCivilCases(vendorId) {
    getCivil(vendorId)
      .then((response) => setCivilData(response.data.data))
      .catch((error) => console.error(error));
  }

  function fetchViolationCases(vendorId) {
    getViolation(vendorId)
      .then((response) => setAdministrativeData(response.data.data))
      .catch((error) => console.error(error));
  }

  function setFormFieldValues(data) {
    setVendorProfileDataLitigation(data);
    form.setFieldsValue({ ...data });
  }

  function setVendorProfileDataLitigation(changedValues) {
    vendorProfileFormData.litigation = {
      ...vendorProfileFormData.litigation,
      ...changedValues,
    };
    setVendorProfileFormData({ ...vendorProfileFormData });
  }

  const onFinish = (values) => {
    const formData = {
      ...values,
      vendor_id: vendorId,
    };

    let shouldSave = true;
    if (showCriminalTable) {
      shouldSave = handleCriminalFormValidation();
      if (!shouldSave) return;
    }
    if (showCivilTable && civilData.length === 0) {
      shouldSave = false;
      message.error("Please add atleast one Civil");
      return;
    }
    if (showAdministrativeTable) {
      shouldSave = handleViolationFormValidation();
      if (!shouldSave) return;
    }

    if (shouldSave) {
      setDisabled(true);
      saveLitigation(formData);
    }
  };

  function handleCriminalFormValidation() {
    let shouldSave = true;
    let ids = litigationData.map((eachItem) => eachItem.crime_specified);

    criminalQuestions.map((eachQusn) => {
      if (litigation[eachQusn.value] && !ids.includes(eachQusn.id)) {
        shouldSave = false;
        message.error(
          `Please add Litigation for (${eachQusn.id}) "${eachQusn.name}"`
        );
      } else if (!litigation[eachQusn.value] && ids.includes(eachQusn.id)) {
        shouldSave = false;
        message.error(
          `Please select "Yes" for (${eachQusn.id}) "${eachQusn.name}" under Litigation`
        );
      }
    });
    return shouldSave;
  }

  function handleViolationFormValidation() {
    let shouldSave = true;
    let ids = administrativeData.map((eachItem) => eachItem.crime_specified);

    administrativeQuestions.map((eachQusn) => {
      if (litigation[eachQusn.value] && !ids.includes(eachQusn.id)) {
        shouldSave = false;
        message.error(
          `Please add Violation for (${eachQusn.id}) "${eachQusn.name}"`
        );
      } else if (!litigation[eachQusn.value] && ids.includes(eachQusn.id)) {
        shouldSave = false;
        message.error(
          `Please select "Yes" for (${eachQusn.id}) "${eachQusn.name}" under Violation`
        );
      }
    });
    return shouldSave;
  }

  function saveLitigation(requestBody) {
    createLitigation(requestBody)
      .then((response) => {
        setDisabled(false);
        if (!vendorDetails.litigation_status) {
          setVendorDetails({
            ...vendorDetails,
            litigation_status: true,
            profile_completion_status:
              vendorDetails.profile_completion_status +
              profileCompletion.litigation_status,
          });
        }
        message.success(response.data.message);
        props.onStepChange(props.currentStep + 1);
      })
      .catch((error) => {
        setDisabled(false);
        console.error(error);
        if (error && error.response) {
          if (error.response.status === 400) {
            message.error(
              "Something went wrong with the form being saved. Please contact administrator"
            );
          } else {
            message.error(
              error.response.data.message || error.response.message
            );
          }
        } else {
          message.error("Error creating Litigation");
        }
      });
  }

  const onFormValuesChange = () => {
    setVendorProfileDataLitigation(form.getFieldsValue());
  };

  const criminalItems = (
    <Fragment>
      {criminalQuestions.map((eachQusn) => (
        <Form.Item
          key={eachQusn.value}
          name={eachQusn.value}
          label={eachQusn.label}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {RadioGroupWizz({ data: radioYesOrNo })}
        </Form.Item>
      ))}
    </Fragment>
  );

  const civilItems = (
    <Fragment>
      {civilQuestions.map((eachQusn) => (
        <Form.Item
          key={eachQusn.value}
          name={eachQusn.value}
          label={eachQusn.label}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {RadioButton()}
        </Form.Item>
      ))}
    </Fragment>
  );

  const administrativeItems = (
    <Fragment>
      {administrativeQuestions.map((eachQusn) => (
        <Form.Item
          key={eachQusn.value}
          name={eachQusn.value}
          label={eachQusn.label}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {RadioButton()}
        </Form.Item>
      ))}
    </Fragment>
  );

  return (
    <Form
      name="litigation"
      className="form-litigation"
      labelAlign="left"
      colon={false}
      form={form}
      {...wideLabelLayout}
      onValuesChange={onFormValuesChange}
      onFinish={onFinish}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1", "2", "3"]}>
        <Panel header="Criminal" key="1">
          <Paragraph style={{ padding: "0px 30px" }}>
            Within the past seven years, has the applicant firm or any of its
            Key Individuals:
          </Paragraph>
          {criminalItems}
          {showCriminalTable && (
            <CriminalCases
              vendorId={vendorId}
              data={litigationData}
              fetchCriminalCases={fetchCriminalCases}
            />
          )}
        </Panel>
        <Panel header="Civil" key="2">
          <Paragraph style={{ padding: "0px 30px" }}>
            Within in the past five years, has the applicant firm or any of its
            Key Individuals:{" "}
          </Paragraph>
          {civilItems}
          {showCivilTable && (
            <CivilCases
              vendorId={vendorId}
              data={civilData}
              fetchCivilCases={fetchCivilCases}
            />
          )}
        </Panel>
        <Panel header="Administrative" key="3">
          <Paragraph style={{ padding: "0px 30px" }}>
            At present or within the past seven years, has the applicant firm or
            any of its Key Individuals committed or have pending against it:
          </Paragraph>
          {administrativeItems}
          {showAdministrativeTable && (
            <AdministrativeCases
              vendorId={vendorId}
              data={administrativeData}
              fetchViolationCases={fetchViolationCases}
            />
          )}
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Litigation;
