import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import RadioGroupWizz from "../../../../../blocks/RadioGroupWizz/RadioGroupWizz";
import { radioYesOrNo } from "../../../../../config/radioGroupConstants";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const NYCLicencesModel = ({ create, update, editTable, title, setVisible }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      let formValues = editTable;
      formValues.issued_date = moment(editTable.issued_date);
      formValues.expiration_date = moment(editTable.expiration_date);
      form.setFieldsValue(formValues);
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    // refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      update(values);
    } else {
      create(values);
    }
  };

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: "Please input type!",
              },
            ]}
          >
            <Input placeholder="Type" />
          </Form.Item>
          <Form.Item
            name="registered"
            label="Registered"
            rules={[
              {
                required: true,
                message: "Please input registered!",
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          <Form.Item
            name="issued_to"
            label="Issued To"
            rules={[
              {
                required: true,
                message: "Please input issued to!",
              },
            ]}
          >
            <Input placeholder="Issued To" />
          </Form.Item>
          <Form.Item
            name="issued_date"
            label="Issued Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              format={dateFormat}
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item
            name="contractor_id"
            label="Contractor Id"
            rules={[
              {
                required: true,
                message: "Please input contractor id!",
              },
            ]}
          >
            <Input placeholder="Contractor Id" />
          </Form.Item>
          <Form.Item
            name="is_active"
            label="Is Active"
            rules={[
              {
                required: true,
                message: "Please input is active!",
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          <Form.Item
            name="expiration_date"
            label="Expiration Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              format={dateFormat}
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item
            name="is_city_employee"
            label="Is City Employee"
            rules={[
              {
                required: true,
                message: "Please input is city employee!",
              },
            ]}
          >
            {RadioGroupWizz({ data: radioYesOrNo })}
          </Form.Item>
          <Form.Item
            name="business_name"
            label="Business Name"
            rules={[
              {
                required: true,
                message: "Please input business name!",
              },
            ]}
          >
            <Input placeholder="Business Name" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};
export default NYCLicencesModel;
