import React, { Fragment, useState, useRef } from "react";
import { Modal, Carousel, Button, Typography, Col, Row, Space } from "antd";
import { useHistory } from "react-router-dom";
import "./IntroPage.css";
import { ForwardFilled } from "@ant-design/icons";

const { Paragraph, Title } = Typography;

const IntroPage = ({ handleSkipTour, vendorId, vendorDetails }) => {
  const slider = useRef(null);
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState({
    next: false,
    prev: false,
  });

  const handleCancel = () => {
    handleSkipTour();
    setIsModalVisible(false);
  };

  const nextPage = () => {
    setIsLoading({ ...isLoading, next: true });

    setCurrentStep(currentStep + 1);
    setTimeout(() => {
      setIsLoading({ ...isLoading, next: false });
      slider.current.next();
    }, 200);
  };

  const previousPage = () => {
    setIsLoading({ ...isLoading, prev: true });

    setCurrentStep(currentStep - 1);
    setTimeout(() => {
      setIsLoading({ ...isLoading, prev: false });
      slider.current.prev();
    }, 200);
  };

  const moveToApplication = () => {
    handleSkipTour();
    history.push(`/vendor/${vendorId}/vendor-profile/basic-details`);
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Modal
        title={
          <Row>
            <Col span={18}>
              <Title level={4}>
                Welcome to the Vendor Pre-Qualification process!
              </Title>
            </Col>
            <Col span={6} style={{ textAlign: "end" }}>
              <Button
                type="primary"
                icon={<ForwardFilled />}
                onClick={handleSkipTour}
              >
                Skip Intro
              </Button>
            </Col>
          </Row>
        }
        visible={isModalVisible}
        maskClosable={false}
        closable={false}
        width={1200}
        style={{ height: "900px" }}
        footer={
          <>
            <Button
              disabled={currentStep === 1}
              loading={isLoading.prev}
              onClick={previousPage}
            >
              Previous
            </Button>
            <Button
              disabled={currentStep === 5}
              loading={isLoading.next}
              onClick={nextPage}
            >
              Next
            </Button>
          </>
        }
        onCancel={handleCancel}
      >
        <Carousel
          className="intropage-corousel"
          effect="fade"
          ref={(ref) => (slider.current = ref)}
          dots={false}
        >
          <div>
            <Title level={4}>Summary</Title>
            <Paragraph>
              {vendorDetails?.customers?.[0]?.name} has implemented a process by
              which each vendor will have to fill out and submit a new
              Pre-Qualification Questionnaire (“PQQ”) to i4 Strategies. All
              contractors, subcontractors, professional service consultants, and
              suppliers seeking to do business with{" "}
              {vendorDetails?.customers?.[0]?.name} must complete and submit a
              Pre-Qualification Questionnaire.
            </Paragraph>

            <Paragraph>
              This portal provides a streamlined way for you to do so. As a
              Vendor, you will be required to complete the following steps, each
              of which will be included in the subsequent steps: Complete your
              i4 Vendor Profile. Submit payment to cover the initial PQQ
              process. Attach profile and certify your PQQ submission. Thank you
              for your timely submission and look forward to having your firm a
              part of the bid process.
            </Paragraph>
            {/* <Image
              src={i4VendorProfilePage}
              preview={false}
              alt="i4VendorProfilePage"
              className="logo"
            /> */}
          </div>
          <div>
            <Row>
              <Col span={12}>
                <Title level={4}>Complete your i4Profile</Title>
                <Paragraph>
                  For a list of required documentation needed to complete the
                  profile, please click
                  https://help.i4strategies.com/documentation-checklist
                </Paragraph>
              </Col>
              <Col span={12}></Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={12}>
                <Title level={4}>Submit Payment</Title>
                <Paragraph>
                  Vendors must provide payment to i4 Strategies in the amount of
                  $1250. This will cover the initial Pre-Qualification process.
                  An additional $750 + 2.9% transaction fee will be required
                  annually for a recertification. Pre-Qualification
                  Questionnaires will remain pending until payment is received
                  and processed.
                </Paragraph>
              </Col>
              <Col span={12}></Col>
            </Row>

            {/* <Image
              src={i4PqqPage}
              preview={false}
              alt="i4PqqPage"
              className="logo"
            /> */}
          </div>

          <div>
            <Row>
              <Col span={12}>
                <Title level={4}>Attach and Certify PQQ</Title>
                <Paragraph>
                  Following completion of the i4 Vendor Profile and submitting
                  payment, you may attach your profile to a PQQ submission to a
                  project and bid ID. Note: only Approvers are able to attach,
                  certify, and submit a PQQ submission.
                </Paragraph>
              </Col>
              <Col span={12}></Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={20} offset={2}>
                <Title level={4}>Help and Support</Title>
                <Paragraph>
                  Any questions or concerns can be directed to
                  info@i4strategies.com. Additionally, you may click the bulb
                  icon in the upper right corner for additional information on
                  how to use the portal.
                </Paragraph>
                <Space>
                  <Button type="primary" onClick={moveToApplication}>
                    Click to proceed to i4Profile
                  </Button>
                  <Button onClick={handleCancel}>Done</Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Carousel>
      </Modal>
    </Fragment>
  );
};

export default IntroPage;
