import React, { useEffect } from "react";
import { Typography, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./PrivacyPolicy.css";
import CardWizz from "../../blocks/Card/Card";

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Row className="privacyPolicy" gutter={[32, 32]}>
      <Col span={20} offset={2}>
        <span onClick={() => history.goBack()} className="privacy-policy-back">
          <ArrowLeftOutlined /> Back
        </span>
      </Col>
      <Col span={20} offset={2}>
        <CardWizz>
          <Title>Privacy Policy</Title>
          <Paragraph>
            This privacy statement addresses the collection, use and
            dissemination of the personal and entity data you provide to us as a
            result of your use of this Vendor Portal and i4strategies.com; and
            is hereinafter referred to as the "Site".
          </Paragraph>
          <Paragraph>
            As a potential vendor, this policy will allow for an informed
            decision as to how to utilize this Site, and to further explain how
            i4 Strategies protects your privacy and that of your entity.
          </Paragraph>

          <Title level={2}>Personal Information Collection and Use</Title>
          <Paragraph>
            i4 Strategies is the sole owner of all information collected on this
            Site, which is administered, operated and maintained by i4
            Strategies and its technology contractors. i4 Strategies will not
            sell, share, or rent the information you provide to us in any ways
            other than those disclosed in this statement.
          </Paragraph>
          <Paragraph>
            We collect and use your personal data for the following purposes:
          </Paragraph>
          <ol>
            <li>
              To create and maintain a database of potential vendors for
              construction contracts within the greater New York metropolitan
              area
            </li>
            <li>
              To provide information necessary to evaluate whether a particular
              vendor qualifies for a specific bid package specified by a
              contracted entity.
            </li>
            <li>
              To receive and process questions and conduct due diligence vendor
              reporting specified by a contracted entity.
            </li>
            <li>Provide, maintain and improve our Services;</li>
            <li>
              Send you an invitation or other correspondence related to becoming
              a subscriber to or an expert on the Services;
            </li>
            <li>
              Provide and deliver products, reports, and services about the
              information you provide to a contracted entity.
            </li>
            <li>
              Send you technical notices, updates, security alerts, and support
              and administrative messages;
            </li>
            <li>
              Monitor and analyze trends, usage and activities in connection
              with our services;
            </li>
            <li>
              Carry out any other purpose for which the information was
              collected.
            </li>
          </ol>

          <Title level={2}>Sharing Personal and Entity Information</Title>
          <Paragraph>
            Your personal information may be shared with the following:
          </Paragraph>
          <ol>
            <li>
              Employees, contractors and agents of i4 strategies and the
              specified contracted entity requesting the information.
            </li>
            <li>
              With contracted entities and other users in connection with your
              use of i4 Strategies services.
            </li>
            <li>
              With vendors, consultants and other service providers who need
              access to such information to carry out work or perform services
              on our behalf;
            </li>
            <li>
              In response to a request for information if we believe disclosure
              is in accordance with any applicable law, regulation or legal
              process, or as otherwise required by any applicable law, rule or
              regulation;
            </li>
            <li>
              If we believe your actions are inconsistent with our user
              agreements or policies, or to protect the rights, property and
              safety of i4 strategies or others;
            </li>
            <li>With your consent or at your direction.</li>
          </ol>
          <Paragraph>
            We also may share aggregated or de-identified information, which
            cannot reasonably be used to identify you.
          </Paragraph>
          <br />
          <Paragraph>Thank You</Paragraph>
          <Paragraph>i4Strategies</Paragraph>
        </CardWizz>
      </Col>
    </Row>
  );
};

export default PrivacyPolicy;
