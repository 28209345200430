import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Divider,
  Typography,
  Row,
  Col,
  Modal,
  Select,
} from "antd";
import {
  emailValidation,
  mobileNumberRegex,
} from "../../../../config/validation.config";
import { editUser } from "../../../../services/userProfile.services";
import {
  reSendWelcomeMail,
  resetPassword,
  sendRecertEmailApi,
} from "../../../../services/auth.services";

const { Option } = Select;
const { Text } = Typography;
const validateMessages = {
  required: "'${label}' is required",
};

const UserProfile = ({
  vendorsList,
  selectedUserData,
  setShowManageUserDrawer,
  fetchAllUsers,
}) => {
  const [form] = Form.useForm();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedVendorIds, setSelectedVendorIds] = useState([]);

  useEffect(() => {
    let data = {
      ...selectedUserData,
      contact_number: selectedUserData?.contact_number?.slice(
        3,
        selectedUserData?.contact_number.length
      ),
      vendor_id: selectedUserData.vendorsandusers__vendor__id,
    };
    setFormFieldValues(data);
  }, []);

  function setFormFieldValues(data) {
    form.setFieldsValue(data);
  }

  const onSubmit = () => {
    resetPassword({ username: selectedUserData.username })
      .then((res) => message.success(res.data.message))
      .catch((error) => {
        message.error(error.response.data.message);
        console.error(error);
      });
  };

  function onFinish(values) {
    let reqBody = {
      ...values,
      id: selectedUserData.id,
      is_i4_verified: selectedUserData.is_i4_verified,
    };
    editUser(reqBody)
      .then((res) => {
        message.success(res.data.message);
        fetchAllUsers();
        setShowManageUserDrawer(false);
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("manage user error");
        }
      });
  }
  function reSendWelcmeMail() {
    reSendWelcomeMail({ username: selectedUserData?.username })
      .then((res) => {
        message.success(res.data.message);
      })
      .catch((error) => console.log(error));
  }

  async function sendRecertEmail() {
    try {
      const res = await sendRecertEmailApi({
        vendor_ids: selectedVendorIds,
      });
      if (res) {
        message.success(res?.data?.message);
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.res?.data?.message);
    } finally {
      setIsModelOpen(false);
    }
  }
  return (
    <Fragment>
      <Form
        name="manage_user"
        form={form}
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={onFinish}
        colon={false}
        scrollToFirstError
      >
        <Form.Item name="username" label="User Email" rules={emailValidation}>
          <Input placeholder="User Email" />
        </Form.Item>
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: "First Name is required",
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Last Name is required",
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          name="contact_number"
          label="Mobile"
          rules={mobileNumberRegex}
        >
          <Input
            placeholder="10-digits without spaces"
            addonBefore={
              selectedUserData?.contact_number
                ? selectedUserData?.contact_number?.slice(
                    0,
                    selectedUserData?.contact_number.length - 10
                  )
                : "+1"
            }
          />
        </Form.Item>

        <Divider>
          <Text style={{ fontSize: "14px" }}> Activate/De-activate User</Text>{" "}
        </Divider>
        <Form.Item
          name="is_active"
          valuePropName="checked"
          label="Is User Active?"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Switch className="add-vendor" />
        </Form.Item>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Button
              type="secondary"
              onClick={() => setIsModelOpen(!isModelOpen)}
            >
              Send Recertification Reminder
            </Button>
          </Col>
          <Col span={24}>
            <Button type="secondary" onClick={reSendWelcmeMail}>
              Resend welcome email
            </Button>
          </Col>
          <Col span={24}>
            <Button
              type="secondary"
              onClick={onSubmit}
              style={{ marginBottom: "20px" }}
            >
              Email Reset Password Link
            </Button>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
      <Modal
        maskClosable={false}
        title="Send Recertification Reminder"
        visible={isModelOpen}
        onCancel={() => setIsModelOpen(false)}
        footer={[
          <Button type="primary" onClick={sendRecertEmail}>
            Send email
          </Button>,
        ]}
      >
        <Form
          name="Recertification"
          form={form}
          // scrollToFirstError
          // onFinish={sendRecertEmail}
        >
          <Form.Item name="vendor_name" label="Vendor name">
            <Select
              onChange={(value) => setSelectedVendorIds(value)}
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Vendor name"
              showSearch
              defaultValue={selectedVendorIds}
              optionFilterProp="vendor_id"
              filterOption={(input, option) =>
                option?.children?.toLowerCase()?.includes(input?.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA?.children
                  ?.toLowerCase()
                  ?.localeCompare(optionB.children?.toLowerCase())
              }
            >
              {selectedUserData?.vendors?.map((item) => (
                <Option key={item.vendor_id} value={item.vendor_id}>
                  {item.vendor_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};
export default UserProfile;
