import { useEffect, useState } from "react";
import { Row, Skeleton, Result, Button, Col, Layout } from "antd";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { verifyemail, resendVerifyEmail } from "../../services/auth.services";
import Logo from "../../Logo/Logo";

const { Header } = Layout;

const VerifyEmail = () => {
  const { token } = useParams();
  let search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    if (token) {
      verifyemail({ token: token })
        .then((response) => {
          setIsEmailVerified(true);
          setLoading(false);
        })
        .catch((error) => {
          setIsEmailVerified(false);

          //if token fails, this will resend new email
          resendVerifyEmail({ email: email })
            .then((response) => {
              setIsEmailSent(true);
              setLoading(false);
            })
            .catch((error) => {
              setIsEmailSent(false);
              setLoading(false);
            });
        });
    }
  }, [token]);

  return (
    <Layout>
      <Header>
        <Col xs={3} sm={3} md={6} lg={6} xl={6}>
          <div className="menu-logo">
            <Logo />
          </div>
        </Col>
        <Row type="flex" justify="center" align="middle">
          <Col span={8}>
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                {isEmailVerified ? (
                  <Result
                    status="success"
                    title="Your Email has been verified, please click below button to login"
                    extra={[
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          history.push("/")
                        localStorage.clear()
                      }
                      }
                      >
                        Login
                      </Button>,
                    ]}
                  />
                ) : (
                  <Result
                    title={
                      isEmailSent
                        ? "Your token has been expired, We've sent a new verification link to your email, Please verify"
                        : "Something went wrong"
                    }
                    extra={
                      !isEmailSent && (
                        <Button type="primary" key="console">
                          Contact Admin
                        </Button>
                      )
                    }
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Header>
    </Layout>
  );
};
export default VerifyEmail;
