import React, { useState, useEffect } from "react";
import { message, Skeleton } from "antd";
import { adminApplicationList } from "../../../services/applications.services";
import "./AdminApplicationsList.css";
import { applicationStatusValues } from "../../../constants/defaultKeys";
import AdminApplicationsList from "./AdminApplicationsList";
import moment from "moment";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const [listData, setListData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDates, setSelectedDates] = useState({
    start_date: moment().subtract(90, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [refresh, setRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    fetchAdminApplicationList({
      ...selectedDates,
      status: applicationStatusValues
        .map((eachStatus) => eachStatus.value)
        .slice(0, 8),
    });
  }, [refresh]);

  function fetchAdminApplicationList(queryParams = selectedDates) {
    adminApplicationList(queryParams)
      .then((response) => {
        setListData(response?.data);
        setIsLoading(false);
      })
      .catch((error) => message.error(error.response.data.message));
  }

  return isLoading && !listData ? (
    <Skeleton loading={isLoading} active />
  ) : (
    <AdminApplicationsList
      isLoading={isLoading}
      listData={listData}
      applicationStatusValues={applicationStatusValues}
      setIsLoading={setIsLoading}
      fetchAdminApplicationList={fetchAdminApplicationList}
      setSelectedDates={setSelectedDates}
      selectedDates={selectedDates}
      refresh={refreshPage}
    />
  );
};

export default AdminDashboard;
