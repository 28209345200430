import axiosInstance from "../config/axios/axios.config";

export const getVendorDetails = (vendorId) => {
  return axiosInstance.get(
    `/accounts/get-vendor-details/?vendor_id=${vendorId}`
  );
};

export const getBasicDetails = (vendorId) => {
  return axiosInstance.get(`/core/get-basic-details/?vendor_id=${vendorId}`);
};

export const createBasicDetails = (payload) => {
  return axiosInstance.post(`/core/create-basic-details/`, payload);
};

export const updateBasicDetails = (payload) => {
  return axiosInstance.put("/core/update-basic-details/", payload);
};

//Insurance starts

export const createInsurance = (payload) => {
  return axiosInstance.post("/core/create-insurance/", payload);
};

export const getInsurance = (vendorId) => {
  return axiosInstance.get(`/core/get-insurance/?vendor_id=${vendorId}`);
};

export const workersInsurance = (payload) => {
  return axiosInstance.post("/core/create-workers-insurance/", payload);
};

export const getWorkersInsurance = (payload) => {
  return axiosInstance.get(`/core/get-workers-insurance/?vendor_id=${payload}`);
};

export const updateWorkersInsurance = (payload) => {
  return axiosInstance.put("/core/update-workers-insurance/", payload);
};

export const removeWorkersInsurance = (params) => {
  return axiosInstance.delete(
    `/core/delete-workers-insurance/${params.id}/${params.vendor_id}/`
  );
};

export const changeWorkersInsurance = (payload) => {
  return axiosInstance.post("/core/create-change-workers-insurance/", payload);
};

export const getChangeWorkersInsurance = (payload) => {
  return axiosInstance.get(
    `/core/get-change-workers-insurance/?vendor_id=${payload}`
  );
};

export const updateChangeWorkersInsurance = (payload) => {
  return axiosInstance.put("/core/update-change-workers-insurance/", payload);
};

export const removeChangeWorkersInsurance = (params) => {
  return axiosInstance.delete(
    `/core/delete-change-workers-insurance/${params.id}/${params.vendor_id}/`
  );
};

//Insurance ends

//Financial Reports

export const createFinancialReports = (payload) => {
  return axiosInstance.post("/core/create-financial-reports/", payload);
};

export const getFinancialReports = (payload) => {
  return axiosInstance.get(`/core/get-financial-reports/?vendor_id=${payload}`);
};

//Litigation Start

export const createLitigation = (payload) => {
  return axiosInstance.post("/core/create-litigation/", payload);
};

export const getLitigations = (payload) => {
  return axiosInstance.get(`/core/get-litigations/?vendor_id=${payload}`);
};

export const getCriminalCases = (vendorId) => {
  return axiosInstance.get(
    `/core/get-criminal-litigations/?vendor_id=${vendorId}`
  );
};

export const createCriminalCases = (payload) => {
  return axiosInstance.post("/core/create-criminal-litigation/", payload);
};

export const updateCriminalCases = (payload) => {
  return axiosInstance.put("/core/update-criminal-litigation/", payload);
};

export const removeCriminalCases = (params) => {
  return axiosInstance.delete(
    `/core/delete-criminal-litigation/${params.id}/${params.vendor_id}/`
  );
};

export const getCivil = (vendorId) => {
  return axiosInstance.get(`/core/get-civil-litigation/?vendor_id=${vendorId}`);
};

export const createCivil = (payload) => {
  return axiosInstance.post("/core/create-civil-litigation/", payload);
};

export const updateCivil = (payload) => {
  return axiosInstance.put("/core/update-civil-litigation/", payload);
};

export const removeCivil = (params) => {
  return axiosInstance.delete(
    `/core/delete-civil-litigation/${params.id}/${params.vendor_id}/`
  );
};

export const getViolation = (vendorId) => {
  return axiosInstance.get(
    `/core/get-administrative-voilation/?vendor_id=${vendorId}`
  );
};

export const createViolation = (payload) => {
  return axiosInstance.post("/core/create-administrative-voilation/", payload);
};

export const updateViolation = (payload) => {
  return axiosInstance.put("/core/update-administrative-voilation/", payload);
};

export const removeViolation = (params) => {
  return axiosInstance.delete(
    `/core/delete-administrative-voilation/${params.id}/${params.vendor_id}/`
  );
};

//Litigation End

// Bank Details

export const getBankDetails = (vendorId) => {
  return axiosInstance.get(`/core/get-bank-details/?vendor_id=${vendorId}`);
};

export const createBankDetails = (payload) => {
  return axiosInstance.post("/core/create-bank-details/", payload);
};

export const getSuretyContact = (vendorId) => {
  return axiosInstance.get(`/core/get-surety-contacts/?vendor_id=${vendorId}`);
};

export const createSuretyContact = (payload) => {
  return axiosInstance.post("/core/create-surety-contact/", payload);
};

export const updateSuretyContact = (payload) => {
  return axiosInstance.put("/core/update-surety-contact/", payload);
};

export const removeSuretyContact = (params) => {
  return axiosInstance.delete(
    `/core/delete-surety-contacts/${params.id}/${params.vendor_id}/`
  );
};

export const getBankLiens = (vendorId) => {
  return axiosInstance.get(`/core/get-bank-liens/?vendor_id=${vendorId}`);
};

export const createBankLiens = (payload) => {
  return axiosInstance.post("/core/create-bank-liens/", payload);
};

export const updateBankLiens = (payload) => {
  return axiosInstance.put("/core/update-bank-liens/", payload);
};

export const removeBankLiens = (params) => {
  return axiosInstance.delete(
    `/core/delete-bank-liens/${params.id}/${params.vendor_id}/`
  );
};

export const getLineOfCredit = (vendorId) => {
  return axiosInstance.get(`/core/get-line-of-credit/?vendor_id=${vendorId}`);
};

export const createLineOfCredit = (payload) => {
  return axiosInstance.post("/core/create-line-of-credit/", payload);
};

export const updateLineOfCredit = (payload) => {
  return axiosInstance.put("/core/update-line-of-credit/", payload);
};

export const removeLineOfCredit = (params) => {
  return axiosInstance.delete(
    `/core/delete-line-of-credit/${params.id}/${params.vendor_id}/`
  );
};

//Additional Details Start

export const createAdditionalDetails = (payload) => {
  return axiosInstance.post("/core/create-additional-details/", payload);
};

export const getAdditionalDetails = (payload) => {
  return axiosInstance.get(
    `/core/get-additional-details/?vendor_id=${payload}`
  );
};

export const createServiceLicense = (payload) => {
  return axiosInstance.post("/core/create-service-license/", payload);
};

export const getServiceLicense = (payload) => {
  return axiosInstance.get(`/core/get-service-license/?vendor_id=${payload}`);
};

export const updateServiceLicense = (payload) => {
  return axiosInstance.put(`/core/update-service-license/`, payload);
};

export const removeServiceLicense = (params) => {
  return axiosInstance.delete(
    `/core/delete-service-license/${params.id}/${params.vendor_id}/`
  );
};

export const createRevokedLicense = (payload) => {
  return axiosInstance.post("/core/create-revoked-license/", payload);
};

export const getRevokedLicense = (payload) => {
  return axiosInstance.get(`/core/get-revoked-license/?vendor_id=${payload}`);
};

export const updateRevokedLicense = (payload) => {
  return axiosInstance.put(`/core/update-revoked-license/`, payload);
};

export const removeRevokedLicense = (params) => {
  return axiosInstance.delete(
    `/core/delete-revoked-license/${params.id}/${params.vendor_id}/`
  );
};

export const createContracts = (payload) => {
  return axiosInstance.post("/core/create-contracts-in-progress/", payload);
};

export const getContracts = (payload) => {
  return axiosInstance.get(
    `/core/get-contracts-in-progress/?vendor_id=${payload}`
  );
};

export const updateContracts = (payload) => {
  return axiosInstance.put(`/core/update-contracts-in-progress/`, payload);
};

export const removeContracts = (params) => {
  return axiosInstance.delete(
    `/core/delete-contracts-in-progress/${params.id}/${params.vendor_id}/`
  );
};

export const createCertification = (payload) => {
  return axiosInstance.post("/core/create-certification/", payload);
};

export const getCertifications = (payload) => {
  return axiosInstance.get(`/core/get-certifications/?vendor_id=${payload}`);
};

export const updateCertification = (payload) => {
  return axiosInstance.put(`/core/update-certification/`, payload);
};

export const removeCertification = (params) => {
  return axiosInstance.delete(
    `/core/delete-certification/${params.id}/${params.vendor_id}/`
  );
};

//Additional Details End

//Experience Start

export const createExperience = (payload) => {
  return axiosInstance.post("/core/create-experience/", payload);
};

export const getExperience = (payload) => {
  return axiosInstance.get(`/core/get-experience/?vendor_id=${payload}`);
};

export const updateExperience = (payload) => {
  return axiosInstance.put(`/core/update-experience/`, payload);
};

export const createPastProjects = (payload) => {
  return axiosInstance.post("/core/create-past-project/", payload);
};

export const getPastProjects = (payload) => {
  return axiosInstance.get(`/core/get-past-project/?vendor_id=${payload}`);
};

export const updatePastProjects = (payload) => {
  return axiosInstance.put(`/core/update-past-project/`, payload);
};

export const removePastProjects = (params) => {
  return axiosInstance.delete(
    `/core/delete-past-projects/${params.id}/${params.vendor_id}/`
  );
};

export const createContractorReference = (payload) => {
  return axiosInstance.post("/core/create-contractor-reference/", payload);
};

export const getContractorReference = (payload) => {
  return axiosInstance.get(
    `/core/get-contractor-reference/?vendor_id=${payload}`
  );
};

export const updateContractorReference = (payload) => {
  return axiosInstance.put(`/core/update-contractor-reference/`, payload);
};

export const removeContractorReference = (params) => {
  return axiosInstance.delete(
    `/core/delete-contractor-references/${params.id}/${params.vendor_id}/`
  );
};

export const createSupplierReference = (payload) => {
  return axiosInstance.post("/core/create-supplier-references/", payload);
};

export const getSupplierReference = (payload) => {
  return axiosInstance.get(
    `/core/get-supplier-references/?vendor_id=${payload}`
  );
};

export const updateSupplierReference = (payload) => {
  return axiosInstance.put(`/core/update-supplier-references/`, payload);
};

export const removeSupplierReference = (params) => {
  return axiosInstance.delete(
    `/core/delete-supplier-references/${params.id}/${params.vendor_id}/`
  );
};

export const createTerminatedContracts = (payload) => {
  return axiosInstance.post("/core/create-terminated-contracts/", payload);
};

export const getTerminatedContracts = (payload) => {
  return axiosInstance.get(
    `/core/get-terminated-contracts/?vendor_id=${payload}`
  );
};

export const updateTerminatedContracts = (payload) => {
  return axiosInstance.put(`/core/update-terminated-contracts/`, payload);
};

export const removeTerminatedContracts = (params) => {
  return axiosInstance.delete(
    `/core/delete-terminated-contracts/${params.id}/${params.vendor_id}/`
  );
};

export const createLiquidatedDamages = (payload) => {
  return axiosInstance.post("/core/create-liquidated-damages/", payload);
};

export const getLiquidatedDamages = (payload) => {
  return axiosInstance.get(
    `/core/get-liquidated-damages/?vendor_id=${payload}`
  );
};

export const updateLiquidatedDamages = (payload) => {
  return axiosInstance.put(`/core/update-liquidated-damages/`, payload);
};

export const removeLiquidatedDamages = (params) => {
  return axiosInstance.delete(
    `/core/delete-liquidated-damages/${params.id}/${params.vendor_id}/`
  );
};

//Experience End

//Integrity start

export const createIntegrity = (payload) => {
  return axiosInstance.post("/core/create-integrity/", payload);
};

export const getIntegrity = (payload) => {
  return axiosInstance.get(`/core/get-integrity/?vendor_id=${payload}`);
};

export const createIntegrityMonitor = (payload) => {
  return axiosInstance.post("/core/create-integrity-monitor/", payload);
};

export const getIntegrityMonitor = (payload) => {
  return axiosInstance.get(`/core/get-integrity-monitor/?vendor_id=${payload}`);
};

export const updateIntegrityMonitor = (payload) => {
  return axiosInstance.put(`/core/update-integrity-monitor/`, payload);
};

export const removeIntegrityMonitor = (params) => {
  return axiosInstance.delete(
    `/core/delete-integrity-monitor/${params.id}/${params.vendor_id}/`
  );
};

export const createPrevDisQualification = (payload) => {
  return axiosInstance.post("/core/create-prev-disqualifications/", payload);
};

export const getPrevDisQualification = (payload) => {
  return axiosInstance.get(
    `/core/get-prev-disqualifications/?vendor_id=${payload}`
  );
};

export const updatePrevDisQualification = (payload) => {
  return axiosInstance.put(`/core/update-prev-disqualifications/`, payload);
};

export const removePrevDisQualification = (params) => {
  return axiosInstance.delete(
    `/core/delete-prev-disqualifications/${params.id}/${params.vendor_id}/`
  );
};

export const createDisqualifiedSubcontractors = (payload) => {
  return axiosInstance.post(
    "/core/create-disqualified-subcontractors/",
    payload
  );
};

export const getDisqualifiedSubcontractors = (payload) => {
  return axiosInstance.get(
    `/core/get-disqualified-subcontractors/?vendor_id=${payload}`
  );
};

export const updateDisqualifiedSubcontractors = (payload) => {
  return axiosInstance.put(
    `/core/update-disqualified-subcontractors/`,
    payload
  );
};

export const removeDisqualifiedSubcontractors = (params) => {
  return axiosInstance.delete(
    `/core/delete-disqualified-sub-contractor/${params.id}/${params.vendor_id}/`
  );
};

//Integrity End

// Safety

export const getSafetyRecord = (payload) => {
  return axiosInstance.get(`/core/get-safety-record/?vendor_id=${payload}`);
};

export const createSafetyRecord = (payload) => {
  return axiosInstance.post("/core/create-safety-record/", payload);
};

// Ownership

export const getOwnership = (vendorId) => {
  return axiosInstance.get(`/core/get-ownership/?vendor_id=${vendorId}`);
};

export const createOwnership = (payload) => {
  return axiosInstance.post("/core/create-ownership/", payload);
};

export const getOwnershipDetails = (vendorId) => {
  return axiosInstance.get(
    `/core/get-ownership-details/?vendor_id=${vendorId}`
  );
};

export const createOwnershipDetails = (payload) => {
  return axiosInstance.post("/core/create-ownership-details/", payload);
};

export const updateOwnershipDetails = (payload) => {
  return axiosInstance.put("/core/update-ownership-details/", payload);
};

export const deleteOwnershipDetails = (params) => {
  return axiosInstance.delete(
    `/core/delete-ownership-details/${params.id}/${params.vendor_id}/`
  );
};

// Tour
export const createSkipTour = (payload) => {
  return axiosInstance.post("/core/skip-tour/", payload);
};
