import axiosInstance from "../config/axios/axios.config";

export const signIn = (payload) => {
  return axiosInstance.post("/accounts/login/", payload);
};

export const resetPassword = (payload) => {
  return axiosInstance.post("/accounts/reset-password/", payload);
};

export const setPassword = (payload) => {
  return axiosInstance.post("/accounts/set-password/", payload);
};

export const setProfile = (payload) => {
  return axiosInstance.post("/accounts/set-profile/", payload);
};

export const verifyMobileNumber = (payload) => {
  return axiosInstance.post("/accounts/verify-mobile/", payload);
};
export const verifyPhoneNumberApi = (payload) => {
  return axiosInstance.post(`accounts/verify-phone-number/`, payload);
};
export const verifyOtp = (payload) => {
  return axiosInstance.post("/accounts/verify-otp/", payload);
};

export const resendOtp = (payload) => {
  return axiosInstance.post("/accounts/re-verify-mobile/", payload);
};
export const verifyemail = (payload) => {
  return axiosInstance.post("/accounts/verify-email/", payload);
};
export const resendVerifyEmail = (payload) => {
  return axiosInstance.post("/accounts/resend-verification-email/", payload);
};
export const reSendWelcomeMail = (payload) => {
  return axiosInstance.post("/accounts/resend-welcome-email/", payload);
};

export const sendRecertEmailApi = (payload) => {
  return axiosInstance.post(`core/admin-form/send-recert-emails/`, payload);
};
