import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import "./NavigationItems.css";

const NavigationItems = ({ navUrl, vendorId }) => {
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState("dashboard");

  useEffect(() => {
    let key = navUrl.split("/")[3];
    setSelectedKey(key);
  }, [navUrl]);

  function handleClick(e) {
    if (e.key === "vendor-profile") {
      history.push(`/vendor/${vendorId}/vendor-profile/basic-details`);
    } else if (e.key === "instructions") {
      window.open("/instructions", "_blank");
    } else {
      history.push(`/vendor/${vendorId}/${e.key}`);
    }
    setSelectedKey(e.key);
  }

  return (
    <Fragment>
      <Menu
        onClick={(e) => handleClick(e)}
        selectedKeys={[selectedKey]}
        mode="horizontal"
        className="menu-items"
      >
        <Menu.Item key="dashboard">Dashboard</Menu.Item>
        <Menu.Item key="vendor-profile">i4Profile</Menu.Item>
        <Menu.Item key="get-certified">Payment</Menu.Item>
        {/* <Menu.Item key="pqq">PQQ</Menu.Item> */}
        <Menu.Item key="instructions">Help</Menu.Item>
      </Menu>
    </Fragment>
  );
};

export default NavigationItems;
