import { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import {
  createUccFilings,
  updateUccFilings,
} from "../../../../../services/adminForm.services";
import moment from "moment";
import { useQuery } from "../../../../../helpers/utility";

const dateFormat = "YYYY-MM-DD";

const UCCFilingsModel = ({ title, setVisible, editTable, refresh }) => {
  const query = useQuery();
  const applicationId = query.get("id");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editTable?.formData)
      form.setFieldsValue({
        ...editTable?.formData,
        filing_date: moment(editTable.formData.filing_date),
      });
  }, [editTable]);

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    let data = values;
    data.filing_date = moment(values.filing_date).format(dateFormat);
    setLoading(true);
    let api =
      editTable.status == "update"
        ? updateUccFilings(editTable.formData.id, data)
        : createUccFilings({ ...values, application: applicationId });
    api
      .then((res) => {
        setLoading(false);
        setVisible(false);
        message.success(res.data.message || "Saved UCC Filings");
        refresh();
      })
      .catch((error) => {
        console.error("save ucc filings error", error);
        setLoading(false);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("save ucc filings error");
        }
      });
  };

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="debtor"
            label="Debtor"
            rules={[
              {
                required: true,
                message: "Please input your debtor!",
              },
            ]}
          >
            <Input placeholder="Debtor" />
          </Form.Item>
          <Form.Item
            name="secured_party"
            label="Secured Party"
            rules={[
              {
                required: true,
                message: "Please input your  secured party!",
              },
            ]}
          >
            <Input placeholder="Secured Party" />
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: "Please input your state!",
              },
            ]}
          >
            <Input placeholder="State" />
          </Form.Item>
          <Form.Item
            name="collateral"
            label="Collateral"
            rules={[
              {
                required: true,
                message: "Please input collateral!",
              },
            ]}
          >
            <Input.TextArea rows={3} placeholder="Please enter Collateral" />
          </Form.Item>
          <Form.Item
            name="filing_no"
            label="Filing No"
            rules={[
              {
                required: true,
                message: "Please input your filing no!",
              },
            ]}
          >
            <Input placeholder="Filing No" />
          </Form.Item>
          <Form.Item
            name="filing_date"
            label="Filing Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};
export default UCCFilingsModel;
