import React from "react";
import PropTypes from "prop-types";
import JavascriptTimeAgo from "javascript-time-ago";

// The desired locales.
import en from "javascript-time-ago/locale/en";
//import ru from "javascript-time-ago/locale/ru";

import ReactTimeAgo from "react-time-ago";

// let value = "2020-01-22T11:14:24.394945Z";
// Initialize the desired locales.
JavascriptTimeAgo.locale(en);
//JavascriptTimeAgo.locale(ru);

const TimeStamp = ({ value, timeStyle }) => {
  return value && <ReactTimeAgo timeStyle={timeStyle} date={value} />;
};

TimeStamp.propTypes = {
  value: PropTypes.instanceOf(Date),
  timeStyle: PropTypes.string,
};

export default TimeStamp;
