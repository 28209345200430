import React from "react";
import DrawerWizz from "../../../reusable/DrawerWizz";
import { Form, Input, Button, message, Select, Switch } from "antd";
import { emailValidation } from "../../../config/validation.config";
import { adminInviteUser } from "../../../services/userProfile.services";

const { Option } = Select;
const validateMessages = {
  required: "'${label}' is required",
};

const AddUserDrawer = ({
  setShowAddUserDrawer,
  fetchAllUsers,
  vendorsList,
}) => {
  const [form] = Form.useForm();

  function onFinish(values) {
    adminInviteUser(values)
      .then((res) => {
        message.success(res.data.message);
        fetchAllUsers();
        setShowAddUserDrawer(false);
      })
      .catch((error) => {
        console.error("error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("invite user error");
        }
      });
  }

  return (
    <DrawerWizz
      title="Invite User"
      handleCancel={() => setShowAddUserDrawer(false)}
      content={
        <Form
          name="invite_user"
          form={form}
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={onFinish}
          colon={false}
          initialValues={{ is_approver: false }}
          scrollToFirstError
        >
          <Form.Item
            name="vendor_id"
            label="Vendor"
            rules={[
              {
                required: true,
                message: "Vendor is required!",
              },
            ]}
          >
            <Select
              placeholder="Select Vendor"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {vendorsList.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="email" label="User Email" rules={emailValidation}>
            <Input placeholder="User Email" />
          </Form.Item>
          <Form.Item
            name="is_approver"
            label="Is Approver?"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Switch className="add-vendor" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Invite
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};

export default AddUserDrawer;
