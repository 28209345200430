import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import DrawerWizz from "../../../../reusable/DrawerWizz";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const MWBEModel = ({ create, update, editTable, title, setVisible }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      let formValues = editTable;
      formValues.certification_date = moment(editTable.certification_date);
      form.setFieldsValue(formValues);
    }
  }, []);
  const handleCancel = () => {
    setVisible(false);
    // refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      update(values);
    } else {
      create(values);
    }
  };

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="certifying_agency"
            label="Certifying Agency"
            rules={[
              {
                required: true,
                message: "Please input certifying agency!",
              },
            ]}
          >
            <Input placeholder="Certifying Agency" />
          </Form.Item>
          <Form.Item
            name="certification_type"
            label="Certification Type"
            rules={[
              {
                required: true,
                message: "Please input certification type!",
              },
            ]}
          >
            <Input placeholder="Certification Type" />
          </Form.Item>
          <Form.Item
            name="certification_date"
            label="Certification Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              format={dateFormat}
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};
export default MWBEModel;
