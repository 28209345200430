import React, { useState } from "react";
import { Button, Collapse, message, Result } from "antd";
import { useQuery } from "../../../../helpers/utility";
import { downloadGeneratedReport } from "../../../../services/adminForm.services";
import { FilePdfOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const GenerateReport = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const query = useQuery();
  const applicationId = query.get("id");

  function generateReport() {
    setIsDownloading(true);
    downloadGeneratedReport(applicationId)
      .then((res) => res.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `generated-report.pdf`;
        a.click();
        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.error("error downloding", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "error downloding"
          );
        } else {
          message.error("error downloding");
        }
      });
  }

  return (
    <Collapse defaultActiveKey={["1"]}>
      <Panel header="Generate Report" key="1">
        <Result
          title="Generate Report"
          subTitle="Please complete all the steps before generating report"
          extra={[
            <Button
              type="primary"
              size="large"
              loading={isDownloading}
              icon={<FilePdfOutlined />}
              onClick={generateReport}
            >
              Generate Report
            </Button>,
          ]}
        />
      </Panel>
    </Collapse>
  );
};

export default GenerateReport;
