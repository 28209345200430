import React, { Fragment } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { setProfile } from "../../services/auth.services";
import { useParams } from "react-router-dom";
import { TOKEN_KEY, USER } from "../../constants/defaultKeys";
import { setItem } from "../../helpers/localStorage";
import Logo from "../../assets/images/logoBlue.png";
import CardWizz from "../../blocks/Card/Card";

const SetupProfile = () => {
  const query = useParams();
  const history = useHistory();

  const onFinish = (values) => {
    const data = { ...values, token: query && query.token };
    setProfile(data)
      .then((response) => {
        setItem(TOKEN_KEY, response.data.data.token);
        setItem(USER, JSON.stringify(response.data.data.payload));
        message.success(response.data.message);
        history.push("/verification");
      })
      .catch((error) => {
        message.error(error.response.data.message);
        console.error(error);
      });
  };

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <CardWizz bordered={false}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              <img src={Logo} alt="check logo" height="52px" />
            </div>
            <Form
              name="login"
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input autoFocus={true} placeholder="First Name" size="large" />
              </Form.Item>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input placeholder="Last Name" size="large" />
              </Form.Item>
              <Form.Item
                name="new_password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message:
                      "Use 8 or more characters with a mix of letters, numbers & symbols.",
                    pattern: new RegExp(
                      "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                    ),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="New Password"
                  size="large"
                  type="password"
                />
              </Form.Item>
              <Form.Item
                name="new_password_confirm"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The new passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm Password"
                  size="large"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  className="login-form-button"
                >
                  <span>Submit</span>
                </Button>
              </Form.Item>
            </Form>
          </CardWizz>
        </Col>
      </Row>
    </Fragment>
  );
};
export default SetupProfile;
