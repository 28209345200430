import BasicDetails from "./BasicDetails/BasicDetails";
import FinancialReports from "./Financial/FinancialReports";
import BankDetails from "./Bank/BankDetails";
import Ownership from "./Ownership/Ownership";
import Litigation from "./Litigation/Litigation";
import AdditionalDetails from "./AdditionalDetails/AdditionalDetails";
import Experience from "./Experience/Experience";
import Integrity from "./Integrity/Integrity";
import Insurance from "./Insurance/Insurance";
import Safety from "./Safety/Safety";
import VendorDescription from "../VendorDescription/VendorDescription";
import ProfileCompleted from "./ProfileCompleted";
import PqqModal from "./PQQModal/PqqModal";

export const PROFILE_STEPS = [
  {
    title: "Basic Details",
    subTitle: null,
    status: "wait",
    description: null,
    component: BasicDetails,
    stepId: "basic_details_status",
    step_name: "basic-details",
  },
  {
    title: "Ownership",
    subTitle: null,
    status: "wait",
    description: null,
    component: Ownership,
    stepId: "ownership_status",
    step_name: "ownership",
    disable: false,
  },
  {
    title: "Financial Reports",
    subTitle: null,
    status: "wait",
    description: null,
    component: FinancialReports,
    stepId: "financial_status",
    step_name: "financial-reports",
    disable: false,
  },
  {
    title: "Bank Details",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: BankDetails,
    stepId: "bank_status",
    step_name: "bank-details",
    disable: false,
  },
  {
    title: "Litigation",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: Litigation,
    stepId: "litigation_status",
    step_name: "litigation",
    disable: false,
  },
  {
    title: "Additional Details",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: AdditionalDetails,
    stepId: "additional_status",
    step_name: "additional-details",
    disable: false,
  },
  {
    title: "Experience",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: Experience,
    stepId: "experience_status",
    step_name: "experience",
    disable: false,
  },
  {
    title: "Integrity",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: Integrity,
    stepId: "integrity_status",
    step_name: "integrity",
    disable: false,
  },
  {
    title: "Insurance",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: Insurance,
    stepId: "insurance_status",
    step_name: "insurance",
    disable: false,
  },
  {
    title: "Safety",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: Safety,
    stepId: "safety_status",
    step_name: "safety",
    disable: false,
  },
  {
    title: "Submit PQQ",
    subTitle: null,
    status: "wait",
    description: "Enter company details",
    component: PqqModal,
    stepId: "submit-pqq",
    step_name: "submit-pqq",
    disable: false,
  },
  {
    title: "Finish",
    subTitle: null,
    status: "wait",
    description: null,
    component: ProfileCompleted,
    stepId: "profile_completed_status",
    step_name: "profile-completed",
  },
];
