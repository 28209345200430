import { Typography, Row, Col, Progress, Statistic, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import "./WelcomeCard.css";
import CardWizz from "../../../blocks/Card/Card";

const { Title, Paragraph, Text } = Typography;

const WelcomeCard = ({
  vendorId,
  progressPercent,
  userDetails,
  isSubscribed,
}) => {
  const history = useHistory();
  const redirectToVendorProfile = () => {
    if (progressPercent < 100 || (progressPercent === 100 && isSubscribed)) {
      !isMobileOnly &&
        history.push(`/vendor/${vendorId}/vendor-profile/basic-details`);
    } else if (progressPercent === 100) {
      !isMobileOnly && history.push(`/vendor/${vendorId}/get-certified`);
    }
  };

  return (
    <CardWizz className="welcome-card" onClick={redirectToVendorProfile}>
      {progressPercent !== undefined ? (
        <Row justify="center" align="middle">
          <Col md={14} lg={14} xl={8} style={{ textAlign: "center" }}>
            <Progress
              width="75px"
              type="dashboard"
              strokeWidth={12}
              percent={(progressPercent && progressPercent) || 0}
              format={(percent) => <Statistic value={percent} suffix="%" />}
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
            />
            <Text style={{ fontSize: "13px" }}>Completed</Text>
          </Col>
          <Col
            md={{ span: 10, offset: 0 }}
            lg={{ span: 10, offset: 0 }}
            xl={{ span: 15, offset: 1 }}
          >
            <Title level={3}>Hello {userDetails?.first_name}</Title>
            <Paragraph style={{ fontSize: "12px" }}>
              {progressPercent < 100
                ? `Welcome to the Vendor Pre-Qualification process! Please click here to complete your vendor profile.`
                : isSubscribed
                ? `Great! You've completed your i4Profile. `
                : `Great! you've completed your i4Profile. Get subscription by clicking here.`}
            </Paragraph>
          </Col>
        </Row>
      ) : (
        <Skeleton active size="small" />
      )}
    </CardWizz>
  );
};

export default WelcomeCard;
