import { Fragment, useState, useEffect } from "react";
import { Typography, Button, Table, Space, message } from "antd";
import InsuranceModal from "./InsuranceModal";
import {
  getWorkersInsurance,
  removeWorkersInsurance,
} from "../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../reusable/ModalWizz";
import { WorkersInsuranceColumns } from "../../../PQQ/tableColumns";

const { Paragraph } = Typography;

const PastInsuranceCarriers = ({
  vendorId,
  workersInsuranceData,
  fetchWorkersInsurance,
}) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  const refreshPage = () => {
    fetchWorkersInsurance();
  };

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  const removeColumn = (id) => {
    removeWorkersInsurance({
      id: id,
      vendor_id: vendorId,
    })
      .then((response) => {
        refreshPage();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const columns = [
    ...WorkersInsuranceColumns,
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Paragraph className="mandatory-field-mark">
        Please provide General Liability, Excess Liability and Workers'
        Compensation insurance:
      </Paragraph>
      <Button
        type="secondary"
        style={{
          marginBottom: 16,
        }}
        onClick={() => openModal({ status: "create", id: null })}
      >
        Add Carrier
      </Button>
      <Table
        size="small"
        columns={columns}
        dataSource={workersInsuranceData && workersInsuranceData}
        pagination={false}
        scroll={{ x: 800 }}
      />
      {visible && (
        <InsuranceModal
          setVisible={setVisible}
          title="Past Insurance Carriers"
          value="past_insurance_carriers"
          vendorId={vendorId}
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
};

export default PastInsuranceCarriers;
