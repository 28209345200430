import React from "react";
import { Radio } from "antd";

const RadioGroupWizz = ({ data }) => {
  return (
    <Radio.Group>
      {data.map((eachItem, index) => (
        <Radio key={index} value={eachItem.value}>
          {eachItem.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RadioGroupWizz;
