import React, { Fragment, useEffect } from "react";
import { Form, Button, DatePicker, message, InputNumber } from "antd";
import {
  createLineOfCredit,
  updateLineOfCredit,
} from "../../../../services/vendorProfile.services";
import { numberValidation } from "../../../../config/validation.config";
import moment from "moment";
import DrawerWizz from "../../../../reusable/DrawerWizz";

const dateFormat = "YYYY-MM-DD";

const LineOfCreditModal = ({
  vendorId,
  title,
  setVisible,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable && editTable.status === "update") {
      form.setFieldsValue({
        ...editTable,
        expiration_date: moment(editTable.expiration_date),
      });
    }
  }, [editTable]);

  function handleCancel() {
    setVisible(false);
  }

  function onFinish(values) {
    let requestBody = {
      ...values,
      vendor_id: vendorId,
      expiration_date: moment(values.expiration_date).format(dateFormat),
    };

    if (editTable && editTable.status === "create") {
      saveLineOfCredits(requestBody);
    }
    if (editTable && editTable.status === "update") {
      requestBody = { ...requestBody, id: editTable.id };
      updateLineOfCreditsFunc(requestBody);
    }
  }

  function saveLineOfCredits(requestBody) {
    createLineOfCredit(requestBody)
      .then((res) => {
        message.success(res.data.message);
        setVisible(false);
        refresh();
      })
      .catch((error) => {
        console.error("create line of credit error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error creating line of credit");
        }
      });
  }

  function updateLineOfCreditsFunc(requestBody) {
    updateLineOfCredit(requestBody)
      .then((res) => {
        message.success(res.data.message);
        setVisible(false);
        refresh();
      })
      .catch((error) => {
        console.error("update line of credit error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error updating line of credit");
        }
      });
  }

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item name="amount" label="Amount" rules={numberValidation}>
              <InputNumber
                min={0}
                style={{ minWidth: "100px" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
            <Form.Item
              name="available_amount"
              label="Available Amount"
              rules={numberValidation}
            >
              <InputNumber
                min={0}
                placeholder="Available Amount"
                style={{ minWidth: "100px" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
            <Form.Item
              name="expiration_date"
              label="Exp. Date"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder="Date"
                size="large"
                style={{ minWidth: "100px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default LineOfCreditModal;
