import React, { Fragment, useState } from "react";
import {
  Form,
  Button,
  Input,
  Typography,
  Popover,
  message,
  Select,
} from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER,
  VENDORS,
} from "../../constants/defaultKeys";
import { setItem } from "../../helpers/localStorage";
import { useHistory } from "react-router-dom";
import * as config from "../../config/validation.config";
import { isMobileOnly } from "react-device-detect";
import {
  verifyOtp,
  verifyMobileNumber,
  verifyPhoneNumberApi,
} from "../../services/auth.services";
import { metaData } from "../../services/metaData.services";

const { Paragraph } = Typography;
const { Option } = Select;

const VerifyMobileNumber = ({ showMessage }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [verifyMobileNumberRes, setVerifyMobileNumberRes] = useState(null);
  const [contryCode, setCountryCode] = useState("+1");
  const [showOtpVerificationForm, setShowOtpVerificationForm] = useState(false);
  const countryCodeName = {
    "+1": "us",
    "+91": "in",
  };
  const onFinish = (values) => {
    verifyMobileNumber({ contact_number: contryCode + values.phone })
      .then((response) => {
        setVerifyMobileNumberRes(response.data);
        if (response.status === 221) {
          message.info(response.data.message);
        } else {
          message.success("OTP sent successfully");
          form.resetFields();
          setShowOtpVerificationForm(true);
        }
      })
      .catch((error) => {
        message.error(error.response.data.message);
        console.error(error);
      });
  };

  async function validatePhoneNumber(values) {
    const data = {
      country_code: countryCodeName[contryCode],
      phone_number: contryCode + values.phone,
    };
    try {
      const res = await verifyPhoneNumberApi(data);
      // message.success("OTP sent successfully");
      res?.data?.is_valid_phone_number && onFinish(values);
    } catch (error) {
      message.error(error.response.data.message);
      console.log(error);
    }
  }
  const onVerifyOtp = (values) => {
    const data = { ...values, token: verifyMobileNumberRes.token };
    verifyOtp(data)
      .then((response) => {
        const responseData = response.data.payload;
        setItem(ACCESS_TOKEN, responseData.access_token);
        setItem(REFRESH_TOKEN, responseData.refresh_token);
        setItem(USER, JSON.stringify(responseData));
        showMessage && message.success("Mobile number updated successfully");
        form.resetFields();
        if (
          responseData.is_admin ||
          responseData.is_client_admin ||
          responseData.is_project_coordinator
        ) {
          history.push("/admin/dashboard");
        } else {
          metaData()
            .then((res) => {
              const vendors = res.data?.vendors || [];
              setItem(VENDORS, JSON.stringify({ vendors: vendors }));
              if (vendors.length) {
                history.push(`/vendor/${vendors[0].vendor_id}/dashboard`);
              } else {
                history.push(`/improperly-configured`);
              }
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => {
        form.resetFields();
        message.error(error.response.data.message);
        console.error(error);
      });
  };

  const resendCode = () => {
    form.setFieldsValue({ otp: "" });

    verifyMobileNumber({ token: verifyMobileNumberRes.token })
      .then((response) => {
        setVerifyMobileNumberRes(response.data);
        message.success("OTP sent successfully");
        form.resetFields();
        setShowOtpVerificationForm(true);
      })
      .catch((error) => {
        message.error(error.response.data.message);
        console.error(error);
      });
  };

  const addOnValue = (value) => {
    setCountryCode(value);
  };

  const changeNumber = () => {
    form.setFieldsValue({ otp: "" });
    setShowOtpVerificationForm(false);
  };

  const content = (
    <div style={{ width: "300px" }}>
      <Paragraph>
        We require a verified mobile number for Multi-Factor Authentication
        (MFA). You'll receive a verification code to the provided number every
        time you login.
      </Paragraph>
    </div>
  );

  const selectBefore = (
    <Select
      defaultValue={contryCode}
      className="select-before"
      onChange={addOnValue}
    >
      <Option value="+1">+1</Option>
      <Option value="+91">+91</Option>
    </Select>
  );

  return (
    <Fragment>
      {!showOtpVerificationForm && (
        <div>
          <Form
            name="verify_otp"
            onFinish={validatePhoneNumber}
            scrollToFirstError
          >
            <Form.Item name="phone" rules={config.mobileNumberRegex}>
              <Input
                id="clearInput"
                size="large"
                maxLength="10"
                placeholder="Mobile Number"
                addonBefore={selectBefore}
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className={isMobileOnly ? "mobileVerifyOtpBtn" : "verifyOtpBtn"}
              >
                Verify
              </Button>
            </Form.Item>
          </Form>

          <div style={{ color: "#4B4B4B" }}>
            <Popover content={content}>
              <span
                style={{
                  cursor: "pointer",
                  fontSize: "15px",
                }}
              >
                <QuestionCircleFilled /> Why verify a mobile number?
              </span>
            </Popover>
          </div>
        </div>
      )}
      {showOtpVerificationForm && (
        <div className="verifymobilenumber-otp-verification-form">
          <Paragraph>
            We sent verification code to{" "}
            {verifyMobileNumberRes && verifyMobileNumberRes.contact_number}
            <span>
              {" "}
              (
              <span onClick={changeNumber} className="footer-links">
                Change number
              </span>
              )
            </span>
          </Paragraph>
          <Form
            name="horizontal_login"
            layout="inline"
            onFinish={onVerifyOtp}
            style={{ marginBottom: "10px" }}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Please input your verification code!",
                },
              ]}
              help="Verification code is valid for 15 mins"
            >
              <Input
                autoFocus={true}
                size="large"
                placeholder="OTP"
                maxLength="6"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className={isMobileOnly ? "mobileVerifyOtpBtn" : "verifyOtpBtn"}
              >
                Verify OTP
              </Button>
            </Form.Item>
          </Form>
          <Paragraph>
            Didn't receive a code?{" "}
            <span className="footer-links" onClick={resendCode}>
              Resend Code
            </span>
          </Paragraph>
        </div>
      )}
    </Fragment>
  );
};
export default VerifyMobileNumber;
