import React from "react";
import { Row, Col, Typography } from "antd";
const { Title, Paragraph } = Typography;

const i4VendorProfile = () => {
  return (
    <Row type="flex">
      <Col spna={20} offset={1}>
        <Title level={3}>
          All Vendors are required to first complete their i4 Vendor Profile
        </Title>
        <Paragraph>
          For a list of required documentation needed to complete the profile,
          please click{" "}
          <a
            href="https://help.i4strategies.com/documentation-checklist"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </Paragraph>
      </Col>
    </Row>
  );
};
export default i4VendorProfile;
