import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import {
  createSuretyContact,
  updateSuretyContact,
} from "../../../../services/vendorProfile.services";
import DrawerWizz from "../../../../reusable/DrawerWizz";

const SuretyContactsModal = ({
  vendorId,
  title,
  setVisible,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable && editTable.status === "update") {
      form.setFieldsValue(editTable);
    }
  }, [editTable]);

  function handleCancel() {
    setVisible(false);
  }

  function onFinish(values) {
    let requestBody = {
      ...values,
      vendor_id: vendorId,
    };

    if (editTable && editTable.status === "create") {
      saveSuretyContact(requestBody);
    }
    if (editTable && editTable.status === "update") {
      requestBody = { ...requestBody, id: editTable.id };
      updateSuretyContactFunc(requestBody);
    }
  }

  function saveSuretyContact(requestBody) {
    createSuretyContact(requestBody)
      .then((res) => {
        message.success(res.data.message);
        setVisible(false);
        refresh();
      })
      .catch((error) => {
        console.error("Error creating surety contacts", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error creating surety contacts");
        }
      });
  }

  function updateSuretyContactFunc(requestBody) {
    updateSuretyContact(requestBody)
      .then((res) => {
        message.success(res.data.message);
        setVisible(false);
        refresh();
      })
      .catch((error) => {
        console.error("Error updating surety contacts", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error updating surety contacts");
        }
      });
  }

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please input your address!",
                },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default SuretyContactsModal;
