import React from "react";
import { Row, Col, Typography } from "antd";
const { Title, Paragraph } = Typography;

const InitialScreen = () => {
  return (
    <Row type="flex">
      <Col spna={20} offset={1}>
        <Title level={3}>
          Welcome to the Vendor Pre-Qualification process!
        </Title>
        <Paragraph>
          {"CUSTOMER"} has implemented a process by which each vendor will have
          to fill out and submit a new Pre-Qualification Questionnaire (“PQQ”)
          to i4 Strategies.
        </Paragraph>
        <Paragraph>
          All contractors, subcontractors, professional service consultants, and
          suppliers seeking to do business with {"CUSTOMER"} must complete and
          submit a Pre-Qualification Questionnaire. This portal provides a
          streamlined way for you to do so.
        </Paragraph>
        <Paragraph>
          As a Vendor, you will be required to complete the following steps,
          each of which will be included in the subsequent steps:
          <ol>
            <li> Complete your i4 Vendor Profile.</li>
            <li> Submit payment to cover the initial PQQ process.</li>
            <li> Attach profile and certify your PQQ submission.</li>
          </ol>
          Thank you for your timely submission and look forward to having your
          firm a part of the bid process.”
        </Paragraph>
      </Col>
    </Row>
  );
};

export default InitialScreen;
