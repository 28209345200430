import React, { Fragment, useState } from "react";
import { Table, Typography, Button, Row, Col, Space, message } from "antd";
import LineOfCreditModal from "./LineOfCreditModal";
import { removeLineOfCredit } from "../../../../services/vendorProfile.services";
import { confirmModal } from "../../../../reusable/ModalWizz";
import { LineOfCreditsColumns } from "../../../PQQ/tableColumns";

const { Paragraph } = Typography;

const LineOfCredits = ({ vendorId, lineOfCreditData, fetchLineOfCredit }) => {
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);

  // Line of credits table columns
  const columns = [
    ...LineOfCreditsColumns,
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const refreshPage = () => {
    fetchLineOfCredit();
  };

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  const removeColumn = (id) => {
    removeLineOfCredit({
      id: id,
      vendor_id: vendorId,
    })
      .then((response) => {
        refreshPage();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col span={20} offset={2}>
          <Paragraph className="mandatory-field-mark">
            {" "}
            Provide the following with respect to the applicant firm’s lines of
            credit:
          </Paragraph>
          <Button
            onClick={() => openModal({ status: "create" })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Line of Credit
          </Button>
        </Col>
        <Col span={20} offset={2}>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={lineOfCreditData}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <LineOfCreditModal
          setVisible={setVisible}
          title="Add Line of Credit"
          vendorId={vendorId}
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
};

export default LineOfCredits;
