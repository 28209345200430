import React, { useState } from "react";
import { Mentions } from "antd";

const { Option } = Mentions;

const MentionUsers = ({ value, placeholder, handleOnChange, usersList }) => {
  const [mentionedUsers, setMentionedUsers] = useState([]);

  function handleSelect(e) {
    setMentionedUsers([...mentionedUsers, e?.value]);
  }

  return (
    <Mentions
      autoFocus
      value={value}
      placeholder={placeholder}
      onSelect={handleSelect}
      onChange={(value) => handleOnChange(value)}
      placement="top"
      autoSize={{
        minRows: 3,
      }}
      style={{ textAlign: "start" }}
    >
      {usersList.map((eachUser) => (
        <Option key={eachUser.id} value={eachUser.email}>
          {eachUser.full_name} ({eachUser.email})
        </Option>
      ))}
    </Mentions>
  );
};

export default MentionUsers;
