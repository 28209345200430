import React, { Fragment } from "react";
import { Col, Row, Tabs } from "antd";
import { useHistory } from "react-router-dom";

const { TabPane } = Tabs;

const AdminMobileNavigationItems = () => {
  const history = useHistory();

  function onTabChange(key) {
    history.push(key);
  }

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          <Tabs
            tabPosition="bottom"
            className="admin-bottom-nav"
            size="small"
            // defaultActiveKey={String(this.props.selectedTabKey)}
            onChange={onTabChange}
            tabBarStyle={{
              background: "#0b2239",
              color: "#ffffff",
              fontWeight: "600",
              margin: "0px",
              borderTop: "1px solid #eeeeee",
            }}
          >
            <TabPane tab="Dashboard" key="admin/dashboard" />
            <TabPane tab="PQQ" key="admin/pqq" />
            <TabPane tab="Users" key="admin/users" />
          </Tabs>
        </Col>
      </Row>
    </Fragment>
  );
};
export default AdminMobileNavigationItems;
