import React, { Fragment } from "react";
import { Result } from "antd";

const EmptyVendors = () => {
  return (
    <Fragment>
      <Result title="You don't have any vendors assigned to you" />
    </Fragment>
  );
};

export default EmptyVendors;
