import React from "react";
import { Tabs } from "antd";
import UserProfile from "./UserProfile";
import VendorMapping from "./VendorMapping";

const { TabPane } = Tabs;

const ManageUserTabs = ({
  vendorsList,
  selectedUserData,
  setShowManageUserDrawer,
  fetchAllUsers,
  refresh,
}) => {
  return (
    <Tabs defaultActiveKey="user">
      <TabPane tab="User Profile" key="user">
        <UserProfile
          vendorsList={vendorsList}
          selectedUserData={selectedUserData}
          setShowManageUserDrawer={setShowManageUserDrawer}
          fetchAllUsers={fetchAllUsers}
        />
      </TabPane>
      <TabPane tab="Vendor Mapping" key="vendorMapping">
        <VendorMapping
          vendorsList={vendorsList}
          selectedUserData={selectedUserData}
          setShowManageUserDrawer={setShowManageUserDrawer}
          refresh={refresh}
        />
      </TabPane>
    </Tabs>
  );
};

export default ManageUserTabs;
