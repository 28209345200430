import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, message, InputNumber } from "antd";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import moment from "moment";
import {
  updateTwlRecords,
  createTwlRecords,
} from "../../../../../services/adminForm.services";
import { useQuery } from "../../../../../helpers/utility";
import { grossRevenueValidation } from "../../../../../config/validation.config";
const dateFormat = "YYYY-MM-DD";

const TaxWarrantLiensModel = ({ title, editTable, setVisible, refresh }) => {
  const [form] = Form.useForm();
  const query = useQuery();
  const applicationId = query.get("id");
  useEffect(() => {
    if (editTable?.formData)
      form.setFieldsValue({
        ...editTable?.formData,
        filing_date: moment(editTable.formData.filing_date),
        release_date: moment(editTable.formData.release_date),
      });
  }, [editTable]);

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    let data = values;
    data.filing_date = moment(values.filing_date).format(dateFormat);
    data.release_date = moment(values.release_date).format(dateFormat);
    let api =
      editTable.status == "update"
        ? updateTwlRecords(editTable.formData.id, data)
        : createTwlRecords({ ...values, application: applicationId });
    api
      .then((res) => {
        message.success(res.data.message || "Saved Twl Records");
        setVisible(false);
        refresh();
      })
      .catch((error) => {
        console.error("save twl record error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("save twl record error");
        }
      });
  };

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="file_type"
            label="File Type"
            rules={[
              {
                required: true,
                message: "Please input file type!",
              },
            ]}
          >
            <Input placeholder="File Type" />
          </Form.Item>
          <Form.Item
            name="filing_no"
            label="Filing No"
            rules={[
              {
                required: true,
                message: "Please input filing no!",
              },
            ]}
          >
            <Input placeholder="Filing No" />
          </Form.Item>
          <Form.Item
            name="filing_date"
            label="Filing Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
            rules={grossRevenueValidation}
          >
            <InputNumber
              min={0}
              style={{ width: 150 }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              placeholder="Amount"
            />
          </Form.Item>
          <Form.Item
            name="county"
            label="County"
            rules={[
              {
                required: true,
                message: "Please input county!",
              },
            ]}
          >
            <Input placeholder="County" />
          </Form.Item>
          <Form.Item
            name="release_date"
            label="Release Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};
export default TaxWarrantLiensModel;
