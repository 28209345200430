import React, { Fragment, useEffect } from "react";
import {
  Button,
  Col,
  Collapse,
  message,
  Row,
  Typography,
  Select,
  Form,
  Tag,
  InputNumber,
} from "antd";
import { useQuery } from "../../../../helpers/utility";
import { fullRowLayout, tailLayout } from "../../../../config/formLayout.config";
import { createDetails, getDetails } from "../../../../services/adminForm.services";

const { Panel } = Collapse;
const { Text, Title } = Typography;
const { Option } = Select;

const sections = [
  {
    section_label: "Corporate Information",
    flag_value: "corporate_flag",
    discovery_value: "corporate_discovery",
    count_value: "corporate_count",
  },
  {
    section_label: "Corporate Filing",
    flag_value: "corporate_filing_flag",
    discovery_value: "corporate_filing_discovery",
    count_value: "corporate_filing_count",
  },
  {
    section_label: "Ownership",
    flag_value: "ownership_flag",
    discovery_value: "ownership_discovery",
    count_value: "ownership_count",
  },
  {
    section_label: "Related Entities",
    flag_value: "related_entities_flag",
    discovery_value: "related_entities_discovery",
    count_value: "related_entities_count",
  },
  {
    section_label: "Key Engagement Contacts",
    flag_value: "key_contacts_flag",
    discovery_value: "key_contacts_discovery",
    count_value: "key_contacts_count",
  },
  {
    section_label: "Key Individual Information - Executive Management",
    flag_value: "executive_management_flag",
    discovery_value: "executive_management_discovery",
    count_value: "executive_management_count",
  },
  {
    section_label: "Financial Information",
    flag_value: "financial_information_flag",
    discovery_value: "financial_information_discovery",
    count_value: "financial_information_count",
  },
  {
    section_label: "Safety Records",
    flag_value: "safety_records_flag",
    discovery_value: "safety_records_discovery",
    count_value: "safety_records_count",
  },
  {
    section_label: "Sanctions, Suspensions, Debarment and other Misconduct ",
    flag_value: "ssdom_flag",
    discovery_value: "ssdom_discovery",
    count_value: "ssdom_count",
  },
  {
    section_label: "Liens/Judgments",
    flag_value: "lj_flag",
    discovery_value: "lj_discovery",
    count_value: "lj_count",
  },
  {
    section_label: "Tax Warranty/Liens",
    flag_value: "twl_flag",
    discovery_value: "twl_discovery",
    count_value: "twl_count",
  },
  {
    section_label: "UCC Filings",
    flag_value: "ucc_filings_flag",
    discovery_value: "ucc_filings_discovery",
    count_value: "ucc_filings_count",
  },
  {
    section_label: "OSHA Violations",
    flag_value: "osha_voilations_flag",
    discovery_value: "osha_voilations_discovery",
    count_value: "osha_voilations_count",
  },
  {
    section_label: "Notable Litigation",
    flag_value: "notable_litigation_flag",
    discovery_value: "notable_litigation_discovery",
    count_value: "notable_litigation_count",
  },
  {
    section_label: "Politically Exposed Persons",
    flag_value: "pep_flag",
    discovery_value: "pep_discovery",
    count_value: "pep_count",
  },
  {
    section_label: "Bankruptcy",
    flag_value: "bankruptcy_flag",
    discovery_value: "bankruptcy_discovery",
    count_value: "bankruptcy_count",
  },
  {
    section_label: "Criminal Records/Activities/Arrests/Warrants",
    flag_value: "criminal_records_flag",
    discovery_value: "criminal_records",
    count_value: "criminal_records_count",
  },
  {
    section_label: "Negative Media",
    flag_value: "nm_records_flag",
    discovery_value: "nm_records",
    count_value: "nm_records_count",
  },
];

const discoveries = [
  {
    label: "Information Verified",
    value: "Information verified",
  },
  {
    label: "Information Not Verified",
    value: "Information not verified",
  },
  {
    label: "Information Identified",
    value: "Information identified",
  },
  {
    label: "No Information Identified",
    value: "No information identified",
  },
  {
    label: "Records Identified",
    value: "Records identified",
  },
];

const flags = [
  {
    label: "Green",
    value: "Green",
    color: "green",
  },
  {
    label: "Yellow",
    value: "Yellow",
    color: "yellow",
  },
  {
    label: "Red",
    value: "Red",
    color: "red",
  },
];

const SummaryOfFindings = () => {
  const [form] = Form.useForm();
  const query = useQuery();
  const applicationId = query.get("id");

  useEffect(() => {
    fetchDetails();
  }, []);

  function fetchDetails(id = applicationId) {
    getDetails(id)
      .then((res) => {
        let data = res.data[0];
        form.setFieldsValue(data);
      })
      .catch((error) => {
        console.error("get other violations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message || error.response.message || "get OSHA violations error"
          );
        } else {
          message.error("get other violations error");
        }
      });
  }

  function onFinish(values) {
    let data = values;
    data.application = applicationId;

    createDetails(data)
      .then((res) => {
        message.success("Successfully saved summary of findings");
      })
      .catch((error) => {
        console.error("create summary of findings error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("create summary of findings error");
        }
      });
  }

  function renderSummaryOfFindings() {
    return (
      <Fragment>
        <Row>
          <Col span={6}>
            <Title level={5}>Section</Title>{" "}
          </Col>
          <Col span={6}>
            <Title level={5}>Discovery</Title>{" "}
          </Col>
          <Col span={6}>
            <Title level={5}>Flag</Title>{" "}
          </Col>
          <Col span={6}>
            <Title level={5}>Count</Title>{" "}
          </Col>
        </Row>

        {sections.map((eachSection, index) => {
          return (
            <Row key={index}>
              <Col span={6}>
                <Text level={5}>{eachSection.section_label}</Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={eachSection.discovery_value}
                  rules={[
                    {
                      required: true,
                      message: "Please select a value!",
                    },
                  ]}
                >
                  <Select allowClear>
                    {discoveries.map((eachOption, optionIndex) => (
                      <Option value={eachOption.value} key={optionIndex}>
                        {eachOption.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={eachSection.flag_value}
                  rules={[
                    {
                      required: true,
                      message: "Please select a value!",
                    },
                  ]}
                >
                  <Select allowClear>
                    {flags.map((eachFlag, optionIndex) => (
                      <Option value={eachFlag.value} key={optionIndex}>
                        <Tag color={eachFlag.color}>{eachFlag.label}</Tag>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={eachSection.count_value}>
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
          );
        })}
      </Fragment>
    );
  }

  //   function getInitialValues() {
  //     let initialValues = {};
  //     sections.map((eachSection) => {
  //       initialValues = {
  //         ...initialValues,
  //         [eachSection.discovery_value]: "Information verified",
  //         [eachSection.flag_value]: <Text style={{ color: "green" }}>Green</Text>,
  //       };
  //     });
  //     return initialValues;
  //   }

  return (
    <Form
      name="Summary of Findings"
      form={form}
      className="form-summary-of-findings"
      onFinish={onFinish}
      colon={false}
      scrollToFirstError
      //   initialValues={getInitialValues()}
      {...fullRowLayout}
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Summary of Findings" key="1">
          {renderSummaryOfFindings()}
        </Panel>
      </Collapse>

      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          // loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SummaryOfFindings;
