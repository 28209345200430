import { Button, Collapse, Form, Input, InputNumber, message } from "antd";
import React, { useEffect } from "react";
import {
  narrowLabelLayout,
  tailLayout,
} from "../../../../config/formLayout.config";
import {
  getFinancialRatios,
  updateFinancialRatios,
} from "../../../../services/adminForm.services";
import { useQuery } from "../../../../helpers/utility";
const { Panel } = Collapse;

function FinancialRatios() {
  const [form] = Form.useForm();

  const query = useQuery();
  const applicationId = query.get("id");

  useEffect(() => {
    fetchFinancialRatios();
  }, []);

  function fetchFinancialRatios() {
    getFinancialRatios(applicationId)
      .then((res) => {
        let data = res.data;
        form.setFieldsValue(data);
      })
      .catch((error) => {
        console.error("get Financial ratios error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "get Financial ratios error"
          );
        } else {
          message.error("get Financial ratios error");
        }
      });
  }

  async function SaveFinancialRatios(value) {
    value = { ...value, application: applicationId };
    try {
      const res = await updateFinancialRatios(value);
      fetchFinancialRatios();
      message.success(res.data.message || "Saved Financial ratios");
    } catch (error) {
      console.error("save Financial ratios error", error);
      if (error && error.response) {
        message.error(
          error.response.data.message ||
            error.response.message ||
            "Failed to save Financial ratios"
        );
      } else {
        message.error("save Financial ratioserror");
      }
    }
  }

  return (
    <Form
      {...narrowLabelLayout}
      name="safety"
      form={form}
      className="form-safety"
      onFinish={SaveFinancialRatios}
      colon={false}
      scrollToFirstError
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Based on Latest Financial Audit Year" key="1">
          <Form.Item name="name_and_aff" label="Name and Affiliates">
            <Input placeholder="Name and Affiliates" />
          </Form.Item>
          <Form.Item name="current" label="Current Ratio">
            <InputNumber
              min={0}
              style={{ minWidth: "500px" }}
              placeholder="Current Ratio"
            />
          </Form.Item>
          <Form.Item name="quick" label="Quick Ratio">
            <InputNumber
              min={0}
              style={{ minWidth: "500px" }}
              placeholder="Quick Ratio"
            />
          </Form.Item>
          <Form.Item name="working_capital" label="Working Capital">
            <InputNumber
              min={0}
              style={{ minWidth: "500px" }}
              placeholder="Working Capital"
            />
          </Form.Item>
          <Form.Item name="debt_worth" label="Debt/Worth Ratio">
            <InputNumber
              min={0}
              style={{ minWidth: "500px" }}
              placeholder="Debt/Worth Ratio"
            />
          </Form.Item>
          <Form.Item name="gross_margin" label="Gross Margin Ratio">
            <InputNumber
              min={0}
              style={{ minWidth: "500px" }}
              placeholder="Gross Margin Ratio"
            />
          </Form.Item>
          <Form.Item name="net_profit_margin" label="Net Profit Margin Ratio">
            <InputNumber
              min={0}
              style={{ minWidth: "500px" }}
              placeholder="Net Profit Margin Ratio"
            />
          </Form.Item>
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          // loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default FinancialRatios;
