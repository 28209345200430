import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Space,
  message,
  DatePicker,
  Typography,
  Form,
  InputNumber,
  Divider,
  Collapse,
  Checkbox,
  Spin,
} from "antd";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import OSHAViolationsModel from "./OSHAViolationsModel";
import {
  createDetails,
  createOshaVoilations,
  deleteOshaVoilations,
  getApplicationNoRecords,
  getDetails,
  getOshaVoilations,
  updateApplicationNoRecords,
  updateOshaVoilations,
} from "../../../../../services/adminForm.services";
import moment from "moment";
import { useQuery } from "../../../../../helpers/utility";
import { tailLayout } from "../../../../../config/formLayout.config";

const { Paragraph } = Typography;
const { Panel } = Collapse;

const dateFormat = "YYYY-MM-DD";

const OSHAViolations = () => {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [data, setData] = useState([]);

  const query = useQuery();
  const applicationId = query.get("id");
  const [appNoRcd, setAppNoRcdData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkBox, setCheckBox] = useState({
    no_osha_records: appNoRcd?.no_osha_records,
    no_osha_300a_forms: appNoRcd?.no_osha_300a_forms,
    no_emr_letter: appNoRcd?.no_emr_letter,
    no_report_verified_by_nycirb: appNoRcd?.no_report_verified_by_nycirb,
  });
  const [checked, setChecked] = useState({
    key: [],
  });
  console.log(appNoRcd);
  const columns = [
    {
      title: "NR",
      dataIndex: "nr",
      key: "nr",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Open Date",
      dataIndex: "open_date",
      key: "open_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Inspection",
      dataIndex: "inspection",
      key: "inspection",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Safety or Health",
      dataIndex: "saftey_or_health",
      key: "saftey_or_health",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Emphasis",
      dataIndex: "emphasis",
      key: "emphasis",
      ellipsis: true,
      width: 100,
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Inspection Type",
      dataIndex: "insepction_type",
      key: "insepction_type",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Disposition",
      dataIndex: "disposition",
      key: "disposition",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Close Date",
      dataIndex: "close_date",
      key: "close_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ ...record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    getApplicationNoRecordsApi();
  }, [applicationId]);

  async function getApplicationNoRecordsApi() {
    try {
      const res = await getApplicationNoRecords(applicationId);
      setAppNoRcdData(res?.data?.data);
      // setCheckBox({
      //   ...checkBox,
      //   no_osha_records: res?.data?.data?.no_osha_records,
      // });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function updateApplicationApi(values) {
    try {
      setLoading(true);
      const res = await updateApplicationNoRecords(applicationId, {
        no_osha_records: values?.no_osha_records,
        no_osha_300a_forms: values?.no_osha_300a_forms,
        no_emr_letter: values?.no_emr_letter,
        no_report_verified_by_nycirb: values?.no_report_verified_by_nycirb,
      });
      message.success(res.data?.message);
    } catch (err) {
      console.log(err);
    } finally {
      getApplicationNoRecordsApi();
    }
  }
  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  useEffect(() => {
    fetchOshaVoilations();
    fetchDetails();
  }, []);

  function fetchDetails(id = applicationId) {
    getDetails(id)
      .then((res) => {
        let data = res.data[0];
        form.setFieldsValue({
          ...data,
          re_year1: moment().subtract(1, "year"),
          re_year2: moment().subtract(2, "year"),
          re_year3: moment().subtract(3, "year"),
        });
      })
      .catch((error) => {
        console.error("get OSHA violations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "get OSHA violations error"
          );
        } else {
          message.error("get OSHA violations error");
        }
      });
  }

  function fetchOshaVoilations(id = applicationId) {
    getOshaVoilations(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("get OshaVoilations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("get OshaVoilations error");
        }
      });
  }

  function saveOshaVoilations(values) {
    let data = values;
    data.open_date = moment(values.open_date).format(dateFormat);
    data.close_date = moment(values.close_date).format(dateFormat);
    data.application = applicationId;

    createOshaVoilations(data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Created Osha Voilations");
        fetchOshaVoilations(applicationId);
      })
      .catch((error) => {
        console.error("save OshaVoilations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to create Osha Voilations"
          );
        } else {
          message.error("save OshaVoilations error");
        }
      });
  }

  function handleUpdateOshaVoilations(values) {
    let data = values;
    data.open_date = moment(values.open_date).format(dateFormat);
    data.close_date = moment(values.close_date).format(dateFormat);
    data.application = applicationId;

    updateOshaVoilations(editTable.id, data)
      .then((res) => {
        setVisible(false);
        message.success(res.data.message || "Updated Osha Voilations");
        fetchOshaVoilations(applicationId);
      })
      .catch((error) => {
        console.error("save OshaVoilations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to update Osha Voilations"
          );
        } else {
          message.error("save OshaVoilations error");
        }
      });
  }

  function removeColumn(id) {
    deleteOshaVoilations(id)
      .then((res) => {
        message.success(res.data.message || "Removed Osha Voilations");
        fetchOshaVoilations(applicationId);
      })
      .catch((error) => {
        console.error("delete OshaVoilations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Failed to remove Osha Voilations"
          );
        } else {
          message.error("delete OshaVoilations error");
        }
      });
  }

  function renderEmr() {
    let formItems = [];
    for (let i = 1; i < 4; i++) {
      formItems.push(
        <Row key={i} type="flex" gutter={[8, 0]}>
          <Col span={8}>
            {i == 1 && <Paragraph className="header-label">Year</Paragraph>}
            <Form.Item name={`re_year${i}`} className="formitem-no-margin">
              <DatePicker picker="year" disabled allowClear={false} />
            </Form.Item>
          </Col>
          <Col span={16}>
            {i === 1 && <Paragraph className="header-label">EMR</Paragraph>}
            <Form.Item
              className="formitem-no-margin"
              name={`emr${i}`}
              // rules={[
              //   {
              //     required: true,
              //     message: "EMR is required",
              //   },
              // ]}
            >
              <InputNumber
                style={{ minWidth: 150 }}
                placeholder="EMR (Optional)"
                type="number"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return formItems;
  }

  function renderApplicantsFirm() {
    let formItems = [];
    for (let i = 1; i < 4; i++) {
      formItems.push(
        <Row
          key={i}
          gutter={[8, 0]}
          // style={
          //   appNoRcd?.no_osha_300a_forms && checked?.key?.includes("3")
          //     ? { cursor: "not-allowed" }
          //     : {}
          // }
        >
          <Col span={6}>
            {i == 1 && <Paragraph className="header-label">Year</Paragraph>}
            <Form.Item name={`re_year${i}`} className="formitem-no-margin">
              <DatePicker picker="year" disabled allowClear={false} />
            </Form.Item>
          </Col>
          <Col span={9}>
            {i == 1 && (
              <Paragraph className="header-label">
                Lost Time Incidence Rate
              </Paragraph>
            )}
            <Form.Item
              className="formitem-no-margin"
              name={`ltir${i}`}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                min={0}
                type="number"
                style={{ width: 250 }}
                placeholder="Lost Time Incidence Rate"
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            {i == 1 && (
              <Paragraph className="header-label">
                Recordable Incidence Rate
              </Paragraph>
            )}
            <Form.Item
              className="formitem-no-margin"
              name={`rir${i}`}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                min={0}
                type="number"
                style={{ width: 250 }}
                placeholder="Recordable Incidence Rate"
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return formItems;
  }

  function onFinish(values) {
    let data = values;
    data.re_year1 = moment(values.re_year1).format("YYYY");
    data.re_year2 = moment(values.re_year2).format("YYYY");
    data.re_year3 = moment(values.re_year3).format("YYYY");
    data.application = applicationId;

    createDetails(data)
      .then((res) => {
        message.success("Successfully saved OshaVoilations");
      })
      .catch((error) => {
        console.error("create OshaVoilations details error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("create OshaVoilations details error");
        }
      });
  }
  return (
    <Fragment>
      <Spin tip="Updating..." spinning={loading}>
        {/* <Col
          span={24}
          style={{ textAlign: "right", marginBottom: "10px" }}
        ></Col> */}
        <Form
          name="safety"
          form={form}
          className="form-safety"
          onFinish={onFinish}
          colon={false}
          scrollToFirstError
        >
          <Collapse
            activeKey={
              checkBox?.no_osha_records ? checked?.key : ["1", "2", "3"]
            }
            onChange={(e) => {
              setChecked({ key: e });
            }}
          >
            <Panel
              header="OSHA Violations"
              key="1"
              collapsible={appNoRcd?.no_osha_records ? "disabled" : ""}
              extra={
                <Checkbox
                  checked={appNoRcd?.no_osha_records}
                  onChange={(e) => {
                    setCheckBox({
                      ...checkBox,
                      no_osha_records: e.target.checked,
                    });
                    updateApplicationApi({ no_osha_records: e.target.checked });
                  }}
                  collapsible={appNoRcd?.no_osha_records ? "disabled" : ""}
                >
                  No Information Identified
                </Checkbox>
              }
            >
              {" "}
              <div
                style={
                  appNoRcd?.no_osha_records ? { pointerEvents: "none" } : {}
                }
              >
                <Row
                  style={
                    appNoRcd?.no_osha_records && checked?.key?.includes("1")
                      ? { cursor: "not-allowed" }
                      : {}
                  }
                >
                  <Col>
                    <Paragraph>Please add OSHA Violations:</Paragraph>
                    <Button
                      onClick={() => openModal({ status: "create" })}
                      type="secondary"
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      Add Details
                    </Button>
                    <Table
                      size="small"
                      columns={columns}
                      pagination={false}
                      dataSource={data}
                      scroll={{ x: 800 }}
                    />
                  </Col>
                </Row>
              </div>
            </Panel>
            <Panel
              header="Experience Modification Rate"
              key="2"
              // collapsible={appNoRcd?.no_osha_records ? "disabled" : ""}
            >
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Paragraph>
                    Indicate below the applicant firm's EMR for the most recent
                    three years:
                  </Paragraph>
                  {renderEmr()}
                </Col>

                <Col span={24}>
                  <Form.Item name="no_emr_letter">
                    <Checkbox
                      checked={appNoRcd?.no_emr_letter}
                      onChange={(e) => {
                        setCheckBox({
                          ...checkBox,
                          no_emr_letter: e.target.checked,
                        });
                        updateApplicationApi({
                          no_emr_letter: e.target.checked,
                        });
                      }}
                    >
                      Requested EMR Broker Letter was not provided by the
                      applicant firm.
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="no_report_verified_by_nycirb">
                    <Checkbox
                      checked={appNoRcd?.no_report_verified_by_nycirb}
                      onChange={(e) => {
                        setCheckBox({
                          ...checkBox,
                          no_report_verified_by_nycirb: e.target.checked,
                        });
                        updateApplicationApi({
                          no_report_verified_by_nycirb: e.target.checked,
                        });
                      }}
                    >
                      Information above as reported by entity; could not be
                      verified by NYCIRB.
                    </Checkbox>{" "}
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="OSHA 300a Forms"
              key="3"
              // collapsible={appNoRcd?.no_osha_300a_forms ? "disabled" : ""}
            >
              {/* <div
                style={
                  appNoRcd?.no_osha_300a_forms ? { pointerEvents: "none" } : {}
                }
              > */}
              <Paragraph>
                Indicate the applicant firms lost time and recordable incidence
                rates for the most recent three years:
              </Paragraph>
              {renderApplicantsFirm()}
              <Checkbox
                style={{ paddingTop: "10px" }}
                checked={appNoRcd?.no_osha_300a_forms}
                onChange={(e) => {
                  setCheckBox({
                    ...checkBox,
                    no_osha_300a_forms: e.target.checked,
                  });
                  updateApplicationApi({
                    no_osha_300a_forms: e.target.checked,
                  });
                }}
                // collapsible={appNoRcd?.no_osha_300a_forms ? "disabled" : ""}
              >
                Requested OSHA 300a forms were not provided by the applicant
                firm.
              </Checkbox>
              {/* </div> */}
            </Panel>
          </Collapse>
          {!appNoRcd?.no_osha_records && (
            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="form-submit-btn"
                // loading={disabled}
              >
                SAVE
              </Button>
            </Form.Item>
          )}
        </Form>
        {visible && (
          <OSHAViolationsModel
            create={saveOshaVoilations}
            update={handleUpdateOshaVoilations}
            setVisible={setVisible}
            title="OSHA Violations"
            editTable={editTable}
          />
        )}
      </Spin>
    </Fragment>
  );
};

export default OSHAViolations;
