import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Spin,
  Button,
  Badge,
  Typography,
  Select,
  Tag,
  Form,
  Skeleton,
  message,
} from "antd";
import {
  ApplicationList,
  getApplicationById,
} from "../../services/applications.services";
import { SelectedVendorDetailsContext } from "../../context/SelectedVendorContext";
import { useHistory, useLocation } from "react-router-dom";
import EmptyPQQ from "./EmptyPQQ";
import { useQuery, isAdmin } from "../../helpers/utility";
import CreateApplicationModal from "../Dashboard/ApplicationsList/CreateApplicationModal";
import PQQContent from "./PQQContent";
import "./PQQ.css";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Comments from "./Comments/Comments";
import { applicationStatusValues } from "../../constants/defaultKeys";
import CardWizz from "../../blocks/Card/Card";

const { Text } = Typography;
const { Option } = Select;

const PQQ = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { vendorDetails } = useContext(SelectedVendorDetailsContext);
  const vendorId = vendorDetails?.id;
  const isUserAdmin = isAdmin();

  let query = useQuery();
  const applicationId = query.get("form");

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [listCol, setListCol] = useState(24);
  const [appList, setAppList] = useState([]);

  useEffect(() => {
    if (vendorId !== undefined) {
      ApplicationList(vendorId)
        .then((res) => setAppList(res.data.applications))
        .catch((error) => console.error(error));
    }
  }, [vendorId]);

  useEffect(() => {
    if (vendorId || applicationId) {
      if (applicationId) {
        fetchApplicationById();
      } else {
        fetchApplicationsList();
      }
    }
  }, [applicationId, vendorId, refresh]);

  function fetchApplicationById() {
    // setLoading(true);
    getApplicationById(applicationId)
      .then((response) => {
        setData(response.data.data);
        form.setFieldsValue({
          remarks: response.data.data.remarks,
          status: response.data.data.status,
          information_required: response.data.data.information_required,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("fetch application by Id error", error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("fetch application by Id error");
        }
      });
  }

  function fetchApplicationsList() {
    setLoading(true);
    if (vendorId !== undefined)
      ApplicationList(vendorId)
        .then((response) => {
          const res = response?.data?.applications;
          if (res.length !== 0) {
            setData(res[0]);
            // !pathname.includes("PQQ") &&
            history.push(
              `/vendor/${vendorId}/vendor-profile/${
                vendorDetails?.profile_completion_status !== 100
                  ? "basic-details"
                  : "submit-pqq"
              }?form=${applicationId || res[0].application_id}`
            );
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false);
        });
  }

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const openPQQForm = () => {
    setVisible(true);
  };

  const openComments = () => {
    setListCol(listCol === 24 ? 16 : 24);
  };

  const onChange = (e) => {
    history.push(
      `/vendor/${vendorId}/vendor-profile/${
        vendorDetails?.profile_completion_status != 100
          ? "basic-details"
          : "submit-pqq"
      }?form=${e}`
    );
  };

  const handleTagColor = (data) => {
    let found = applicationStatusValues.find(
      (eachStatus) => eachStatus?.value === data?.status
    );
    if (found.label === "Completed") {
      return "grey";
    } else {
      return found.color;
    }
  };

  return (
    <Fragment>
      <Row type="flex" justify="center">
        {loading ? (
          <Skeleton />
        ) : (
          <>
            {data ? (
              <>
                <Col span={listCol}>
                  {pathname.includes("PQQ") && (
                    <>
                      <ArrowLeftOutlined
                        onClick={() => history.goBack()}
                        style={{ paddingRight: "10px" }}
                      />
                      Go back
                    </>
                  )}
                  <Row align="middle">
                    <Col sm={12} lg={14}>
                      {appList.length > 1 && applicationId ? (
                        <span className="app-change">
                          <Select
                            defaultValue={applicationId}
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                            trigger="click"
                            onChange={onChange}
                            bordered={false}
                          >
                            {appList &&
                              appList.map((item) => (
                                <Option
                                  key={item.application_id}
                                  value={item.application_id}
                                >
                                  {item.application_id}
                                </Option>
                              ))}
                          </Select>
                        </span>
                      ) : (
                        <Text
                          title="PQQ Id"
                          strong
                          style={{ fontSize: "16px", marginRight: "20px" }}
                        >
                          {data?.application_id}
                        </Text>
                      )}
                    </Col>
                    {pathname.includes("PQQ") && (
                      <Col
                        sm={12}
                        lg={10}
                        style={{ textAlign: "right", marginBottom: "10px" }}
                      >
                        <Button
                          size="large"
                          type="primary"
                          onClick={openPQQForm}
                          disabled={
                            !(
                              vendorDetails?.is_subscribed ||
                              vendorDetails?.profile_completion_status == 100
                            )
                          }
                        >
                          Create PQQ
                        </Button>
                        {appList.length > 0 && (
                          <Badge count={data && data.num_unread_comments}>
                            <Button
                              style={{ marginLeft: "20px" }}
                              size="large"
                              type="primary"
                              onClick={openComments}
                            >
                              Comments
                              <ArrowRightOutlined
                                className={
                                  listCol === 24 &&
                                  data?.num_unread_comments > 0 &&
                                  "pqq-comments-arrow-pulse"
                                }
                              />
                            </Button>
                          </Badge>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Row gutter={[0, 32]}>
                    <Col span={24}>
                      <CardWizz
                        title={
                          loading ? (
                            <Skeleton active />
                          ) : (
                            data?.length !== 0 &&
                            data?.customer &&
                            data?.project && (
                              <>
                                <Text title="Customer">{data.customer}</Text>/
                                <Text title="Project">{data.project}</Text>/
                                <Text title="Bid Id">{data.bid_no}</Text>{" "}
                                <Tag
                                  title="PQQ Status"
                                  color={handleTagColor(data)}
                                >
                                  {data.status}
                                </Tag>
                              </>
                            )
                          )
                        }
                      >
                        <PQQContent
                          application={data && data}
                          vendorId={vendorId}
                          isUserAdmin={isUserAdmin}
                          vendorDetails={vendorDetails && vendorDetails}
                          refresh={refreshPage}
                          openPQQForm={openPQQForm}
                        />
                      </CardWizz>
                    </Col>
                  </Row>
                </Col>
                {listCol === 16 && (
                  <>
                    <Col span={7} offset={1}>
                      <div className="pqq-comments">
                        <Comments
                          application={data && data}
                          fetchData={fetchApplicationById}
                        />
                      </div>
                    </Col>
                  </>
                )}
              </>
            ) : (
              <EmptyPQQ vendorDetails={vendorDetails && vendorDetails} />
            )}
          </>
        )}
        {visible && (
          <CreateApplicationModal
            setVisible={setVisible}
            vendorDetails={vendorDetails && vendorDetails}
            vendorId={vendorId}
          />
        )}
      </Row>
    </Fragment>
  );
};

export default PQQ;
