export const DynamicTable = [
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    ellipsis: true,
    width: 100,
  },
  {
    title: "County",
    dataIndex: "county",
    key: "county",
    ellipsis: true,
    width: 100,
  },
  {
    title: "Property",
    dataIndex: "property",
    key: "property",
    ellipsis: true,
    width: 100,
  },
  {
    title: "Filing No",
    dataIndex: "filing_no",
    key: "filing_no",
    ellipsis: true,
    width: 100,
  },
  {
    title: "Filing Date",
    dataIndex: "filing_date",
    key: "filing_date",
    ellipsis: true,
    width: 100,
  },
  {
    title: "Release Date",
    dataIndex: "release_date",
    key: "release_date",
    ellipsis: true,
    width: 100,
  },
];
