import React from "react";
import { Radio } from "antd";

const RadioButton = () => {
  return (
    <Radio.Group>
      <Radio value={true}>Yes</Radio>
      <Radio value={false}>No</Radio>
    </Radio.Group>
  );
};
export default RadioButton;
