export const narrowLabelLayout = {
  labelCol: {
    span: 8,
    xxl: 8,
    xl: 9,
    lg: 10,
    md: 16,
  },
  wrapperCol: {
    span: 10,
    xxl: 10,
    xl: 12,
    lg: 13,
    md: 7,
  },
};

export const midLabelLayout = {
  labelCol: {
    span: 14,
    xxl: 10,
    xl: 12,
    lg: 14,
    md: 14,
    offset: 1,
  },
  wrapperCol: {
    span: 6,
    xxl: 6,
    xl: 6,
    lg: 8,
    md: 10,
  },
};

export const wideLabelLayout = {
  labelCol: {
    span: 16,
    xxl: 16,
    xl: 16,
    lg: 14,
    md: 14,
    offset: 1,
  },
  wrapperCol: {
    span: 4,
    xxl: 4,
    xl: 4,
    lg: 6,
    md: 8,
  },
};

export const fullRowLayout = {
  labelCol: {
    span: 22,
    offset: 1,
  },
  wrapperCol: {
    span: 22,
    offset: 1,
  },
};

export const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 8,
  },
};
