import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Button, Table, Space, message } from "antd";
import { confirmModal } from "../../../../../reusable/ModalWizz";
import UCCFilingsModel from "./UCCFilingsModel";
import {
  getUccFilings,
  deleteUccFilings,
} from "../../../../../services/adminForm.services";
import { useQuery } from "../../../../../helpers/utility";

const UCCFilings = ({ disable }) => {
  const query = useQuery();
  const applicationId = query.get("id");
  const [visible, setVisible] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  useEffect(() => {
    setTableLoading(true);
    getUccFilings(applicationId)
      .then((res) => {
        setTableData(res.data);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  }, [refresh]);
  const columns = [
    {
      title: "Debtor",
      dataIndex: "debtor",
      key: "debtor",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Secured Party",
      dataIndex: "secured_party",
      key: "secured_party",
      ellipsis: true,
      width: 100,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Collateral",
      dataIndex: "collateral",
      key: "collateral",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Filing No",
      dataIndex: "filing_no",
      key: "filing_no",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Filing Date",
      dataIndex: "filing_date",
      key: "filing_date",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (record) => (
        <Space size="middle">
          <span
            className="footer-links"
            onClick={() => openModal({ formData: record, status: "update" })}
          >
            Edit
          </span>
          <span
            className="footer-links"
            onClick={() => {
              confirmModal({
                title: `Are you sure to delete this record?`,
                onOk: () => removeColumn(record.id),
              });
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  const openModal = (data) => {
    setVisible(true);
    setEditTable(data);
  };

  const removeColumn = (id) => {
    deleteUccFilings(id)
      .then((res) => {
        message.success("Deleted");
        refreshPage();
      })
      .catch((err) => {
        message.error("Failed to remove UCC Filings");
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Row style={disable ? { pointerEvents: "none" } : {}}>
        <Col>
          <Button
            onClick={() => openModal({ status: "create" })}
            type="secondary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Details
          </Button>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            columns={columns}
            pagination={false}
            loading={tableLoading}
            dataSource={tableData}
            scroll={{ x: 800 }}
          />
        </Col>
      </Row>
      {visible && (
        <UCCFilingsModel
          setVisible={setVisible}
          title="UCC Filing"
          editTable={editTable}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
};

export default UCCFilings;
