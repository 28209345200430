import React, { useEffect } from "react";
import { Button, Collapse, message, Input, Form } from "antd";
import { useQuery } from "../../../../helpers/utility";
import {
  midLabelLayout,
  tailLayout,
} from "../../../../config/formLayout.config";
import {
  createDetails,
  getDetails,
} from "../../../../services/adminForm.services";

const { Panel } = Collapse;

const OtherViolations = () => {
  const [form] = Form.useForm();

  const query = useQuery();
  const applicationId = query.get("id");

  useEffect(() => {
    fetchDetails();
  }, []);

  function fetchDetails(id = applicationId) {
    getDetails(id)
      .then((res) => {
        let data = res.data[0];
        form.setFieldsValue(data);
      })
      .catch((error) => {
        console.error("get other violations error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "get OSHA violations error"
          );
        } else {
          message.error("get other violations error");
        }
      });
  }

  function onFinish(values) {
    let data = values;
    data.application = applicationId;

    createDetails(data)
      .then((res) => {
        message.success("Successfully saved other violations and misconduct");
      })
      .catch((error) => {
        console.error("create other violations and misconduct error", error);
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "create other violations and misconduct error"
          );
        } else {
          message.error("create other violations and misconduct error");
        }
      });
  }

  return (
    <Form
      name="Other Violations and Misconduct"
      form={form}
      className="form-other-violations-and-misconduct"
      onFinish={onFinish}
      colon={false}
      scrollToFirstError
      {...midLabelLayout}
      layout="vertical"
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Other Violations and Misconduct" key="1">
          <Form.Item
            name="fce_list"
            label="Federal Contractor Exclusion List (Optional)"
          >
            <Input placeholder="Federal Contractor Exclusion List" />
          </Form.Item>
          <Form.Item
            name="fcm_db"
            label="Federal Contractor Misconduct Database (Optional)"
          >
            <Input placeholder="Federal Contractor Misconduct Database" />
          </Form.Item>
          <Form.Item
            name="nys_dol_dl"
            label="NYS Department of Labor Debarment List (Optional)"
          >
            <Input placeholder="NYS Department of Labor Debarment List " />
          </Form.Item>
          <Form.Item
            name="nys_wcbd_list"
            label="NYS Workers Compensation Board Debarment List (Optional)"
          >
            <Input placeholder="NYS Workers Compensation Board Debarment List" />
          </Form.Item>
          <Form.Item
            name="nys_dsif"
            label="NYCSCA Disqualified/Suspended/Ineligible Firms (Optional)"
          >
            <Input placeholder="NYCSCA Disqualified/Suspended/Ineligible Firms" />
          </Form.Item>
          <Form.Item
            name="facsdn_blocked_list"
            label="Office of Foreign Assets Control Specially Designated Nationals and Blocked Persons List (Optional)"
          >
            <Input placeholder="Office of Foreign Assets Control Specially Designated Nationals and Blocked Persons List" />
          </Form.Item>
          <Form.Item name="pep" label="Politically Exposed Persons (Optional)">
            <Input placeholder="Politically Exposed Persons" />
          </Form.Item>
          <Form.Item name="negative_media" label="Negative Media (Optional)">
            <Input placeholder="Negative Media" />
          </Form.Item>
        </Panel>
      </Collapse>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="form-submit-btn"
          // loading={disabled}
        >
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OtherViolations;
