import React, { Fragment, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Login from "../components/Login/Login";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import SetPassword from "../components/SetPassword/SetPassword";
import SetupProfile from "../components/SetupProfile/SetupProfile";
import Mfa from "../components/Mfa/Mfa";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import DefaultLayout from "../layout/DefaultLayout/DefaultLayout";
import Logout from "../components/Logout/Logout";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import Dashboard from "../components/Dashboard/Dashboard";
import { SelectedVendorDetailsContext } from "../context/SelectedVendorContext";
import { VendorProfileFormDataContext } from "../context/VendorProfileContext";
import MyProfile from "../components/MyProfile/MyProfile";
import Verification from "../components/Verification/Verification";
import PQQ from "../components/PQQ/PQQ";
import Organization from "../components/Organization/Organization";
import GetCerfied from "../components/Organization/VendorProfile/GetCertified/GetCertified";
import AdminDashboard from "../components/Admin/AdminDashboard/AdminDashboard";
import Users from "../components/Admin/Users/Users";
import AdminForm from "../components/Admin/AdminForm/AdminForm";
import AdminViewVendorProfile from "../components/Admin/VendorProfile/AdminViewVendorProfile";
import AllSubscriptions from "../components/Admin/Subscriptions/Subscriptions";
import { defaultFormValues } from "../constants/defaultKeys";
import Instructions from "../components/Instructions/Instructions";
import EmptyVendors from "../components/Dashboard/EmptyVendors";
import AdminPQQ from "../components/Admin/AdminPQQ/AdminPQQ";
import AdminVendors from "../components/Admin/AdminVendors/AdminVendors";
import VerifyEmail from "../components/VerifyEmail/VerifyEmail";

const Routes = () => {
  const [vendorDetails, setVendorDetails] = useState(null);
  const [vendorProfileFormData, setVendorProfileFormData] =
    useState(defaultFormValues);

  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <PublicRoutes exact restricted={false} path="/" component={Login} />
          <PublicRoutes
            exact
            restricted={false}
            path="/forgot-password"
            component={ForgotPassword}
          />

          <PublicRoutes
            exact
            restricted={false}
            path="/setpassword/:token"
            component={SetPassword}
          />

          <PublicRoutes
            exact
            restricted={false}
            path="/logout"
            component={Logout}
          />
          <PublicRoutes
            exact
            path="/setprofile/:token"
            component={SetupProfile}
          />
          <PrivateRoutes exact path="/verification" component={Verification} />

          <PublicRoutes
            exact
            path="/verify-email/:token/"
            component={VerifyEmail}
          />
          <PublicRoutes
            exact
            restricted={false}
            path="/privacy-policy"
            component={PrivacyPolicy}
          />
          <PublicRoutes exact path="/mfa" component={Mfa} />
          <PublicRoutes exact path="/instructions" component={Instructions} />
          <SelectedVendorDetailsContext.Provider
            value={{ vendorDetails, setVendorDetails }}
          >
            {/* 'Set Context' is async in behaviour so moved 'VendorProfileFormDataContext' above 'DefaultLayout' to set context in 'Toolbar'  */}
            <VendorProfileFormDataContext.Provider
              value={{ vendorProfileFormData, setVendorProfileFormData }}
            >
              <DefaultLayout>
                <PrivateRoutes
                  exact
                  path="/vendor/:vendor_id/dashboard"
                  component={Dashboard}
                />
                <PrivateRoutes
                  exact
                  path="/vendor/:vendor_id/PQQ"
                  component={PQQ}
                />
                <PrivateRoutes
                  exact
                  path="/vendor/:vendor_id/vendor-profile/:step"
                  component={Organization}
                />
                <PrivateRoutes
                  exact
                  path="/vendor/:vendor_id/get-certified"
                  component={GetCerfied}
                />
                <PrivateRoutes
                  exact
                  path="/admin/pqq/:application_id/vendor/:vendor_id/view-profile/:step"
                  component={AdminViewVendorProfile}
                />
                <PrivateRoutes exact path="/myprofile" component={MyProfile} />
                <PrivateRoutes
                  exact
                  path="/improperly-configured"
                  component={EmptyVendors}
                />
                <PrivateRoutes
                  exact
                  path="/admin/dashboard"
                  component={AdminDashboard}
                />
                <PrivateRoutes exact path="/admin/pqq" component={AdminPQQ} />
                <PrivateRoutes exact path="/admin/users" component={Users} />
                <PrivateRoutes
                  exact
                  path="/admin/pqq/generate-report"
                  component={AdminForm}
                />
                <PrivateRoutes
                  exact
                  path="/admin/subscriptions"
                  component={AllSubscriptions}
                />
                <PrivateRoutes
                  exact
                  path="/admin/vendors"
                  component={AdminVendors}
                />
              </DefaultLayout>
            </VendorProfileFormDataContext.Provider>
          </SelectedVendorDetailsContext.Provider>
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default Routes;
