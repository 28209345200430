import { Col, Row, Typography } from "antd";

const { Title, Paragraph } = Typography;
const RequiredDocumnet = () => {
  return (
    <Row type="flex">
      <Col span={20} offset={1}>
        <Title level={3}>
          The following documentation is required to complete the i4 Vendor
          Profile and Submit a Pre-Qualification Questionnaire (“PQQ”):{" "}
        </Title>
        <Paragraph>
          Applicant Firm’s (or Parent Firm’s) most recent Audited Financial
          Statement
        </Paragraph>
        <Paragraph>Entity Organization Chart</Paragraph>

        <Paragraph>
          Contracts in process and projects under contract but not yet started,
          including:
          <ol>
            <li>Name of owner</li>
            <li>Architect</li>
            <li>Construction Manager and General Contractor (as relevant)</li>
            <li>Value of contract</li>
            <li>Percent complete and expected completion date</li>
          </ol>
        </Paragraph>
        <Paragraph>Code of conductor compliance program manual</Paragraph>
        <Paragraph>
          Sample insurance certificate indicating current coverage carriers and
          limits for the following:
          <ol>
            <li>General liability</li>
            <li>Workers' compensation</li>
            <li>Auto liability</li>
            <li>Excess liability and professional liability</li>
          </ol>
        </Paragraph>
        <Paragraph>
          Broker letter indicating the applicant firm's EMR for the most recent
          three years
        </Paragraph>
        <Paragraph>
          Applicant firm's OSHA 300a forms for the most recent three years
        </Paragraph>
        <Paragraph>
          Resume for the above-identified individual (Safety Director)
        </Paragraph>
        <Paragraph>Safety policy and program</Paragraph>
        <Paragraph>Return to work policy and program</Paragraph>
        <Paragraph>Drug testing policy</Paragraph>
      </Col>
    </Row>
  );
};
export default RequiredDocumnet;
