import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker, message, InputNumber } from "antd";
import DrawerWizz from "../../../../reusable/DrawerWizz";
import {
  createNCRecords,
  updateNCRecords,
} from "../../../../services/adminForm.services";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const DynamicModel = ({
  editTable,
  title,
  setVisible,
  applicationId,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable?.formData)
      form.setFieldsValue({
        ...editTable?.formData,
        filing_date: moment(editTable.formData.filing_date),
        release_date: moment(editTable.formData.release_date),
      });
  }, [editTable]);

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    let data = { ...values, application: applicationId, ...editTable.refData };
    data.release_date = moment(values.release_date).format(dateFormat);
    data.filing_date = moment(values.filing_date).format(dateFormat);

    let api =
      editTable?.status === "create"
        ? createNCRecords(data)
        : updateNCRecords(editTable?.formData?.id, data);
    api
      .then((response) => {
        setVisible(false);
        message.success(response.data.message || "Saved NC Records");
        refresh();
      })
      .catch((error) => {
        if (error && error.response) {
          message.error(
            error.response.data.message ||
              error.response.message ||
              "Something Went Wrong"
          );
        } else {
          message.error("something went wrong, please try after sometime");
        }
      });
  };

  return (
    <DrawerWizz
      title={title}
      handleCancel={handleCancel}
      content={
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please input amount!",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="county"
            label="County"
            rules={[
              {
                required: true,
                message: "Please input county!",
              },
            ]}
          >
            <Input placeholder="County" />
          </Form.Item>
          <Form.Item
            name="property"
            label="Property"
            rules={[
              {
                required: true,
                message: "Please input property!",
              },
            ]}
          >
            <Input placeholder="Property" />
          </Form.Item>
          <Form.Item
            name="filing_no"
            label="Filing No"
            rules={[
              {
                required: true,
                message: "Please input filing no!",
              },
            ]}
          >
            <Input placeholder="Filing No" />
          </Form.Item>
          <Form.Item
            name="filing_date"
            label="Filing Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>
          <Form.Item
            name="release_date"
            label="Release Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              placeholder="Date"
              size="large"
              style={{ minWidth: "280px" }}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};
export default DynamicModel;
