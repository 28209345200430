import { Drawer } from "antd";

const DrawerWizz = ({ title, handleCancel, content }) => {
  return (
    <Drawer
      title={title}
      visible={true}
      width={650}
      footer={null}
      maskClosable={false}
      onClose={handleCancel}
    >
      {content}
    </Drawer>
  );
};

export default DrawerWizz;
