import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, DatePicker, message, InputNumber } from "antd";
import {
  createContracts,
  updateContracts,
} from "../../../../../services/vendorProfile.services";
import moment from "moment";
import DrawerWizz from "../../../../../reusable/DrawerWizz";
import { maxNumValidation } from "../../../../../config/validation.config";

const ContractsInProgressModal = ({
  title,
  setVisible,
  vendorId,
  editTable,
  refresh,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editTable.status === "update") {
      setFormFieldValues(editTable);
    }
  }, []);

  function setFormFieldValues(data) {
    form.setFieldsValue({
      ...data,
      expected_completion_date: moment(data.expected_completion_date),
    });
  }

  const handleCancel = () => {
    setVisible(false);
    refresh();
  };

  const onFinish = (values) => {
    if (editTable.status === "update") {
      const formData = {
        ...values,
        vendor_id: vendorId,
        id: editTable.id,
        expected_completion_date: moment(
          values.expected_completion_date
        ).format("YYYY-MM-DD"),
      };

      updateContracts(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      const formData = {
        ...values,
        vendor_id: vendorId,
        expected_completion_date: moment(
          values.expected_completion_date
        ).format("YYYY-MM-DD"),
      };

      createContracts(formData)
        .then((response) => {
          message.success(response.data.message);
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Fragment>
      <DrawerWizz
        title={title}
        handleCancel={handleCancel}
        content={
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            <Form.Item
              name="owner_name"
              label="Owner Name"
              rules={[
                {
                  required: true,
                  message: "Please input  owner name!",
                },
              ]}
            >
              <Input placeholder="Owner Name" />
            </Form.Item>
            <Form.Item
              name="construction_manager"
              label="Construction Manager"
              rules={[
                {
                  required: true,
                  message: "Please input your  construction manager!",
                },
              ]}
            >
              <Input placeholder="Construction Manager" />
            </Form.Item>
            <Form.Item
              name="value_of_contract"
              label="Value of Contract"
              rules={[
                {
                  required: true,
                  message: "Please input your  value of contract!",
                },
              ]}
            >
              <InputNumber
                style={{ minWidth: "200px" }}
                placeholder="Value of Contract"
                min={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
            <Form.Item
              name="percent_complete"
              label="Percent Complete"
              rules={maxNumValidation}
            >
              <InputNumber
                style={{ minWidth: "200px" }}
                placeholder="Percent Completes"
              />
            </Form.Item>
            <Form.Item
              name="expected_completion_date"
              label="Expected Completion Date"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                placeholder="Date"
                size="large"
                style={{ minWidth: "200px" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default ContractsInProgressModal;
