import React, { useState, useEffect } from "react";
import { Steps, Row, Col, Button } from "antd";
import "./VendorProfile.css";
import { PROFILE_STEPS } from "./ProfileSteps";
import ProfileCompleted from "./ProfileCompleted";
import { useHistory, useParams } from "react-router";
import { isAdmin } from "../../../helpers/utility";

const VendorProfile = ({
  vendorId,
  vendorDetails,
  showProfileCompletedForm,
}) => {
  const query = useParams();
  const history = useHistory();
  const isUserAdmin = isAdmin();
  const steps = PROFILE_STEPS;
  const [currentStep, setCurrentStep] = useState(
    !isUserAdmin && showProfileCompletedForm ? PROFILE_STEPS.length - 1 : 0
  );

  // useEffect(() => {
  //   if (!isUserAdmin && showProfileCompletedForm && vendorId) {
  //     history.push(`/vendor/${vendorId}/vendor-profile/profile-completed`);
  //   }
  // }, [vendorId, showProfileCompletedForm]);

  useEffect(() => {
    let stepIndex =
      PROFILE_STEPS.findIndex(
        (eachStep) => eachStep.step_name === query?.step
      ) || 0;
    setCurrentStep(stepIndex);
  }, [query]);

  function getStepStatus(stepId, stepIndex) {
    let stepStatus = "wait";
    if (vendorDetails) {
      if (stepIndex === currentStep) {
        return "process";
      }
      if (vendorDetails[stepId]) {
        stepStatus = "finish";
      } else {
        stepStatus = "wait";
      }
    }
    return stepStatus;
  }

  function onStepChange(current) {
    setCurrentStep(current);
    if (isUserAdmin) {
      history.push(
        `/admin/pqq/${query.application_id}/vendor/${vendorDetails?.id}/view-profile/${PROFILE_STEPS[current].step_name}`
      );
    } else {
      history.push(
        `/vendor/${vendorId}/vendor-profile/${PROFILE_STEPS[current].step_name}`
      );
    }
  }

  return (
    <Row type="flex" className="steps-container" justify="start">
      <Col span={4}>
        <Steps
          direction="vertical"
          current={currentStep}
          onChange={onStepChange}
        >
          {steps.map((element, index) => {
            if (element.step_name !== "profile-completed") {
              return (
                <Steps.Step
                  key={index}
                  title={element.title}
                  status={getStepStatus(element.stepId, index)}
                  disable={element.disable}
                />
              );
            }
          })}
        </Steps>
      </Col>
      <Col span={20} style={isUserAdmin ? { cursor: "not-allowed" } : {}}>
        {!isUserAdmin &&
        showProfileCompletedForm &&
        query?.step === "profile-completed" ? (
          <ProfileCompleted
            status="success"
            title="Thanks for completing your i4Profile!"
            subTitle={
              !vendorDetails?.is_subscribed && (
                <span>
                  Vendors must provide payment to i4 Strategies in the amount of
                  $1250. This will cover the initial Pre-Qualification process.
                </span>
              )
            }
            extra={[
              !vendorDetails?.is_subscribed && (
                <Button
                  type="primary"
                  key="console"
                  onClick={() =>
                    history.push(`/vendor/${vendorId}/get-certified`)
                  }
                >
                  Pay Now
                </Button>
              ),
            ]}
          />
        ) : (
          <div
            style={
              isUserAdmin
                ? {
                    pointerEvents: "none",
                  }
                : {}
            }
          >
            {React.createElement(steps?.[currentStep]?.component, {
              currentStep,
              onStepChange,
              vendorId,
              isUserAdmin,
            })}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default VendorProfile;
