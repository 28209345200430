import axiosInstance from "../config/axios/axios.config";

export const getComments = (applicationId) => {
  return axiosInstance.get(`/comments/get-comments/${applicationId}/`);
};

export const createComment = (applicationId, payLoad) => {
  return axiosInstance.post(`/comments/add-comment/${applicationId}/`, payLoad);
};

export const createReply = (commentId, payLoad) => {
  return axiosInstance.post(`/comments/add-reply/${commentId}/`, payLoad);
};

export const updateComment = (commentId, commentType, payLoad) => {
  return axiosInstance.put(
    `/comments/edit-comment/${commentId}/?comment_type=${commentType}`,
    payLoad
  );
};

export const deleteComment = (commentId, commentType) => {
  return axiosInstance.delete(
    `/comments/delete-comment/${commentId}/?comment_type=${commentType}`
  );
};

export const updateUnreadCommentsStatus = (payload) => {
  return axiosInstance.put(`/comments/update-comments/`, payload);
};
